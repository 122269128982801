/* tslint:disable */
/* eslint-disable */
/**
 * Ticketing Portal
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface PayoutsReadResponsePayoutsInner
 */
export interface PayoutsReadResponsePayoutsInner {
  /**
   * Payout ID
   * @type {string}
   * @memberof PayoutsReadResponsePayoutsInner
   */
  id: string;
  /**
   * Amount paid out for the period
   * @type {number}
   * @memberof PayoutsReadResponsePayoutsInner
   */
  payout_amount: number;
  /**
   * Deposit amount retained for the period
   * @type {number}
   * @memberof PayoutsReadResponsePayoutsInner
   */
  risk_deposit_amount: number;
  /**
   * Currency of money amounts
   * @type {string}
   * @memberof PayoutsReadResponsePayoutsInner
   */
  currency: string;
  /**
   * Start date of the current period in ISO 8601 date format
   * @type {Date}
   * @memberof PayoutsReadResponsePayoutsInner
   */
  start_date: Date;
  /**
   * End date of the current period in ISO 8601 date format
   * @type {Date}
   * @memberof PayoutsReadResponsePayoutsInner
   */
  end_date: Date;
  /**
   * Total revenue transacted in the period by this partner
   * @type {number}
   * @memberof PayoutsReadResponsePayoutsInner
   */
  total_transacted_revenue_amount: number;
  /**
   * Amount of refunds for the period
   * @type {number}
   * @memberof PayoutsReadResponsePayoutsInner
   */
  returns_amount: number;
  /**
   * Status of the payout
   * @type {string}
   * @memberof PayoutsReadResponsePayoutsInner
   */
  status: string;
}

/**
 * Check if a given object implements the PayoutsReadResponsePayoutsInner interface.
 */
export function instanceOfPayoutsReadResponsePayoutsInner(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && 'id' in value;
  isInstance = isInstance && 'payout_amount' in value;
  isInstance = isInstance && 'risk_deposit_amount' in value;
  isInstance = isInstance && 'currency' in value;
  isInstance = isInstance && 'start_date' in value;
  isInstance = isInstance && 'end_date' in value;
  isInstance = isInstance && 'total_transacted_revenue_amount' in value;
  isInstance = isInstance && 'returns_amount' in value;
  isInstance = isInstance && 'status' in value;

  return isInstance;
}

export function PayoutsReadResponsePayoutsInnerFromJSON(
  json: any
): PayoutsReadResponsePayoutsInner {
  return PayoutsReadResponsePayoutsInnerFromJSONTyped(json, false);
}

export function PayoutsReadResponsePayoutsInnerFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): PayoutsReadResponsePayoutsInner {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: json['id'],
    payout_amount: json['payout_amount'],
    risk_deposit_amount: json['risk_deposit_amount'],
    currency: json['currency'],
    start_date: new Date(json['start_date']),
    end_date: new Date(json['end_date']),
    total_transacted_revenue_amount: json['total_transacted_revenue_amount'],
    returns_amount: json['returns_amount'],
    status: json['status']
  };
}

export function PayoutsReadResponsePayoutsInnerToJSON(
  value?: PayoutsReadResponsePayoutsInner | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    id: value.id,
    payout_amount: value.payout_amount,
    risk_deposit_amount: value.risk_deposit_amount,
    currency: value.currency,
    start_date: value.start_date.toISOString(),
    end_date: value.end_date.toISOString(),
    total_transacted_revenue_amount: value.total_transacted_revenue_amount,
    returns_amount: value.returns_amount,
    status: value.status
  };
}
