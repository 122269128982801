/* tslint:disable */
/* eslint-disable */
/**
 * Ticketing Portal
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from '../runtime';
import type {
  InitializationReadResponse,
  RegisterCreateRequest,
  RegisterCreateResponse,
  RegisterReadResponse,
  ResetPasswordUpdateRequest,
  ResetPasswordUpdateResponse,
  RolesReadResponse,
  RolesReadResponse1,
  RolesUpdateRequest,
  RolesUpdateResponse,
  TokenValidityCheckRequest,
  UserInviteAcceptRequest,
  UserSettingsUpdateRequest,
  UserSettingsUpdateResponse
} from '../models';
import {
  InitializationReadResponseFromJSON,
  InitializationReadResponseToJSON,
  RegisterCreateRequestFromJSON,
  RegisterCreateRequestToJSON,
  RegisterCreateResponseFromJSON,
  RegisterCreateResponseToJSON,
  RegisterReadResponseFromJSON,
  RegisterReadResponseToJSON,
  ResetPasswordUpdateRequestFromJSON,
  ResetPasswordUpdateRequestToJSON,
  ResetPasswordUpdateResponseFromJSON,
  ResetPasswordUpdateResponseToJSON,
  RolesReadResponseFromJSON,
  RolesReadResponseToJSON,
  RolesReadResponse1FromJSON,
  RolesReadResponse1ToJSON,
  RolesUpdateRequestFromJSON,
  RolesUpdateRequestToJSON,
  RolesUpdateResponseFromJSON,
  RolesUpdateResponseToJSON,
  TokenValidityCheckRequestFromJSON,
  TokenValidityCheckRequestToJSON,
  UserInviteAcceptRequestFromJSON,
  UserInviteAcceptRequestToJSON,
  UserSettingsUpdateRequestFromJSON,
  UserSettingsUpdateRequestToJSON,
  UserSettingsUpdateResponseFromJSON,
  UserSettingsUpdateResponseToJSON
} from '../models';

export interface AuthInitializationGetRequest {
  localeCode: string;
}

export interface AuthRegisterPostRequest {
  registerCreateRequest?: RegisterCreateRequest;
}

export interface AuthResetPasswordPostRequest {
  resetPasswordUpdateRequest?: ResetPasswordUpdateRequest;
}

export interface AuthRolesGetRequest {
  localeCode: string;
}

export interface AuthUpdateRolePostRequest {
  rolesUpdateRequest?: RolesUpdateRequest;
}

export interface AuthUserInviteAcceptPostRequest {
  userInviteAcceptRequest?: UserInviteAcceptRequest;
}

export interface AuthUserSettingsPostRequest {
  userSettingsUpdateRequest?: UserSettingsUpdateRequest;
}

export interface AuthUserTokenValidityPostRequest {
  tokenValidityCheckRequest?: TokenValidityCheckRequest;
}

/**
 *
 */
export class AuthApi extends runtime.BaseAPI {
  /**
   * Check role for GYG employees
   */
  async authCheckRoleGetRaw(
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<RolesReadResponse>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request(
      {
        path: `/auth/check-role`,
        method: 'GET',
        headers: headerParameters,
        query: queryParameters
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      RolesReadResponseFromJSON(jsonValue)
    );
  }

  /**
   * Check role for GYG employees
   */
  async authCheckRoleGet(
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<RolesReadResponse> {
    const response = await this.authCheckRoleGetRaw(initOverrides);
    return await response.value();
  }

  /**
   * Initialization endpoint to return user, partner and locales
   */
  async authInitializationGetRaw(
    requestParameters: AuthInitializationGetRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<InitializationReadResponse>> {
    if (requestParameters.localeCode === null || requestParameters.localeCode === undefined) {
      throw new runtime.RequiredError(
        'localeCode',
        'Required parameter requestParameters.localeCode was null or undefined when calling authInitializationGet.'
      );
    }

    const queryParameters: any = {};

    if (requestParameters.localeCode !== undefined) {
      queryParameters['locale_code'] = requestParameters.localeCode;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request(
      {
        path: `/auth/initialization`,
        method: 'GET',
        headers: headerParameters,
        query: queryParameters
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      InitializationReadResponseFromJSON(jsonValue)
    );
  }

  /**
   * Initialization endpoint to return user, partner and locales
   */
  async authInitializationGet(
    requestParameters: AuthInitializationGetRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<InitializationReadResponse> {
    const response = await this.authInitializationGetRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Get register page information
   */
  async authRegisterGetRaw(
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<RegisterReadResponse>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request(
      {
        path: `/auth/register`,
        method: 'GET',
        headers: headerParameters,
        query: queryParameters
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      RegisterReadResponseFromJSON(jsonValue)
    );
  }

  /**
   * Get register page information
   */
  async authRegisterGet(
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<RegisterReadResponse> {
    const response = await this.authRegisterGetRaw(initOverrides);
    return await response.value();
  }

  /**
   * Create a new user, partner and other sensible defaults
   */
  async authRegisterPostRaw(
    requestParameters: AuthRegisterPostRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<RegisterCreateResponse>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    const response = await this.request(
      {
        path: `/auth/register`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: RegisterCreateRequestToJSON(requestParameters.registerCreateRequest)
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      RegisterCreateResponseFromJSON(jsonValue)
    );
  }

  /**
   * Create a new user, partner and other sensible defaults
   */
  async authRegisterPost(
    requestParameters: AuthRegisterPostRequest = {},
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<RegisterCreateResponse> {
    const response = await this.authRegisterPostRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Reset password
   */
  async authResetPasswordPostRaw(
    requestParameters: AuthResetPasswordPostRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<ResetPasswordUpdateResponse>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    const response = await this.request(
      {
        path: `/auth/reset-password`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: ResetPasswordUpdateRequestToJSON(requestParameters.resetPasswordUpdateRequest)
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      ResetPasswordUpdateResponseFromJSON(jsonValue)
    );
  }

  /**
   * Reset password
   */
  async authResetPasswordPost(
    requestParameters: AuthResetPasswordPostRequest = {},
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<ResetPasswordUpdateResponse> {
    const response = await this.authResetPasswordPostRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Get roles
   */
  async authRolesGetRaw(
    requestParameters: AuthRolesGetRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<RolesReadResponse1>> {
    if (requestParameters.localeCode === null || requestParameters.localeCode === undefined) {
      throw new runtime.RequiredError(
        'localeCode',
        'Required parameter requestParameters.localeCode was null or undefined when calling authRolesGet.'
      );
    }

    const queryParameters: any = {};

    if (requestParameters.localeCode !== undefined) {
      queryParameters['locale_code'] = requestParameters.localeCode;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request(
      {
        path: `/auth/roles`,
        method: 'GET',
        headers: headerParameters,
        query: queryParameters
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      RolesReadResponse1FromJSON(jsonValue)
    );
  }

  /**
   * Get roles
   */
  async authRolesGet(
    requestParameters: AuthRolesGetRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<RolesReadResponse1> {
    const response = await this.authRolesGetRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Update role for GYG employees
   */
  async authUpdateRolePostRaw(
    requestParameters: AuthUpdateRolePostRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<RolesUpdateResponse>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    const response = await this.request(
      {
        path: `/auth/update-role`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: RolesUpdateRequestToJSON(requestParameters.rolesUpdateRequest)
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      RolesUpdateResponseFromJSON(jsonValue)
    );
  }

  /**
   * Update role for GYG employees
   */
  async authUpdateRolePost(
    requestParameters: AuthUpdateRolePostRequest = {},
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<RolesUpdateResponse> {
    const response = await this.authUpdateRolePostRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Accept user invitation
   */
  async authUserInviteAcceptPostRaw(
    requestParameters: AuthUserInviteAcceptPostRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<void>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    const response = await this.request(
      {
        path: `/auth/user/invite/accept`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: UserInviteAcceptRequestToJSON(requestParameters.userInviteAcceptRequest)
      },
      initOverrides
    );

    return new runtime.VoidApiResponse(response);
  }

  /**
   * Accept user invitation
   */
  async authUserInviteAcceptPost(
    requestParameters: AuthUserInviteAcceptPostRequest = {},
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<void> {
    await this.authUserInviteAcceptPostRaw(requestParameters, initOverrides);
  }

  /**
   * Update user settings
   */
  async authUserSettingsPostRaw(
    requestParameters: AuthUserSettingsPostRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<UserSettingsUpdateResponse>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    const response = await this.request(
      {
        path: `/auth/user-settings`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: UserSettingsUpdateRequestToJSON(requestParameters.userSettingsUpdateRequest)
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      UserSettingsUpdateResponseFromJSON(jsonValue)
    );
  }

  /**
   * Update user settings
   */
  async authUserSettingsPost(
    requestParameters: AuthUserSettingsPostRequest = {},
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<UserSettingsUpdateResponse> {
    const response = await this.authUserSettingsPostRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Check Token Validity
   */
  async authUserTokenValidityPostRaw(
    requestParameters: AuthUserTokenValidityPostRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<void>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    const response = await this.request(
      {
        path: `/auth/user/token/validity`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: TokenValidityCheckRequestToJSON(requestParameters.tokenValidityCheckRequest)
      },
      initOverrides
    );

    return new runtime.VoidApiResponse(response);
  }

  /**
   * Check Token Validity
   */
  async authUserTokenValidityPost(
    requestParameters: AuthUserTokenValidityPostRequest = {},
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<void> {
    await this.authUserTokenValidityPostRaw(requestParameters, initOverrides);
  }
}
