/* tslint:disable */
/* eslint-disable */
/**
 * Ticketing Portal
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { PromoCodeByIdReadResponsePromoCode } from './PromoCodeByIdReadResponsePromoCode';
import {
  PromoCodeByIdReadResponsePromoCodeFromJSON,
  PromoCodeByIdReadResponsePromoCodeFromJSONTyped,
  PromoCodeByIdReadResponsePromoCodeToJSON
} from './PromoCodeByIdReadResponsePromoCode';

/**
 *
 * @export
 * @interface PromoCodeByIdReadResponse
 */
export interface PromoCodeByIdReadResponse {
  /**
   *
   * @type {boolean}
   * @memberof PromoCodeByIdReadResponse
   */
  success: boolean;
  /**
   *
   * @type {string}
   * @memberof PromoCodeByIdReadResponse
   */
  exception_code?: string;
  /**
   *
   * @type {string}
   * @memberof PromoCodeByIdReadResponse
   */
  exception_message?: string;
  /**
   *
   * @type {PromoCodeByIdReadResponsePromoCode}
   * @memberof PromoCodeByIdReadResponse
   */
  promo_code?: PromoCodeByIdReadResponsePromoCode;
}

/**
 * Check if a given object implements the PromoCodeByIdReadResponse interface.
 */
export function instanceOfPromoCodeByIdReadResponse(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && 'success' in value;

  return isInstance;
}

export function PromoCodeByIdReadResponseFromJSON(json: any): PromoCodeByIdReadResponse {
  return PromoCodeByIdReadResponseFromJSONTyped(json, false);
}

export function PromoCodeByIdReadResponseFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): PromoCodeByIdReadResponse {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    success: json['success'],
    exception_code: !exists(json, 'exception_code') ? undefined : json['exception_code'],
    exception_message: !exists(json, 'exception_message') ? undefined : json['exception_message'],
    promo_code: !exists(json, 'promo_code')
      ? undefined
      : PromoCodeByIdReadResponsePromoCodeFromJSON(json['promo_code'])
  };
}

export function PromoCodeByIdReadResponseToJSON(value?: PromoCodeByIdReadResponse | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    success: value.success,
    exception_code: value.exception_code,
    exception_message: value.exception_message,
    promo_code: PromoCodeByIdReadResponsePromoCodeToJSON(value.promo_code)
  };
}
