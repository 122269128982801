// Posthog doesn't currently export their survey type, so we have to copy it here

export enum SurveyType {
  Popover = 'popover',
  Button = 'button',
  FullScreen = 'full_screen',
  Email = 'email',
  API = 'api'
}
export enum SurveyQuestionType {
  Open = 'open',
  MultipleChoice = 'multiple_choice',
  SingleChoice = 'single_choice',
  Rating = 'rating',
  Link = 'link'
}
interface SurveyAppearance {
  backgroundColor?: string;
  submitButtonColor?: string;
  textColor?: string;
  submitButtonText?: string;
  descriptionTextColor?: string;
  ratingButtonColor?: string;
  ratingButtonActiveColor?: string;
  ratingButtonHoverColor?: string;
  whiteLabel?: boolean;
  displayThankYouMessage?: boolean;
  thankYouMessageHeader?: string;
  thankYouMessageDescription?: string;
  borderColor?: string;
  position?: 'left' | 'right' | 'center';
  placeholder?: string;
  maxWidth?: string;
  zIndex?: string;
}
declare type SurveyQuestion =
  | BasicSurveyQuestion
  | LinkSurveyQuestion
  | RatingSurveyQuestion
  | MultipleSurveyQuestion;
interface SurveyQuestionBase {
  question: string;
  description?: string | null;
  optional?: boolean;
}
interface BasicSurveyQuestion extends SurveyQuestionBase {
  type: SurveyQuestionType.Open;
}
interface LinkSurveyQuestion extends SurveyQuestionBase {
  type: SurveyQuestionType.Link;
  link: string | null;
}
interface RatingSurveyQuestion extends SurveyQuestionBase {
  type: SurveyQuestionType.Rating;
  display: 'number' | 'emoji';
  scale: number;
  lowerBoundLabel: string;
  upperBoundLabel: string;
}
interface MultipleSurveyQuestion extends SurveyQuestionBase {
  type: SurveyQuestionType.SingleChoice | SurveyQuestionType.MultipleChoice;
  choices: string[];
}
declare type SurveyUrlMatchType = 'regex' | 'exact' | 'icontains';

export interface Survey {
  id: string;
  name: string;
  description: string;
  type: SurveyType;
  linked_flag_key: string | null;
  targeting_flag_key: string | null;
  questions: SurveyQuestion[];
  appearance: SurveyAppearance | null;
  conditions: {
    url?: string;
    selector?: string;
    seenSurveyWaitPeriodInDays?: number;
    urlMatchType?: SurveyUrlMatchType;
  } | null;
  start_date: string | null;
  end_date: string | null;
}
