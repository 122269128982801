/* tslint:disable */
/* eslint-disable */
/**
 * Ticketing Portal
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { PromoCodeRestrictionsReadResponseMeta } from './PromoCodeRestrictionsReadResponseMeta';
import {
  PromoCodeRestrictionsReadResponseMetaFromJSON,
  PromoCodeRestrictionsReadResponseMetaFromJSONTyped,
  PromoCodeRestrictionsReadResponseMetaToJSON
} from './PromoCodeRestrictionsReadResponseMeta';
import type { PromoCodeRestrictionsReadResponsePromoCodeProductRestrictionsInner } from './PromoCodeRestrictionsReadResponsePromoCodeProductRestrictionsInner';
import {
  PromoCodeRestrictionsReadResponsePromoCodeProductRestrictionsInnerFromJSON,
  PromoCodeRestrictionsReadResponsePromoCodeProductRestrictionsInnerFromJSONTyped,
  PromoCodeRestrictionsReadResponsePromoCodeProductRestrictionsInnerToJSON
} from './PromoCodeRestrictionsReadResponsePromoCodeProductRestrictionsInner';

/**
 *
 * @export
 * @interface PromoCodeRestrictionsReadResponse
 */
export interface PromoCodeRestrictionsReadResponse {
  /**
   *
   * @type {boolean}
   * @memberof PromoCodeRestrictionsReadResponse
   */
  success: boolean;
  /**
   *
   * @type {string}
   * @memberof PromoCodeRestrictionsReadResponse
   */
  exception_code?: string;
  /**
   *
   * @type {string}
   * @memberof PromoCodeRestrictionsReadResponse
   */
  exception_message?: string;
  /**
   *
   * @type {Array<PromoCodeRestrictionsReadResponsePromoCodeProductRestrictionsInner>}
   * @memberof PromoCodeRestrictionsReadResponse
   */
  promo_code_product_restrictions: Array<PromoCodeRestrictionsReadResponsePromoCodeProductRestrictionsInner>;
  /**
   *
   * @type {PromoCodeRestrictionsReadResponseMeta}
   * @memberof PromoCodeRestrictionsReadResponse
   */
  meta: PromoCodeRestrictionsReadResponseMeta;
}

/**
 * Check if a given object implements the PromoCodeRestrictionsReadResponse interface.
 */
export function instanceOfPromoCodeRestrictionsReadResponse(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && 'success' in value;
  isInstance = isInstance && 'promo_code_product_restrictions' in value;
  isInstance = isInstance && 'meta' in value;

  return isInstance;
}

export function PromoCodeRestrictionsReadResponseFromJSON(
  json: any
): PromoCodeRestrictionsReadResponse {
  return PromoCodeRestrictionsReadResponseFromJSONTyped(json, false);
}

export function PromoCodeRestrictionsReadResponseFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): PromoCodeRestrictionsReadResponse {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    success: json['success'],
    exception_code: !exists(json, 'exception_code') ? undefined : json['exception_code'],
    exception_message: !exists(json, 'exception_message') ? undefined : json['exception_message'],
    promo_code_product_restrictions: (json['promo_code_product_restrictions'] as Array<any>).map(
      PromoCodeRestrictionsReadResponsePromoCodeProductRestrictionsInnerFromJSON
    ),
    meta: PromoCodeRestrictionsReadResponseMetaFromJSON(json['meta'])
  };
}

export function PromoCodeRestrictionsReadResponseToJSON(
  value?: PromoCodeRestrictionsReadResponse | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    success: value.success,
    exception_code: value.exception_code,
    exception_message: value.exception_message,
    promo_code_product_restrictions: (value.promo_code_product_restrictions as Array<any>).map(
      PromoCodeRestrictionsReadResponsePromoCodeProductRestrictionsInnerToJSON
    ),
    meta: PromoCodeRestrictionsReadResponseMetaToJSON(value.meta)
  };
}
