import { DIRECTUS_API_URL } from '@/config';

export const getResizedImage = (
  fileId: string,
  width?: number,
  height?: number,
  quality?: number
): string =>
  `${DIRECTUS_API_URL}/assets/${fileId}?fit=cover&width=${width ?? 600}&height=${
    height ?? 300
  }&quality=${quality ?? 80}&format=webp`;
