/* tslint:disable */
/* eslint-disable */
/**
 * Ticketing Portal
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface AllocationsReadResponseAllocationsInnerProductOptionsInner
 */
export interface AllocationsReadResponseAllocationsInnerProductOptionsInner {
  /**
   * the product id
   * @type {string}
   * @memberof AllocationsReadResponseAllocationsInnerProductOptionsInner
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof AllocationsReadResponseAllocationsInnerProductOptionsInner
   */
  product_name: string;
  /**
   *
   * @type {string}
   * @memberof AllocationsReadResponseAllocationsInnerProductOptionsInner
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof AllocationsReadResponseAllocationsInnerProductOptionsInner
   */
  status: AllocationsReadResponseAllocationsInnerProductOptionsInnerStatusEnum;
  /**
   *
   * @type {string}
   * @memberof AllocationsReadResponseAllocationsInnerProductOptionsInner
   */
  availability_restriction_type?: AllocationsReadResponseAllocationsInnerProductOptionsInnerAvailabilityRestrictionTypeEnum;
  /**
   *
   * @type {number}
   * @memberof AllocationsReadResponseAllocationsInnerProductOptionsInner
   */
  availability_restriction_value?: number;
  /**
   *
   * @type {number}
   * @memberof AllocationsReadResponseAllocationsInnerProductOptionsInner
   */
  channel_commission_rate?: number;
}

/**
 * @export
 */
export const AllocationsReadResponseAllocationsInnerProductOptionsInnerStatusEnum = {
  Active: 'active',
  Deleted: 'deleted',
  Inactive: 'inactive',
  Draft: 'draft'
} as const;
export type AllocationsReadResponseAllocationsInnerProductOptionsInnerStatusEnum =
  (typeof AllocationsReadResponseAllocationsInnerProductOptionsInnerStatusEnum)[keyof typeof AllocationsReadResponseAllocationsInnerProductOptionsInnerStatusEnum];

/**
 * @export
 */
export const AllocationsReadResponseAllocationsInnerProductOptionsInnerAvailabilityRestrictionTypeEnum =
  {
    AbsoluteMin: 'absolute_min',
    AbsoluteMax: 'absolute_max',
    PercentageMin: 'percentage_min',
    PercentageMax: 'percentage_max'
  } as const;
export type AllocationsReadResponseAllocationsInnerProductOptionsInnerAvailabilityRestrictionTypeEnum =
  (typeof AllocationsReadResponseAllocationsInnerProductOptionsInnerAvailabilityRestrictionTypeEnum)[keyof typeof AllocationsReadResponseAllocationsInnerProductOptionsInnerAvailabilityRestrictionTypeEnum];

/**
 * Check if a given object implements the AllocationsReadResponseAllocationsInnerProductOptionsInner interface.
 */
export function instanceOfAllocationsReadResponseAllocationsInnerProductOptionsInner(
  value: object
): boolean {
  let isInstance = true;
  isInstance = isInstance && 'id' in value;
  isInstance = isInstance && 'product_name' in value;
  isInstance = isInstance && 'name' in value;
  isInstance = isInstance && 'status' in value;

  return isInstance;
}

export function AllocationsReadResponseAllocationsInnerProductOptionsInnerFromJSON(
  json: any
): AllocationsReadResponseAllocationsInnerProductOptionsInner {
  return AllocationsReadResponseAllocationsInnerProductOptionsInnerFromJSONTyped(json, false);
}

export function AllocationsReadResponseAllocationsInnerProductOptionsInnerFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): AllocationsReadResponseAllocationsInnerProductOptionsInner {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: json['id'],
    product_name: json['product_name'],
    name: json['name'],
    status: json['status'],
    availability_restriction_type: !exists(json, 'availability_restriction_type')
      ? undefined
      : json['availability_restriction_type'],
    availability_restriction_value: !exists(json, 'availability_restriction_value')
      ? undefined
      : json['availability_restriction_value'],
    channel_commission_rate: !exists(json, 'channel_commission_rate')
      ? undefined
      : json['channel_commission_rate']
  };
}

export function AllocationsReadResponseAllocationsInnerProductOptionsInnerToJSON(
  value?: AllocationsReadResponseAllocationsInnerProductOptionsInner | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    id: value.id,
    product_name: value.product_name,
    name: value.name,
    status: value.status,
    availability_restriction_type: value.availability_restriction_type,
    availability_restriction_value: value.availability_restriction_value,
    channel_commission_rate: value.channel_commission_rate
  };
}
