/* tslint:disable */
/* eslint-disable */
/**
 * Ticketing Portal
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ReceiptSettingsPartnerTranslationsInner } from './ReceiptSettingsPartnerTranslationsInner';
import {
  ReceiptSettingsPartnerTranslationsInnerFromJSON,
  ReceiptSettingsPartnerTranslationsInnerFromJSONTyped,
  ReceiptSettingsPartnerTranslationsInnerToJSON
} from './ReceiptSettingsPartnerTranslationsInner';

/**
 *
 * @export
 * @interface ReceiptSettingsReadResponseReceiptSettings
 */
export interface ReceiptSettingsReadResponseReceiptSettings {
  /**
   *
   * @type {string}
   * @memberof ReceiptSettingsReadResponseReceiptSettings
   */
  id: string;
  /**
   *
   * @type {Array<ReceiptSettingsPartnerTranslationsInner>}
   * @memberof ReceiptSettingsReadResponseReceiptSettings
   */
  partner_translation: Array<ReceiptSettingsPartnerTranslationsInner>;
  /**
   *
   * @type {boolean}
   * @memberof ReceiptSettingsReadResponseReceiptSettings
   */
  attach_receipt: boolean;
}

/**
 * Check if a given object implements the ReceiptSettingsReadResponseReceiptSettings interface.
 */
export function instanceOfReceiptSettingsReadResponseReceiptSettings(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && 'id' in value;
  isInstance = isInstance && 'partner_translation' in value;
  isInstance = isInstance && 'attach_receipt' in value;

  return isInstance;
}

export function ReceiptSettingsReadResponseReceiptSettingsFromJSON(
  json: any
): ReceiptSettingsReadResponseReceiptSettings {
  return ReceiptSettingsReadResponseReceiptSettingsFromJSONTyped(json, false);
}

export function ReceiptSettingsReadResponseReceiptSettingsFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): ReceiptSettingsReadResponseReceiptSettings {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: json['id'],
    partner_translation: (json['partner_translation'] as Array<any>).map(
      ReceiptSettingsPartnerTranslationsInnerFromJSON
    ),
    attach_receipt: json['attach_receipt']
  };
}

export function ReceiptSettingsReadResponseReceiptSettingsToJSON(
  value?: ReceiptSettingsReadResponseReceiptSettings | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    id: value.id,
    partner_translation: (value.partner_translation as Array<any>).map(
      ReceiptSettingsPartnerTranslationsInnerToJSON
    ),
    attach_receipt: value.attach_receipt
  };
}
