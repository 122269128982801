/* tslint:disable */
/* eslint-disable */
/**
 * Ticketing Portal
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface AddonByIdReadResponseAddonTranslationsInner
 */
export interface AddonByIdReadResponseAddonTranslationsInner {
  /**
   *
   * @type {string}
   * @memberof AddonByIdReadResponseAddonTranslationsInner
   */
  id?: string;
  /**
   *
   * @type {string}
   * @memberof AddonByIdReadResponseAddonTranslationsInner
   */
  addon_id?: string;
  /**
   *
   * @type {string}
   * @memberof AddonByIdReadResponseAddonTranslationsInner
   */
  locale_code: string;
  /**
   *
   * @type {string}
   * @memberof AddonByIdReadResponseAddonTranslationsInner
   */
  display_name?: string | null;
  /**
   *
   * @type {string}
   * @memberof AddonByIdReadResponseAddonTranslationsInner
   */
  description?: string | null;
}

/**
 * Check if a given object implements the AddonByIdReadResponseAddonTranslationsInner interface.
 */
export function instanceOfAddonByIdReadResponseAddonTranslationsInner(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && 'locale_code' in value;

  return isInstance;
}

export function AddonByIdReadResponseAddonTranslationsInnerFromJSON(
  json: any
): AddonByIdReadResponseAddonTranslationsInner {
  return AddonByIdReadResponseAddonTranslationsInnerFromJSONTyped(json, false);
}

export function AddonByIdReadResponseAddonTranslationsInnerFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): AddonByIdReadResponseAddonTranslationsInner {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: !exists(json, 'id') ? undefined : json['id'],
    addon_id: !exists(json, 'addon_id') ? undefined : json['addon_id'],
    locale_code: json['locale_code'],
    display_name: !exists(json, 'display_name') ? undefined : json['display_name'],
    description: !exists(json, 'description') ? undefined : json['description']
  };
}

export function AddonByIdReadResponseAddonTranslationsInnerToJSON(
  value?: AddonByIdReadResponseAddonTranslationsInner | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    id: value.id,
    addon_id: value.addon_id,
    locale_code: value.locale_code,
    display_name: value.display_name,
    description: value.description
  };
}
