/* tslint:disable */
/* eslint-disable */
/**
 * Ticketing Portal
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ProductOptionQuestionUpdateRequestProductOptionQuestionsInner } from './ProductOptionQuestionUpdateRequestProductOptionQuestionsInner';
import {
  ProductOptionQuestionUpdateRequestProductOptionQuestionsInnerFromJSON,
  ProductOptionQuestionUpdateRequestProductOptionQuestionsInnerFromJSONTyped,
  ProductOptionQuestionUpdateRequestProductOptionQuestionsInnerToJSON
} from './ProductOptionQuestionUpdateRequestProductOptionQuestionsInner';

/**
 *
 * @export
 * @interface ProductOptionQuestionUpdateRequest
 */
export interface ProductOptionQuestionUpdateRequest {
  /**
   *
   * @type {string}
   * @memberof ProductOptionQuestionUpdateRequest
   */
  locale_code: string;
  /**
   *
   * @type {Array<ProductOptionQuestionUpdateRequestProductOptionQuestionsInner>}
   * @memberof ProductOptionQuestionUpdateRequest
   */
  product_option_questions: Array<ProductOptionQuestionUpdateRequestProductOptionQuestionsInner>;
}

/**
 * Check if a given object implements the ProductOptionQuestionUpdateRequest interface.
 */
export function instanceOfProductOptionQuestionUpdateRequest(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && 'locale_code' in value;
  isInstance = isInstance && 'product_option_questions' in value;

  return isInstance;
}

export function ProductOptionQuestionUpdateRequestFromJSON(
  json: any
): ProductOptionQuestionUpdateRequest {
  return ProductOptionQuestionUpdateRequestFromJSONTyped(json, false);
}

export function ProductOptionQuestionUpdateRequestFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): ProductOptionQuestionUpdateRequest {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    locale_code: json['locale_code'],
    product_option_questions: (json['product_option_questions'] as Array<any>).map(
      ProductOptionQuestionUpdateRequestProductOptionQuestionsInnerFromJSON
    )
  };
}

export function ProductOptionQuestionUpdateRequestToJSON(
  value?: ProductOptionQuestionUpdateRequest | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    locale_code: value.locale_code,
    product_option_questions: (value.product_option_questions as Array<any>).map(
      ProductOptionQuestionUpdateRequestProductOptionQuestionsInnerToJSON
    )
  };
}
