import { ROUTE_NAMES } from '@/constants/routes';
import { PAGE_TITLES } from '@/constants/breadcrumbs';
import { InitializationReadResponsePermissionsEnum } from '@openapi/models';

export const USERS_ROUTES = [
  {
    path: 'manage/users',
    name: ROUTE_NAMES.USERS,
    component: () => import('@/views/pages/manage/Users.vue'),
    meta: {
      breadcrumbs: [PAGE_TITLES.MANAGE, PAGE_TITLES.USERS],
      menu: ['main'],
      permissions: [InitializationReadResponsePermissionsEnum.UserView],
      label: PAGE_TITLES.USERS,
      icon: 'pi pi-fw pi-user-edit'
    }
  },
  {
    path: 'manage/users/+',
    name: ROUTE_NAMES.INVITE_USER,
    component: () => import('@/views/pages/manage/UserInvite.vue'),
    meta: {
      breadcrumbs: [PAGE_TITLES.MANAGE, PAGE_TITLES.USERS, PAGE_TITLES.INVITE_USER]
    }
  },
  {
    path: 'manage/users/:userId',
    name: ROUTE_NAMES.EDIT_USER,
    component: () => import('@/views/pages/manage/UserEdit.vue'),
    meta: {
      breadcrumbs: [PAGE_TITLES.MANAGE, PAGE_TITLES.USERS, PAGE_TITLES.EDIT_USER]
    }
  }
];
