/* tslint:disable */
/* eslint-disable */
/**
 * Ticketing Portal
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { TicketByCodeReadResponseTicketAddonValiditiesInnerAddon } from './TicketByCodeReadResponseTicketAddonValiditiesInnerAddon';
import {
  TicketByCodeReadResponseTicketAddonValiditiesInnerAddonFromJSON,
  TicketByCodeReadResponseTicketAddonValiditiesInnerAddonFromJSONTyped,
  TicketByCodeReadResponseTicketAddonValiditiesInnerAddonToJSON
} from './TicketByCodeReadResponseTicketAddonValiditiesInnerAddon';

/**
 *
 * @export
 * @interface TicketByCodeReadResponseTicketAddonValiditiesInner
 */
export interface TicketByCodeReadResponseTicketAddonValiditiesInner {
  /**
   *
   * @type {string}
   * @memberof TicketByCodeReadResponseTicketAddonValiditiesInner
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof TicketByCodeReadResponseTicketAddonValiditiesInner
   */
  valid_from: string;
  /**
   *
   * @type {string}
   * @memberof TicketByCodeReadResponseTicketAddonValiditiesInner
   */
  valid_until: string;
  /**
   *
   * @type {number}
   * @memberof TicketByCodeReadResponseTicketAddonValiditiesInner
   */
  original_num_validations: number;
  /**
   *
   * @type {number}
   * @memberof TicketByCodeReadResponseTicketAddonValiditiesInner
   */
  remaining_num_validations: number;
  /**
   *
   * @type {boolean}
   * @memberof TicketByCodeReadResponseTicketAddonValiditiesInner
   */
  reedemable_now: boolean;
  /**
   *
   * @type {TicketByCodeReadResponseTicketAddonValiditiesInnerAddon}
   * @memberof TicketByCodeReadResponseTicketAddonValiditiesInner
   */
  addon: TicketByCodeReadResponseTicketAddonValiditiesInnerAddon;
}

/**
 * Check if a given object implements the TicketByCodeReadResponseTicketAddonValiditiesInner interface.
 */
export function instanceOfTicketByCodeReadResponseTicketAddonValiditiesInner(
  value: object
): boolean {
  let isInstance = true;
  isInstance = isInstance && 'id' in value;
  isInstance = isInstance && 'valid_from' in value;
  isInstance = isInstance && 'valid_until' in value;
  isInstance = isInstance && 'original_num_validations' in value;
  isInstance = isInstance && 'remaining_num_validations' in value;
  isInstance = isInstance && 'reedemable_now' in value;
  isInstance = isInstance && 'addon' in value;

  return isInstance;
}

export function TicketByCodeReadResponseTicketAddonValiditiesInnerFromJSON(
  json: any
): TicketByCodeReadResponseTicketAddonValiditiesInner {
  return TicketByCodeReadResponseTicketAddonValiditiesInnerFromJSONTyped(json, false);
}

export function TicketByCodeReadResponseTicketAddonValiditiesInnerFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): TicketByCodeReadResponseTicketAddonValiditiesInner {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: json['id'],
    valid_from: json['valid_from'],
    valid_until: json['valid_until'],
    original_num_validations: json['original_num_validations'],
    remaining_num_validations: json['remaining_num_validations'],
    reedemable_now: json['reedemable_now'],
    addon: TicketByCodeReadResponseTicketAddonValiditiesInnerAddonFromJSON(json['addon'])
  };
}

export function TicketByCodeReadResponseTicketAddonValiditiesInnerToJSON(
  value?: TicketByCodeReadResponseTicketAddonValiditiesInner | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    id: value.id,
    valid_from: value.valid_from,
    valid_until: value.valid_until,
    original_num_validations: value.original_num_validations,
    remaining_num_validations: value.remaining_num_validations,
    reedemable_now: value.reedemable_now,
    addon: TicketByCodeReadResponseTicketAddonValiditiesInnerAddonToJSON(value.addon)
  };
}
