/* tslint:disable */
/* eslint-disable */
/**
 * Ticketing Portal
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ProductOptionAddressReadResponseAddressTranslationsInner } from './ProductOptionAddressReadResponseAddressTranslationsInner';
import {
  ProductOptionAddressReadResponseAddressTranslationsInnerFromJSON,
  ProductOptionAddressReadResponseAddressTranslationsInnerFromJSONTyped,
  ProductOptionAddressReadResponseAddressTranslationsInnerToJSON
} from './ProductOptionAddressReadResponseAddressTranslationsInner';
import type { ProductOptionAddressReadResponseProductOptionAddress } from './ProductOptionAddressReadResponseProductOptionAddress';
import {
  ProductOptionAddressReadResponseProductOptionAddressFromJSON,
  ProductOptionAddressReadResponseProductOptionAddressFromJSONTyped,
  ProductOptionAddressReadResponseProductOptionAddressToJSON
} from './ProductOptionAddressReadResponseProductOptionAddress';

/**
 *
 * @export
 * @interface ProductOptionAddressReadResponse
 */
export interface ProductOptionAddressReadResponse {
  /**
   *
   * @type {boolean}
   * @memberof ProductOptionAddressReadResponse
   */
  success: boolean;
  /**
   *
   * @type {string}
   * @memberof ProductOptionAddressReadResponse
   */
  exception_code?: string;
  /**
   *
   * @type {string}
   * @memberof ProductOptionAddressReadResponse
   */
  exception_message?: string;
  /**
   *
   * @type {ProductOptionAddressReadResponseProductOptionAddress}
   * @memberof ProductOptionAddressReadResponse
   */
  product_option_address?: ProductOptionAddressReadResponseProductOptionAddress;
  /**
   *
   * @type {Array<ProductOptionAddressReadResponseAddressTranslationsInner>}
   * @memberof ProductOptionAddressReadResponse
   */
  address_translations: Array<ProductOptionAddressReadResponseAddressTranslationsInner>;
}

/**
 * Check if a given object implements the ProductOptionAddressReadResponse interface.
 */
export function instanceOfProductOptionAddressReadResponse(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && 'success' in value;
  isInstance = isInstance && 'address_translations' in value;

  return isInstance;
}

export function ProductOptionAddressReadResponseFromJSON(
  json: any
): ProductOptionAddressReadResponse {
  return ProductOptionAddressReadResponseFromJSONTyped(json, false);
}

export function ProductOptionAddressReadResponseFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): ProductOptionAddressReadResponse {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    success: json['success'],
    exception_code: !exists(json, 'exception_code') ? undefined : json['exception_code'],
    exception_message: !exists(json, 'exception_message') ? undefined : json['exception_message'],
    product_option_address: !exists(json, 'product_option_address')
      ? undefined
      : ProductOptionAddressReadResponseProductOptionAddressFromJSON(
          json['product_option_address']
        ),
    address_translations: (json['address_translations'] as Array<any>).map(
      ProductOptionAddressReadResponseAddressTranslationsInnerFromJSON
    )
  };
}

export function ProductOptionAddressReadResponseToJSON(
  value?: ProductOptionAddressReadResponse | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    success: value.success,
    exception_code: value.exception_code,
    exception_message: value.exception_message,
    product_option_address: ProductOptionAddressReadResponseProductOptionAddressToJSON(
      value.product_option_address
    ),
    address_translations: (value.address_translations as Array<any>).map(
      ProductOptionAddressReadResponseAddressTranslationsInnerToJSON
    )
  };
}
