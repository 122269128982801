/* tslint:disable */
/* eslint-disable */
/**
 * Ticketing Portal
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface PayoutByIdReadResponsePayoutAdjustmentsInner
 */
export interface PayoutByIdReadResponsePayoutAdjustmentsInner {
  /**
   * Amount of the adjustment
   * @type {string}
   * @memberof PayoutByIdReadResponsePayoutAdjustmentsInner
   */
  amount: string;
  /**
   * Comment explaining the adjustment
   * @type {string}
   * @memberof PayoutByIdReadResponsePayoutAdjustmentsInner
   */
  comment: string;
}

/**
 * Check if a given object implements the PayoutByIdReadResponsePayoutAdjustmentsInner interface.
 */
export function instanceOfPayoutByIdReadResponsePayoutAdjustmentsInner(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && 'amount' in value;
  isInstance = isInstance && 'comment' in value;

  return isInstance;
}

export function PayoutByIdReadResponsePayoutAdjustmentsInnerFromJSON(
  json: any
): PayoutByIdReadResponsePayoutAdjustmentsInner {
  return PayoutByIdReadResponsePayoutAdjustmentsInnerFromJSONTyped(json, false);
}

export function PayoutByIdReadResponsePayoutAdjustmentsInnerFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): PayoutByIdReadResponsePayoutAdjustmentsInner {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    amount: json['amount'],
    comment: json['comment']
  };
}

export function PayoutByIdReadResponsePayoutAdjustmentsInnerToJSON(
  value?: PayoutByIdReadResponsePayoutAdjustmentsInner | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    amount: value.amount,
    comment: value.comment
  };
}
