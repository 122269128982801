/* tslint:disable */
/* eslint-disable */
/**
 * Ticketing Portal
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { CancellationPolicyDeleteRequestCancellationPolicy } from './CancellationPolicyDeleteRequestCancellationPolicy';
import {
  CancellationPolicyDeleteRequestCancellationPolicyFromJSON,
  CancellationPolicyDeleteRequestCancellationPolicyFromJSONTyped,
  CancellationPolicyDeleteRequestCancellationPolicyToJSON
} from './CancellationPolicyDeleteRequestCancellationPolicy';

/**
 *
 * @export
 * @interface IntegrationCreateResponse
 */
export interface IntegrationCreateResponse {
  /**
   *
   * @type {boolean}
   * @memberof IntegrationCreateResponse
   */
  success: boolean;
  /**
   *
   * @type {string}
   * @memberof IntegrationCreateResponse
   */
  exception_code?: string;
  /**
   *
   * @type {string}
   * @memberof IntegrationCreateResponse
   */
  exception_message?: string;
  /**
   *
   * @type {CancellationPolicyDeleteRequestCancellationPolicy}
   * @memberof IntegrationCreateResponse
   */
  integration?: CancellationPolicyDeleteRequestCancellationPolicy;
}

/**
 * Check if a given object implements the IntegrationCreateResponse interface.
 */
export function instanceOfIntegrationCreateResponse(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && 'success' in value;

  return isInstance;
}

export function IntegrationCreateResponseFromJSON(json: any): IntegrationCreateResponse {
  return IntegrationCreateResponseFromJSONTyped(json, false);
}

export function IntegrationCreateResponseFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): IntegrationCreateResponse {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    success: json['success'],
    exception_code: !exists(json, 'exception_code') ? undefined : json['exception_code'],
    exception_message: !exists(json, 'exception_message') ? undefined : json['exception_message'],
    integration: !exists(json, 'integration')
      ? undefined
      : CancellationPolicyDeleteRequestCancellationPolicyFromJSON(json['integration'])
  };
}

export function IntegrationCreateResponseToJSON(value?: IntegrationCreateResponse | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    success: value.success,
    exception_code: value.exception_code,
    exception_message: value.exception_message,
    integration: CancellationPolicyDeleteRequestCancellationPolicyToJSON(value.integration)
  };
}
