/* tslint:disable */
/* eslint-disable */
/**
 * Ticketing Portal
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { CustomizationSettingsReadResponseCustomizationSettings } from './CustomizationSettingsReadResponseCustomizationSettings';
import {
  CustomizationSettingsReadResponseCustomizationSettingsFromJSON,
  CustomizationSettingsReadResponseCustomizationSettingsFromJSONTyped,
  CustomizationSettingsReadResponseCustomizationSettingsToJSON
} from './CustomizationSettingsReadResponseCustomizationSettings';

/**
 *
 * @export
 * @interface CustomizationSettingsReadResponse
 */
export interface CustomizationSettingsReadResponse {
  /**
   *
   * @type {boolean}
   * @memberof CustomizationSettingsReadResponse
   */
  success: boolean;
  /**
   *
   * @type {string}
   * @memberof CustomizationSettingsReadResponse
   */
  exception_code?: string;
  /**
   *
   * @type {string}
   * @memberof CustomizationSettingsReadResponse
   */
  exception_message?: string;
  /**
   *
   * @type {CustomizationSettingsReadResponseCustomizationSettings}
   * @memberof CustomizationSettingsReadResponse
   */
  customization_settings: CustomizationSettingsReadResponseCustomizationSettings;
}

/**
 * Check if a given object implements the CustomizationSettingsReadResponse interface.
 */
export function instanceOfCustomizationSettingsReadResponse(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && 'success' in value;
  isInstance = isInstance && 'customization_settings' in value;

  return isInstance;
}

export function CustomizationSettingsReadResponseFromJSON(
  json: any
): CustomizationSettingsReadResponse {
  return CustomizationSettingsReadResponseFromJSONTyped(json, false);
}

export function CustomizationSettingsReadResponseFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): CustomizationSettingsReadResponse {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    success: json['success'],
    exception_code: !exists(json, 'exception_code') ? undefined : json['exception_code'],
    exception_message: !exists(json, 'exception_message') ? undefined : json['exception_message'],
    customization_settings: CustomizationSettingsReadResponseCustomizationSettingsFromJSON(
      json['customization_settings']
    )
  };
}

export function CustomizationSettingsReadResponseToJSON(
  value?: CustomizationSettingsReadResponse | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    success: value.success,
    exception_code: value.exception_code,
    exception_message: value.exception_message,
    customization_settings: CustomizationSettingsReadResponseCustomizationSettingsToJSON(
      value.customization_settings
    )
  };
}
