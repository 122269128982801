/* tslint:disable */
/* eslint-disable */
/**
 * Ticketing Portal
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface TimeSlotByIdReadResponseTimeSlot
 */
export interface TimeSlotByIdReadResponseTimeSlot {
  /**
   *
   * @type {string}
   * @memberof TimeSlotByIdReadResponseTimeSlot
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof TimeSlotByIdReadResponseTimeSlot
   */
  status: TimeSlotByIdReadResponseTimeSlotStatusEnum;
  /**
   *
   * @type {string}
   * @memberof TimeSlotByIdReadResponseTimeSlot
   */
  start_date: string;
  /**
   *
   * @type {string}
   * @memberof TimeSlotByIdReadResponseTimeSlot
   */
  start_time: string;
  /**
   *
   * @type {string}
   * @memberof TimeSlotByIdReadResponseTimeSlot
   */
  product_name: string;
  /**
   *
   * @type {string}
   * @memberof TimeSlotByIdReadResponseTimeSlot
   */
  pricing_name: string;
  /**
   *
   * @type {string}
   * @memberof TimeSlotByIdReadResponseTimeSlot
   */
  product_option_name: string;
  /**
   *
   * @type {number}
   * @memberof TimeSlotByIdReadResponseTimeSlot
   */
  bookings: number;
  /**
   *
   * @type {number}
   * @memberof TimeSlotByIdReadResponseTimeSlot
   */
  vacancies: number | null;
  /**
   *
   * @type {number}
   * @memberof TimeSlotByIdReadResponseTimeSlot
   */
  capacity: number | null;
}

/**
 * @export
 */
export const TimeSlotByIdReadResponseTimeSlotStatusEnum = {
  Active: 'active',
  Deleted: 'deleted',
  NotBookable: 'not_bookable'
} as const;
export type TimeSlotByIdReadResponseTimeSlotStatusEnum =
  (typeof TimeSlotByIdReadResponseTimeSlotStatusEnum)[keyof typeof TimeSlotByIdReadResponseTimeSlotStatusEnum];

/**
 * Check if a given object implements the TimeSlotByIdReadResponseTimeSlot interface.
 */
export function instanceOfTimeSlotByIdReadResponseTimeSlot(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && 'id' in value;
  isInstance = isInstance && 'status' in value;
  isInstance = isInstance && 'start_date' in value;
  isInstance = isInstance && 'start_time' in value;
  isInstance = isInstance && 'product_name' in value;
  isInstance = isInstance && 'pricing_name' in value;
  isInstance = isInstance && 'product_option_name' in value;
  isInstance = isInstance && 'bookings' in value;
  isInstance = isInstance && 'vacancies' in value;
  isInstance = isInstance && 'capacity' in value;

  return isInstance;
}

export function TimeSlotByIdReadResponseTimeSlotFromJSON(
  json: any
): TimeSlotByIdReadResponseTimeSlot {
  return TimeSlotByIdReadResponseTimeSlotFromJSONTyped(json, false);
}

export function TimeSlotByIdReadResponseTimeSlotFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): TimeSlotByIdReadResponseTimeSlot {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: json['id'],
    status: json['status'],
    start_date: json['start_date'],
    start_time: json['start_time'],
    product_name: json['product_name'],
    pricing_name: json['pricing_name'],
    product_option_name: json['product_option_name'],
    bookings: json['bookings'],
    vacancies: json['vacancies'],
    capacity: json['capacity']
  };
}

export function TimeSlotByIdReadResponseTimeSlotToJSON(
  value?: TimeSlotByIdReadResponseTimeSlot | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    id: value.id,
    status: value.status,
    start_date: value.start_date,
    start_time: value.start_time,
    product_name: value.product_name,
    pricing_name: value.pricing_name,
    product_option_name: value.product_option_name,
    bookings: value.bookings,
    vacancies: value.vacancies,
    capacity: value.capacity
  };
}
