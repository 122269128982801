import { ROUTE_NAMES } from '@/constants/routes';
import { PAGE_TITLES } from '@/constants/breadcrumbs';
import { InitializationReadResponsePermissionsEnum } from '@openapi/models';

export const REPORTS_ROUTES = [
  {
    path: 'reports',
    name: ROUTE_NAMES.REPORTS,
    component: () => import('@/views/pages/home/Reports.vue'),
    redirect: { path: '/reports/revenue' },
    meta: {
      breadcrumbs: [PAGE_TITLES.REPORTS],
      menu: ['main'],
      permissions: [InitializationReadResponsePermissionsEnum.ReportView],
      label: PAGE_TITLES.REPORTS,
      icon: 'pi pi-fw pi-chart-line'
    },
    children: [
      {
        path: 'revenue',
        name: ROUTE_NAMES.REVENUE,
        component: () => import('@/views/pages/home/Revenue.vue'),
        meta: {
          breadcrumbs: [PAGE_TITLES.REPORTS, PAGE_TITLES.REVENUE]
        }
      },
      {
        path: 'payouts',
        name: ROUTE_NAMES.PAYOUTS,
        component: () => import('@/views/pages/home/Payouts.vue'),
        meta: {
          breadcrumbs: [PAGE_TITLES.REPORTS, PAGE_TITLES.PAYOUTS]
        }
      },
      {
        path: 'payouts/:payoutId',
        name: ROUTE_NAMES.PAYOUT,
        component: () => import('@/views/pages/home/Payout.vue'),
        meta: {
          breadcrumbs: [PAGE_TITLES.REPORTS, PAGE_TITLES.PAYOUTS, PAGE_TITLES.PAYOUT]
        }
      }
    ]
  }
];
