/* tslint:disable */
/* eslint-disable */
/**
 * Ticketing Portal
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface IntegrationUpdateRequestIntegration
 */
export interface IntegrationUpdateRequestIntegration {
  /**
   *
   * @type {string}
   * @memberof IntegrationUpdateRequestIntegration
   */
  name?: string | null;
  /**
   *
   * @type {string}
   * @memberof IntegrationUpdateRequestIntegration
   */
  locale_code?: string | null;
  /**
   *
   * @type {string}
   * @memberof IntegrationUpdateRequestIntegration
   */
  comment?: string | null;
  /**
   *
   * @type {string}
   * @memberof IntegrationUpdateRequestIntegration
   */
  status?: IntegrationUpdateRequestIntegrationStatusEnum;
  /**
   *
   * @type {Array<string>}
   * @memberof IntegrationUpdateRequestIntegration
   */
  products?: Array<string>;
}

/**
 * @export
 */
export const IntegrationUpdateRequestIntegrationStatusEnum = {
  Active: 'active',
  Inactive: 'inactive',
  Deleted: 'deleted'
} as const;
export type IntegrationUpdateRequestIntegrationStatusEnum =
  (typeof IntegrationUpdateRequestIntegrationStatusEnum)[keyof typeof IntegrationUpdateRequestIntegrationStatusEnum];

/**
 * Check if a given object implements the IntegrationUpdateRequestIntegration interface.
 */
export function instanceOfIntegrationUpdateRequestIntegration(value: object): boolean {
  let isInstance = true;

  return isInstance;
}

export function IntegrationUpdateRequestIntegrationFromJSON(
  json: any
): IntegrationUpdateRequestIntegration {
  return IntegrationUpdateRequestIntegrationFromJSONTyped(json, false);
}

export function IntegrationUpdateRequestIntegrationFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): IntegrationUpdateRequestIntegration {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    name: !exists(json, 'name') ? undefined : json['name'],
    locale_code: !exists(json, 'locale_code') ? undefined : json['locale_code'],
    comment: !exists(json, 'comment') ? undefined : json['comment'],
    status: !exists(json, 'status') ? undefined : json['status'],
    products: !exists(json, 'products') ? undefined : json['products']
  };
}

export function IntegrationUpdateRequestIntegrationToJSON(
  value?: IntegrationUpdateRequestIntegration | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    name: value.name,
    locale_code: value.locale_code,
    comment: value.comment,
    status: value.status,
    products: value.products
  };
}
