/* tslint:disable */
/* eslint-disable */
/**
 * Ticketing Portal
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface CancellationPolicyUpdateRequestCancellationPolicyRulesInner
 */
export interface CancellationPolicyUpdateRequestCancellationPolicyRulesInner {
  /**
   *
   * @type {string}
   * @memberof CancellationPolicyUpdateRequestCancellationPolicyRulesInner
   */
  cancel_before: string;
  /**
   *
   * @type {number}
   * @memberof CancellationPolicyUpdateRequestCancellationPolicyRulesInner
   */
  refund_percentage: number;
}

/**
 * Check if a given object implements the CancellationPolicyUpdateRequestCancellationPolicyRulesInner interface.
 */
export function instanceOfCancellationPolicyUpdateRequestCancellationPolicyRulesInner(
  value: object
): boolean {
  let isInstance = true;
  isInstance = isInstance && 'cancel_before' in value;
  isInstance = isInstance && 'refund_percentage' in value;

  return isInstance;
}

export function CancellationPolicyUpdateRequestCancellationPolicyRulesInnerFromJSON(
  json: any
): CancellationPolicyUpdateRequestCancellationPolicyRulesInner {
  return CancellationPolicyUpdateRequestCancellationPolicyRulesInnerFromJSONTyped(json, false);
}

export function CancellationPolicyUpdateRequestCancellationPolicyRulesInnerFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): CancellationPolicyUpdateRequestCancellationPolicyRulesInner {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    cancel_before: json['cancel_before'],
    refund_percentage: json['refund_percentage']
  };
}

export function CancellationPolicyUpdateRequestCancellationPolicyRulesInnerToJSON(
  value?: CancellationPolicyUpdateRequestCancellationPolicyRulesInner | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    cancel_before: value.cancel_before,
    refund_percentage: value.refund_percentage
  };
}
