/* tslint:disable */
/* eslint-disable */
/**
 * Ticketing Portal
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { BookingByIdReadResponseBooking } from './BookingByIdReadResponseBooking';
import {
  BookingByIdReadResponseBookingFromJSON,
  BookingByIdReadResponseBookingFromJSONTyped,
  BookingByIdReadResponseBookingToJSON
} from './BookingByIdReadResponseBooking';
import type { BookingByIdReadResponseCustomer } from './BookingByIdReadResponseCustomer';
import {
  BookingByIdReadResponseCustomerFromJSON,
  BookingByIdReadResponseCustomerFromJSONTyped,
  BookingByIdReadResponseCustomerToJSON
} from './BookingByIdReadResponseCustomer';
import type { BookingByIdReadResponseRefund } from './BookingByIdReadResponseRefund';
import {
  BookingByIdReadResponseRefundFromJSON,
  BookingByIdReadResponseRefundFromJSONTyped,
  BookingByIdReadResponseRefundToJSON
} from './BookingByIdReadResponseRefund';

/**
 *
 * @export
 * @interface BookingByIdReadResponse
 */
export interface BookingByIdReadResponse {
  /**
   *
   * @type {boolean}
   * @memberof BookingByIdReadResponse
   */
  success: boolean;
  /**
   *
   * @type {string}
   * @memberof BookingByIdReadResponse
   */
  exception_code?: string;
  /**
   *
   * @type {string}
   * @memberof BookingByIdReadResponse
   */
  exception_message?: string;
  /**
   *
   * @type {BookingByIdReadResponseBooking}
   * @memberof BookingByIdReadResponse
   */
  booking: BookingByIdReadResponseBooking;
  /**
   *
   * @type {BookingByIdReadResponseCustomer}
   * @memberof BookingByIdReadResponse
   */
  customer?: BookingByIdReadResponseCustomer;
  /**
   *
   * @type {BookingByIdReadResponseRefund}
   * @memberof BookingByIdReadResponse
   */
  refund: BookingByIdReadResponseRefund;
}

/**
 * Check if a given object implements the BookingByIdReadResponse interface.
 */
export function instanceOfBookingByIdReadResponse(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && 'success' in value;
  isInstance = isInstance && 'booking' in value;
  isInstance = isInstance && 'refund' in value;

  return isInstance;
}

export function BookingByIdReadResponseFromJSON(json: any): BookingByIdReadResponse {
  return BookingByIdReadResponseFromJSONTyped(json, false);
}

export function BookingByIdReadResponseFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): BookingByIdReadResponse {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    success: json['success'],
    exception_code: !exists(json, 'exception_code') ? undefined : json['exception_code'],
    exception_message: !exists(json, 'exception_message') ? undefined : json['exception_message'],
    booking: BookingByIdReadResponseBookingFromJSON(json['booking']),
    customer: !exists(json, 'customer')
      ? undefined
      : BookingByIdReadResponseCustomerFromJSON(json['customer']),
    refund: BookingByIdReadResponseRefundFromJSON(json['refund'])
  };
}

export function BookingByIdReadResponseToJSON(value?: BookingByIdReadResponse | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    success: value.success,
    exception_code: value.exception_code,
    exception_message: value.exception_message,
    booking: BookingByIdReadResponseBookingToJSON(value.booking),
    customer: BookingByIdReadResponseCustomerToJSON(value.customer),
    refund: BookingByIdReadResponseRefundToJSON(value.refund)
  };
}
