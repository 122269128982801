/* tslint:disable */
/* eslint-disable */
/**
 * Ticketing Portal
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface BusinessInfoPartnerTranslationsInner
 */
export interface BusinessInfoPartnerTranslationsInner {
  /**
   *
   * @type {string}
   * @memberof BusinessInfoPartnerTranslationsInner
   */
  id?: string;
  /**
   *
   * @type {string}
   * @memberof BusinessInfoPartnerTranslationsInner
   */
  terms_and_conditions?: string | null;
  /**
   *
   * @type {string}
   * @memberof BusinessInfoPartnerTranslationsInner
   */
  locale_code: string;
}

/**
 * Check if a given object implements the BusinessInfoPartnerTranslationsInner interface.
 */
export function instanceOfBusinessInfoPartnerTranslationsInner(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && 'locale_code' in value;

  return isInstance;
}

export function BusinessInfoPartnerTranslationsInnerFromJSON(
  json: any
): BusinessInfoPartnerTranslationsInner {
  return BusinessInfoPartnerTranslationsInnerFromJSONTyped(json, false);
}

export function BusinessInfoPartnerTranslationsInnerFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): BusinessInfoPartnerTranslationsInner {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: !exists(json, 'id') ? undefined : json['id'],
    terms_and_conditions: !exists(json, 'terms_and_conditions')
      ? undefined
      : json['terms_and_conditions'],
    locale_code: json['locale_code']
  };
}

export function BusinessInfoPartnerTranslationsInnerToJSON(
  value?: BusinessInfoPartnerTranslationsInner | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    id: value.id,
    terms_and_conditions: value.terms_and_conditions,
    locale_code: value.locale_code
  };
}
