/* tslint:disable */
/* eslint-disable */
/**
 * Ticketing Portal
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface BookingsCountReadResponseBookingsInnerPeriodPrevious
 */
export interface BookingsCountReadResponseBookingsInnerPeriodPrevious {
  /**
   * Start date of the previous period in ISO 8601 date format
   * @type {Date}
   * @memberof BookingsCountReadResponseBookingsInnerPeriodPrevious
   */
  start: Date;
  /**
   * End date of the previous period in ISO 8601 date format
   * @type {Date}
   * @memberof BookingsCountReadResponseBookingsInnerPeriodPrevious
   */
  end: Date;
}

/**
 * Check if a given object implements the BookingsCountReadResponseBookingsInnerPeriodPrevious interface.
 */
export function instanceOfBookingsCountReadResponseBookingsInnerPeriodPrevious(
  value: object
): boolean {
  let isInstance = true;
  isInstance = isInstance && 'start' in value;
  isInstance = isInstance && 'end' in value;

  return isInstance;
}

export function BookingsCountReadResponseBookingsInnerPeriodPreviousFromJSON(
  json: any
): BookingsCountReadResponseBookingsInnerPeriodPrevious {
  return BookingsCountReadResponseBookingsInnerPeriodPreviousFromJSONTyped(json, false);
}

export function BookingsCountReadResponseBookingsInnerPeriodPreviousFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): BookingsCountReadResponseBookingsInnerPeriodPrevious {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    start: new Date(json['start']),
    end: new Date(json['end'])
  };
}

export function BookingsCountReadResponseBookingsInnerPeriodPreviousToJSON(
  value?: BookingsCountReadResponseBookingsInnerPeriodPrevious | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    start: value.start.toISOString(),
    end: value.end.toISOString()
  };
}
