/* tslint:disable */
/* eslint-disable */
/**
 * Ticketing Portal
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { OrdersReadResponseOrdersInnerParticipantsParticipantsInner } from './OrdersReadResponseOrdersInnerParticipantsParticipantsInner';
import {
  OrdersReadResponseOrdersInnerParticipantsParticipantsInnerFromJSON,
  OrdersReadResponseOrdersInnerParticipantsParticipantsInnerFromJSONTyped,
  OrdersReadResponseOrdersInnerParticipantsParticipantsInnerToJSON
} from './OrdersReadResponseOrdersInnerParticipantsParticipantsInner';

/**
 *
 * @export
 * @interface OrdersReadResponseOrdersInnerParticipants
 */
export interface OrdersReadResponseOrdersInnerParticipants {
  /**
   *
   * @type {number}
   * @memberof OrdersReadResponseOrdersInnerParticipants
   */
  total_participants: number;
  /**
   *
   * @type {number}
   * @memberof OrdersReadResponseOrdersInnerParticipants
   */
  total_redeemed_tickets: number;
  /**
   *
   * @type {Array<OrdersReadResponseOrdersInnerParticipantsParticipantsInner>}
   * @memberof OrdersReadResponseOrdersInnerParticipants
   */
  participants: Array<OrdersReadResponseOrdersInnerParticipantsParticipantsInner>;
}

/**
 * Check if a given object implements the OrdersReadResponseOrdersInnerParticipants interface.
 */
export function instanceOfOrdersReadResponseOrdersInnerParticipants(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && 'total_participants' in value;
  isInstance = isInstance && 'total_redeemed_tickets' in value;
  isInstance = isInstance && 'participants' in value;

  return isInstance;
}

export function OrdersReadResponseOrdersInnerParticipantsFromJSON(
  json: any
): OrdersReadResponseOrdersInnerParticipants {
  return OrdersReadResponseOrdersInnerParticipantsFromJSONTyped(json, false);
}

export function OrdersReadResponseOrdersInnerParticipantsFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): OrdersReadResponseOrdersInnerParticipants {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    total_participants: json['total_participants'],
    total_redeemed_tickets: json['total_redeemed_tickets'],
    participants: (json['participants'] as Array<any>).map(
      OrdersReadResponseOrdersInnerParticipantsParticipantsInnerFromJSON
    )
  };
}

export function OrdersReadResponseOrdersInnerParticipantsToJSON(
  value?: OrdersReadResponseOrdersInnerParticipants | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    total_participants: value.total_participants,
    total_redeemed_tickets: value.total_redeemed_tickets,
    participants: (value.participants as Array<any>).map(
      OrdersReadResponseOrdersInnerParticipantsParticipantsInnerToJSON
    )
  };
}
