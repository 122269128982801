/* tslint:disable */
/* eslint-disable */
/**
 * Ticketing Portal
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface ProductOptionTranslationReadResponseProductOptionTranslationsInner
 */
export interface ProductOptionTranslationReadResponseProductOptionTranslationsInner {
  /**
   *
   * @type {string}
   * @memberof ProductOptionTranslationReadResponseProductOptionTranslationsInner
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof ProductOptionTranslationReadResponseProductOptionTranslationsInner
   */
  product_option_id: string;
  /**
   *
   * @type {string}
   * @memberof ProductOptionTranslationReadResponseProductOptionTranslationsInner
   */
  locale_code: string;
  /**
   *
   * @type {string}
   * @memberof ProductOptionTranslationReadResponseProductOptionTranslationsInner
   */
  title: string;
  /**
   *
   * @type {string}
   * @memberof ProductOptionTranslationReadResponseProductOptionTranslationsInner
   */
  description?: string | null;
}

/**
 * Check if a given object implements the ProductOptionTranslationReadResponseProductOptionTranslationsInner interface.
 */
export function instanceOfProductOptionTranslationReadResponseProductOptionTranslationsInner(
  value: object
): boolean {
  let isInstance = true;
  isInstance = isInstance && 'id' in value;
  isInstance = isInstance && 'product_option_id' in value;
  isInstance = isInstance && 'locale_code' in value;
  isInstance = isInstance && 'title' in value;

  return isInstance;
}

export function ProductOptionTranslationReadResponseProductOptionTranslationsInnerFromJSON(
  json: any
): ProductOptionTranslationReadResponseProductOptionTranslationsInner {
  return ProductOptionTranslationReadResponseProductOptionTranslationsInnerFromJSONTyped(
    json,
    false
  );
}

export function ProductOptionTranslationReadResponseProductOptionTranslationsInnerFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): ProductOptionTranslationReadResponseProductOptionTranslationsInner {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: json['id'],
    product_option_id: json['product_option_id'],
    locale_code: json['locale_code'],
    title: json['title'],
    description: !exists(json, 'description') ? undefined : json['description']
  };
}

export function ProductOptionTranslationReadResponseProductOptionTranslationsInnerToJSON(
  value?: ProductOptionTranslationReadResponseProductOptionTranslationsInner | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    id: value.id,
    product_option_id: value.product_option_id,
    locale_code: value.locale_code,
    title: value.title,
    description: value.description
  };
}
