/* tslint:disable */
/* eslint-disable */
/**
 * Ticketing Portal
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface SupportedCountriesReadResponseSupportedCountriesInner
 */
export interface SupportedCountriesReadResponseSupportedCountriesInner {
  /**
   *
   * @type {string}
   * @memberof SupportedCountriesReadResponseSupportedCountriesInner
   */
  country_code: string;
}

/**
 * Check if a given object implements the SupportedCountriesReadResponseSupportedCountriesInner interface.
 */
export function instanceOfSupportedCountriesReadResponseSupportedCountriesInner(
  value: object
): boolean {
  let isInstance = true;
  isInstance = isInstance && 'country_code' in value;

  return isInstance;
}

export function SupportedCountriesReadResponseSupportedCountriesInnerFromJSON(
  json: any
): SupportedCountriesReadResponseSupportedCountriesInner {
  return SupportedCountriesReadResponseSupportedCountriesInnerFromJSONTyped(json, false);
}

export function SupportedCountriesReadResponseSupportedCountriesInnerFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): SupportedCountriesReadResponseSupportedCountriesInner {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    country_code: json['country_code']
  };
}

export function SupportedCountriesReadResponseSupportedCountriesInnerToJSON(
  value?: SupportedCountriesReadResponseSupportedCountriesInner | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    country_code: value.country_code
  };
}
