/* tslint:disable */
/* eslint-disable */
/**
 * Ticketing Portal
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface TicketsRedemptionReadResponseLogInner
 */
export interface TicketsRedemptionReadResponseLogInner {
  /**
   *
   * @type {string}
   * @memberof TicketsRedemptionReadResponseLogInner
   */
  created_at: string;
  /**
   *
   * @type {string}
   * @memberof TicketsRedemptionReadResponseLogInner
   */
  result: TicketsRedemptionReadResponseLogInnerResultEnum;
  /**
   *
   * @type {boolean}
   * @memberof TicketsRedemptionReadResponseLogInner
   */
  overridden_by_partner: boolean;
  /**
   *
   * @type {string}
   * @memberof TicketsRedemptionReadResponseLogInner
   */
  product_translated: string;
  /**
   *
   * @type {string}
   * @memberof TicketsRedemptionReadResponseLogInner
   */
  ticket_code: string;
  /**
   *
   * @type {string}
   * @memberof TicketsRedemptionReadResponseLogInner
   */
  booking_id: string;
  /**
   *
   * @type {string}
   * @memberof TicketsRedemptionReadResponseLogInner
   */
  booking_hash_code: string;
  /**
   *
   * @type {string}
   * @memberof TicketsRedemptionReadResponseLogInner
   */
  redeemed_by: string;
  /**
   *
   * @type {string}
   * @memberof TicketsRedemptionReadResponseLogInner
   */
  customer_name: string;
}

/**
 * @export
 */
export const TicketsRedemptionReadResponseLogInnerResultEnum = {
  Success: 'success',
  ServerError: 'server_error',
  NotFound: 'not_found',
  NoRemainingVisits: 'no_remaining_visits',
  OutsideValidity: 'outside_validity'
} as const;
export type TicketsRedemptionReadResponseLogInnerResultEnum =
  (typeof TicketsRedemptionReadResponseLogInnerResultEnum)[keyof typeof TicketsRedemptionReadResponseLogInnerResultEnum];

/**
 * Check if a given object implements the TicketsRedemptionReadResponseLogInner interface.
 */
export function instanceOfTicketsRedemptionReadResponseLogInner(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && 'created_at' in value;
  isInstance = isInstance && 'result' in value;
  isInstance = isInstance && 'overridden_by_partner' in value;
  isInstance = isInstance && 'product_translated' in value;
  isInstance = isInstance && 'ticket_code' in value;
  isInstance = isInstance && 'booking_id' in value;
  isInstance = isInstance && 'booking_hash_code' in value;
  isInstance = isInstance && 'redeemed_by' in value;
  isInstance = isInstance && 'customer_name' in value;

  return isInstance;
}

export function TicketsRedemptionReadResponseLogInnerFromJSON(
  json: any
): TicketsRedemptionReadResponseLogInner {
  return TicketsRedemptionReadResponseLogInnerFromJSONTyped(json, false);
}

export function TicketsRedemptionReadResponseLogInnerFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): TicketsRedemptionReadResponseLogInner {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    created_at: json['created_at'],
    result: json['result'],
    overridden_by_partner: json['overridden_by_partner'],
    product_translated: json['product_translated'],
    ticket_code: json['ticket_code'],
    booking_id: json['booking_id'],
    booking_hash_code: json['booking_hash_code'],
    redeemed_by: json['redeemed_by'],
    customer_name: json['customer_name']
  };
}

export function TicketsRedemptionReadResponseLogInnerToJSON(
  value?: TicketsRedemptionReadResponseLogInner | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    created_at: value.created_at,
    result: value.result,
    overridden_by_partner: value.overridden_by_partner,
    product_translated: value.product_translated,
    ticket_code: value.ticket_code,
    booking_id: value.booking_id,
    booking_hash_code: value.booking_hash_code,
    redeemed_by: value.redeemed_by,
    customer_name: value.customer_name
  };
}
