import { Temporal } from '@js-temporal/polyfill';
import i18n from '@/i18n';

export const toTemporalDuration = (temporalString?: string) => {
  if (!temporalString) {
    return '';
  }
  const hoursAndMinutesTemporal = Temporal.Duration.from(temporalString).round({
    smallestUnit: 'minute'
  });
  return `${hoursAndMinutesTemporal.hours}h ${hoursAndMinutesTemporal.minutes}m`;
};

const SCHEDULE_AFTER = 1;

export const optionAfter = () => {
  return {
    id: SCHEDULE_AFTER,
    name: i18n.global.t('portal_duration_after_label')
  };
};
const SCHEDULE_BEFORE = -1;
export const optionBefore = () => {
  return {
    id: SCHEDULE_BEFORE,
    name: i18n.global.t('portal_duration_before_label')
  };
};
