/* tslint:disable */
/* eslint-disable */
/**
 * Ticketing Portal
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface TicketByCodeReadResponseTicketParticipantValiditiesInnerPricingCategory
 */
export interface TicketByCodeReadResponseTicketParticipantValiditiesInnerPricingCategory {
  /**
   *
   * @type {string}
   * @memberof TicketByCodeReadResponseTicketParticipantValiditiesInnerPricingCategory
   */
  name_translated: string;
  /**
   *
   * @type {string}
   * @memberof TicketByCodeReadResponseTicketParticipantValiditiesInnerPricingCategory
   */
  age_translated: string;
}

/**
 * Check if a given object implements the TicketByCodeReadResponseTicketParticipantValiditiesInnerPricingCategory interface.
 */
export function instanceOfTicketByCodeReadResponseTicketParticipantValiditiesInnerPricingCategory(
  value: object
): boolean {
  let isInstance = true;
  isInstance = isInstance && 'name_translated' in value;
  isInstance = isInstance && 'age_translated' in value;

  return isInstance;
}

export function TicketByCodeReadResponseTicketParticipantValiditiesInnerPricingCategoryFromJSON(
  json: any
): TicketByCodeReadResponseTicketParticipantValiditiesInnerPricingCategory {
  return TicketByCodeReadResponseTicketParticipantValiditiesInnerPricingCategoryFromJSONTyped(
    json,
    false
  );
}

export function TicketByCodeReadResponseTicketParticipantValiditiesInnerPricingCategoryFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): TicketByCodeReadResponseTicketParticipantValiditiesInnerPricingCategory {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    name_translated: json['name_translated'],
    age_translated: json['age_translated']
  };
}

export function TicketByCodeReadResponseTicketParticipantValiditiesInnerPricingCategoryToJSON(
  value?: TicketByCodeReadResponseTicketParticipantValiditiesInnerPricingCategory | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    name_translated: value.name_translated,
    age_translated: value.age_translated
  };
}
