/* tslint:disable */
/* eslint-disable */
/**
 * Ticketing Portal
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { EmailTemplateTranslation } from './EmailTemplateTranslation';
import {
  EmailTemplateTranslationFromJSON,
  EmailTemplateTranslationFromJSONTyped,
  EmailTemplateTranslationToJSON
} from './EmailTemplateTranslation';
import type { EmailTriggerProductId } from './EmailTriggerProductId';
import {
  EmailTriggerProductIdFromJSON,
  EmailTriggerProductIdFromJSONTyped,
  EmailTriggerProductIdToJSON
} from './EmailTriggerProductId';
import type { EmailTriggerProductOptionId } from './EmailTriggerProductOptionId';
import {
  EmailTriggerProductOptionIdFromJSON,
  EmailTriggerProductOptionIdFromJSONTyped,
  EmailTriggerProductOptionIdToJSON
} from './EmailTriggerProductOptionId';

/**
 *
 * @export
 * @interface EmailTriggerUpdateRequest
 */
export interface EmailTriggerUpdateRequest {
  /**
   *
   * @type {string}
   * @memberof EmailTriggerUpdateRequest
   */
  locale_code: string;
  /**
   *
   * @type {string}
   * @memberof EmailTriggerUpdateRequest
   */
  status: EmailTriggerUpdateRequestStatusEnum;
  /**
   *
   * @type {Array<EmailTemplateTranslation>}
   * @memberof EmailTriggerUpdateRequest
   */
  email_template_translations: Array<EmailTemplateTranslation>;
  /**
   *
   * @type {string}
   * @memberof EmailTriggerUpdateRequest
   */
  schedule_relative_to: EmailTriggerUpdateRequestScheduleRelativeToEnum;
  /**
   *
   * @type {string}
   * @memberof EmailTriggerUpdateRequest
   */
  schedule_offset: string;
  /**
   *
   * @type {string}
   * @memberof EmailTriggerUpdateRequest
   */
  triggers_on: EmailTriggerUpdateRequestTriggersOnEnum;
  /**
   *
   * @type {boolean}
   * @memberof EmailTriggerUpdateRequest
   */
  send_only_if_uncancelled: boolean;
  /**
   *
   * @type {string}
   * @memberof EmailTriggerUpdateRequest
   */
  name: string;
  /**
   *
   * @type {EmailTriggerProductId}
   * @memberof EmailTriggerUpdateRequest
   */
  product_id: EmailTriggerProductId;
  /**
   *
   * @type {EmailTriggerProductOptionId}
   * @memberof EmailTriggerUpdateRequest
   */
  product_option_id: EmailTriggerProductOptionId;
}

/**
 * @export
 */
export const EmailTriggerUpdateRequestStatusEnum = {
  Draft: 'draft',
  Active: 'active',
  Inactive: 'inactive',
  Deleted: 'deleted'
} as const;
export type EmailTriggerUpdateRequestStatusEnum =
  (typeof EmailTriggerUpdateRequestStatusEnum)[keyof typeof EmailTriggerUpdateRequestStatusEnum];

/**
 * @export
 */
export const EmailTriggerUpdateRequestScheduleRelativeToEnum = {
  PurchaseDateTime: 'purchase_date_time',
  VisitDateTime: 'visit_date_time'
} as const;
export type EmailTriggerUpdateRequestScheduleRelativeToEnum =
  (typeof EmailTriggerUpdateRequestScheduleRelativeToEnum)[keyof typeof EmailTriggerUpdateRequestScheduleRelativeToEnum];

/**
 * @export
 */
export const EmailTriggerUpdateRequestTriggersOnEnum = {
  CartConfirmed: 'cart_confirmed'
} as const;
export type EmailTriggerUpdateRequestTriggersOnEnum =
  (typeof EmailTriggerUpdateRequestTriggersOnEnum)[keyof typeof EmailTriggerUpdateRequestTriggersOnEnum];

/**
 * Check if a given object implements the EmailTriggerUpdateRequest interface.
 */
export function instanceOfEmailTriggerUpdateRequest(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && 'locale_code' in value;
  isInstance = isInstance && 'status' in value;
  isInstance = isInstance && 'email_template_translations' in value;
  isInstance = isInstance && 'schedule_relative_to' in value;
  isInstance = isInstance && 'schedule_offset' in value;
  isInstance = isInstance && 'triggers_on' in value;
  isInstance = isInstance && 'send_only_if_uncancelled' in value;
  isInstance = isInstance && 'name' in value;
  isInstance = isInstance && 'product_id' in value;
  isInstance = isInstance && 'product_option_id' in value;

  return isInstance;
}

export function EmailTriggerUpdateRequestFromJSON(json: any): EmailTriggerUpdateRequest {
  return EmailTriggerUpdateRequestFromJSONTyped(json, false);
}

export function EmailTriggerUpdateRequestFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): EmailTriggerUpdateRequest {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    locale_code: json['locale_code'],
    status: json['status'],
    email_template_translations: (json['email_template_translations'] as Array<any>).map(
      EmailTemplateTranslationFromJSON
    ),
    schedule_relative_to: json['schedule_relative_to'],
    schedule_offset: json['schedule_offset'],
    triggers_on: json['triggers_on'],
    send_only_if_uncancelled: json['send_only_if_uncancelled'],
    name: json['name'],
    product_id: EmailTriggerProductIdFromJSON(json['product_id']),
    product_option_id: EmailTriggerProductOptionIdFromJSON(json['product_option_id'])
  };
}

export function EmailTriggerUpdateRequestToJSON(value?: EmailTriggerUpdateRequest | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    locale_code: value.locale_code,
    status: value.status,
    email_template_translations: (value.email_template_translations as Array<any>).map(
      EmailTemplateTranslationToJSON
    ),
    schedule_relative_to: value.schedule_relative_to,
    schedule_offset: value.schedule_offset,
    triggers_on: value.triggers_on,
    send_only_if_uncancelled: value.send_only_if_uncancelled,
    name: value.name,
    product_id: EmailTriggerProductIdToJSON(value.product_id),
    product_option_id: EmailTriggerProductOptionIdToJSON(value.product_option_id)
  };
}
