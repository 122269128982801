/* tslint:disable */
/* eslint-disable */
/**
 * Ticketing Portal
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { PromoCodeUsagesReadResponseMeta } from './PromoCodeUsagesReadResponseMeta';
import {
  PromoCodeUsagesReadResponseMetaFromJSON,
  PromoCodeUsagesReadResponseMetaFromJSONTyped,
  PromoCodeUsagesReadResponseMetaToJSON
} from './PromoCodeUsagesReadResponseMeta';
import type { PromoCodeUsagesReadResponsePromoCodeUsesInner } from './PromoCodeUsagesReadResponsePromoCodeUsesInner';
import {
  PromoCodeUsagesReadResponsePromoCodeUsesInnerFromJSON,
  PromoCodeUsagesReadResponsePromoCodeUsesInnerFromJSONTyped,
  PromoCodeUsagesReadResponsePromoCodeUsesInnerToJSON
} from './PromoCodeUsagesReadResponsePromoCodeUsesInner';

/**
 *
 * @export
 * @interface PromoCodeUsagesReadResponse
 */
export interface PromoCodeUsagesReadResponse {
  /**
   *
   * @type {boolean}
   * @memberof PromoCodeUsagesReadResponse
   */
  success: boolean;
  /**
   *
   * @type {string}
   * @memberof PromoCodeUsagesReadResponse
   */
  exception_code?: string;
  /**
   *
   * @type {string}
   * @memberof PromoCodeUsagesReadResponse
   */
  exception_message?: string;
  /**
   *
   * @type {Array<PromoCodeUsagesReadResponsePromoCodeUsesInner>}
   * @memberof PromoCodeUsagesReadResponse
   */
  promo_code_uses: Array<PromoCodeUsagesReadResponsePromoCodeUsesInner>;
  /**
   *
   * @type {PromoCodeUsagesReadResponseMeta}
   * @memberof PromoCodeUsagesReadResponse
   */
  meta: PromoCodeUsagesReadResponseMeta;
}

/**
 * Check if a given object implements the PromoCodeUsagesReadResponse interface.
 */
export function instanceOfPromoCodeUsagesReadResponse(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && 'success' in value;
  isInstance = isInstance && 'promo_code_uses' in value;
  isInstance = isInstance && 'meta' in value;

  return isInstance;
}

export function PromoCodeUsagesReadResponseFromJSON(json: any): PromoCodeUsagesReadResponse {
  return PromoCodeUsagesReadResponseFromJSONTyped(json, false);
}

export function PromoCodeUsagesReadResponseFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): PromoCodeUsagesReadResponse {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    success: json['success'],
    exception_code: !exists(json, 'exception_code') ? undefined : json['exception_code'],
    exception_message: !exists(json, 'exception_message') ? undefined : json['exception_message'],
    promo_code_uses: (json['promo_code_uses'] as Array<any>).map(
      PromoCodeUsagesReadResponsePromoCodeUsesInnerFromJSON
    ),
    meta: PromoCodeUsagesReadResponseMetaFromJSON(json['meta'])
  };
}

export function PromoCodeUsagesReadResponseToJSON(value?: PromoCodeUsagesReadResponse | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    success: value.success,
    exception_code: value.exception_code,
    exception_message: value.exception_message,
    promo_code_uses: (value.promo_code_uses as Array<any>).map(
      PromoCodeUsagesReadResponsePromoCodeUsesInnerToJSON
    ),
    meta: PromoCodeUsagesReadResponseMetaToJSON(value.meta)
  };
}
