/* tslint:disable */
/* eslint-disable */
/**
 * Ticketing Portal
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { InitializationReadResponseLocalesInner } from './InitializationReadResponseLocalesInner';
import {
  InitializationReadResponseLocalesInnerFromJSON,
  InitializationReadResponseLocalesInnerFromJSONTyped,
  InitializationReadResponseLocalesInnerToJSON
} from './InitializationReadResponseLocalesInner';
import type { InitializationReadResponsePartner } from './InitializationReadResponsePartner';
import {
  InitializationReadResponsePartnerFromJSON,
  InitializationReadResponsePartnerFromJSONTyped,
  InitializationReadResponsePartnerToJSON
} from './InitializationReadResponsePartner';
import type { InitializationReadResponseUser } from './InitializationReadResponseUser';
import {
  InitializationReadResponseUserFromJSON,
  InitializationReadResponseUserFromJSONTyped,
  InitializationReadResponseUserToJSON
} from './InitializationReadResponseUser';

/**
 *
 * @export
 * @interface InitializationReadResponse
 */
export interface InitializationReadResponse {
  /**
   *
   * @type {boolean}
   * @memberof InitializationReadResponse
   */
  success: boolean;
  /**
   *
   * @type {string}
   * @memberof InitializationReadResponse
   */
  exception_code?: string;
  /**
   *
   * @type {string}
   * @memberof InitializationReadResponse
   */
  exception_message?: string;
  /**
   *
   * @type {InitializationReadResponseUser}
   * @memberof InitializationReadResponse
   */
  user: InitializationReadResponseUser;
  /**
   *
   * @type {InitializationReadResponsePartner}
   * @memberof InitializationReadResponse
   */
  partner: InitializationReadResponsePartner;
  /**
   *
   * @type {Array<InitializationReadResponseLocalesInner>}
   * @memberof InitializationReadResponse
   */
  locales: Array<InitializationReadResponseLocalesInner>;
  /**
   *
   * @type {Array<string>}
   * @memberof InitializationReadResponse
   */
  permissions: Array<InitializationReadResponsePermissionsEnum>;
}

/**
 * @export
 */
export const InitializationReadResponsePermissionsEnum = {
  ReportView: 'portal_report_view',
  DashboardView: 'portal_dashboard_view',
  CustomerView: 'portal_customer_view',
  CustomerManage: 'portal_customer_manage',
  OrderView: 'portal_order_view',
  OrderManage: 'portal_order_manage',
  ProductView: 'portal_product_view',
  ProductManage: 'portal_product_manage',
  ChannelView: 'portal_channel_view',
  ChannelManage: 'portal_channel_manage',
  PromoCodeView: 'portal_promo_code_view',
  PromoCodeManage: 'portal_promo_code_manage',
  GiftCodeView: 'portal_gift_code_view',
  GiftCodeManage: 'portal_gift_code_manage',
  SettingsView: 'portal_settings_view',
  SettingsManage: 'portal_settings_manage',
  ScannerView: 'portal_scanner_view',
  ScannerManage: 'portal_scanner_manage',
  UserView: 'portal_user_view',
  UserManage: 'portal_user_manage',
  QuestionView: 'portal_question_view',
  QuestionManage: 'portal_question_manage',
  EmailView: 'portal_email_view',
  EmailManage: 'portal_email_manage'
} as const;
export type InitializationReadResponsePermissionsEnum =
  (typeof InitializationReadResponsePermissionsEnum)[keyof typeof InitializationReadResponsePermissionsEnum];

/**
 * Check if a given object implements the InitializationReadResponse interface.
 */
export function instanceOfInitializationReadResponse(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && 'success' in value;
  isInstance = isInstance && 'user' in value;
  isInstance = isInstance && 'partner' in value;
  isInstance = isInstance && 'locales' in value;
  isInstance = isInstance && 'permissions' in value;

  return isInstance;
}

export function InitializationReadResponseFromJSON(json: any): InitializationReadResponse {
  return InitializationReadResponseFromJSONTyped(json, false);
}

export function InitializationReadResponseFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): InitializationReadResponse {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    success: json['success'],
    exception_code: !exists(json, 'exception_code') ? undefined : json['exception_code'],
    exception_message: !exists(json, 'exception_message') ? undefined : json['exception_message'],
    user: InitializationReadResponseUserFromJSON(json['user']),
    partner: InitializationReadResponsePartnerFromJSON(json['partner']),
    locales: (json['locales'] as Array<any>).map(InitializationReadResponseLocalesInnerFromJSON),
    permissions: json['permissions']
  };
}

export function InitializationReadResponseToJSON(value?: InitializationReadResponse | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    success: value.success,
    exception_code: value.exception_code,
    exception_message: value.exception_message,
    user: InitializationReadResponseUserToJSON(value.user),
    partner: InitializationReadResponsePartnerToJSON(value.partner),
    locales: (value.locales as Array<any>).map(InitializationReadResponseLocalesInnerToJSON),
    permissions: value.permissions
  };
}
