/* tslint:disable */
/* eslint-disable */
/**
 * Ticketing Portal
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { EmailTemplateTranslation } from './EmailTemplateTranslation';
import {
  EmailTemplateTranslationFromJSON,
  EmailTemplateTranslationFromJSONTyped,
  EmailTemplateTranslationToJSON
} from './EmailTemplateTranslation';
import type { EmailTriggerProductId } from './EmailTriggerProductId';
import {
  EmailTriggerProductIdFromJSON,
  EmailTriggerProductIdFromJSONTyped,
  EmailTriggerProductIdToJSON
} from './EmailTriggerProductId';
import type { EmailTriggerProductOptionId } from './EmailTriggerProductOptionId';
import {
  EmailTriggerProductOptionIdFromJSON,
  EmailTriggerProductOptionIdFromJSONTyped,
  EmailTriggerProductOptionIdToJSON
} from './EmailTriggerProductOptionId';

/**
 *
 * @export
 * @interface EmailTrigger
 */
export interface EmailTrigger {
  /**
   *
   * @type {string}
   * @memberof EmailTrigger
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof EmailTrigger
   */
  status: EmailTriggerStatusEnum;
  /**
   *
   * @type {Array<EmailTemplateTranslation>}
   * @memberof EmailTrigger
   */
  email_template_translations: Array<EmailTemplateTranslation>;
  /**
   *
   * @type {string}
   * @memberof EmailTrigger
   */
  schedule_relative_to: EmailTriggerScheduleRelativeToEnum;
  /**
   *
   * @type {string}
   * @memberof EmailTrigger
   */
  schedule_relative_to_translated: string;
  /**
   *
   * @type {string}
   * @memberof EmailTrigger
   */
  schedule_offset: string;
  /**
   *
   * @type {string}
   * @memberof EmailTrigger
   */
  schedule_offset_translated: string;
  /**
   *
   * @type {number}
   * @memberof EmailTrigger
   */
  scheduled_emails_count: number;
  /**
   *
   * @type {string}
   * @memberof EmailTrigger
   */
  triggers_on: EmailTriggerTriggersOnEnum;
  /**
   *
   * @type {string}
   * @memberof EmailTrigger
   */
  triggers_on_translated: string;
  /**
   *
   * @type {boolean}
   * @memberof EmailTrigger
   */
  send_only_if_uncancelled: boolean;
  /**
   *
   * @type {string}
   * @memberof EmailTrigger
   */
  name: string;
  /**
   *
   * @type {EmailTriggerProductId}
   * @memberof EmailTrigger
   */
  product_id: EmailTriggerProductId;
  /**
   *
   * @type {string}
   * @memberof EmailTrigger
   */
  product_name: string | null;
  /**
   *
   * @type {EmailTriggerProductOptionId}
   * @memberof EmailTrigger
   */
  product_option_id: EmailTriggerProductOptionId;
  /**
   *
   * @type {string}
   * @memberof EmailTrigger
   */
  product_option_name: string | null;
  /**
   *
   * @type {string}
   * @memberof EmailTrigger
   */
  created_at: string;
}

/**
 * @export
 */
export const EmailTriggerStatusEnum = {
  Draft: 'draft',
  Active: 'active',
  Inactive: 'inactive',
  Deleted: 'deleted'
} as const;
export type EmailTriggerStatusEnum =
  (typeof EmailTriggerStatusEnum)[keyof typeof EmailTriggerStatusEnum];

/**
 * @export
 */
export const EmailTriggerScheduleRelativeToEnum = {
  PurchaseDateTime: 'purchase_date_time',
  VisitDateTime: 'visit_date_time'
} as const;
export type EmailTriggerScheduleRelativeToEnum =
  (typeof EmailTriggerScheduleRelativeToEnum)[keyof typeof EmailTriggerScheduleRelativeToEnum];

/**
 * @export
 */
export const EmailTriggerTriggersOnEnum = {
  CartConfirmed: 'cart_confirmed'
} as const;
export type EmailTriggerTriggersOnEnum =
  (typeof EmailTriggerTriggersOnEnum)[keyof typeof EmailTriggerTriggersOnEnum];

/**
 * Check if a given object implements the EmailTrigger interface.
 */
export function instanceOfEmailTrigger(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && 'id' in value;
  isInstance = isInstance && 'status' in value;
  isInstance = isInstance && 'email_template_translations' in value;
  isInstance = isInstance && 'schedule_relative_to' in value;
  isInstance = isInstance && 'schedule_relative_to_translated' in value;
  isInstance = isInstance && 'schedule_offset' in value;
  isInstance = isInstance && 'schedule_offset_translated' in value;
  isInstance = isInstance && 'scheduled_emails_count' in value;
  isInstance = isInstance && 'triggers_on' in value;
  isInstance = isInstance && 'triggers_on_translated' in value;
  isInstance = isInstance && 'send_only_if_uncancelled' in value;
  isInstance = isInstance && 'name' in value;
  isInstance = isInstance && 'product_id' in value;
  isInstance = isInstance && 'product_name' in value;
  isInstance = isInstance && 'product_option_id' in value;
  isInstance = isInstance && 'product_option_name' in value;
  isInstance = isInstance && 'created_at' in value;

  return isInstance;
}

export function EmailTriggerFromJSON(json: any): EmailTrigger {
  return EmailTriggerFromJSONTyped(json, false);
}

export function EmailTriggerFromJSONTyped(json: any, ignoreDiscriminator: boolean): EmailTrigger {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: json['id'],
    status: json['status'],
    email_template_translations: (json['email_template_translations'] as Array<any>).map(
      EmailTemplateTranslationFromJSON
    ),
    schedule_relative_to: json['schedule_relative_to'],
    schedule_relative_to_translated: json['schedule_relative_to_translated'],
    schedule_offset: json['schedule_offset'],
    schedule_offset_translated: json['schedule_offset_translated'],
    scheduled_emails_count: json['scheduled_emails_count'],
    triggers_on: json['triggers_on'],
    triggers_on_translated: json['triggers_on_translated'],
    send_only_if_uncancelled: json['send_only_if_uncancelled'],
    name: json['name'],
    product_id: EmailTriggerProductIdFromJSON(json['product_id']),
    product_name: json['product_name'],
    product_option_id: EmailTriggerProductOptionIdFromJSON(json['product_option_id']),
    product_option_name: json['product_option_name'],
    created_at: json['created_at']
  };
}

export function EmailTriggerToJSON(value?: EmailTrigger | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    id: value.id,
    status: value.status,
    email_template_translations: (value.email_template_translations as Array<any>).map(
      EmailTemplateTranslationToJSON
    ),
    schedule_relative_to: value.schedule_relative_to,
    schedule_relative_to_translated: value.schedule_relative_to_translated,
    schedule_offset: value.schedule_offset,
    schedule_offset_translated: value.schedule_offset_translated,
    scheduled_emails_count: value.scheduled_emails_count,
    triggers_on: value.triggers_on,
    triggers_on_translated: value.triggers_on_translated,
    send_only_if_uncancelled: value.send_only_if_uncancelled,
    name: value.name,
    product_id: EmailTriggerProductIdToJSON(value.product_id),
    product_name: value.product_name,
    product_option_id: EmailTriggerProductOptionIdToJSON(value.product_option_id),
    product_option_name: value.product_option_name,
    created_at: value.created_at
  };
}
