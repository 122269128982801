/* tslint:disable */
/* eslint-disable */
/**
 * Ticketing Portal
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { CancellationPolicyReadResponseMeta } from './CancellationPolicyReadResponseMeta';
import {
  CancellationPolicyReadResponseMetaFromJSON,
  CancellationPolicyReadResponseMetaFromJSONTyped,
  CancellationPolicyReadResponseMetaToJSON
} from './CancellationPolicyReadResponseMeta';
import type { TicketsRedemptionReadResponseLogInner } from './TicketsRedemptionReadResponseLogInner';
import {
  TicketsRedemptionReadResponseLogInnerFromJSON,
  TicketsRedemptionReadResponseLogInnerFromJSONTyped,
  TicketsRedemptionReadResponseLogInnerToJSON
} from './TicketsRedemptionReadResponseLogInner';

/**
 *
 * @export
 * @interface TicketsRedemptionReadResponse
 */
export interface TicketsRedemptionReadResponse {
  /**
   *
   * @type {boolean}
   * @memberof TicketsRedemptionReadResponse
   */
  success: boolean;
  /**
   *
   * @type {string}
   * @memberof TicketsRedemptionReadResponse
   */
  exception_code?: string;
  /**
   *
   * @type {string}
   * @memberof TicketsRedemptionReadResponse
   */
  exception_message?: string;
  /**
   *
   * @type {CancellationPolicyReadResponseMeta}
   * @memberof TicketsRedemptionReadResponse
   */
  meta: CancellationPolicyReadResponseMeta;
  /**
   *
   * @type {Array<TicketsRedemptionReadResponseLogInner>}
   * @memberof TicketsRedemptionReadResponse
   */
  log: Array<TicketsRedemptionReadResponseLogInner>;
}

/**
 * Check if a given object implements the TicketsRedemptionReadResponse interface.
 */
export function instanceOfTicketsRedemptionReadResponse(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && 'success' in value;
  isInstance = isInstance && 'meta' in value;
  isInstance = isInstance && 'log' in value;

  return isInstance;
}

export function TicketsRedemptionReadResponseFromJSON(json: any): TicketsRedemptionReadResponse {
  return TicketsRedemptionReadResponseFromJSONTyped(json, false);
}

export function TicketsRedemptionReadResponseFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): TicketsRedemptionReadResponse {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    success: json['success'],
    exception_code: !exists(json, 'exception_code') ? undefined : json['exception_code'],
    exception_message: !exists(json, 'exception_message') ? undefined : json['exception_message'],
    meta: CancellationPolicyReadResponseMetaFromJSON(json['meta']),
    log: (json['log'] as Array<any>).map(TicketsRedemptionReadResponseLogInnerFromJSON)
  };
}

export function TicketsRedemptionReadResponseToJSON(
  value?: TicketsRedemptionReadResponse | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    success: value.success,
    exception_code: value.exception_code,
    exception_message: value.exception_message,
    meta: CancellationPolicyReadResponseMetaToJSON(value.meta),
    log: (value.log as Array<any>).map(TicketsRedemptionReadResponseLogInnerToJSON)
  };
}
