<template>
  <Field v-slot="{ value, handleChange }" :name="name" :rules="rules">
    <Select
      :filter="optionFilter"
      :model-value="value"
      :options="options"
      :option-label="optionLabel"
      :option-value="optionValue"
      :placeholder="placeholder"
      :disabled="disabled"
      :show-clear="showClear"
      @update:model-value="handleChange"
    />
  </Field>
</template>

<script setup lang="ts">
import { Field } from 'vee-validate';
import { toRefs } from 'vue';
import Select from 'primevue/select';

interface Props {
  autofocus?: boolean;
  name: string;
  placeholder?: string;
  options: Array<{ label: string; value: string }>;
  optionLabel?: string;
  optionValue?: string;
  optionFilter?: boolean;
  rules?: string;
  helpText?: string | null;
  disabled?: boolean;
  showClear?: boolean;
}
const props = withDefaults(defineProps<Props>(), {
  autofocus: false,
  name: '',
  placeholder: '',
  options: () => [],
  optionLabel: undefined,
  optionValue: undefined,
  optionFilter: true,
  rules: '',
  helpText: null,
  disabled: false,
  showClear: false
});
const { name, placeholder, optionLabel, optionValue, optionFilter, options, rules, showClear } =
  toRefs(props);
</script>
