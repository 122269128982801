/* tslint:disable */
/* eslint-disable */
/**
 * Ticketing Portal
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface GiftCodeReadResponseGiftCodesInner
 */
export interface GiftCodeReadResponseGiftCodesInner {
  /**
   *
   * @type {string}
   * @memberof GiftCodeReadResponseGiftCodesInner
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof GiftCodeReadResponseGiftCodesInner
   */
  code: string;
  /**
   *
   * @type {string}
   * @memberof GiftCodeReadResponseGiftCodesInner
   */
  amount: string;
  /**
   *
   * @type {number}
   * @memberof GiftCodeReadResponseGiftCodesInner
   */
  amount_unformatted: number;
  /**
   *
   * @type {string}
   * @memberof GiftCodeReadResponseGiftCodesInner
   */
  amount_left: string;
  /**
   *
   * @type {number}
   * @memberof GiftCodeReadResponseGiftCodesInner
   */
  amount_left_unformatted: number;
  /**
   *
   * @type {string}
   * @memberof GiftCodeReadResponseGiftCodesInner
   */
  created_at: string;
  /**
   *
   * @type {string}
   * @memberof GiftCodeReadResponseGiftCodesInner
   */
  valid_until?: string | null;
  /**
   *
   * @type {string}
   * @memberof GiftCodeReadResponseGiftCodesInner
   */
  status: GiftCodeReadResponseGiftCodesInnerStatusEnum;
}

/**
 * @export
 */
export const GiftCodeReadResponseGiftCodesInnerStatusEnum = {
  Active: 'active',
  Processed: 'processed',
  DeletedForCloning: 'deleted_for_cloning'
} as const;
export type GiftCodeReadResponseGiftCodesInnerStatusEnum =
  (typeof GiftCodeReadResponseGiftCodesInnerStatusEnum)[keyof typeof GiftCodeReadResponseGiftCodesInnerStatusEnum];

/**
 * Check if a given object implements the GiftCodeReadResponseGiftCodesInner interface.
 */
export function instanceOfGiftCodeReadResponseGiftCodesInner(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && 'id' in value;
  isInstance = isInstance && 'code' in value;
  isInstance = isInstance && 'amount' in value;
  isInstance = isInstance && 'amount_unformatted' in value;
  isInstance = isInstance && 'amount_left' in value;
  isInstance = isInstance && 'amount_left_unformatted' in value;
  isInstance = isInstance && 'created_at' in value;
  isInstance = isInstance && 'status' in value;

  return isInstance;
}

export function GiftCodeReadResponseGiftCodesInnerFromJSON(
  json: any
): GiftCodeReadResponseGiftCodesInner {
  return GiftCodeReadResponseGiftCodesInnerFromJSONTyped(json, false);
}

export function GiftCodeReadResponseGiftCodesInnerFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): GiftCodeReadResponseGiftCodesInner {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: json['id'],
    code: json['code'],
    amount: json['amount'],
    amount_unformatted: json['amount_unformatted'],
    amount_left: json['amount_left'],
    amount_left_unformatted: json['amount_left_unformatted'],
    created_at: json['created_at'],
    valid_until: !exists(json, 'valid_until') ? undefined : json['valid_until'],
    status: json['status']
  };
}

export function GiftCodeReadResponseGiftCodesInnerToJSON(
  value?: GiftCodeReadResponseGiftCodesInner | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    id: value.id,
    code: value.code,
    amount: value.amount,
    amount_unformatted: value.amount_unformatted,
    amount_left: value.amount_left,
    amount_left_unformatted: value.amount_left_unformatted,
    created_at: value.created_at,
    valid_until: value.valid_until,
    status: value.status
  };
}
