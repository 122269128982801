/* tslint:disable */
/* eslint-disable */
/**
 * Ticketing Portal
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { BookingByIdReadResponseBookingPartnerQuestionAnswersBookingInner } from './BookingByIdReadResponseBookingPartnerQuestionAnswersBookingInner';
import {
  BookingByIdReadResponseBookingPartnerQuestionAnswersBookingInnerFromJSON,
  BookingByIdReadResponseBookingPartnerQuestionAnswersBookingInnerFromJSONTyped,
  BookingByIdReadResponseBookingPartnerQuestionAnswersBookingInnerToJSON
} from './BookingByIdReadResponseBookingPartnerQuestionAnswersBookingInner';
import type { BookingByIdReadResponseBookingPartnerQuestionAnswersParticipantInner } from './BookingByIdReadResponseBookingPartnerQuestionAnswersParticipantInner';
import {
  BookingByIdReadResponseBookingPartnerQuestionAnswersParticipantInnerFromJSON,
  BookingByIdReadResponseBookingPartnerQuestionAnswersParticipantInnerFromJSONTyped,
  BookingByIdReadResponseBookingPartnerQuestionAnswersParticipantInnerToJSON
} from './BookingByIdReadResponseBookingPartnerQuestionAnswersParticipantInner';

/**
 *
 * @export
 * @interface BookingByIdReadResponseBookingPartnerQuestionAnswers
 */
export interface BookingByIdReadResponseBookingPartnerQuestionAnswers {
  /**
   *
   * @type {Array<BookingByIdReadResponseBookingPartnerQuestionAnswersBookingInner>}
   * @memberof BookingByIdReadResponseBookingPartnerQuestionAnswers
   */
  booking: Array<BookingByIdReadResponseBookingPartnerQuestionAnswersBookingInner>;
  /**
   *
   * @type {Array<BookingByIdReadResponseBookingPartnerQuestionAnswersParticipantInner>}
   * @memberof BookingByIdReadResponseBookingPartnerQuestionAnswers
   */
  participant: Array<BookingByIdReadResponseBookingPartnerQuestionAnswersParticipantInner>;
}

/**
 * Check if a given object implements the BookingByIdReadResponseBookingPartnerQuestionAnswers interface.
 */
export function instanceOfBookingByIdReadResponseBookingPartnerQuestionAnswers(
  value: object
): boolean {
  let isInstance = true;
  isInstance = isInstance && 'booking' in value;
  isInstance = isInstance && 'participant' in value;

  return isInstance;
}

export function BookingByIdReadResponseBookingPartnerQuestionAnswersFromJSON(
  json: any
): BookingByIdReadResponseBookingPartnerQuestionAnswers {
  return BookingByIdReadResponseBookingPartnerQuestionAnswersFromJSONTyped(json, false);
}

export function BookingByIdReadResponseBookingPartnerQuestionAnswersFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): BookingByIdReadResponseBookingPartnerQuestionAnswers {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    booking: (json['booking'] as Array<any>).map(
      BookingByIdReadResponseBookingPartnerQuestionAnswersBookingInnerFromJSON
    ),
    participant: (json['participant'] as Array<any>).map(
      BookingByIdReadResponseBookingPartnerQuestionAnswersParticipantInnerFromJSON
    )
  };
}

export function BookingByIdReadResponseBookingPartnerQuestionAnswersToJSON(
  value?: BookingByIdReadResponseBookingPartnerQuestionAnswers | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    booking: (value.booking as Array<any>).map(
      BookingByIdReadResponseBookingPartnerQuestionAnswersBookingInnerToJSON
    ),
    participant: (value.participant as Array<any>).map(
      BookingByIdReadResponseBookingPartnerQuestionAnswersParticipantInnerToJSON
    )
  };
}
