/* tslint:disable */
/* eslint-disable */
/**
 * Ticketing Portal
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface GiftCodeOrdersReadResponseMeta
 */
export interface GiftCodeOrdersReadResponseMeta {
  /**
   * total number of completed gift code purchases
   * @type {number}
   * @memberof GiftCodeOrdersReadResponseMeta
   */
  total_count: number;
}

/**
 * Check if a given object implements the GiftCodeOrdersReadResponseMeta interface.
 */
export function instanceOfGiftCodeOrdersReadResponseMeta(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && 'total_count' in value;

  return isInstance;
}

export function GiftCodeOrdersReadResponseMetaFromJSON(json: any): GiftCodeOrdersReadResponseMeta {
  return GiftCodeOrdersReadResponseMetaFromJSONTyped(json, false);
}

export function GiftCodeOrdersReadResponseMetaFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): GiftCodeOrdersReadResponseMeta {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    total_count: json['total_count']
  };
}

export function GiftCodeOrdersReadResponseMetaToJSON(
  value?: GiftCodeOrdersReadResponseMeta | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    total_count: value.total_count
  };
}
