/* tslint:disable */
/* eslint-disable */
/**
 * Ticketing Portal
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ChannelsReadResponseMeta } from './ChannelsReadResponseMeta';
import {
  ChannelsReadResponseMetaFromJSON,
  ChannelsReadResponseMetaFromJSONTyped,
  ChannelsReadResponseMetaToJSON
} from './ChannelsReadResponseMeta';
import type { CustomersReadResponseCustomersInner } from './CustomersReadResponseCustomersInner';
import {
  CustomersReadResponseCustomersInnerFromJSON,
  CustomersReadResponseCustomersInnerFromJSONTyped,
  CustomersReadResponseCustomersInnerToJSON
} from './CustomersReadResponseCustomersInner';

/**
 *
 * @export
 * @interface CustomersReadResponse
 */
export interface CustomersReadResponse {
  /**
   *
   * @type {boolean}
   * @memberof CustomersReadResponse
   */
  success: boolean;
  /**
   *
   * @type {string}
   * @memberof CustomersReadResponse
   */
  exception_code?: string;
  /**
   *
   * @type {string}
   * @memberof CustomersReadResponse
   */
  exception_message?: string;
  /**
   *
   * @type {ChannelsReadResponseMeta}
   * @memberof CustomersReadResponse
   */
  meta: ChannelsReadResponseMeta;
  /**
   *
   * @type {Array<CustomersReadResponseCustomersInner>}
   * @memberof CustomersReadResponse
   */
  customers: Array<CustomersReadResponseCustomersInner>;
}

/**
 * Check if a given object implements the CustomersReadResponse interface.
 */
export function instanceOfCustomersReadResponse(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && 'success' in value;
  isInstance = isInstance && 'meta' in value;
  isInstance = isInstance && 'customers' in value;

  return isInstance;
}

export function CustomersReadResponseFromJSON(json: any): CustomersReadResponse {
  return CustomersReadResponseFromJSONTyped(json, false);
}

export function CustomersReadResponseFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): CustomersReadResponse {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    success: json['success'],
    exception_code: !exists(json, 'exception_code') ? undefined : json['exception_code'],
    exception_message: !exists(json, 'exception_message') ? undefined : json['exception_message'],
    meta: ChannelsReadResponseMetaFromJSON(json['meta']),
    customers: (json['customers'] as Array<any>).map(CustomersReadResponseCustomersInnerFromJSON)
  };
}

export function CustomersReadResponseToJSON(value?: CustomersReadResponse | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    success: value.success,
    exception_code: value.exception_code,
    exception_message: value.exception_message,
    meta: ChannelsReadResponseMetaToJSON(value.meta),
    customers: (value.customers as Array<any>).map(CustomersReadResponseCustomersInnerToJSON)
  };
}
