/* tslint:disable */
/* eslint-disable */
/**
 * Ticketing Portal
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { PricingFlexibleCreateRequestPricingCategoryPricesInner } from './PricingFlexibleCreateRequestPricingCategoryPricesInner';
import {
  PricingFlexibleCreateRequestPricingCategoryPricesInnerFromJSON,
  PricingFlexibleCreateRequestPricingCategoryPricesInnerFromJSONTyped,
  PricingFlexibleCreateRequestPricingCategoryPricesInnerToJSON
} from './PricingFlexibleCreateRequestPricingCategoryPricesInner';

/**
 *
 * @export
 * @interface PricingFlexibleCreateRequest
 */
export interface PricingFlexibleCreateRequest {
  /**
   *
   * @type {string}
   * @memberof PricingFlexibleCreateRequest
   */
  locale_code: string;
  /**
   *
   * @type {string}
   * @memberof PricingFlexibleCreateRequest
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof PricingFlexibleCreateRequest
   */
  product_option_id: string;
  /**
   *
   * @type {Array<PricingFlexibleCreateRequestPricingCategoryPricesInner>}
   * @memberof PricingFlexibleCreateRequest
   */
  pricing_category_prices: Array<PricingFlexibleCreateRequestPricingCategoryPricesInner>;
}

/**
 * Check if a given object implements the PricingFlexibleCreateRequest interface.
 */
export function instanceOfPricingFlexibleCreateRequest(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && 'locale_code' in value;
  isInstance = isInstance && 'name' in value;
  isInstance = isInstance && 'product_option_id' in value;
  isInstance = isInstance && 'pricing_category_prices' in value;

  return isInstance;
}

export function PricingFlexibleCreateRequestFromJSON(json: any): PricingFlexibleCreateRequest {
  return PricingFlexibleCreateRequestFromJSONTyped(json, false);
}

export function PricingFlexibleCreateRequestFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): PricingFlexibleCreateRequest {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    locale_code: json['locale_code'],
    name: json['name'],
    product_option_id: json['product_option_id'],
    pricing_category_prices: (json['pricing_category_prices'] as Array<any>).map(
      PricingFlexibleCreateRequestPricingCategoryPricesInnerFromJSON
    )
  };
}

export function PricingFlexibleCreateRequestToJSON(
  value?: PricingFlexibleCreateRequest | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    locale_code: value.locale_code,
    name: value.name,
    product_option_id: value.product_option_id,
    pricing_category_prices: (value.pricing_category_prices as Array<any>).map(
      PricingFlexibleCreateRequestPricingCategoryPricesInnerToJSON
    )
  };
}
