/* tslint:disable */
/* eslint-disable */
/**
 * Ticketing Portal
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface BookingByIdReadResponseRefundRefundsInner
 */
export interface BookingByIdReadResponseRefundRefundsInner {
  /**
   *
   * @type {number}
   * @memberof BookingByIdReadResponseRefundRefundsInner
   */
  amount: number;
  /**
   *
   * @type {string}
   * @memberof BookingByIdReadResponseRefundRefundsInner
   */
  amount_formatted: string;
  /**
   *
   * @type {string}
   * @memberof BookingByIdReadResponseRefundRefundsInner
   */
  payment_method: BookingByIdReadResponseRefundRefundsInnerPaymentMethodEnum;
  /**
   *
   * @type {string}
   * @memberof BookingByIdReadResponseRefundRefundsInner
   */
  refunded_at: string;
}

/**
 * @export
 */
export const BookingByIdReadResponseRefundRefundsInnerPaymentMethodEnum = {
  AdyenWeb: 'adyen_web',
  AdyenPos: 'adyen_pos',
  Cash: 'cash',
  GiftCode: 'gift_code',
  Other: 'other',
  ExternalDevice: 'external_device'
} as const;
export type BookingByIdReadResponseRefundRefundsInnerPaymentMethodEnum =
  (typeof BookingByIdReadResponseRefundRefundsInnerPaymentMethodEnum)[keyof typeof BookingByIdReadResponseRefundRefundsInnerPaymentMethodEnum];

/**
 * Check if a given object implements the BookingByIdReadResponseRefundRefundsInner interface.
 */
export function instanceOfBookingByIdReadResponseRefundRefundsInner(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && 'amount' in value;
  isInstance = isInstance && 'amount_formatted' in value;
  isInstance = isInstance && 'payment_method' in value;
  isInstance = isInstance && 'refunded_at' in value;

  return isInstance;
}

export function BookingByIdReadResponseRefundRefundsInnerFromJSON(
  json: any
): BookingByIdReadResponseRefundRefundsInner {
  return BookingByIdReadResponseRefundRefundsInnerFromJSONTyped(json, false);
}

export function BookingByIdReadResponseRefundRefundsInnerFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): BookingByIdReadResponseRefundRefundsInner {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    amount: json['amount'],
    amount_formatted: json['amount_formatted'],
    payment_method: json['payment_method'],
    refunded_at: json['refunded_at']
  };
}

export function BookingByIdReadResponseRefundRefundsInnerToJSON(
  value?: BookingByIdReadResponseRefundRefundsInner | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    amount: value.amount,
    amount_formatted: value.amount_formatted,
    payment_method: value.payment_method,
    refunded_at: value.refunded_at
  };
}
