/* tslint:disable */
/* eslint-disable */
/**
 * Ticketing Portal
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface UsersReadResponseUsersInnerRolesInner
 */
export interface UsersReadResponseUsersInnerRolesInner {
  /**
   *
   * @type {string}
   * @memberof UsersReadResponseUsersInnerRolesInner
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof UsersReadResponseUsersInnerRolesInner
   */
  display_name: string;
}

/**
 * Check if a given object implements the UsersReadResponseUsersInnerRolesInner interface.
 */
export function instanceOfUsersReadResponseUsersInnerRolesInner(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && 'id' in value;
  isInstance = isInstance && 'display_name' in value;

  return isInstance;
}

export function UsersReadResponseUsersInnerRolesInnerFromJSON(
  json: any
): UsersReadResponseUsersInnerRolesInner {
  return UsersReadResponseUsersInnerRolesInnerFromJSONTyped(json, false);
}

export function UsersReadResponseUsersInnerRolesInnerFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): UsersReadResponseUsersInnerRolesInner {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: json['id'],
    display_name: json['display_name']
  };
}

export function UsersReadResponseUsersInnerRolesInnerToJSON(
  value?: UsersReadResponseUsersInnerRolesInner | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    id: value.id,
    display_name: value.display_name
  };
}
