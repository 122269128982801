/* tslint:disable */
/* eslint-disable */
/**
 * Ticketing Portal
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { BookingByIdReadResponseBookingPartnerQuestionAnswers } from './BookingByIdReadResponseBookingPartnerQuestionAnswers';
import {
  BookingByIdReadResponseBookingPartnerQuestionAnswersFromJSON,
  BookingByIdReadResponseBookingPartnerQuestionAnswersFromJSONTyped,
  BookingByIdReadResponseBookingPartnerQuestionAnswersToJSON
} from './BookingByIdReadResponseBookingPartnerQuestionAnswers';
import type { OrdersReadResponseOrdersInnerAddons } from './OrdersReadResponseOrdersInnerAddons';
import {
  OrdersReadResponseOrdersInnerAddonsFromJSON,
  OrdersReadResponseOrdersInnerAddonsFromJSONTyped,
  OrdersReadResponseOrdersInnerAddonsToJSON
} from './OrdersReadResponseOrdersInnerAddons';
import type { OrdersReadResponseOrdersInnerParticipants } from './OrdersReadResponseOrdersInnerParticipants';
import {
  OrdersReadResponseOrdersInnerParticipantsFromJSON,
  OrdersReadResponseOrdersInnerParticipantsFromJSONTyped,
  OrdersReadResponseOrdersInnerParticipantsToJSON
} from './OrdersReadResponseOrdersInnerParticipants';

/**
 *
 * @export
 * @interface OrdersReadResponseOrdersInner
 */
export interface OrdersReadResponseOrdersInner {
  /**
   *
   * @type {string}
   * @memberof OrdersReadResponseOrdersInner
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof OrdersReadResponseOrdersInner
   */
  hash_code: string;
  /**
   *
   * @type {string}
   * @memberof OrdersReadResponseOrdersInner
   */
  status: OrdersReadResponseOrdersInnerStatusEnum;
  /**
   *
   * @type {string}
   * @memberof OrdersReadResponseOrdersInner
   */
  revenue: string;
  /**
   *
   * @type {number}
   * @memberof OrdersReadResponseOrdersInner
   */
  revenue_amount: number;
  /**
   *
   * @type {string}
   * @memberof OrdersReadResponseOrdersInner
   */
  revenue_currency: string;
  /**
   *
   * @type {number}
   * @memberof OrdersReadResponseOrdersInner
   */
  participants_total: number;
  /**
   *
   * @type {OrdersReadResponseOrdersInnerParticipants}
   * @memberof OrdersReadResponseOrdersInner
   */
  participants: OrdersReadResponseOrdersInnerParticipants;
  /**
   *
   * @type {number}
   * @memberof OrdersReadResponseOrdersInner
   */
  addon_total: number;
  /**
   *
   * @type {OrdersReadResponseOrdersInnerAddons}
   * @memberof OrdersReadResponseOrdersInner
   */
  addons: OrdersReadResponseOrdersInnerAddons;
  /**
   *
   * @type {string}
   * @memberof OrdersReadResponseOrdersInner
   */
  purchase_date: string;
  /**
   *
   * @type {string}
   * @memberof OrdersReadResponseOrdersInner
   */
  customer_name: string | null;
  /**
   *
   * @type {string}
   * @memberof OrdersReadResponseOrdersInner
   */
  customer_email: string | null;
  /**
   *
   * @type {string}
   * @memberof OrdersReadResponseOrdersInner
   */
  customer_phone: string | null;
  /**
   *
   * @type {string}
   * @memberof OrdersReadResponseOrdersInner
   */
  customer_locale: string | null;
  /**
   *
   * @type {string}
   * @memberof OrdersReadResponseOrdersInner
   */
  travel_date: string;
  /**
   *
   * @type {string}
   * @memberof OrdersReadResponseOrdersInner
   */
  travel_time: string;
  /**
   *
   * @type {string}
   * @memberof OrdersReadResponseOrdersInner
   */
  channel: string;
  /**
   *
   * @type {string}
   * @memberof OrdersReadResponseOrdersInner
   */
  channel_comment: string | null;
  /**
   *
   * @type {string}
   * @memberof OrdersReadResponseOrdersInner
   */
  cancelled_at: string | null;
  /**
   *
   * @type {string}
   * @memberof OrdersReadResponseOrdersInner
   */
  product: string | null;
  /**
   *
   * @type {string}
   * @memberof OrdersReadResponseOrdersInner
   */
  product_option: string;
  /**
   *
   * @type {string}
   * @memberof OrdersReadResponseOrdersInner
   */
  taxes: string;
  /**
   *
   * @type {number}
   * @memberof OrdersReadResponseOrdersInner
   */
  taxes_amount: number;
  /**
   *
   * @type {string}
   * @memberof OrdersReadResponseOrdersInner
   */
  taxes_currency: string;
  /**
   *
   * @type {BookingByIdReadResponseBookingPartnerQuestionAnswers}
   * @memberof OrdersReadResponseOrdersInner
   */
  question_answers?: BookingByIdReadResponseBookingPartnerQuestionAnswers;
}

/**
 * @export
 */
export const OrdersReadResponseOrdersInnerStatusEnum = {
  Active: 'active',
  CancelledByCustomer: 'cancelled_by_customer',
  CancelledByPartner: 'cancelled_by_partner',
  DeletedByCustomer: 'deleted_by_customer',
  Processed: 'processed',
  CancelledByAdmin: 'cancelled_by_admin',
  DeletedByCleanupJob: 'deleted_by_cleanup_job',
  DeletedForCloning: 'deleted_for_cloning',
  ReservationSystemUnconfirmed: 'reservation_system_unconfirmed',
  AwaitingPartnerConfirmation: 'awaiting_partner_confirmation',
  PendingPaymentCompletion: 'pending_payment_completion'
} as const;
export type OrdersReadResponseOrdersInnerStatusEnum =
  (typeof OrdersReadResponseOrdersInnerStatusEnum)[keyof typeof OrdersReadResponseOrdersInnerStatusEnum];

/**
 * Check if a given object implements the OrdersReadResponseOrdersInner interface.
 */
export function instanceOfOrdersReadResponseOrdersInner(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && 'id' in value;
  isInstance = isInstance && 'hash_code' in value;
  isInstance = isInstance && 'status' in value;
  isInstance = isInstance && 'revenue' in value;
  isInstance = isInstance && 'revenue_amount' in value;
  isInstance = isInstance && 'revenue_currency' in value;
  isInstance = isInstance && 'participants_total' in value;
  isInstance = isInstance && 'participants' in value;
  isInstance = isInstance && 'addon_total' in value;
  isInstance = isInstance && 'addons' in value;
  isInstance = isInstance && 'purchase_date' in value;
  isInstance = isInstance && 'customer_name' in value;
  isInstance = isInstance && 'customer_email' in value;
  isInstance = isInstance && 'customer_phone' in value;
  isInstance = isInstance && 'customer_locale' in value;
  isInstance = isInstance && 'travel_date' in value;
  isInstance = isInstance && 'travel_time' in value;
  isInstance = isInstance && 'channel' in value;
  isInstance = isInstance && 'channel_comment' in value;
  isInstance = isInstance && 'cancelled_at' in value;
  isInstance = isInstance && 'product' in value;
  isInstance = isInstance && 'product_option' in value;
  isInstance = isInstance && 'taxes' in value;
  isInstance = isInstance && 'taxes_amount' in value;
  isInstance = isInstance && 'taxes_currency' in value;

  return isInstance;
}

export function OrdersReadResponseOrdersInnerFromJSON(json: any): OrdersReadResponseOrdersInner {
  return OrdersReadResponseOrdersInnerFromJSONTyped(json, false);
}

export function OrdersReadResponseOrdersInnerFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): OrdersReadResponseOrdersInner {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: json['id'],
    hash_code: json['hash_code'],
    status: json['status'],
    revenue: json['revenue'],
    revenue_amount: json['revenue_amount'],
    revenue_currency: json['revenue_currency'],
    participants_total: json['participants_total'],
    participants: OrdersReadResponseOrdersInnerParticipantsFromJSON(json['participants']),
    addon_total: json['addon_total'],
    addons: OrdersReadResponseOrdersInnerAddonsFromJSON(json['addons']),
    purchase_date: json['purchase_date'],
    customer_name: json['customer_name'],
    customer_email: json['customer_email'],
    customer_phone: json['customer_phone'],
    customer_locale: json['customer_locale'],
    travel_date: json['travel_date'],
    travel_time: json['travel_time'],
    channel: json['channel'],
    channel_comment: json['channel_comment'],
    cancelled_at: json['cancelled_at'],
    product: json['product'],
    product_option: json['product_option'],
    taxes: json['taxes'],
    taxes_amount: json['taxes_amount'],
    taxes_currency: json['taxes_currency'],
    question_answers: !exists(json, 'question_answers')
      ? undefined
      : BookingByIdReadResponseBookingPartnerQuestionAnswersFromJSON(json['question_answers'])
  };
}

export function OrdersReadResponseOrdersInnerToJSON(
  value?: OrdersReadResponseOrdersInner | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    id: value.id,
    hash_code: value.hash_code,
    status: value.status,
    revenue: value.revenue,
    revenue_amount: value.revenue_amount,
    revenue_currency: value.revenue_currency,
    participants_total: value.participants_total,
    participants: OrdersReadResponseOrdersInnerParticipantsToJSON(value.participants),
    addon_total: value.addon_total,
    addons: OrdersReadResponseOrdersInnerAddonsToJSON(value.addons),
    purchase_date: value.purchase_date,
    customer_name: value.customer_name,
    customer_email: value.customer_email,
    customer_phone: value.customer_phone,
    customer_locale: value.customer_locale,
    travel_date: value.travel_date,
    travel_time: value.travel_time,
    channel: value.channel,
    channel_comment: value.channel_comment,
    cancelled_at: value.cancelled_at,
    product: value.product,
    product_option: value.product_option,
    taxes: value.taxes,
    taxes_amount: value.taxes_amount,
    taxes_currency: value.taxes_currency,
    question_answers: BookingByIdReadResponseBookingPartnerQuestionAnswersToJSON(
      value.question_answers
    )
  };
}
