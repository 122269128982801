/* tslint:disable */
/* eslint-disable */
/**
 * Ticketing Portal
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { CancellationPolicyReadResponseCancellationPoliciesInner } from './CancellationPolicyReadResponseCancellationPoliciesInner';
import {
  CancellationPolicyReadResponseCancellationPoliciesInnerFromJSON,
  CancellationPolicyReadResponseCancellationPoliciesInnerFromJSONTyped,
  CancellationPolicyReadResponseCancellationPoliciesInnerToJSON
} from './CancellationPolicyReadResponseCancellationPoliciesInner';
import type { CancellationPolicyReadResponseMeta } from './CancellationPolicyReadResponseMeta';
import {
  CancellationPolicyReadResponseMetaFromJSON,
  CancellationPolicyReadResponseMetaFromJSONTyped,
  CancellationPolicyReadResponseMetaToJSON
} from './CancellationPolicyReadResponseMeta';

/**
 *
 * @export
 * @interface CancellationPolicyReadResponse
 */
export interface CancellationPolicyReadResponse {
  /**
   *
   * @type {boolean}
   * @memberof CancellationPolicyReadResponse
   */
  success: boolean;
  /**
   *
   * @type {string}
   * @memberof CancellationPolicyReadResponse
   */
  exception_code?: string;
  /**
   *
   * @type {string}
   * @memberof CancellationPolicyReadResponse
   */
  exception_message?: string;
  /**
   *
   * @type {Array<CancellationPolicyReadResponseCancellationPoliciesInner>}
   * @memberof CancellationPolicyReadResponse
   */
  cancellation_policies: Array<CancellationPolicyReadResponseCancellationPoliciesInner>;
  /**
   *
   * @type {CancellationPolicyReadResponseMeta}
   * @memberof CancellationPolicyReadResponse
   */
  meta: CancellationPolicyReadResponseMeta;
}

/**
 * Check if a given object implements the CancellationPolicyReadResponse interface.
 */
export function instanceOfCancellationPolicyReadResponse(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && 'success' in value;
  isInstance = isInstance && 'cancellation_policies' in value;
  isInstance = isInstance && 'meta' in value;

  return isInstance;
}

export function CancellationPolicyReadResponseFromJSON(json: any): CancellationPolicyReadResponse {
  return CancellationPolicyReadResponseFromJSONTyped(json, false);
}

export function CancellationPolicyReadResponseFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): CancellationPolicyReadResponse {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    success: json['success'],
    exception_code: !exists(json, 'exception_code') ? undefined : json['exception_code'],
    exception_message: !exists(json, 'exception_message') ? undefined : json['exception_message'],
    cancellation_policies: (json['cancellation_policies'] as Array<any>).map(
      CancellationPolicyReadResponseCancellationPoliciesInnerFromJSON
    ),
    meta: CancellationPolicyReadResponseMetaFromJSON(json['meta'])
  };
}

export function CancellationPolicyReadResponseToJSON(
  value?: CancellationPolicyReadResponse | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    success: value.success,
    exception_code: value.exception_code,
    exception_message: value.exception_message,
    cancellation_policies: (value.cancellation_policies as Array<any>).map(
      CancellationPolicyReadResponseCancellationPoliciesInnerToJSON
    ),
    meta: CancellationPolicyReadResponseMetaToJSON(value.meta)
  };
}
