/* tslint:disable */
/* eslint-disable */
/**
 * Ticketing Portal
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { IntegrationCreateRequestIntegration } from './IntegrationCreateRequestIntegration';
import {
  IntegrationCreateRequestIntegrationFromJSON,
  IntegrationCreateRequestIntegrationFromJSONTyped,
  IntegrationCreateRequestIntegrationToJSON
} from './IntegrationCreateRequestIntegration';

/**
 *
 * @export
 * @interface IntegrationCreateRequest
 */
export interface IntegrationCreateRequest {
  /**
   *
   * @type {string}
   * @memberof IntegrationCreateRequest
   */
  locale_code: string;
  /**
   *
   * @type {IntegrationCreateRequestIntegration}
   * @memberof IntegrationCreateRequest
   */
  integration?: IntegrationCreateRequestIntegration;
}

/**
 * Check if a given object implements the IntegrationCreateRequest interface.
 */
export function instanceOfIntegrationCreateRequest(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && 'locale_code' in value;

  return isInstance;
}

export function IntegrationCreateRequestFromJSON(json: any): IntegrationCreateRequest {
  return IntegrationCreateRequestFromJSONTyped(json, false);
}

export function IntegrationCreateRequestFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): IntegrationCreateRequest {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    locale_code: json['locale_code'],
    integration: !exists(json, 'integration')
      ? undefined
      : IntegrationCreateRequestIntegrationFromJSON(json['integration'])
  };
}

export function IntegrationCreateRequestToJSON(value?: IntegrationCreateRequest | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    locale_code: value.locale_code,
    integration: IntegrationCreateRequestIntegrationToJSON(value.integration)
  };
}
