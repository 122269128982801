import { ROUTE_NAMES } from '@/constants/routes';
import { PAGE_TITLES } from '@/constants/breadcrumbs';
import { InitializationReadResponsePermissionsEnum } from '@openapi/models';

export const GIFTCODES_ROUTES = [
  {
    path: 'sell/gift-codes',
    name: ROUTE_NAMES.GIFT_CODES,
    component: () => import('@/views/pages/sell/GiftCodes.vue'),
    meta: {
      breadcrumbs: [PAGE_TITLES.SELL, PAGE_TITLES.GIFT_CODES],
      label: PAGE_TITLES.GIFT_CODES,
      menu: ['main'],
      permissions: [InitializationReadResponsePermissionsEnum.GiftCodeView],
      icon: 'pi pi-fw pi-wallet'
    }
  },
  {
    path: 'sell/gift-codes/+',
    name: ROUTE_NAMES.NEW_GIFT_CODE,
    component: () => import('@/views/pages/sell/GiftCodeNew.vue'),
    meta: {
      breadcrumbs: [PAGE_TITLES.SELL, PAGE_TITLES.GIFT_CODES, PAGE_TITLES.NEW_GIFT_CODE]
    }
  },
  {
    path: 'sell/gift-codes/:giftCodeId',
    name: ROUTE_NAMES.EDIT_GIFT_CODE,
    component: () => import('@/views/pages/sell/GiftCodeEdit.vue'),
    meta: {
      breadcrumbs: [PAGE_TITLES.SELL, PAGE_TITLES.GIFT_CODES, PAGE_TITLES.EDIT_GIFT_CODE]
    }
  }
];
