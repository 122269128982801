<template>
  <Panel :header="t('portal_pricing_default_pricing')">
    <div v-for="(item, index) of fields" :key="index" class="flex flex-wrap justify-between">
      <div class="flex flex-initial flex-col">
        <p class="mb-0 font-bold">
          {{ getAddonField(item.value, 'name') }}
        </p>
      </div>
      <div class="flex flex-initial items-center justify-center">
        <Field v-slot="{ value, handleChange }" :name="`${name}[${index}].value`">
          <InputNumber
            :model-value="value"
            :currency="getCurrencyCode"
            mode="currency"
            :min-fraction-digits="getNumFractionDigits"
            :locale="getSelectedLocale"
            @update:model-value="handleChange"
          ></InputNumber>
        </Field>
      </div>
      <Divider v-if="index !== addons.length - 1" />
    </div>
  </Panel>
</template>

<script lang="ts" setup>
import type {
  PricingByIdReadResponsePricingAddonPricesInner,
  AddonsReadResponseAddonsInner
} from '@openapi/models';
import { Field, type FieldEntry } from 'vee-validate';
import { toRefs, type PropType } from 'vue';
import Panel from 'primevue/panel';
import Divider from 'primevue/divider';
import { useI18n } from 'vue-i18n';
import InputNumber from 'primevue/inputnumber';
import { useCurrencyStore } from '@/stores/currencies';
import { useLocaleStore } from '@/stores/locales';

const { t } = useI18n();
const props = defineProps({
  name: {
    type: String,
    required: true
  },
  placeholder: {
    type: String,
    required: false,
    default: ''
  },
  rules: {
    type: String,
    required: false,
    default: 'required'
  },
  fields: {
    type: Array as PropType<FieldEntry<PricingByIdReadResponsePricingAddonPricesInner>[]>,
    required: false,
    default: () => []
  },
  addons: {
    type: Array as PropType<AddonsReadResponseAddonsInner[]>,
    required: true,
    default: () => []
  },
  disabled: {
    type: Boolean,
    required: false,
    default: () => false
  }
});
const { getCurrencyCode, getNumFractionDigits } = useCurrencyStore();
const { getSelectedLocale } = useLocaleStore();
const { name, addons, fields } = toRefs(props);

const getAddonField = (
  fieldEntry: PricingByIdReadResponsePricingAddonPricesInner,
  field: keyof AddonsReadResponseAddonsInner
) => {
  const addon = addons.value.find((item) => item.id === fieldEntry.addon_id);
  return addon ? addon[field] : '';
};
</script>
