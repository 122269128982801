/* tslint:disable */
/* eslint-disable */
/**
 * Ticketing Portal
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface GiftCodeOrdersReadResponseGiftCodeOrdersInner
 */
export interface GiftCodeOrdersReadResponseGiftCodeOrdersInner {
  /**
   *
   * @type {string}
   * @memberof GiftCodeOrdersReadResponseGiftCodeOrdersInner
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof GiftCodeOrdersReadResponseGiftCodeOrdersInner
   */
  shopping_cart_hash_code: string;
  /**
   *
   * @type {string}
   * @memberof GiftCodeOrdersReadResponseGiftCodeOrdersInner
   */
  revenue: string;
  /**
   *
   * @type {string}
   * @memberof GiftCodeOrdersReadResponseGiftCodeOrdersInner
   */
  purchase_date: string;
  /**
   *
   * @type {string}
   * @memberof GiftCodeOrdersReadResponseGiftCodeOrdersInner
   */
  code: string;
  /**
   *
   * @type {string}
   * @memberof GiftCodeOrdersReadResponseGiftCodeOrdersInner
   */
  customer_name: string | null;
  /**
   *
   * @type {string}
   * @memberof GiftCodeOrdersReadResponseGiftCodeOrdersInner
   */
  customer_email: string | null;
  /**
   *
   * @type {string}
   * @memberof GiftCodeOrdersReadResponseGiftCodeOrdersInner
   */
  customer_phone: string | null;
  /**
   *
   * @type {string}
   * @memberof GiftCodeOrdersReadResponseGiftCodeOrdersInner
   */
  customer_locale: string | null;
  /**
   *
   * @type {string}
   * @memberof GiftCodeOrdersReadResponseGiftCodeOrdersInner
   */
  channel: string;
}

/**
 * Check if a given object implements the GiftCodeOrdersReadResponseGiftCodeOrdersInner interface.
 */
export function instanceOfGiftCodeOrdersReadResponseGiftCodeOrdersInner(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && 'id' in value;
  isInstance = isInstance && 'shopping_cart_hash_code' in value;
  isInstance = isInstance && 'revenue' in value;
  isInstance = isInstance && 'purchase_date' in value;
  isInstance = isInstance && 'code' in value;
  isInstance = isInstance && 'customer_name' in value;
  isInstance = isInstance && 'customer_email' in value;
  isInstance = isInstance && 'customer_phone' in value;
  isInstance = isInstance && 'customer_locale' in value;
  isInstance = isInstance && 'channel' in value;

  return isInstance;
}

export function GiftCodeOrdersReadResponseGiftCodeOrdersInnerFromJSON(
  json: any
): GiftCodeOrdersReadResponseGiftCodeOrdersInner {
  return GiftCodeOrdersReadResponseGiftCodeOrdersInnerFromJSONTyped(json, false);
}

export function GiftCodeOrdersReadResponseGiftCodeOrdersInnerFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): GiftCodeOrdersReadResponseGiftCodeOrdersInner {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: json['id'],
    shopping_cart_hash_code: json['shopping_cart_hash_code'],
    revenue: json['revenue'],
    purchase_date: json['purchase_date'],
    code: json['code'],
    customer_name: json['customer_name'],
    customer_email: json['customer_email'],
    customer_phone: json['customer_phone'],
    customer_locale: json['customer_locale'],
    channel: json['channel']
  };
}

export function GiftCodeOrdersReadResponseGiftCodeOrdersInnerToJSON(
  value?: GiftCodeOrdersReadResponseGiftCodeOrdersInner | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    id: value.id,
    shopping_cart_hash_code: value.shopping_cart_hash_code,
    revenue: value.revenue,
    purchase_date: value.purchase_date,
    code: value.code,
    customer_name: value.customer_name,
    customer_email: value.customer_email,
    customer_phone: value.customer_phone,
    customer_locale: value.customer_locale,
    channel: value.channel
  };
}
