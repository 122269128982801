import type { Currency, DirectusUsers, I18N, Locale, Partner, Product } from '@/types/types';
import { createDirectus, authentication, rest } from '@directus/sdk';
import { DIRECTUS_API_URL } from '@/config';

type Collections = {
  currency: Currency;
  i18n: I18N;
  locale: Locale;
  partner: Partner;
  product: Product;
  directus_users: DirectusUsers;
};
class SessionStorage {
  SESSION_STORAGE_KEY = 'directus-auth';
  get() {
    const data = sessionStorage.getItem(this.SESSION_STORAGE_KEY);
    if (data === null) return null;
    return JSON.parse(data);
  }
  set(data: any) {
    sessionStorage.setItem(this.SESSION_STORAGE_KEY, JSON.stringify(data));
  }
}

export const directus = createDirectus<Collections>(DIRECTUS_API_URL)
  .with(authentication('cookie', { credentials: 'include', storage: new SessionStorage() }))
  .with(rest());
