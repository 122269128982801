/* tslint:disable */
/* eslint-disable */
/**
 * Ticketing Portal
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface ExternalTicketCodesByProductOptionIdReadResponseExternalTicketCodesInner
 */
export interface ExternalTicketCodesByProductOptionIdReadResponseExternalTicketCodesInner {
  /**
   *
   * @type {string}
   * @memberof ExternalTicketCodesByProductOptionIdReadResponseExternalTicketCodesInner
   */
  pricing_category_id: string;
  /**
   *
   * @type {string}
   * @memberof ExternalTicketCodesByProductOptionIdReadResponseExternalTicketCodesInner
   */
  pricing_category_name: string;
  /**
   *
   * @type {number}
   * @memberof ExternalTicketCodesByProductOptionIdReadResponseExternalTicketCodesInner
   */
  used: number;
  /**
   *
   * @type {number}
   * @memberof ExternalTicketCodesByProductOptionIdReadResponseExternalTicketCodesInner
   */
  left: number;
}

/**
 * Check if a given object implements the ExternalTicketCodesByProductOptionIdReadResponseExternalTicketCodesInner interface.
 */
export function instanceOfExternalTicketCodesByProductOptionIdReadResponseExternalTicketCodesInner(
  value: object
): boolean {
  let isInstance = true;
  isInstance = isInstance && 'pricing_category_id' in value;
  isInstance = isInstance && 'pricing_category_name' in value;
  isInstance = isInstance && 'used' in value;
  isInstance = isInstance && 'left' in value;

  return isInstance;
}

export function ExternalTicketCodesByProductOptionIdReadResponseExternalTicketCodesInnerFromJSON(
  json: any
): ExternalTicketCodesByProductOptionIdReadResponseExternalTicketCodesInner {
  return ExternalTicketCodesByProductOptionIdReadResponseExternalTicketCodesInnerFromJSONTyped(
    json,
    false
  );
}

export function ExternalTicketCodesByProductOptionIdReadResponseExternalTicketCodesInnerFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): ExternalTicketCodesByProductOptionIdReadResponseExternalTicketCodesInner {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    pricing_category_id: json['pricing_category_id'],
    pricing_category_name: json['pricing_category_name'],
    used: json['used'],
    left: json['left']
  };
}

export function ExternalTicketCodesByProductOptionIdReadResponseExternalTicketCodesInnerToJSON(
  value?: ExternalTicketCodesByProductOptionIdReadResponseExternalTicketCodesInner | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    pricing_category_id: value.pricing_category_id,
    pricing_category_name: value.pricing_category_name,
    used: value.used,
    left: value.left
  };
}
