<template>
  <DatePicker v-bind="vBind" date-format="yy-mm-dd" show-icon :selection-mode="mode"></DatePicker>
</template>

<script setup lang="ts">
import { toRefs, type PropType } from 'vue';
import DatePicker, { type DatePickerProps } from 'primevue/datepicker';

const props = defineProps({
  mode: {
    type: String as PropType<DatePickerProps['selectionMode']>,
    required: false,
    default: 'range'
  },
  vBind: {
    type: Object,
    required: true
  }
});
const { mode, vBind } = toRefs(props);
</script>
