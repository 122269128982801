/* tslint:disable */
/* eslint-disable */
/**
 * Ticketing Portal
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface BookingByIdReadResponseBookingPartnerQuestionAnswersBookingInner
 */
export interface BookingByIdReadResponseBookingPartnerQuestionAnswersBookingInner {
  /**
   *
   * @type {string}
   * @memberof BookingByIdReadResponseBookingPartnerQuestionAnswersBookingInner
   */
  partner_question_id: string;
  /**
   *
   * @type {string}
   * @memberof BookingByIdReadResponseBookingPartnerQuestionAnswersBookingInner
   */
  question_text: string;
  /**
   *
   * @type {string}
   * @memberof BookingByIdReadResponseBookingPartnerQuestionAnswersBookingInner
   */
  answer: string;
}

/**
 * Check if a given object implements the BookingByIdReadResponseBookingPartnerQuestionAnswersBookingInner interface.
 */
export function instanceOfBookingByIdReadResponseBookingPartnerQuestionAnswersBookingInner(
  value: object
): boolean {
  let isInstance = true;
  isInstance = isInstance && 'partner_question_id' in value;
  isInstance = isInstance && 'question_text' in value;
  isInstance = isInstance && 'answer' in value;

  return isInstance;
}

export function BookingByIdReadResponseBookingPartnerQuestionAnswersBookingInnerFromJSON(
  json: any
): BookingByIdReadResponseBookingPartnerQuestionAnswersBookingInner {
  return BookingByIdReadResponseBookingPartnerQuestionAnswersBookingInnerFromJSONTyped(json, false);
}

export function BookingByIdReadResponseBookingPartnerQuestionAnswersBookingInnerFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): BookingByIdReadResponseBookingPartnerQuestionAnswersBookingInner {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    partner_question_id: json['partner_question_id'],
    question_text: json['question_text'],
    answer: json['answer']
  };
}

export function BookingByIdReadResponseBookingPartnerQuestionAnswersBookingInnerToJSON(
  value?: BookingByIdReadResponseBookingPartnerQuestionAnswersBookingInner | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    partner_question_id: value.partner_question_id,
    question_text: value.question_text,
    answer: value.answer
  };
}
