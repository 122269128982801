/* tslint:disable */
/* eslint-disable */
/**
 * Ticketing Portal
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface RegisterReadResponseSupportedCountriesInner
 */
export interface RegisterReadResponseSupportedCountriesInner {
  /**
   *
   * @type {string}
   * @memberof RegisterReadResponseSupportedCountriesInner
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof RegisterReadResponseSupportedCountriesInner
   */
  code: string;
}

/**
 * Check if a given object implements the RegisterReadResponseSupportedCountriesInner interface.
 */
export function instanceOfRegisterReadResponseSupportedCountriesInner(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && 'name' in value;
  isInstance = isInstance && 'code' in value;

  return isInstance;
}

export function RegisterReadResponseSupportedCountriesInnerFromJSON(
  json: any
): RegisterReadResponseSupportedCountriesInner {
  return RegisterReadResponseSupportedCountriesInnerFromJSONTyped(json, false);
}

export function RegisterReadResponseSupportedCountriesInnerFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): RegisterReadResponseSupportedCountriesInner {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    name: json['name'],
    code: json['code']
  };
}

export function RegisterReadResponseSupportedCountriesInnerToJSON(
  value?: RegisterReadResponseSupportedCountriesInner | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    name: value.name,
    code: value.code
  };
}
