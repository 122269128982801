/* tslint:disable */
/* eslint-disable */
/**
 * Ticketing Portal
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface PricingAvailabilityCreateRequestPricingCategoryPricesInner
 */
export interface PricingAvailabilityCreateRequestPricingCategoryPricesInner {
  /**
   *
   * @type {string}
   * @memberof PricingAvailabilityCreateRequestPricingCategoryPricesInner
   */
  id?: string;
  /**
   *
   * @type {string}
   * @memberof PricingAvailabilityCreateRequestPricingCategoryPricesInner
   */
  pricing_category_id: string;
  /**
   *
   * @type {number}
   * @memberof PricingAvailabilityCreateRequestPricingCategoryPricesInner
   */
  value: number;
}

/**
 * Check if a given object implements the PricingAvailabilityCreateRequestPricingCategoryPricesInner interface.
 */
export function instanceOfPricingAvailabilityCreateRequestPricingCategoryPricesInner(
  value: object
): boolean {
  let isInstance = true;
  isInstance = isInstance && 'pricing_category_id' in value;
  isInstance = isInstance && 'value' in value;

  return isInstance;
}

export function PricingAvailabilityCreateRequestPricingCategoryPricesInnerFromJSON(
  json: any
): PricingAvailabilityCreateRequestPricingCategoryPricesInner {
  return PricingAvailabilityCreateRequestPricingCategoryPricesInnerFromJSONTyped(json, false);
}

export function PricingAvailabilityCreateRequestPricingCategoryPricesInnerFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): PricingAvailabilityCreateRequestPricingCategoryPricesInner {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: !exists(json, 'id') ? undefined : json['id'],
    pricing_category_id: json['pricing_category_id'],
    value: json['value']
  };
}

export function PricingAvailabilityCreateRequestPricingCategoryPricesInnerToJSON(
  value?: PricingAvailabilityCreateRequestPricingCategoryPricesInner | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    id: value.id,
    pricing_category_id: value.pricing_category_id,
    value: value.value
  };
}
