/* tslint:disable */
/* eslint-disable */
/**
 * Ticketing Portal
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { IntegrationUpdateRequestIntegration } from './IntegrationUpdateRequestIntegration';
import {
  IntegrationUpdateRequestIntegrationFromJSON,
  IntegrationUpdateRequestIntegrationFromJSONTyped,
  IntegrationUpdateRequestIntegrationToJSON
} from './IntegrationUpdateRequestIntegration';

/**
 *
 * @export
 * @interface IntegrationUpdateRequest
 */
export interface IntegrationUpdateRequest {
  /**
   *
   * @type {string}
   * @memberof IntegrationUpdateRequest
   */
  locale_code: string;
  /**
   *
   * @type {IntegrationUpdateRequestIntegration}
   * @memberof IntegrationUpdateRequest
   */
  integration?: IntegrationUpdateRequestIntegration;
}

/**
 * Check if a given object implements the IntegrationUpdateRequest interface.
 */
export function instanceOfIntegrationUpdateRequest(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && 'locale_code' in value;

  return isInstance;
}

export function IntegrationUpdateRequestFromJSON(json: any): IntegrationUpdateRequest {
  return IntegrationUpdateRequestFromJSONTyped(json, false);
}

export function IntegrationUpdateRequestFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): IntegrationUpdateRequest {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    locale_code: json['locale_code'],
    integration: !exists(json, 'integration')
      ? undefined
      : IntegrationUpdateRequestIntegrationFromJSON(json['integration'])
  };
}

export function IntegrationUpdateRequestToJSON(value?: IntegrationUpdateRequest | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    locale_code: value.locale_code,
    integration: IntegrationUpdateRequestIntegrationToJSON(value.integration)
  };
}
