/* tslint:disable */
/* eslint-disable */
/**
 * Ticketing Portal
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { GiftCodeUpdateRequestGiftCode } from './GiftCodeUpdateRequestGiftCode';
import {
  GiftCodeUpdateRequestGiftCodeFromJSON,
  GiftCodeUpdateRequestGiftCodeFromJSONTyped,
  GiftCodeUpdateRequestGiftCodeToJSON
} from './GiftCodeUpdateRequestGiftCode';

/**
 *
 * @export
 * @interface GiftCodeUpdateRequest
 */
export interface GiftCodeUpdateRequest {
  /**
   *
   * @type {string}
   * @memberof GiftCodeUpdateRequest
   */
  locale_code: string;
  /**
   *
   * @type {GiftCodeUpdateRequestGiftCode}
   * @memberof GiftCodeUpdateRequest
   */
  gift_code: GiftCodeUpdateRequestGiftCode;
}

/**
 * Check if a given object implements the GiftCodeUpdateRequest interface.
 */
export function instanceOfGiftCodeUpdateRequest(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && 'locale_code' in value;
  isInstance = isInstance && 'gift_code' in value;

  return isInstance;
}

export function GiftCodeUpdateRequestFromJSON(json: any): GiftCodeUpdateRequest {
  return GiftCodeUpdateRequestFromJSONTyped(json, false);
}

export function GiftCodeUpdateRequestFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): GiftCodeUpdateRequest {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    locale_code: json['locale_code'],
    gift_code: GiftCodeUpdateRequestGiftCodeFromJSON(json['gift_code'])
  };
}

export function GiftCodeUpdateRequestToJSON(value?: GiftCodeUpdateRequest | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    locale_code: value.locale_code,
    gift_code: GiftCodeUpdateRequestGiftCodeToJSON(value.gift_code)
  };
}
