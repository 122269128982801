/* tslint:disable */
/* eslint-disable */
/**
 * Ticketing Portal
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface UserInviteAcceptRequest
 */
export interface UserInviteAcceptRequest {
  /**
   *
   * @type {string}
   * @memberof UserInviteAcceptRequest
   */
  locale_code: string;
  /**
   * JWT token for user invitation
   * @type {string}
   * @memberof UserInviteAcceptRequest
   */
  token: string;
  /**
   * New password for the user
   * @type {string}
   * @memberof UserInviteAcceptRequest
   */
  password: string;
}

/**
 * Check if a given object implements the UserInviteAcceptRequest interface.
 */
export function instanceOfUserInviteAcceptRequest(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && 'locale_code' in value;
  isInstance = isInstance && 'token' in value;
  isInstance = isInstance && 'password' in value;

  return isInstance;
}

export function UserInviteAcceptRequestFromJSON(json: any): UserInviteAcceptRequest {
  return UserInviteAcceptRequestFromJSONTyped(json, false);
}

export function UserInviteAcceptRequestFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): UserInviteAcceptRequest {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    locale_code: json['locale_code'],
    token: json['token'],
    password: json['password']
  };
}

export function UserInviteAcceptRequestToJSON(value?: UserInviteAcceptRequest | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    locale_code: value.locale_code,
    token: value.token,
    password: value.password
  };
}
