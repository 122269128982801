/* tslint:disable */
/* eslint-disable */
/**
 * Ticketing Portal
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from '../runtime';
import type {
  AddonByIdReadResponse,
  AddonCreateRequest,
  AddonCreateResponse,
  AddonUpdateRequest,
  AddonUpdateResponse,
  AddonsReadRequest,
  AddonsReadResponse,
  AllocationsReadResponse,
  BookingByIdReadResponse,
  BookingCancelRequest,
  BookingCancelResponse,
  BookingCartConfirmEmitRequest,
  BookingCartConfirmEmitResponse,
  BookingConfirmPaymentRequest,
  BookingConfirmPaymentResponse,
  BookingConfirmRejectRequest,
  BookingConfirmRejectResponse,
  BookingPrintRequest,
  BookingPrintResponse,
  BookingRefundRequest,
  BookingRefundResponse,
  ChannelsReadRequest,
  ChannelsReadResponse,
  CustomerByIdReadResponse,
  CustomerUpdateRequest,
  CustomerUpdateResponse,
  CustomersDownloadRequest,
  CustomersReadRequest,
  CustomersReadResponse,
  EmailTriggerCreateRequest,
  EmailTriggerCreateResponse,
  EmailTriggerDeleteRequest,
  EmailTriggerDeleteResponse,
  EmailTriggerReadByIdResponse,
  EmailTriggerReadRequest,
  EmailTriggerReadResponse,
  EmailTriggerUpdateRequest,
  EmailTriggerUpdateResponse,
  ExternalTicketCodeCreateRequest,
  ExternalTicketCodeCreateResponse,
  ExternalTicketCodesByProductOptionIdReadResponse,
  GiftCodeByIdReadResponse,
  GiftCodeCreateRequest,
  GiftCodeCreateResponse,
  GiftCodeDownloadRequest,
  GiftCodeOrdersReadRequest,
  GiftCodeOrdersReadResponse,
  GiftCodeReadRequest,
  GiftCodeReadResponse,
  GiftCodeUpdateRequest,
  GiftCodeUpdateResponse,
  OrdersDownloadRequest,
  OrdersReadRequest,
  OrdersReadResponse,
  PricingAvailabilityCreateRequest,
  PricingAvailabilityCreateResponse,
  PricingAvailabilityReadResponse,
  PricingByIdReadResponse,
  PricingCategoriesDownloadRequest,
  PricingCategoriesReadRequest,
  PricingCategoriesReadResponse,
  PricingCategoryByIdReadResponse,
  PricingCategoryCreateRequest,
  PricingCategoryCreateResponse,
  PricingCategoryRankUpdateRequest,
  PricingCategoryRankUpdateResponse,
  PricingCategoryUpdateRequest,
  PricingCategoryUpdateResponse,
  PricingDeleteRequest,
  PricingDeleteResponse,
  PricingFlexibleCreateRequest,
  PricingFlexibleCreateResponse,
  PricingReadRequest,
  PricingReadResponse,
  PricingUpdateRequest,
  PricingUpdateResponse,
  ProductByIdReadResponse,
  ProductCloneResponse,
  ProductCreateRequest,
  ProductCreateResponse,
  ProductDeleteRequest,
  ProductDeleteResponse,
  ProductMediaReadResponse,
  ProductMediaUpdateRequest,
  ProductMediaUpdateResponse,
  ProductOptionAddressReadResponse,
  ProductOptionAddressUpdateRequest,
  ProductOptionAddressUpdateResponse,
  ProductOptionByIdReadResponse,
  ProductOptionChannelConfigurationByOptionIdResponse,
  ProductOptionChannelConfigurationReadResponse,
  ProductOptionChannelConfigurationUpdateRequest,
  ProductOptionChannelConfigurationUpdateResponse,
  ProductOptionCloneResponse,
  ProductOptionCreateRequest,
  ProductOptionCreateResponse,
  ProductOptionDeleteRequest,
  ProductOptionDeleteResponse,
  ProductOptionQuestionReadResponse,
  ProductOptionQuestionUpdateRequest,
  ProductOptionQuestionUpdateResponse,
  ProductOptionRankUpdateRequest,
  ProductOptionRankUpdateResponse,
  ProductOptionReadResponse,
  ProductOptionTranslationReadResponse,
  ProductOptionTranslationUpdateRequest,
  ProductOptionTranslationUpdateResponse,
  ProductOptionUpdateRequest,
  ProductOptionUpdateResponse,
  ProductPageReadResponse,
  ProductStatusUpdateRequest,
  ProductStatusUpdateResponse,
  ProductTranslationReadResponse,
  ProductTranslationUpdateRequest,
  ProductTranslationUpdateResponse,
  ProductUpdateRequest,
  ProductUpdateResponse,
  ProductsReadRequest,
  ProductsReadResponse,
  PromoCodeByIdReadResponse,
  PromoCodeCreateRequest,
  PromoCodeCreateResponse,
  PromoCodeDeleteRequest,
  PromoCodeDeleteResponse,
  PromoCodeReadRequest,
  PromoCodeReadResponse,
  PromoCodeRestrictionsReadRequest,
  PromoCodeRestrictionsReadResponse,
  PromoCodeUpdateRequest,
  PromoCodeUpdateResponse,
  PromoCodeUsagesReadRequest,
  PromoCodeUsagesReadResponse,
  QuestionByIdReadResponse,
  QuestionCreateRequest,
  QuestionCreateResponse,
  QuestionUpdateRequest,
  QuestionUpdateResponse,
  QuestionsReadRequest,
  QuestionsReadResponse,
  TicketByCodeReadResponse,
  TicketConfigurationByProductOptionIdReadResponse,
  TicketConfigurationUpdateRequest,
  TicketConfigurationUpdateResponse,
  TicketsRedemptionReadRequest,
  TicketsRedemptionReadResponse,
  TicketsRedemptionUpdateRequest,
  TicketsRedemptionUpdateResponse,
  TimeSlotBatchUpdateRequestInner,
  TimeSlotBatchUpdateResponse,
  TimeSlotBlockUpdateRequestInner,
  TimeSlotBlockUpdateResponse,
  TimeSlotByIdReadResponse,
  TimeSlotDeleteRequest,
  TimeSlotDeleteResponse,
  TimeSlotReadRequest,
  TimeSlotReadResponse
} from '../models';
import {
  AddonByIdReadResponseFromJSON,
  AddonByIdReadResponseToJSON,
  AddonCreateRequestFromJSON,
  AddonCreateRequestToJSON,
  AddonCreateResponseFromJSON,
  AddonCreateResponseToJSON,
  AddonUpdateRequestFromJSON,
  AddonUpdateRequestToJSON,
  AddonUpdateResponseFromJSON,
  AddonUpdateResponseToJSON,
  AddonsReadRequestFromJSON,
  AddonsReadRequestToJSON,
  AddonsReadResponseFromJSON,
  AddonsReadResponseToJSON,
  AllocationsReadResponseFromJSON,
  AllocationsReadResponseToJSON,
  BookingByIdReadResponseFromJSON,
  BookingByIdReadResponseToJSON,
  BookingCancelRequestFromJSON,
  BookingCancelRequestToJSON,
  BookingCancelResponseFromJSON,
  BookingCancelResponseToJSON,
  BookingCartConfirmEmitRequestFromJSON,
  BookingCartConfirmEmitRequestToJSON,
  BookingCartConfirmEmitResponseFromJSON,
  BookingCartConfirmEmitResponseToJSON,
  BookingConfirmPaymentRequestFromJSON,
  BookingConfirmPaymentRequestToJSON,
  BookingConfirmPaymentResponseFromJSON,
  BookingConfirmPaymentResponseToJSON,
  BookingConfirmRejectRequestFromJSON,
  BookingConfirmRejectRequestToJSON,
  BookingConfirmRejectResponseFromJSON,
  BookingConfirmRejectResponseToJSON,
  BookingPrintRequestFromJSON,
  BookingPrintRequestToJSON,
  BookingPrintResponseFromJSON,
  BookingPrintResponseToJSON,
  BookingRefundRequestFromJSON,
  BookingRefundRequestToJSON,
  BookingRefundResponseFromJSON,
  BookingRefundResponseToJSON,
  ChannelsReadRequestFromJSON,
  ChannelsReadRequestToJSON,
  ChannelsReadResponseFromJSON,
  ChannelsReadResponseToJSON,
  CustomerByIdReadResponseFromJSON,
  CustomerByIdReadResponseToJSON,
  CustomerUpdateRequestFromJSON,
  CustomerUpdateRequestToJSON,
  CustomerUpdateResponseFromJSON,
  CustomerUpdateResponseToJSON,
  CustomersDownloadRequestFromJSON,
  CustomersDownloadRequestToJSON,
  CustomersReadRequestFromJSON,
  CustomersReadRequestToJSON,
  CustomersReadResponseFromJSON,
  CustomersReadResponseToJSON,
  EmailTriggerCreateRequestFromJSON,
  EmailTriggerCreateRequestToJSON,
  EmailTriggerCreateResponseFromJSON,
  EmailTriggerCreateResponseToJSON,
  EmailTriggerDeleteRequestFromJSON,
  EmailTriggerDeleteRequestToJSON,
  EmailTriggerDeleteResponseFromJSON,
  EmailTriggerDeleteResponseToJSON,
  EmailTriggerReadByIdResponseFromJSON,
  EmailTriggerReadByIdResponseToJSON,
  EmailTriggerReadRequestFromJSON,
  EmailTriggerReadRequestToJSON,
  EmailTriggerReadResponseFromJSON,
  EmailTriggerReadResponseToJSON,
  EmailTriggerUpdateRequestFromJSON,
  EmailTriggerUpdateRequestToJSON,
  EmailTriggerUpdateResponseFromJSON,
  EmailTriggerUpdateResponseToJSON,
  ExternalTicketCodeCreateRequestFromJSON,
  ExternalTicketCodeCreateRequestToJSON,
  ExternalTicketCodeCreateResponseFromJSON,
  ExternalTicketCodeCreateResponseToJSON,
  ExternalTicketCodesByProductOptionIdReadResponseFromJSON,
  ExternalTicketCodesByProductOptionIdReadResponseToJSON,
  GiftCodeByIdReadResponseFromJSON,
  GiftCodeByIdReadResponseToJSON,
  GiftCodeCreateRequestFromJSON,
  GiftCodeCreateRequestToJSON,
  GiftCodeCreateResponseFromJSON,
  GiftCodeCreateResponseToJSON,
  GiftCodeDownloadRequestFromJSON,
  GiftCodeDownloadRequestToJSON,
  GiftCodeOrdersReadRequestFromJSON,
  GiftCodeOrdersReadRequestToJSON,
  GiftCodeOrdersReadResponseFromJSON,
  GiftCodeOrdersReadResponseToJSON,
  GiftCodeReadRequestFromJSON,
  GiftCodeReadRequestToJSON,
  GiftCodeReadResponseFromJSON,
  GiftCodeReadResponseToJSON,
  GiftCodeUpdateRequestFromJSON,
  GiftCodeUpdateRequestToJSON,
  GiftCodeUpdateResponseFromJSON,
  GiftCodeUpdateResponseToJSON,
  OrdersDownloadRequestFromJSON,
  OrdersDownloadRequestToJSON,
  OrdersReadRequestFromJSON,
  OrdersReadRequestToJSON,
  OrdersReadResponseFromJSON,
  OrdersReadResponseToJSON,
  PricingAvailabilityCreateRequestFromJSON,
  PricingAvailabilityCreateRequestToJSON,
  PricingAvailabilityCreateResponseFromJSON,
  PricingAvailabilityCreateResponseToJSON,
  PricingAvailabilityReadResponseFromJSON,
  PricingAvailabilityReadResponseToJSON,
  PricingByIdReadResponseFromJSON,
  PricingByIdReadResponseToJSON,
  PricingCategoriesDownloadRequestFromJSON,
  PricingCategoriesDownloadRequestToJSON,
  PricingCategoriesReadRequestFromJSON,
  PricingCategoriesReadRequestToJSON,
  PricingCategoriesReadResponseFromJSON,
  PricingCategoriesReadResponseToJSON,
  PricingCategoryByIdReadResponseFromJSON,
  PricingCategoryByIdReadResponseToJSON,
  PricingCategoryCreateRequestFromJSON,
  PricingCategoryCreateRequestToJSON,
  PricingCategoryCreateResponseFromJSON,
  PricingCategoryCreateResponseToJSON,
  PricingCategoryRankUpdateRequestFromJSON,
  PricingCategoryRankUpdateRequestToJSON,
  PricingCategoryRankUpdateResponseFromJSON,
  PricingCategoryRankUpdateResponseToJSON,
  PricingCategoryUpdateRequestFromJSON,
  PricingCategoryUpdateRequestToJSON,
  PricingCategoryUpdateResponseFromJSON,
  PricingCategoryUpdateResponseToJSON,
  PricingDeleteRequestFromJSON,
  PricingDeleteRequestToJSON,
  PricingDeleteResponseFromJSON,
  PricingDeleteResponseToJSON,
  PricingFlexibleCreateRequestFromJSON,
  PricingFlexibleCreateRequestToJSON,
  PricingFlexibleCreateResponseFromJSON,
  PricingFlexibleCreateResponseToJSON,
  PricingReadRequestFromJSON,
  PricingReadRequestToJSON,
  PricingReadResponseFromJSON,
  PricingReadResponseToJSON,
  PricingUpdateRequestFromJSON,
  PricingUpdateRequestToJSON,
  PricingUpdateResponseFromJSON,
  PricingUpdateResponseToJSON,
  ProductByIdReadResponseFromJSON,
  ProductByIdReadResponseToJSON,
  ProductCloneResponseFromJSON,
  ProductCloneResponseToJSON,
  ProductCreateRequestFromJSON,
  ProductCreateRequestToJSON,
  ProductCreateResponseFromJSON,
  ProductCreateResponseToJSON,
  ProductDeleteRequestFromJSON,
  ProductDeleteRequestToJSON,
  ProductDeleteResponseFromJSON,
  ProductDeleteResponseToJSON,
  ProductMediaReadResponseFromJSON,
  ProductMediaReadResponseToJSON,
  ProductMediaUpdateRequestFromJSON,
  ProductMediaUpdateRequestToJSON,
  ProductMediaUpdateResponseFromJSON,
  ProductMediaUpdateResponseToJSON,
  ProductOptionAddressReadResponseFromJSON,
  ProductOptionAddressReadResponseToJSON,
  ProductOptionAddressUpdateRequestFromJSON,
  ProductOptionAddressUpdateRequestToJSON,
  ProductOptionAddressUpdateResponseFromJSON,
  ProductOptionAddressUpdateResponseToJSON,
  ProductOptionByIdReadResponseFromJSON,
  ProductOptionByIdReadResponseToJSON,
  ProductOptionChannelConfigurationByOptionIdResponseFromJSON,
  ProductOptionChannelConfigurationByOptionIdResponseToJSON,
  ProductOptionChannelConfigurationReadResponseFromJSON,
  ProductOptionChannelConfigurationReadResponseToJSON,
  ProductOptionChannelConfigurationUpdateRequestFromJSON,
  ProductOptionChannelConfigurationUpdateRequestToJSON,
  ProductOptionChannelConfigurationUpdateResponseFromJSON,
  ProductOptionChannelConfigurationUpdateResponseToJSON,
  ProductOptionCloneResponseFromJSON,
  ProductOptionCloneResponseToJSON,
  ProductOptionCreateRequestFromJSON,
  ProductOptionCreateRequestToJSON,
  ProductOptionCreateResponseFromJSON,
  ProductOptionCreateResponseToJSON,
  ProductOptionDeleteRequestFromJSON,
  ProductOptionDeleteRequestToJSON,
  ProductOptionDeleteResponseFromJSON,
  ProductOptionDeleteResponseToJSON,
  ProductOptionQuestionReadResponseFromJSON,
  ProductOptionQuestionReadResponseToJSON,
  ProductOptionQuestionUpdateRequestFromJSON,
  ProductOptionQuestionUpdateRequestToJSON,
  ProductOptionQuestionUpdateResponseFromJSON,
  ProductOptionQuestionUpdateResponseToJSON,
  ProductOptionRankUpdateRequestFromJSON,
  ProductOptionRankUpdateRequestToJSON,
  ProductOptionRankUpdateResponseFromJSON,
  ProductOptionRankUpdateResponseToJSON,
  ProductOptionReadResponseFromJSON,
  ProductOptionReadResponseToJSON,
  ProductOptionTranslationReadResponseFromJSON,
  ProductOptionTranslationReadResponseToJSON,
  ProductOptionTranslationUpdateRequestFromJSON,
  ProductOptionTranslationUpdateRequestToJSON,
  ProductOptionTranslationUpdateResponseFromJSON,
  ProductOptionTranslationUpdateResponseToJSON,
  ProductOptionUpdateRequestFromJSON,
  ProductOptionUpdateRequestToJSON,
  ProductOptionUpdateResponseFromJSON,
  ProductOptionUpdateResponseToJSON,
  ProductPageReadResponseFromJSON,
  ProductPageReadResponseToJSON,
  ProductStatusUpdateRequestFromJSON,
  ProductStatusUpdateRequestToJSON,
  ProductStatusUpdateResponseFromJSON,
  ProductStatusUpdateResponseToJSON,
  ProductTranslationReadResponseFromJSON,
  ProductTranslationReadResponseToJSON,
  ProductTranslationUpdateRequestFromJSON,
  ProductTranslationUpdateRequestToJSON,
  ProductTranslationUpdateResponseFromJSON,
  ProductTranslationUpdateResponseToJSON,
  ProductUpdateRequestFromJSON,
  ProductUpdateRequestToJSON,
  ProductUpdateResponseFromJSON,
  ProductUpdateResponseToJSON,
  ProductsReadRequestFromJSON,
  ProductsReadRequestToJSON,
  ProductsReadResponseFromJSON,
  ProductsReadResponseToJSON,
  PromoCodeByIdReadResponseFromJSON,
  PromoCodeByIdReadResponseToJSON,
  PromoCodeCreateRequestFromJSON,
  PromoCodeCreateRequestToJSON,
  PromoCodeCreateResponseFromJSON,
  PromoCodeCreateResponseToJSON,
  PromoCodeDeleteRequestFromJSON,
  PromoCodeDeleteRequestToJSON,
  PromoCodeDeleteResponseFromJSON,
  PromoCodeDeleteResponseToJSON,
  PromoCodeReadRequestFromJSON,
  PromoCodeReadRequestToJSON,
  PromoCodeReadResponseFromJSON,
  PromoCodeReadResponseToJSON,
  PromoCodeRestrictionsReadRequestFromJSON,
  PromoCodeRestrictionsReadRequestToJSON,
  PromoCodeRestrictionsReadResponseFromJSON,
  PromoCodeRestrictionsReadResponseToJSON,
  PromoCodeUpdateRequestFromJSON,
  PromoCodeUpdateRequestToJSON,
  PromoCodeUpdateResponseFromJSON,
  PromoCodeUpdateResponseToJSON,
  PromoCodeUsagesReadRequestFromJSON,
  PromoCodeUsagesReadRequestToJSON,
  PromoCodeUsagesReadResponseFromJSON,
  PromoCodeUsagesReadResponseToJSON,
  QuestionByIdReadResponseFromJSON,
  QuestionByIdReadResponseToJSON,
  QuestionCreateRequestFromJSON,
  QuestionCreateRequestToJSON,
  QuestionCreateResponseFromJSON,
  QuestionCreateResponseToJSON,
  QuestionUpdateRequestFromJSON,
  QuestionUpdateRequestToJSON,
  QuestionUpdateResponseFromJSON,
  QuestionUpdateResponseToJSON,
  QuestionsReadRequestFromJSON,
  QuestionsReadRequestToJSON,
  QuestionsReadResponseFromJSON,
  QuestionsReadResponseToJSON,
  TicketByCodeReadResponseFromJSON,
  TicketByCodeReadResponseToJSON,
  TicketConfigurationByProductOptionIdReadResponseFromJSON,
  TicketConfigurationByProductOptionIdReadResponseToJSON,
  TicketConfigurationUpdateRequestFromJSON,
  TicketConfigurationUpdateRequestToJSON,
  TicketConfigurationUpdateResponseFromJSON,
  TicketConfigurationUpdateResponseToJSON,
  TicketsRedemptionReadRequestFromJSON,
  TicketsRedemptionReadRequestToJSON,
  TicketsRedemptionReadResponseFromJSON,
  TicketsRedemptionReadResponseToJSON,
  TicketsRedemptionUpdateRequestFromJSON,
  TicketsRedemptionUpdateRequestToJSON,
  TicketsRedemptionUpdateResponseFromJSON,
  TicketsRedemptionUpdateResponseToJSON,
  TimeSlotBatchUpdateRequestInnerFromJSON,
  TimeSlotBatchUpdateRequestInnerToJSON,
  TimeSlotBatchUpdateResponseFromJSON,
  TimeSlotBatchUpdateResponseToJSON,
  TimeSlotBlockUpdateRequestInnerFromJSON,
  TimeSlotBlockUpdateRequestInnerToJSON,
  TimeSlotBlockUpdateResponseFromJSON,
  TimeSlotBlockUpdateResponseToJSON,
  TimeSlotByIdReadResponseFromJSON,
  TimeSlotByIdReadResponseToJSON,
  TimeSlotDeleteRequestFromJSON,
  TimeSlotDeleteRequestToJSON,
  TimeSlotDeleteResponseFromJSON,
  TimeSlotDeleteResponseToJSON,
  TimeSlotReadRequestFromJSON,
  TimeSlotReadRequestToJSON,
  TimeSlotReadResponseFromJSON,
  TimeSlotReadResponseToJSON
} from '../models';

export interface SellAddonAddonIdGetRequest {
  localeCode: string;
  addonId: string;
}

export interface SellAddonAddonIdPutRequest {
  addonId: string;
  addonUpdateRequest?: AddonUpdateRequest;
}

export interface SellAddonPostRequest {
  addonCreateRequest?: AddonCreateRequest;
}

export interface SellAddonsPostRequest {
  addonsReadRequest?: AddonsReadRequest;
}

export interface SellBlockTimeslotsPatchRequest {
  timeSlotBlockUpdateRequestInner: Array<TimeSlotBlockUpdateRequestInner>;
}

export interface SellBookingBookingIdConfirmPaymentPostRequest {
  bookingId: string;
  bookingConfirmPaymentRequest?: BookingConfirmPaymentRequest;
}

export interface SellBookingBookingIdConfirmRejectPostRequest {
  bookingId: string;
  bookingConfirmRejectRequest?: BookingConfirmRejectRequest;
}

export interface SellBookingBookingIdGetRequest {
  localeCode: string;
  bookingId: string;
}

export interface SellBookingBookingIdPostRequest {
  bookingId: string;
  bookingCartConfirmEmitRequest?: BookingCartConfirmEmitRequest;
}

export interface SellBookingBookingIdPrintPostRequest {
  bookingId: string;
  bookingPrintRequest?: BookingPrintRequest;
}

export interface SellBookingCancelBookingPostRequest {
  bookingCancelRequest?: BookingCancelRequest;
}

export interface SellBookingRefundBookingPostRequest {
  bookingRefundRequest?: BookingRefundRequest;
}

export interface SellChannelsAllocationsGetRequest {
  localeCode: string;
}

export interface SellChannelsPostRequest {
  channelsReadRequest?: ChannelsReadRequest;
}

export interface SellCustomerCustomerIdGetRequest {
  localeCode: string;
  customerId: string;
}

export interface SellCustomerCustomerIdPatchRequest {
  customerId: string;
  customerUpdateRequest?: CustomerUpdateRequest;
}

export interface SellCustomersDownloadPostRequest {
  customersDownloadRequest?: CustomersDownloadRequest;
}

export interface SellCustomersPostRequest {
  customersReadRequest?: CustomersReadRequest;
}

export interface SellDeleteTimeslotsPatchRequest {
  timeSlotDeleteRequest: TimeSlotDeleteRequest;
}

export interface SellEmailTriggerEmailTriggerIdDeleteRequest {
  emailTriggerId: string;
  emailTriggerDeleteRequest?: EmailTriggerDeleteRequest;
}

export interface SellEmailTriggerEmailTriggerIdGetRequest {
  localeCode: string;
  emailTriggerId: string;
}

export interface SellEmailTriggerEmailTriggerIdPutRequest {
  emailTriggerId: string;
  emailTriggerUpdateRequest?: EmailTriggerUpdateRequest;
}

export interface SellEmailTriggerPostRequest {
  emailTriggerCreateRequest?: EmailTriggerCreateRequest;
}

export interface SellExternalTicketCodeGetRequest {
  localeCode: string;
  productOptionId: string;
}

export interface SellExternalTicketCodePostRequest {
  externalTicketCodeCreateRequest?: ExternalTicketCodeCreateRequest;
}

export interface SellGiftCodesDownloadPostRequest {
  giftCodeDownloadRequest?: GiftCodeDownloadRequest;
}

export interface SellGiftCodesGiftCodeIdGetRequest {
  localeCode: string;
  giftCodeId: string;
}

export interface SellGiftCodesGiftCodeIdPutRequest {
  giftCodeId: string;
  giftCodeUpdateRequest?: GiftCodeUpdateRequest;
}

export interface SellGiftCodesPostRequest {
  giftCodeCreateRequest?: GiftCodeCreateRequest;
}

export interface SellOrdersDownloadPostRequest {
  ordersDownloadRequest?: OrdersDownloadRequest;
}

export interface SellOrdersPostRequest {
  ordersReadRequest?: OrdersReadRequest;
}

export interface SellPageEmailTriggerPostRequest {
  emailTriggerReadRequest?: EmailTriggerReadRequest;
}

export interface SellPageGiftCodeOrdersPostRequest {
  giftCodeOrdersReadRequest?: GiftCodeOrdersReadRequest;
}

export interface SellPageGiftCodesPostRequest {
  giftCodeReadRequest?: GiftCodeReadRequest;
}

export interface SellPagePricingPostRequest {
  pricingReadRequest?: PricingReadRequest;
}

export interface SellPageProductProductIdGetRequest {
  localeCode: string;
  productId: string;
}

export interface SellPagePromoCodesPostRequest {
  promoCodeReadRequest?: PromoCodeReadRequest;
}

export interface SellPagePromoCodesPromoCodeIdDeleteRequest {
  promoCodeId: string;
  promoCodeDeleteRequest?: PromoCodeDeleteRequest;
}

export interface SellPagePromoCodesPromoCodeIdGetRequest {
  localeCode: string;
  promoCodeId: string;
}

export interface SellPagePromoCodesPromoCodeIdPatchRequest {
  promoCodeId: string;
  promoCodeUpdateRequest?: PromoCodeUpdateRequest;
}

export interface SellPagePromoCodesPromoCodeIdRestrictionsPostRequest {
  promoCodeId: string;
  promoCodeRestrictionsReadRequest?: PromoCodeRestrictionsReadRequest;
}

export interface SellPagePromoCodesPromoCodeIdUsesPostRequest {
  promoCodeId: string;
  promoCodeUsagesReadRequest?: PromoCodeUsagesReadRequest;
}

export interface SellPricingAvailabilityPostRequest {
  pricingAvailabilityCreateRequest?: PricingAvailabilityCreateRequest;
}

export interface SellPricingAvailabilityProductOptionIdGetRequest {
  localeCode: string;
  productOptionId: string;
}

export interface SellPricingCategoriesDownloadPostRequest {
  pricingCategoriesDownloadRequest?: PricingCategoriesDownloadRequest;
}

export interface SellPricingCategoriesPostRequest {
  pricingCategoriesReadRequest?: PricingCategoriesReadRequest;
}

export interface SellPricingCategoryPostRequest {
  pricingCategoryCreateRequest?: PricingCategoryCreateRequest;
}

export interface SellPricingCategoryPricingCategoryIdGetRequest {
  localeCode: string;
  pricingCategoryId: string;
}

export interface SellPricingCategoryPricingCategoryIdPutRequest {
  pricingCategoryId: string;
  pricingCategoryUpdateRequest?: PricingCategoryUpdateRequest;
}

export interface SellPricingCategoryRankPostRequest {
  pricingCategoryRankUpdateRequest?: PricingCategoryRankUpdateRequest;
}

export interface SellPricingPostRequest {
  pricingFlexibleCreateRequest?: PricingFlexibleCreateRequest;
}

export interface SellPricingPricingIdDeleteRequest {
  pricingId: string;
  pricingDeleteRequest?: PricingDeleteRequest;
}

export interface SellPricingPricingIdGetRequest {
  localeCode: string;
  pricingId: string;
}

export interface SellPricingPricingIdPutRequest {
  pricingId: string;
  pricingUpdateRequest?: PricingUpdateRequest;
}

export interface SellProductMediaPostRequest {
  productMediaUpdateRequest?: ProductMediaUpdateRequest;
}

export interface SellProductMediaProductIdGetRequest {
  localeCode: string;
  productId: string;
}

export interface SellProductOptionAddressPostRequest {
  productOptionAddressUpdateRequest?: ProductOptionAddressUpdateRequest;
}

export interface SellProductOptionAddressProductOptionIdGetRequest {
  localeCode: string;
  productOptionId: string;
}

export interface SellProductOptionChannelConfigurationChannelIdGetRequest {
  localeCode: string;
  channelId: string;
}

export interface SellProductOptionChannelConfigurationProductOptionProductOptionIdGetRequest {
  localeCode: string;
  productOptionId: string;
}

export interface SellProductOptionChannelConfigurationPutRequest {
  productOptionChannelConfigurationUpdateRequest?: ProductOptionChannelConfigurationUpdateRequest;
}

export interface SellProductOptionDeleteRequest {
  productOptionDeleteRequest?: ProductOptionDeleteRequest;
}

export interface SellProductOptionGetRequest {
  localeCode: string;
  productId: string;
}

export interface SellProductOptionPostRequest {
  productOptionCreateRequest?: ProductOptionCreateRequest;
}

export interface SellProductOptionProductOptionIdGetRequest {
  localeCode: string;
  productOptionId: string;
}

export interface SellProductOptionProductOptionIdPutRequest {
  productOptionId: string;
}

export interface SellProductOptionPutRequest {
  productOptionUpdateRequest?: ProductOptionUpdateRequest;
}

export interface SellProductOptionRankPostRequest {
  productOptionRankUpdateRequest?: ProductOptionRankUpdateRequest;
}

export interface SellProductOptionTranslationPostRequest {
  productOptionTranslationUpdateRequest?: ProductOptionTranslationUpdateRequest;
}

export interface SellProductOptionTranslationProductOptionIdGetRequest {
  localeCode: string;
  productOptionId: string;
}

export interface SellProductOptionsProductOptionIdQuestionsGetRequest {
  localeCode: string;
  productOptionId: string;
}

export interface SellProductOptionsProductOptionIdQuestionsPutRequest {
  productOptionId: string;
  productOptionQuestionUpdateRequest?: ProductOptionQuestionUpdateRequest;
}

export interface SellProductPostRequest {
  productCreateRequest?: ProductCreateRequest;
}

export interface SellProductProductIdDeleteRequest {
  productId: string;
  productDeleteRequest?: ProductDeleteRequest;
}

export interface SellProductProductIdGetRequest {
  localeCode: string;
  productId: string;
}

export interface SellProductProductIdPostRequest {
  productId: string;
}

export interface SellProductProductIdPutRequest {
  productId: string;
  productUpdateRequest?: ProductUpdateRequest;
}

export interface SellProductStatusProductIdPutRequest {
  productId: string;
  productStatusUpdateRequest?: ProductStatusUpdateRequest;
}

export interface SellProductTranslationPostRequest {
  productTranslationUpdateRequest?: ProductTranslationUpdateRequest;
}

export interface SellProductTranslationProductIdGetRequest {
  localeCode: string;
  productId: string;
}

export interface SellProductsPostRequest {
  productsReadRequest?: ProductsReadRequest;
}

export interface SellPromoCodesPostRequest {
  promoCodeCreateRequest?: PromoCodeCreateRequest;
}

export interface SellQuestionsPostRequest {
  questionCreateRequest?: QuestionCreateRequest;
}

export interface SellQuestionsQuestionIdGetRequest {
  localeCode: string;
  questionId: string;
}

export interface SellQuestionsQuestionIdPutRequest {
  questionId: string;
  questionUpdateRequest?: QuestionUpdateRequest;
}

export interface SellQuestionsSearchPostRequest {
  questionsReadRequest?: QuestionsReadRequest;
}

export interface SellTicketCodeGetRequest {
  localeCode: string;
  code: string;
}

export interface SellTicketCodeRedeemPostRequest {
  code: string;
  ticketsRedemptionUpdateRequest?: TicketsRedemptionUpdateRequest;
}

export interface SellTicketConfigurationProductOptionIdGetRequest {
  localeCode: string;
  productOptionId: string;
}

export interface SellTicketConfigurationProductOptionIdPutRequest {
  localeCode: string;
  productOptionId: string;
  ticketConfigurationUpdateRequest?: TicketConfigurationUpdateRequest;
}

export interface SellTicketRedemptionLogPostRequest {
  ticketsRedemptionReadRequest?: TicketsRedemptionReadRequest;
}

export interface SellTimeSlotTimeSlotIdGetRequest {
  localeCode: string;
  timeSlotId: string;
}

export interface SellTimeSlotsPostRequest {
  timeSlotReadRequest?: TimeSlotReadRequest;
}

export interface SellUpdateTimeslotsPatchRequest {
  timeSlotBatchUpdateRequestInner: Array<TimeSlotBatchUpdateRequestInner>;
}

/**
 *
 */
export class SellApi extends runtime.BaseAPI {
  /**
   * Get addon by id
   */
  async sellAddonAddonIdGetRaw(
    requestParameters: SellAddonAddonIdGetRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<AddonByIdReadResponse>> {
    if (requestParameters.localeCode === null || requestParameters.localeCode === undefined) {
      throw new runtime.RequiredError(
        'localeCode',
        'Required parameter requestParameters.localeCode was null or undefined when calling sellAddonAddonIdGet.'
      );
    }

    if (requestParameters.addonId === null || requestParameters.addonId === undefined) {
      throw new runtime.RequiredError(
        'addonId',
        'Required parameter requestParameters.addonId was null or undefined when calling sellAddonAddonIdGet.'
      );
    }

    const queryParameters: any = {};

    if (requestParameters.localeCode !== undefined) {
      queryParameters['locale_code'] = requestParameters.localeCode;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request(
      {
        path: `/sell/addon/{addon_id}`.replace(
          `{${'addon_id'}}`,
          encodeURIComponent(String(requestParameters.addonId))
        ),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      AddonByIdReadResponseFromJSON(jsonValue)
    );
  }

  /**
   * Get addon by id
   */
  async sellAddonAddonIdGet(
    requestParameters: SellAddonAddonIdGetRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<AddonByIdReadResponse> {
    const response = await this.sellAddonAddonIdGetRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Update addon by id
   */
  async sellAddonAddonIdPutRaw(
    requestParameters: SellAddonAddonIdPutRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<AddonUpdateResponse>> {
    if (requestParameters.addonId === null || requestParameters.addonId === undefined) {
      throw new runtime.RequiredError(
        'addonId',
        'Required parameter requestParameters.addonId was null or undefined when calling sellAddonAddonIdPut.'
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    const response = await this.request(
      {
        path: `/sell/addon/{addon_id}`.replace(
          `{${'addon_id'}}`,
          encodeURIComponent(String(requestParameters.addonId))
        ),
        method: 'PUT',
        headers: headerParameters,
        query: queryParameters,
        body: AddonUpdateRequestToJSON(requestParameters.addonUpdateRequest)
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      AddonUpdateResponseFromJSON(jsonValue)
    );
  }

  /**
   * Update addon by id
   */
  async sellAddonAddonIdPut(
    requestParameters: SellAddonAddonIdPutRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<AddonUpdateResponse> {
    const response = await this.sellAddonAddonIdPutRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Create addon
   */
  async sellAddonPostRaw(
    requestParameters: SellAddonPostRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<AddonCreateResponse>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    const response = await this.request(
      {
        path: `/sell/addon`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: AddonCreateRequestToJSON(requestParameters.addonCreateRequest)
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      AddonCreateResponseFromJSON(jsonValue)
    );
  }

  /**
   * Create addon
   */
  async sellAddonPost(
    requestParameters: SellAddonPostRequest = {},
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<AddonCreateResponse> {
    const response = await this.sellAddonPostRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Get all addons
   */
  async sellAddonsPostRaw(
    requestParameters: SellAddonsPostRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<AddonsReadResponse>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    const response = await this.request(
      {
        path: `/sell/addons`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: AddonsReadRequestToJSON(requestParameters.addonsReadRequest)
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      AddonsReadResponseFromJSON(jsonValue)
    );
  }

  /**
   * Get all addons
   */
  async sellAddonsPost(
    requestParameters: SellAddonsPostRequest = {},
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<AddonsReadResponse> {
    const response = await this.sellAddonsPostRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Block timeslots
   */
  async sellBlockTimeslotsPatchRaw(
    requestParameters: SellBlockTimeslotsPatchRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<TimeSlotBlockUpdateResponse>> {
    if (
      requestParameters.timeSlotBlockUpdateRequestInner === null ||
      requestParameters.timeSlotBlockUpdateRequestInner === undefined
    ) {
      throw new runtime.RequiredError(
        'timeSlotBlockUpdateRequestInner',
        'Required parameter requestParameters.timeSlotBlockUpdateRequestInner was null or undefined when calling sellBlockTimeslotsPatch.'
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    const response = await this.request(
      {
        path: `/sell/block-timeslots`,
        method: 'PATCH',
        headers: headerParameters,
        query: queryParameters,
        body: requestParameters.timeSlotBlockUpdateRequestInner.map(
          TimeSlotBlockUpdateRequestInnerToJSON
        )
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      TimeSlotBlockUpdateResponseFromJSON(jsonValue)
    );
  }

  /**
   * Block timeslots
   */
  async sellBlockTimeslotsPatch(
    requestParameters: SellBlockTimeslotsPatchRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<TimeSlotBlockUpdateResponse> {
    const response = await this.sellBlockTimeslotsPatchRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Allow a partner to confirm a payment when the status is pending_payment_completion
   */
  async sellBookingBookingIdConfirmPaymentPostRaw(
    requestParameters: SellBookingBookingIdConfirmPaymentPostRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<BookingConfirmPaymentResponse>> {
    if (requestParameters.bookingId === null || requestParameters.bookingId === undefined) {
      throw new runtime.RequiredError(
        'bookingId',
        'Required parameter requestParameters.bookingId was null or undefined when calling sellBookingBookingIdConfirmPaymentPost.'
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    const response = await this.request(
      {
        path: `/sell/booking/{booking_id}/confirm-payment`.replace(
          `{${'booking_id'}}`,
          encodeURIComponent(String(requestParameters.bookingId))
        ),
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: BookingConfirmPaymentRequestToJSON(requestParameters.bookingConfirmPaymentRequest)
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      BookingConfirmPaymentResponseFromJSON(jsonValue)
    );
  }

  /**
   * Allow a partner to confirm a payment when the status is pending_payment_completion
   */
  async sellBookingBookingIdConfirmPaymentPost(
    requestParameters: SellBookingBookingIdConfirmPaymentPostRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<BookingConfirmPaymentResponse> {
    const response = await this.sellBookingBookingIdConfirmPaymentPostRaw(
      requestParameters,
      initOverrides
    );
    return await response.value();
  }

  /**
   * Allow a partner to confirm or reject a booking when the status is awaiting_partner_confirmation
   */
  async sellBookingBookingIdConfirmRejectPostRaw(
    requestParameters: SellBookingBookingIdConfirmRejectPostRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<BookingConfirmRejectResponse>> {
    if (requestParameters.bookingId === null || requestParameters.bookingId === undefined) {
      throw new runtime.RequiredError(
        'bookingId',
        'Required parameter requestParameters.bookingId was null or undefined when calling sellBookingBookingIdConfirmRejectPost.'
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    const response = await this.request(
      {
        path: `/sell/booking/{booking_id}/confirm-reject`.replace(
          `{${'booking_id'}}`,
          encodeURIComponent(String(requestParameters.bookingId))
        ),
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: BookingConfirmRejectRequestToJSON(requestParameters.bookingConfirmRejectRequest)
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      BookingConfirmRejectResponseFromJSON(jsonValue)
    );
  }

  /**
   * Allow a partner to confirm or reject a booking when the status is awaiting_partner_confirmation
   */
  async sellBookingBookingIdConfirmRejectPost(
    requestParameters: SellBookingBookingIdConfirmRejectPostRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<BookingConfirmRejectResponse> {
    const response = await this.sellBookingBookingIdConfirmRejectPostRaw(
      requestParameters,
      initOverrides
    );
    return await response.value();
  }

  /**
   * Get booking by ID
   */
  async sellBookingBookingIdGetRaw(
    requestParameters: SellBookingBookingIdGetRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<BookingByIdReadResponse>> {
    if (requestParameters.localeCode === null || requestParameters.localeCode === undefined) {
      throw new runtime.RequiredError(
        'localeCode',
        'Required parameter requestParameters.localeCode was null or undefined when calling sellBookingBookingIdGet.'
      );
    }

    if (requestParameters.bookingId === null || requestParameters.bookingId === undefined) {
      throw new runtime.RequiredError(
        'bookingId',
        'Required parameter requestParameters.bookingId was null or undefined when calling sellBookingBookingIdGet.'
      );
    }

    const queryParameters: any = {};

    if (requestParameters.localeCode !== undefined) {
      queryParameters['locale_code'] = requestParameters.localeCode;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request(
      {
        path: `/sell/booking/{booking_id}`.replace(
          `{${'booking_id'}}`,
          encodeURIComponent(String(requestParameters.bookingId))
        ),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      BookingByIdReadResponseFromJSON(jsonValue)
    );
  }

  /**
   * Get booking by ID
   */
  async sellBookingBookingIdGet(
    requestParameters: SellBookingBookingIdGetRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<BookingByIdReadResponse> {
    const response = await this.sellBookingBookingIdGetRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Resend booking confirmation email
   */
  async sellBookingBookingIdPostRaw(
    requestParameters: SellBookingBookingIdPostRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<BookingCartConfirmEmitResponse>> {
    if (requestParameters.bookingId === null || requestParameters.bookingId === undefined) {
      throw new runtime.RequiredError(
        'bookingId',
        'Required parameter requestParameters.bookingId was null or undefined when calling sellBookingBookingIdPost.'
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    const response = await this.request(
      {
        path: `/sell/booking/{booking_id}`.replace(
          `{${'booking_id'}}`,
          encodeURIComponent(String(requestParameters.bookingId))
        ),
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: BookingCartConfirmEmitRequestToJSON(requestParameters.bookingCartConfirmEmitRequest)
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      BookingCartConfirmEmitResponseFromJSON(jsonValue)
    );
  }

  /**
   * Resend booking confirmation email
   */
  async sellBookingBookingIdPost(
    requestParameters: SellBookingBookingIdPostRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<BookingCartConfirmEmitResponse> {
    const response = await this.sellBookingBookingIdPostRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Print single pdf
   */
  async sellBookingBookingIdPrintPostRaw(
    requestParameters: SellBookingBookingIdPrintPostRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<BookingPrintResponse>> {
    if (requestParameters.bookingId === null || requestParameters.bookingId === undefined) {
      throw new runtime.RequiredError(
        'bookingId',
        'Required parameter requestParameters.bookingId was null or undefined when calling sellBookingBookingIdPrintPost.'
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    const response = await this.request(
      {
        path: `/sell/booking/{booking_id}/print`.replace(
          `{${'booking_id'}}`,
          encodeURIComponent(String(requestParameters.bookingId))
        ),
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: BookingPrintRequestToJSON(requestParameters.bookingPrintRequest)
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      BookingPrintResponseFromJSON(jsonValue)
    );
  }

  /**
   * Print single pdf
   */
  async sellBookingBookingIdPrintPost(
    requestParameters: SellBookingBookingIdPrintPostRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<BookingPrintResponse> {
    const response = await this.sellBookingBookingIdPrintPostRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Cancel booking by a partner and refunds the amount dictated by the cancellation policy
   */
  async sellBookingCancelBookingPostRaw(
    requestParameters: SellBookingCancelBookingPostRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<BookingCancelResponse>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    const response = await this.request(
      {
        path: `/sell/booking/cancel-booking`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: BookingCancelRequestToJSON(requestParameters.bookingCancelRequest)
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      BookingCancelResponseFromJSON(jsonValue)
    );
  }

  /**
   * Cancel booking by a partner and refunds the amount dictated by the cancellation policy
   */
  async sellBookingCancelBookingPost(
    requestParameters: SellBookingCancelBookingPostRequest = {},
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<BookingCancelResponse> {
    const response = await this.sellBookingCancelBookingPostRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Refund booking by a partner
   */
  async sellBookingRefundBookingPostRaw(
    requestParameters: SellBookingRefundBookingPostRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<BookingRefundResponse>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    const response = await this.request(
      {
        path: `/sell/booking/refund-booking`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: BookingRefundRequestToJSON(requestParameters.bookingRefundRequest)
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      BookingRefundResponseFromJSON(jsonValue)
    );
  }

  /**
   * Refund booking by a partner
   */
  async sellBookingRefundBookingPost(
    requestParameters: SellBookingRefundBookingPostRequest = {},
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<BookingRefundResponse> {
    const response = await this.sellBookingRefundBookingPostRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Get all channel allocations
   */
  async sellChannelsAllocationsGetRaw(
    requestParameters: SellChannelsAllocationsGetRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<AllocationsReadResponse>> {
    if (requestParameters.localeCode === null || requestParameters.localeCode === undefined) {
      throw new runtime.RequiredError(
        'localeCode',
        'Required parameter requestParameters.localeCode was null or undefined when calling sellChannelsAllocationsGet.'
      );
    }

    const queryParameters: any = {};

    if (requestParameters.localeCode !== undefined) {
      queryParameters['locale_code'] = requestParameters.localeCode;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request(
      {
        path: `/sell/channels/allocations`,
        method: 'GET',
        headers: headerParameters,
        query: queryParameters
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      AllocationsReadResponseFromJSON(jsonValue)
    );
  }

  /**
   * Get all channel allocations
   */
  async sellChannelsAllocationsGet(
    requestParameters: SellChannelsAllocationsGetRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<AllocationsReadResponse> {
    const response = await this.sellChannelsAllocationsGetRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Get all channels
   */
  async sellChannelsPostRaw(
    requestParameters: SellChannelsPostRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<ChannelsReadResponse>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    const response = await this.request(
      {
        path: `/sell/channels`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: ChannelsReadRequestToJSON(requestParameters.channelsReadRequest)
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      ChannelsReadResponseFromJSON(jsonValue)
    );
  }

  /**
   * Get all channels
   */
  async sellChannelsPost(
    requestParameters: SellChannelsPostRequest = {},
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<ChannelsReadResponse> {
    const response = await this.sellChannelsPostRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Get all customers
   */
  async sellCustomerCustomerIdGetRaw(
    requestParameters: SellCustomerCustomerIdGetRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<CustomerByIdReadResponse>> {
    if (requestParameters.localeCode === null || requestParameters.localeCode === undefined) {
      throw new runtime.RequiredError(
        'localeCode',
        'Required parameter requestParameters.localeCode was null or undefined when calling sellCustomerCustomerIdGet.'
      );
    }

    if (requestParameters.customerId === null || requestParameters.customerId === undefined) {
      throw new runtime.RequiredError(
        'customerId',
        'Required parameter requestParameters.customerId was null or undefined when calling sellCustomerCustomerIdGet.'
      );
    }

    const queryParameters: any = {};

    if (requestParameters.localeCode !== undefined) {
      queryParameters['locale_code'] = requestParameters.localeCode;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request(
      {
        path: `/sell/customer/{customer_id}`.replace(
          `{${'customer_id'}}`,
          encodeURIComponent(String(requestParameters.customerId))
        ),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      CustomerByIdReadResponseFromJSON(jsonValue)
    );
  }

  /**
   * Get all customers
   */
  async sellCustomerCustomerIdGet(
    requestParameters: SellCustomerCustomerIdGetRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<CustomerByIdReadResponse> {
    const response = await this.sellCustomerCustomerIdGetRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Update customer by id
   */
  async sellCustomerCustomerIdPatchRaw(
    requestParameters: SellCustomerCustomerIdPatchRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<CustomerUpdateResponse>> {
    if (requestParameters.customerId === null || requestParameters.customerId === undefined) {
      throw new runtime.RequiredError(
        'customerId',
        'Required parameter requestParameters.customerId was null or undefined when calling sellCustomerCustomerIdPatch.'
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    const response = await this.request(
      {
        path: `/sell/customer/{customer_id}`.replace(
          `{${'customer_id'}}`,
          encodeURIComponent(String(requestParameters.customerId))
        ),
        method: 'PATCH',
        headers: headerParameters,
        query: queryParameters,
        body: CustomerUpdateRequestToJSON(requestParameters.customerUpdateRequest)
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      CustomerUpdateResponseFromJSON(jsonValue)
    );
  }

  /**
   * Update customer by id
   */
  async sellCustomerCustomerIdPatch(
    requestParameters: SellCustomerCustomerIdPatchRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<CustomerUpdateResponse> {
    const response = await this.sellCustomerCustomerIdPatchRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Get all customers
   */
  async sellCustomersDownloadPostRaw(
    requestParameters: SellCustomersDownloadPostRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<Blob>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    const response = await this.request(
      {
        path: `/sell/customers/download`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: CustomersDownloadRequestToJSON(requestParameters.customersDownloadRequest)
      },
      initOverrides
    );

    return new runtime.BlobApiResponse(response);
  }

  /**
   * Get all customers
   */
  async sellCustomersDownloadPost(
    requestParameters: SellCustomersDownloadPostRequest = {},
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<Blob> {
    const response = await this.sellCustomersDownloadPostRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Get all customers
   */
  async sellCustomersPostRaw(
    requestParameters: SellCustomersPostRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<CustomersReadResponse>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    const response = await this.request(
      {
        path: `/sell/customers`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: CustomersReadRequestToJSON(requestParameters.customersReadRequest)
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      CustomersReadResponseFromJSON(jsonValue)
    );
  }

  /**
   * Get all customers
   */
  async sellCustomersPost(
    requestParameters: SellCustomersPostRequest = {},
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<CustomersReadResponse> {
    const response = await this.sellCustomersPostRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Batch delete timeslots
   */
  async sellDeleteTimeslotsPatchRaw(
    requestParameters: SellDeleteTimeslotsPatchRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<TimeSlotDeleteResponse>> {
    if (
      requestParameters.timeSlotDeleteRequest === null ||
      requestParameters.timeSlotDeleteRequest === undefined
    ) {
      throw new runtime.RequiredError(
        'timeSlotDeleteRequest',
        'Required parameter requestParameters.timeSlotDeleteRequest was null or undefined when calling sellDeleteTimeslotsPatch.'
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    const response = await this.request(
      {
        path: `/sell/delete-timeslots`,
        method: 'PATCH',
        headers: headerParameters,
        query: queryParameters,
        body: TimeSlotDeleteRequestToJSON(requestParameters.timeSlotDeleteRequest)
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      TimeSlotDeleteResponseFromJSON(jsonValue)
    );
  }

  /**
   * Batch delete timeslots
   */
  async sellDeleteTimeslotsPatch(
    requestParameters: SellDeleteTimeslotsPatchRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<TimeSlotDeleteResponse> {
    const response = await this.sellDeleteTimeslotsPatchRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Delete email trigger by ID
   */
  async sellEmailTriggerEmailTriggerIdDeleteRaw(
    requestParameters: SellEmailTriggerEmailTriggerIdDeleteRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<EmailTriggerDeleteResponse>> {
    if (
      requestParameters.emailTriggerId === null ||
      requestParameters.emailTriggerId === undefined
    ) {
      throw new runtime.RequiredError(
        'emailTriggerId',
        'Required parameter requestParameters.emailTriggerId was null or undefined when calling sellEmailTriggerEmailTriggerIdDelete.'
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    const response = await this.request(
      {
        path: `/sell/email-trigger/{email_trigger_id}`.replace(
          `{${'email_trigger_id'}}`,
          encodeURIComponent(String(requestParameters.emailTriggerId))
        ),
        method: 'DELETE',
        headers: headerParameters,
        query: queryParameters,
        body: EmailTriggerDeleteRequestToJSON(requestParameters.emailTriggerDeleteRequest)
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      EmailTriggerDeleteResponseFromJSON(jsonValue)
    );
  }

  /**
   * Delete email trigger by ID
   */
  async sellEmailTriggerEmailTriggerIdDelete(
    requestParameters: SellEmailTriggerEmailTriggerIdDeleteRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<EmailTriggerDeleteResponse> {
    const response = await this.sellEmailTriggerEmailTriggerIdDeleteRaw(
      requestParameters,
      initOverrides
    );
    return await response.value();
  }

  /**
   * Summary
   */
  async sellEmailTriggerEmailTriggerIdGetRaw(
    requestParameters: SellEmailTriggerEmailTriggerIdGetRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<EmailTriggerReadByIdResponse>> {
    if (requestParameters.localeCode === null || requestParameters.localeCode === undefined) {
      throw new runtime.RequiredError(
        'localeCode',
        'Required parameter requestParameters.localeCode was null or undefined when calling sellEmailTriggerEmailTriggerIdGet.'
      );
    }

    if (
      requestParameters.emailTriggerId === null ||
      requestParameters.emailTriggerId === undefined
    ) {
      throw new runtime.RequiredError(
        'emailTriggerId',
        'Required parameter requestParameters.emailTriggerId was null or undefined when calling sellEmailTriggerEmailTriggerIdGet.'
      );
    }

    const queryParameters: any = {};

    if (requestParameters.localeCode !== undefined) {
      queryParameters['locale_code'] = requestParameters.localeCode;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request(
      {
        path: `/sell/email-trigger/{email_trigger_id}`.replace(
          `{${'email_trigger_id'}}`,
          encodeURIComponent(String(requestParameters.emailTriggerId))
        ),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      EmailTriggerReadByIdResponseFromJSON(jsonValue)
    );
  }

  /**
   * Summary
   */
  async sellEmailTriggerEmailTriggerIdGet(
    requestParameters: SellEmailTriggerEmailTriggerIdGetRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<EmailTriggerReadByIdResponse> {
    const response = await this.sellEmailTriggerEmailTriggerIdGetRaw(
      requestParameters,
      initOverrides
    );
    return await response.value();
  }

  /**
   * Update email trigger
   */
  async sellEmailTriggerEmailTriggerIdPutRaw(
    requestParameters: SellEmailTriggerEmailTriggerIdPutRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<EmailTriggerUpdateResponse>> {
    if (
      requestParameters.emailTriggerId === null ||
      requestParameters.emailTriggerId === undefined
    ) {
      throw new runtime.RequiredError(
        'emailTriggerId',
        'Required parameter requestParameters.emailTriggerId was null or undefined when calling sellEmailTriggerEmailTriggerIdPut.'
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    const response = await this.request(
      {
        path: `/sell/email-trigger/{email_trigger_id}`.replace(
          `{${'email_trigger_id'}}`,
          encodeURIComponent(String(requestParameters.emailTriggerId))
        ),
        method: 'PUT',
        headers: headerParameters,
        query: queryParameters,
        body: EmailTriggerUpdateRequestToJSON(requestParameters.emailTriggerUpdateRequest)
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      EmailTriggerUpdateResponseFromJSON(jsonValue)
    );
  }

  /**
   * Update email trigger
   */
  async sellEmailTriggerEmailTriggerIdPut(
    requestParameters: SellEmailTriggerEmailTriggerIdPutRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<EmailTriggerUpdateResponse> {
    const response = await this.sellEmailTriggerEmailTriggerIdPutRaw(
      requestParameters,
      initOverrides
    );
    return await response.value();
  }

  /**
   * Create Email Trigger
   */
  async sellEmailTriggerPostRaw(
    requestParameters: SellEmailTriggerPostRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<EmailTriggerCreateResponse>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    const response = await this.request(
      {
        path: `/sell/email-trigger`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: EmailTriggerCreateRequestToJSON(requestParameters.emailTriggerCreateRequest)
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      EmailTriggerCreateResponseFromJSON(jsonValue)
    );
  }

  /**
   * Create Email Trigger
   */
  async sellEmailTriggerPost(
    requestParameters: SellEmailTriggerPostRequest = {},
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<EmailTriggerCreateResponse> {
    const response = await this.sellEmailTriggerPostRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Read external ticket codes by product option ID
   */
  async sellExternalTicketCodeGetRaw(
    requestParameters: SellExternalTicketCodeGetRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<ExternalTicketCodesByProductOptionIdReadResponse>> {
    if (requestParameters.localeCode === null || requestParameters.localeCode === undefined) {
      throw new runtime.RequiredError(
        'localeCode',
        'Required parameter requestParameters.localeCode was null or undefined when calling sellExternalTicketCodeGet.'
      );
    }

    if (
      requestParameters.productOptionId === null ||
      requestParameters.productOptionId === undefined
    ) {
      throw new runtime.RequiredError(
        'productOptionId',
        'Required parameter requestParameters.productOptionId was null or undefined when calling sellExternalTicketCodeGet.'
      );
    }

    const queryParameters: any = {};

    if (requestParameters.localeCode !== undefined) {
      queryParameters['locale_code'] = requestParameters.localeCode;
    }

    if (requestParameters.productOptionId !== undefined) {
      queryParameters['product_option_id'] = requestParameters.productOptionId;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request(
      {
        path: `/sell/external-ticket-code`,
        method: 'GET',
        headers: headerParameters,
        query: queryParameters
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      ExternalTicketCodesByProductOptionIdReadResponseFromJSON(jsonValue)
    );
  }

  /**
   * Read external ticket codes by product option ID
   */
  async sellExternalTicketCodeGet(
    requestParameters: SellExternalTicketCodeGetRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<ExternalTicketCodesByProductOptionIdReadResponse> {
    const response = await this.sellExternalTicketCodeGetRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Create new external ticket code for a product option
   */
  async sellExternalTicketCodePostRaw(
    requestParameters: SellExternalTicketCodePostRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<ExternalTicketCodeCreateResponse>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    const response = await this.request(
      {
        path: `/sell/external-ticket-code`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: ExternalTicketCodeCreateRequestToJSON(
          requestParameters.externalTicketCodeCreateRequest
        )
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      ExternalTicketCodeCreateResponseFromJSON(jsonValue)
    );
  }

  /**
   * Create new external ticket code for a product option
   */
  async sellExternalTicketCodePost(
    requestParameters: SellExternalTicketCodePostRequest = {},
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<ExternalTicketCodeCreateResponse> {
    const response = await this.sellExternalTicketCodePostRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Download gift code from datatable
   */
  async sellGiftCodesDownloadPostRaw(
    requestParameters: SellGiftCodesDownloadPostRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<Blob>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    const response = await this.request(
      {
        path: `/sell/gift-codes/download`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: GiftCodeDownloadRequestToJSON(requestParameters.giftCodeDownloadRequest)
      },
      initOverrides
    );

    return new runtime.BlobApiResponse(response);
  }

  /**
   * Download gift code from datatable
   */
  async sellGiftCodesDownloadPost(
    requestParameters: SellGiftCodesDownloadPostRequest = {},
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<Blob> {
    const response = await this.sellGiftCodesDownloadPostRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Get gift code by id
   */
  async sellGiftCodesGiftCodeIdGetRaw(
    requestParameters: SellGiftCodesGiftCodeIdGetRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<GiftCodeByIdReadResponse>> {
    if (requestParameters.localeCode === null || requestParameters.localeCode === undefined) {
      throw new runtime.RequiredError(
        'localeCode',
        'Required parameter requestParameters.localeCode was null or undefined when calling sellGiftCodesGiftCodeIdGet.'
      );
    }

    if (requestParameters.giftCodeId === null || requestParameters.giftCodeId === undefined) {
      throw new runtime.RequiredError(
        'giftCodeId',
        'Required parameter requestParameters.giftCodeId was null or undefined when calling sellGiftCodesGiftCodeIdGet.'
      );
    }

    const queryParameters: any = {};

    if (requestParameters.localeCode !== undefined) {
      queryParameters['locale_code'] = requestParameters.localeCode;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request(
      {
        path: `/sell/gift-codes/{gift_code_id}`.replace(
          `{${'gift_code_id'}}`,
          encodeURIComponent(String(requestParameters.giftCodeId))
        ),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      GiftCodeByIdReadResponseFromJSON(jsonValue)
    );
  }

  /**
   * Get gift code by id
   */
  async sellGiftCodesGiftCodeIdGet(
    requestParameters: SellGiftCodesGiftCodeIdGetRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<GiftCodeByIdReadResponse> {
    const response = await this.sellGiftCodesGiftCodeIdGetRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Update gift code by id
   */
  async sellGiftCodesGiftCodeIdPutRaw(
    requestParameters: SellGiftCodesGiftCodeIdPutRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<GiftCodeUpdateResponse>> {
    if (requestParameters.giftCodeId === null || requestParameters.giftCodeId === undefined) {
      throw new runtime.RequiredError(
        'giftCodeId',
        'Required parameter requestParameters.giftCodeId was null or undefined when calling sellGiftCodesGiftCodeIdPut.'
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    const response = await this.request(
      {
        path: `/sell/gift-codes/{gift_code_id}`.replace(
          `{${'gift_code_id'}}`,
          encodeURIComponent(String(requestParameters.giftCodeId))
        ),
        method: 'PUT',
        headers: headerParameters,
        query: queryParameters,
        body: GiftCodeUpdateRequestToJSON(requestParameters.giftCodeUpdateRequest)
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      GiftCodeUpdateResponseFromJSON(jsonValue)
    );
  }

  /**
   * Update gift code by id
   */
  async sellGiftCodesGiftCodeIdPut(
    requestParameters: SellGiftCodesGiftCodeIdPutRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<GiftCodeUpdateResponse> {
    const response = await this.sellGiftCodesGiftCodeIdPutRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Create Gift Code
   */
  async sellGiftCodesPostRaw(
    requestParameters: SellGiftCodesPostRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<GiftCodeCreateResponse>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    const response = await this.request(
      {
        path: `/sell/gift-codes`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: GiftCodeCreateRequestToJSON(requestParameters.giftCodeCreateRequest)
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      GiftCodeCreateResponseFromJSON(jsonValue)
    );
  }

  /**
   * Create Gift Code
   */
  async sellGiftCodesPost(
    requestParameters: SellGiftCodesPostRequest = {},
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<GiftCodeCreateResponse> {
    const response = await this.sellGiftCodesPostRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Download orders from datatable
   */
  async sellOrdersDownloadPostRaw(
    requestParameters: SellOrdersDownloadPostRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<Blob>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    const response = await this.request(
      {
        path: `/sell/orders/download`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: OrdersDownloadRequestToJSON(requestParameters.ordersDownloadRequest)
      },
      initOverrides
    );

    return new runtime.BlobApiResponse(response);
  }

  /**
   * Download orders from datatable
   */
  async sellOrdersDownloadPost(
    requestParameters: SellOrdersDownloadPostRequest = {},
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<Blob> {
    const response = await this.sellOrdersDownloadPostRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Fetch orders for datatable
   */
  async sellOrdersPostRaw(
    requestParameters: SellOrdersPostRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<OrdersReadResponse>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    const response = await this.request(
      {
        path: `/sell/orders`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: OrdersReadRequestToJSON(requestParameters.ordersReadRequest)
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      OrdersReadResponseFromJSON(jsonValue)
    );
  }

  /**
   * Fetch orders for datatable
   */
  async sellOrdersPost(
    requestParameters: SellOrdersPostRequest = {},
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<OrdersReadResponse> {
    const response = await this.sellOrdersPostRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Fetch email triggers for datatable
   */
  async sellPageEmailTriggerPostRaw(
    requestParameters: SellPageEmailTriggerPostRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<EmailTriggerReadResponse>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    const response = await this.request(
      {
        path: `/sell/page/email-trigger`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: EmailTriggerReadRequestToJSON(requestParameters.emailTriggerReadRequest)
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      EmailTriggerReadResponseFromJSON(jsonValue)
    );
  }

  /**
   * Fetch email triggers for datatable
   */
  async sellPageEmailTriggerPost(
    requestParameters: SellPageEmailTriggerPostRequest = {},
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<EmailTriggerReadResponse> {
    const response = await this.sellPageEmailTriggerPostRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Get all gift code purchases for datatable
   */
  async sellPageGiftCodeOrdersPostRaw(
    requestParameters: SellPageGiftCodeOrdersPostRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<GiftCodeOrdersReadResponse>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    const response = await this.request(
      {
        path: `/sell/page/gift-code-orders`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: GiftCodeOrdersReadRequestToJSON(requestParameters.giftCodeOrdersReadRequest)
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      GiftCodeOrdersReadResponseFromJSON(jsonValue)
    );
  }

  /**
   * Get all gift code purchases for datatable
   */
  async sellPageGiftCodeOrdersPost(
    requestParameters: SellPageGiftCodeOrdersPostRequest = {},
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<GiftCodeOrdersReadResponse> {
    const response = await this.sellPageGiftCodeOrdersPostRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Get all gift codes
   */
  async sellPageGiftCodesPostRaw(
    requestParameters: SellPageGiftCodesPostRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<GiftCodeReadResponse>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    const response = await this.request(
      {
        path: `/sell/page/gift-codes`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: GiftCodeReadRequestToJSON(requestParameters.giftCodeReadRequest)
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      GiftCodeReadResponseFromJSON(jsonValue)
    );
  }

  /**
   * Get all gift codes
   */
  async sellPageGiftCodesPost(
    requestParameters: SellPageGiftCodesPostRequest = {},
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<GiftCodeReadResponse> {
    const response = await this.sellPageGiftCodesPostRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Get all pricing
   */
  async sellPagePricingPostRaw(
    requestParameters: SellPagePricingPostRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<PricingReadResponse>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    const response = await this.request(
      {
        path: `/sell/page/pricing`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: PricingReadRequestToJSON(requestParameters.pricingReadRequest)
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      PricingReadResponseFromJSON(jsonValue)
    );
  }

  /**
   * Get all pricing
   */
  async sellPagePricingPost(
    requestParameters: SellPagePricingPostRequest = {},
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<PricingReadResponse> {
    const response = await this.sellPagePricingPostRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Get product info
   */
  async sellPageProductProductIdGetRaw(
    requestParameters: SellPageProductProductIdGetRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<ProductPageReadResponse>> {
    if (requestParameters.localeCode === null || requestParameters.localeCode === undefined) {
      throw new runtime.RequiredError(
        'localeCode',
        'Required parameter requestParameters.localeCode was null or undefined when calling sellPageProductProductIdGet.'
      );
    }

    if (requestParameters.productId === null || requestParameters.productId === undefined) {
      throw new runtime.RequiredError(
        'productId',
        'Required parameter requestParameters.productId was null or undefined when calling sellPageProductProductIdGet.'
      );
    }

    const queryParameters: any = {};

    if (requestParameters.localeCode !== undefined) {
      queryParameters['locale_code'] = requestParameters.localeCode;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request(
      {
        path: `/sell/page/product/{product_id}`.replace(
          `{${'product_id'}}`,
          encodeURIComponent(String(requestParameters.productId))
        ),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      ProductPageReadResponseFromJSON(jsonValue)
    );
  }

  /**
   * Get product info
   */
  async sellPageProductProductIdGet(
    requestParameters: SellPageProductProductIdGetRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<ProductPageReadResponse> {
    const response = await this.sellPageProductProductIdGetRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Get all promo codes
   */
  async sellPagePromoCodesPostRaw(
    requestParameters: SellPagePromoCodesPostRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<PromoCodeReadResponse>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    const response = await this.request(
      {
        path: `/sell/page/promo-codes`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: PromoCodeReadRequestToJSON(requestParameters.promoCodeReadRequest)
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      PromoCodeReadResponseFromJSON(jsonValue)
    );
  }

  /**
   * Get all promo codes
   */
  async sellPagePromoCodesPost(
    requestParameters: SellPagePromoCodesPostRequest = {},
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<PromoCodeReadResponse> {
    const response = await this.sellPagePromoCodesPostRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Delete promo code by id
   */
  async sellPagePromoCodesPromoCodeIdDeleteRaw(
    requestParameters: SellPagePromoCodesPromoCodeIdDeleteRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<PromoCodeDeleteResponse>> {
    if (requestParameters.promoCodeId === null || requestParameters.promoCodeId === undefined) {
      throw new runtime.RequiredError(
        'promoCodeId',
        'Required parameter requestParameters.promoCodeId was null or undefined when calling sellPagePromoCodesPromoCodeIdDelete.'
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    const response = await this.request(
      {
        path: `/sell/page/promo-codes/{promo_code_id}`.replace(
          `{${'promo_code_id'}}`,
          encodeURIComponent(String(requestParameters.promoCodeId))
        ),
        method: 'DELETE',
        headers: headerParameters,
        query: queryParameters,
        body: PromoCodeDeleteRequestToJSON(requestParameters.promoCodeDeleteRequest)
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      PromoCodeDeleteResponseFromJSON(jsonValue)
    );
  }

  /**
   * Delete promo code by id
   */
  async sellPagePromoCodesPromoCodeIdDelete(
    requestParameters: SellPagePromoCodesPromoCodeIdDeleteRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<PromoCodeDeleteResponse> {
    const response = await this.sellPagePromoCodesPromoCodeIdDeleteRaw(
      requestParameters,
      initOverrides
    );
    return await response.value();
  }

  /**
   * Get promo code info
   */
  async sellPagePromoCodesPromoCodeIdGetRaw(
    requestParameters: SellPagePromoCodesPromoCodeIdGetRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<PromoCodeByIdReadResponse>> {
    if (requestParameters.localeCode === null || requestParameters.localeCode === undefined) {
      throw new runtime.RequiredError(
        'localeCode',
        'Required parameter requestParameters.localeCode was null or undefined when calling sellPagePromoCodesPromoCodeIdGet.'
      );
    }

    if (requestParameters.promoCodeId === null || requestParameters.promoCodeId === undefined) {
      throw new runtime.RequiredError(
        'promoCodeId',
        'Required parameter requestParameters.promoCodeId was null or undefined when calling sellPagePromoCodesPromoCodeIdGet.'
      );
    }

    const queryParameters: any = {};

    if (requestParameters.localeCode !== undefined) {
      queryParameters['locale_code'] = requestParameters.localeCode;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request(
      {
        path: `/sell/page/promo-codes/{promo_code_id}`.replace(
          `{${'promo_code_id'}}`,
          encodeURIComponent(String(requestParameters.promoCodeId))
        ),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      PromoCodeByIdReadResponseFromJSON(jsonValue)
    );
  }

  /**
   * Get promo code info
   */
  async sellPagePromoCodesPromoCodeIdGet(
    requestParameters: SellPagePromoCodesPromoCodeIdGetRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<PromoCodeByIdReadResponse> {
    const response = await this.sellPagePromoCodesPromoCodeIdGetRaw(
      requestParameters,
      initOverrides
    );
    return await response.value();
  }

  /**
   * Update promo code by id
   */
  async sellPagePromoCodesPromoCodeIdPatchRaw(
    requestParameters: SellPagePromoCodesPromoCodeIdPatchRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<PromoCodeUpdateResponse>> {
    if (requestParameters.promoCodeId === null || requestParameters.promoCodeId === undefined) {
      throw new runtime.RequiredError(
        'promoCodeId',
        'Required parameter requestParameters.promoCodeId was null or undefined when calling sellPagePromoCodesPromoCodeIdPatch.'
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    const response = await this.request(
      {
        path: `/sell/page/promo-codes/{promo_code_id}`.replace(
          `{${'promo_code_id'}}`,
          encodeURIComponent(String(requestParameters.promoCodeId))
        ),
        method: 'PATCH',
        headers: headerParameters,
        query: queryParameters,
        body: PromoCodeUpdateRequestToJSON(requestParameters.promoCodeUpdateRequest)
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      PromoCodeUpdateResponseFromJSON(jsonValue)
    );
  }

  /**
   * Update promo code by id
   */
  async sellPagePromoCodesPromoCodeIdPatch(
    requestParameters: SellPagePromoCodesPromoCodeIdPatchRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<PromoCodeUpdateResponse> {
    const response = await this.sellPagePromoCodesPromoCodeIdPatchRaw(
      requestParameters,
      initOverrides
    );
    return await response.value();
  }

  /**
   * Get all the restrictions of a promo code
   */
  async sellPagePromoCodesPromoCodeIdRestrictionsPostRaw(
    requestParameters: SellPagePromoCodesPromoCodeIdRestrictionsPostRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<PromoCodeRestrictionsReadResponse>> {
    if (requestParameters.promoCodeId === null || requestParameters.promoCodeId === undefined) {
      throw new runtime.RequiredError(
        'promoCodeId',
        'Required parameter requestParameters.promoCodeId was null or undefined when calling sellPagePromoCodesPromoCodeIdRestrictionsPost.'
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    const response = await this.request(
      {
        path: `/sell/page/promo-codes/{promo_code_id}/restrictions`.replace(
          `{${'promo_code_id'}}`,
          encodeURIComponent(String(requestParameters.promoCodeId))
        ),
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: PromoCodeRestrictionsReadRequestToJSON(
          requestParameters.promoCodeRestrictionsReadRequest
        )
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      PromoCodeRestrictionsReadResponseFromJSON(jsonValue)
    );
  }

  /**
   * Get all the restrictions of a promo code
   */
  async sellPagePromoCodesPromoCodeIdRestrictionsPost(
    requestParameters: SellPagePromoCodesPromoCodeIdRestrictionsPostRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<PromoCodeRestrictionsReadResponse> {
    const response = await this.sellPagePromoCodesPromoCodeIdRestrictionsPostRaw(
      requestParameters,
      initOverrides
    );
    return await response.value();
  }

  /**
   * Get all the uses of a promo code
   */
  async sellPagePromoCodesPromoCodeIdUsesPostRaw(
    requestParameters: SellPagePromoCodesPromoCodeIdUsesPostRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<PromoCodeUsagesReadResponse>> {
    if (requestParameters.promoCodeId === null || requestParameters.promoCodeId === undefined) {
      throw new runtime.RequiredError(
        'promoCodeId',
        'Required parameter requestParameters.promoCodeId was null or undefined when calling sellPagePromoCodesPromoCodeIdUsesPost.'
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    const response = await this.request(
      {
        path: `/sell/page/promo-codes/{promo_code_id}/uses`.replace(
          `{${'promo_code_id'}}`,
          encodeURIComponent(String(requestParameters.promoCodeId))
        ),
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: PromoCodeUsagesReadRequestToJSON(requestParameters.promoCodeUsagesReadRequest)
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      PromoCodeUsagesReadResponseFromJSON(jsonValue)
    );
  }

  /**
   * Get all the uses of a promo code
   */
  async sellPagePromoCodesPromoCodeIdUsesPost(
    requestParameters: SellPagePromoCodesPromoCodeIdUsesPostRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<PromoCodeUsagesReadResponse> {
    const response = await this.sellPagePromoCodesPromoCodeIdUsesPostRaw(
      requestParameters,
      initOverrides
    );
    return await response.value();
  }

  /**
   * Create pricing and availability
   */
  async sellPricingAvailabilityPostRaw(
    requestParameters: SellPricingAvailabilityPostRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<PricingAvailabilityCreateResponse>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    const response = await this.request(
      {
        path: `/sell/pricing-availability`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: PricingAvailabilityCreateRequestToJSON(
          requestParameters.pricingAvailabilityCreateRequest
        )
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      PricingAvailabilityCreateResponseFromJSON(jsonValue)
    );
  }

  /**
   * Create pricing and availability
   */
  async sellPricingAvailabilityPost(
    requestParameters: SellPricingAvailabilityPostRequest = {},
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<PricingAvailabilityCreateResponse> {
    const response = await this.sellPricingAvailabilityPostRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Read pricing and availability
   */
  async sellPricingAvailabilityProductOptionIdGetRaw(
    requestParameters: SellPricingAvailabilityProductOptionIdGetRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<PricingAvailabilityReadResponse>> {
    if (requestParameters.localeCode === null || requestParameters.localeCode === undefined) {
      throw new runtime.RequiredError(
        'localeCode',
        'Required parameter requestParameters.localeCode was null or undefined when calling sellPricingAvailabilityProductOptionIdGet.'
      );
    }

    if (
      requestParameters.productOptionId === null ||
      requestParameters.productOptionId === undefined
    ) {
      throw new runtime.RequiredError(
        'productOptionId',
        'Required parameter requestParameters.productOptionId was null or undefined when calling sellPricingAvailabilityProductOptionIdGet.'
      );
    }

    const queryParameters: any = {};

    if (requestParameters.localeCode !== undefined) {
      queryParameters['locale_code'] = requestParameters.localeCode;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request(
      {
        path: `/sell/pricing-availability/{product_option_id}`.replace(
          `{${'product_option_id'}}`,
          encodeURIComponent(String(requestParameters.productOptionId))
        ),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      PricingAvailabilityReadResponseFromJSON(jsonValue)
    );
  }

  /**
   * Read pricing and availability
   */
  async sellPricingAvailabilityProductOptionIdGet(
    requestParameters: SellPricingAvailabilityProductOptionIdGetRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<PricingAvailabilityReadResponse> {
    const response = await this.sellPricingAvailabilityProductOptionIdGetRaw(
      requestParameters,
      initOverrides
    );
    return await response.value();
  }

  /**
   * Download pricing categories from datatable
   */
  async sellPricingCategoriesDownloadPostRaw(
    requestParameters: SellPricingCategoriesDownloadPostRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<Blob>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    const response = await this.request(
      {
        path: `/sell/pricing-categories/download`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: PricingCategoriesDownloadRequestToJSON(
          requestParameters.pricingCategoriesDownloadRequest
        )
      },
      initOverrides
    );

    return new runtime.BlobApiResponse(response);
  }

  /**
   * Download pricing categories from datatable
   */
  async sellPricingCategoriesDownloadPost(
    requestParameters: SellPricingCategoriesDownloadPostRequest = {},
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<Blob> {
    const response = await this.sellPricingCategoriesDownloadPostRaw(
      requestParameters,
      initOverrides
    );
    return await response.value();
  }

  /**
   * Get all pricing categories
   */
  async sellPricingCategoriesPostRaw(
    requestParameters: SellPricingCategoriesPostRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<PricingCategoriesReadResponse>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    const response = await this.request(
      {
        path: `/sell/pricing-categories`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: PricingCategoriesReadRequestToJSON(requestParameters.pricingCategoriesReadRequest)
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      PricingCategoriesReadResponseFromJSON(jsonValue)
    );
  }

  /**
   * Get all pricing categories
   */
  async sellPricingCategoriesPost(
    requestParameters: SellPricingCategoriesPostRequest = {},
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<PricingCategoriesReadResponse> {
    const response = await this.sellPricingCategoriesPostRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Create pricing category
   */
  async sellPricingCategoryPostRaw(
    requestParameters: SellPricingCategoryPostRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<PricingCategoryCreateResponse>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    const response = await this.request(
      {
        path: `/sell/pricing-category`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: PricingCategoryCreateRequestToJSON(requestParameters.pricingCategoryCreateRequest)
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      PricingCategoryCreateResponseFromJSON(jsonValue)
    );
  }

  /**
   * Create pricing category
   */
  async sellPricingCategoryPost(
    requestParameters: SellPricingCategoryPostRequest = {},
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<PricingCategoryCreateResponse> {
    const response = await this.sellPricingCategoryPostRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Get pricing category by id
   */
  async sellPricingCategoryPricingCategoryIdGetRaw(
    requestParameters: SellPricingCategoryPricingCategoryIdGetRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<PricingCategoryByIdReadResponse>> {
    if (requestParameters.localeCode === null || requestParameters.localeCode === undefined) {
      throw new runtime.RequiredError(
        'localeCode',
        'Required parameter requestParameters.localeCode was null or undefined when calling sellPricingCategoryPricingCategoryIdGet.'
      );
    }

    if (
      requestParameters.pricingCategoryId === null ||
      requestParameters.pricingCategoryId === undefined
    ) {
      throw new runtime.RequiredError(
        'pricingCategoryId',
        'Required parameter requestParameters.pricingCategoryId was null or undefined when calling sellPricingCategoryPricingCategoryIdGet.'
      );
    }

    const queryParameters: any = {};

    if (requestParameters.localeCode !== undefined) {
      queryParameters['locale_code'] = requestParameters.localeCode;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request(
      {
        path: `/sell/pricing-category/{pricing_category_id}`.replace(
          `{${'pricing_category_id'}}`,
          encodeURIComponent(String(requestParameters.pricingCategoryId))
        ),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      PricingCategoryByIdReadResponseFromJSON(jsonValue)
    );
  }

  /**
   * Get pricing category by id
   */
  async sellPricingCategoryPricingCategoryIdGet(
    requestParameters: SellPricingCategoryPricingCategoryIdGetRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<PricingCategoryByIdReadResponse> {
    const response = await this.sellPricingCategoryPricingCategoryIdGetRaw(
      requestParameters,
      initOverrides
    );
    return await response.value();
  }

  /**
   * Update pricing category by id
   */
  async sellPricingCategoryPricingCategoryIdPutRaw(
    requestParameters: SellPricingCategoryPricingCategoryIdPutRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<PricingCategoryUpdateResponse>> {
    if (
      requestParameters.pricingCategoryId === null ||
      requestParameters.pricingCategoryId === undefined
    ) {
      throw new runtime.RequiredError(
        'pricingCategoryId',
        'Required parameter requestParameters.pricingCategoryId was null or undefined when calling sellPricingCategoryPricingCategoryIdPut.'
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    const response = await this.request(
      {
        path: `/sell/pricing-category/{pricing_category_id}`.replace(
          `{${'pricing_category_id'}}`,
          encodeURIComponent(String(requestParameters.pricingCategoryId))
        ),
        method: 'PUT',
        headers: headerParameters,
        query: queryParameters,
        body: PricingCategoryUpdateRequestToJSON(requestParameters.pricingCategoryUpdateRequest)
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      PricingCategoryUpdateResponseFromJSON(jsonValue)
    );
  }

  /**
   * Update pricing category by id
   */
  async sellPricingCategoryPricingCategoryIdPut(
    requestParameters: SellPricingCategoryPricingCategoryIdPutRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<PricingCategoryUpdateResponse> {
    const response = await this.sellPricingCategoryPricingCategoryIdPutRaw(
      requestParameters,
      initOverrides
    );
    return await response.value();
  }

  /**
   * Update pricing category rank.
   */
  async sellPricingCategoryRankPostRaw(
    requestParameters: SellPricingCategoryRankPostRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<PricingCategoryRankUpdateResponse>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    const response = await this.request(
      {
        path: `/sell/pricing-category/rank`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: PricingCategoryRankUpdateRequestToJSON(
          requestParameters.pricingCategoryRankUpdateRequest
        )
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      PricingCategoryRankUpdateResponseFromJSON(jsonValue)
    );
  }

  /**
   * Update pricing category rank.
   */
  async sellPricingCategoryRankPost(
    requestParameters: SellPricingCategoryRankPostRequest = {},
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<PricingCategoryRankUpdateResponse> {
    const response = await this.sellPricingCategoryRankPostRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Create pricing
   */
  async sellPricingPostRaw(
    requestParameters: SellPricingPostRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<PricingFlexibleCreateResponse>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    const response = await this.request(
      {
        path: `/sell/pricing`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: PricingFlexibleCreateRequestToJSON(requestParameters.pricingFlexibleCreateRequest)
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      PricingFlexibleCreateResponseFromJSON(jsonValue)
    );
  }

  /**
   * Create pricing
   */
  async sellPricingPost(
    requestParameters: SellPricingPostRequest = {},
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<PricingFlexibleCreateResponse> {
    const response = await this.sellPricingPostRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Update pricing
   */
  async sellPricingPricingIdDeleteRaw(
    requestParameters: SellPricingPricingIdDeleteRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<PricingDeleteResponse>> {
    if (requestParameters.pricingId === null || requestParameters.pricingId === undefined) {
      throw new runtime.RequiredError(
        'pricingId',
        'Required parameter requestParameters.pricingId was null or undefined when calling sellPricingPricingIdDelete.'
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    const response = await this.request(
      {
        path: `/sell/pricing/{pricing_id}`.replace(
          `{${'pricing_id'}}`,
          encodeURIComponent(String(requestParameters.pricingId))
        ),
        method: 'DELETE',
        headers: headerParameters,
        query: queryParameters,
        body: PricingDeleteRequestToJSON(requestParameters.pricingDeleteRequest)
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      PricingDeleteResponseFromJSON(jsonValue)
    );
  }

  /**
   * Update pricing
   */
  async sellPricingPricingIdDelete(
    requestParameters: SellPricingPricingIdDeleteRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<PricingDeleteResponse> {
    const response = await this.sellPricingPricingIdDeleteRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Get pricing by id
   */
  async sellPricingPricingIdGetRaw(
    requestParameters: SellPricingPricingIdGetRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<PricingByIdReadResponse>> {
    if (requestParameters.localeCode === null || requestParameters.localeCode === undefined) {
      throw new runtime.RequiredError(
        'localeCode',
        'Required parameter requestParameters.localeCode was null or undefined when calling sellPricingPricingIdGet.'
      );
    }

    if (requestParameters.pricingId === null || requestParameters.pricingId === undefined) {
      throw new runtime.RequiredError(
        'pricingId',
        'Required parameter requestParameters.pricingId was null or undefined when calling sellPricingPricingIdGet.'
      );
    }

    const queryParameters: any = {};

    if (requestParameters.localeCode !== undefined) {
      queryParameters['locale_code'] = requestParameters.localeCode;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request(
      {
        path: `/sell/pricing/{pricing_id}`.replace(
          `{${'pricing_id'}}`,
          encodeURIComponent(String(requestParameters.pricingId))
        ),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      PricingByIdReadResponseFromJSON(jsonValue)
    );
  }

  /**
   * Get pricing by id
   */
  async sellPricingPricingIdGet(
    requestParameters: SellPricingPricingIdGetRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<PricingByIdReadResponse> {
    const response = await this.sellPricingPricingIdGetRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Update pricing
   */
  async sellPricingPricingIdPutRaw(
    requestParameters: SellPricingPricingIdPutRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<PricingUpdateResponse>> {
    if (requestParameters.pricingId === null || requestParameters.pricingId === undefined) {
      throw new runtime.RequiredError(
        'pricingId',
        'Required parameter requestParameters.pricingId was null or undefined when calling sellPricingPricingIdPut.'
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    const response = await this.request(
      {
        path: `/sell/pricing/{pricing_id}`.replace(
          `{${'pricing_id'}}`,
          encodeURIComponent(String(requestParameters.pricingId))
        ),
        method: 'PUT',
        headers: headerParameters,
        query: queryParameters,
        body: PricingUpdateRequestToJSON(requestParameters.pricingUpdateRequest)
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      PricingUpdateResponseFromJSON(jsonValue)
    );
  }

  /**
   * Update pricing
   */
  async sellPricingPricingIdPut(
    requestParameters: SellPricingPricingIdPutRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<PricingUpdateResponse> {
    const response = await this.sellPricingPricingIdPutRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Update product media for product id
   */
  async sellProductMediaPostRaw(
    requestParameters: SellProductMediaPostRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<ProductMediaUpdateResponse>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    const response = await this.request(
      {
        path: `/sell/product-media`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: ProductMediaUpdateRequestToJSON(requestParameters.productMediaUpdateRequest)
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      ProductMediaUpdateResponseFromJSON(jsonValue)
    );
  }

  /**
   * Update product media for product id
   */
  async sellProductMediaPost(
    requestParameters: SellProductMediaPostRequest = {},
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<ProductMediaUpdateResponse> {
    const response = await this.sellProductMediaPostRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Get product media
   */
  async sellProductMediaProductIdGetRaw(
    requestParameters: SellProductMediaProductIdGetRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<ProductMediaReadResponse>> {
    if (requestParameters.localeCode === null || requestParameters.localeCode === undefined) {
      throw new runtime.RequiredError(
        'localeCode',
        'Required parameter requestParameters.localeCode was null or undefined when calling sellProductMediaProductIdGet.'
      );
    }

    if (requestParameters.productId === null || requestParameters.productId === undefined) {
      throw new runtime.RequiredError(
        'productId',
        'Required parameter requestParameters.productId was null or undefined when calling sellProductMediaProductIdGet.'
      );
    }

    const queryParameters: any = {};

    if (requestParameters.localeCode !== undefined) {
      queryParameters['locale_code'] = requestParameters.localeCode;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request(
      {
        path: `/sell/product-media/{product_id}`.replace(
          `{${'product_id'}}`,
          encodeURIComponent(String(requestParameters.productId))
        ),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      ProductMediaReadResponseFromJSON(jsonValue)
    );
  }

  /**
   * Get product media
   */
  async sellProductMediaProductIdGet(
    requestParameters: SellProductMediaProductIdGetRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<ProductMediaReadResponse> {
    const response = await this.sellProductMediaProductIdGetRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Update product option address for product option id
   */
  async sellProductOptionAddressPostRaw(
    requestParameters: SellProductOptionAddressPostRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<ProductOptionAddressUpdateResponse>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    const response = await this.request(
      {
        path: `/sell/product-option-address`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: ProductOptionAddressUpdateRequestToJSON(
          requestParameters.productOptionAddressUpdateRequest
        )
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      ProductOptionAddressUpdateResponseFromJSON(jsonValue)
    );
  }

  /**
   * Update product option address for product option id
   */
  async sellProductOptionAddressPost(
    requestParameters: SellProductOptionAddressPostRequest = {},
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<ProductOptionAddressUpdateResponse> {
    const response = await this.sellProductOptionAddressPostRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Get product option address
   */
  async sellProductOptionAddressProductOptionIdGetRaw(
    requestParameters: SellProductOptionAddressProductOptionIdGetRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<ProductOptionAddressReadResponse>> {
    if (requestParameters.localeCode === null || requestParameters.localeCode === undefined) {
      throw new runtime.RequiredError(
        'localeCode',
        'Required parameter requestParameters.localeCode was null or undefined when calling sellProductOptionAddressProductOptionIdGet.'
      );
    }

    if (
      requestParameters.productOptionId === null ||
      requestParameters.productOptionId === undefined
    ) {
      throw new runtime.RequiredError(
        'productOptionId',
        'Required parameter requestParameters.productOptionId was null or undefined when calling sellProductOptionAddressProductOptionIdGet.'
      );
    }

    const queryParameters: any = {};

    if (requestParameters.localeCode !== undefined) {
      queryParameters['locale_code'] = requestParameters.localeCode;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request(
      {
        path: `/sell/product-option-address/{product_option_id}`.replace(
          `{${'product_option_id'}}`,
          encodeURIComponent(String(requestParameters.productOptionId))
        ),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      ProductOptionAddressReadResponseFromJSON(jsonValue)
    );
  }

  /**
   * Get product option address
   */
  async sellProductOptionAddressProductOptionIdGet(
    requestParameters: SellProductOptionAddressProductOptionIdGetRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<ProductOptionAddressReadResponse> {
    const response = await this.sellProductOptionAddressProductOptionIdGetRaw(
      requestParameters,
      initOverrides
    );
    return await response.value();
  }

  /**
   * Get all product option channel configurations for a single channel
   */
  async sellProductOptionChannelConfigurationChannelIdGetRaw(
    requestParameters: SellProductOptionChannelConfigurationChannelIdGetRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<ProductOptionChannelConfigurationReadResponse>> {
    if (requestParameters.localeCode === null || requestParameters.localeCode === undefined) {
      throw new runtime.RequiredError(
        'localeCode',
        'Required parameter requestParameters.localeCode was null or undefined when calling sellProductOptionChannelConfigurationChannelIdGet.'
      );
    }

    if (requestParameters.channelId === null || requestParameters.channelId === undefined) {
      throw new runtime.RequiredError(
        'channelId',
        'Required parameter requestParameters.channelId was null or undefined when calling sellProductOptionChannelConfigurationChannelIdGet.'
      );
    }

    const queryParameters: any = {};

    if (requestParameters.localeCode !== undefined) {
      queryParameters['locale_code'] = requestParameters.localeCode;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request(
      {
        path: `/sell/product_option_channel_configuration/{channel_id}`.replace(
          `{${'channel_id'}}`,
          encodeURIComponent(String(requestParameters.channelId))
        ),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      ProductOptionChannelConfigurationReadResponseFromJSON(jsonValue)
    );
  }

  /**
   * Get all product option channel configurations for a single channel
   */
  async sellProductOptionChannelConfigurationChannelIdGet(
    requestParameters: SellProductOptionChannelConfigurationChannelIdGetRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<ProductOptionChannelConfigurationReadResponse> {
    const response = await this.sellProductOptionChannelConfigurationChannelIdGetRaw(
      requestParameters,
      initOverrides
    );
    return await response.value();
  }

  /**
   * Get product option channel configurations for a single product option
   */
  async sellProductOptionChannelConfigurationProductOptionProductOptionIdGetRaw(
    requestParameters: SellProductOptionChannelConfigurationProductOptionProductOptionIdGetRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<ProductOptionChannelConfigurationByOptionIdResponse>> {
    if (requestParameters.localeCode === null || requestParameters.localeCode === undefined) {
      throw new runtime.RequiredError(
        'localeCode',
        'Required parameter requestParameters.localeCode was null or undefined when calling sellProductOptionChannelConfigurationProductOptionProductOptionIdGet.'
      );
    }

    if (
      requestParameters.productOptionId === null ||
      requestParameters.productOptionId === undefined
    ) {
      throw new runtime.RequiredError(
        'productOptionId',
        'Required parameter requestParameters.productOptionId was null or undefined when calling sellProductOptionChannelConfigurationProductOptionProductOptionIdGet.'
      );
    }

    const queryParameters: any = {};

    if (requestParameters.localeCode !== undefined) {
      queryParameters['locale_code'] = requestParameters.localeCode;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request(
      {
        path: `/sell/product_option_channel_configuration/product_option/{product_option_id}`.replace(
          `{${'product_option_id'}}`,
          encodeURIComponent(String(requestParameters.productOptionId))
        ),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      ProductOptionChannelConfigurationByOptionIdResponseFromJSON(jsonValue)
    );
  }

  /**
   * Get product option channel configurations for a single product option
   */
  async sellProductOptionChannelConfigurationProductOptionProductOptionIdGet(
    requestParameters: SellProductOptionChannelConfigurationProductOptionProductOptionIdGetRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<ProductOptionChannelConfigurationByOptionIdResponse> {
    const response =
      await this.sellProductOptionChannelConfigurationProductOptionProductOptionIdGetRaw(
        requestParameters,
        initOverrides
      );
    return await response.value();
  }

  /**
   * Update product option channel configuration
   */
  async sellProductOptionChannelConfigurationPutRaw(
    requestParameters: SellProductOptionChannelConfigurationPutRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<ProductOptionChannelConfigurationUpdateResponse>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    const response = await this.request(
      {
        path: `/sell/product_option_channel_configuration/`,
        method: 'PUT',
        headers: headerParameters,
        query: queryParameters,
        body: ProductOptionChannelConfigurationUpdateRequestToJSON(
          requestParameters.productOptionChannelConfigurationUpdateRequest
        )
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      ProductOptionChannelConfigurationUpdateResponseFromJSON(jsonValue)
    );
  }

  /**
   * Update product option channel configuration
   */
  async sellProductOptionChannelConfigurationPut(
    requestParameters: SellProductOptionChannelConfigurationPutRequest = {},
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<ProductOptionChannelConfigurationUpdateResponse> {
    const response = await this.sellProductOptionChannelConfigurationPutRaw(
      requestParameters,
      initOverrides
    );
    return await response.value();
  }

  /**
   * Delete product option
   */
  async sellProductOptionDeleteRaw(
    requestParameters: SellProductOptionDeleteRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<ProductOptionDeleteResponse>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    const response = await this.request(
      {
        path: `/sell/product-option`,
        method: 'DELETE',
        headers: headerParameters,
        query: queryParameters,
        body: ProductOptionDeleteRequestToJSON(requestParameters.productOptionDeleteRequest)
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      ProductOptionDeleteResponseFromJSON(jsonValue)
    );
  }

  /**
   * Delete product option
   */
  async sellProductOptionDelete(
    requestParameters: SellProductOptionDeleteRequest = {},
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<ProductOptionDeleteResponse> {
    const response = await this.sellProductOptionDeleteRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Get Product Options
   */
  async sellProductOptionGetRaw(
    requestParameters: SellProductOptionGetRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<ProductOptionReadResponse>> {
    if (requestParameters.localeCode === null || requestParameters.localeCode === undefined) {
      throw new runtime.RequiredError(
        'localeCode',
        'Required parameter requestParameters.localeCode was null or undefined when calling sellProductOptionGet.'
      );
    }

    if (requestParameters.productId === null || requestParameters.productId === undefined) {
      throw new runtime.RequiredError(
        'productId',
        'Required parameter requestParameters.productId was null or undefined when calling sellProductOptionGet.'
      );
    }

    const queryParameters: any = {};

    if (requestParameters.localeCode !== undefined) {
      queryParameters['locale_code'] = requestParameters.localeCode;
    }

    if (requestParameters.productId !== undefined) {
      queryParameters['product_id'] = requestParameters.productId;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request(
      {
        path: `/sell/product-option`,
        method: 'GET',
        headers: headerParameters,
        query: queryParameters
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      ProductOptionReadResponseFromJSON(jsonValue)
    );
  }

  /**
   * Get Product Options
   */
  async sellProductOptionGet(
    requestParameters: SellProductOptionGetRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<ProductOptionReadResponse> {
    const response = await this.sellProductOptionGetRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Create Product Option
   */
  async sellProductOptionPostRaw(
    requestParameters: SellProductOptionPostRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<ProductOptionCreateResponse>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    const response = await this.request(
      {
        path: `/sell/product-option`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: ProductOptionCreateRequestToJSON(requestParameters.productOptionCreateRequest)
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      ProductOptionCreateResponseFromJSON(jsonValue)
    );
  }

  /**
   * Create Product Option
   */
  async sellProductOptionPost(
    requestParameters: SellProductOptionPostRequest = {},
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<ProductOptionCreateResponse> {
    const response = await this.sellProductOptionPostRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Get Product Options
   */
  async sellProductOptionProductOptionIdGetRaw(
    requestParameters: SellProductOptionProductOptionIdGetRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<ProductOptionByIdReadResponse>> {
    if (requestParameters.localeCode === null || requestParameters.localeCode === undefined) {
      throw new runtime.RequiredError(
        'localeCode',
        'Required parameter requestParameters.localeCode was null or undefined when calling sellProductOptionProductOptionIdGet.'
      );
    }

    if (
      requestParameters.productOptionId === null ||
      requestParameters.productOptionId === undefined
    ) {
      throw new runtime.RequiredError(
        'productOptionId',
        'Required parameter requestParameters.productOptionId was null or undefined when calling sellProductOptionProductOptionIdGet.'
      );
    }

    const queryParameters: any = {};

    if (requestParameters.localeCode !== undefined) {
      queryParameters['locale_code'] = requestParameters.localeCode;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request(
      {
        path: `/sell/product-option/{product_option_id}`.replace(
          `{${'product_option_id'}}`,
          encodeURIComponent(String(requestParameters.productOptionId))
        ),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      ProductOptionByIdReadResponseFromJSON(jsonValue)
    );
  }

  /**
   * Get Product Options
   */
  async sellProductOptionProductOptionIdGet(
    requestParameters: SellProductOptionProductOptionIdGetRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<ProductOptionByIdReadResponse> {
    const response = await this.sellProductOptionProductOptionIdGetRaw(
      requestParameters,
      initOverrides
    );
    return await response.value();
  }

  /**
   * Clone product option
   */
  async sellProductOptionProductOptionIdPutRaw(
    requestParameters: SellProductOptionProductOptionIdPutRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<ProductOptionCloneResponse>> {
    if (
      requestParameters.productOptionId === null ||
      requestParameters.productOptionId === undefined
    ) {
      throw new runtime.RequiredError(
        'productOptionId',
        'Required parameter requestParameters.productOptionId was null or undefined when calling sellProductOptionProductOptionIdPut.'
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request(
      {
        path: `/sell/product-option/{product_option_id}`.replace(
          `{${'product_option_id'}}`,
          encodeURIComponent(String(requestParameters.productOptionId))
        ),
        method: 'PUT',
        headers: headerParameters,
        query: queryParameters
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      ProductOptionCloneResponseFromJSON(jsonValue)
    );
  }

  /**
   * Clone product option
   */
  async sellProductOptionProductOptionIdPut(
    requestParameters: SellProductOptionProductOptionIdPutRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<ProductOptionCloneResponse> {
    const response = await this.sellProductOptionProductOptionIdPutRaw(
      requestParameters,
      initOverrides
    );
    return await response.value();
  }

  /**
   * Update Product Option
   */
  async sellProductOptionPutRaw(
    requestParameters: SellProductOptionPutRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<ProductOptionUpdateResponse>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    const response = await this.request(
      {
        path: `/sell/product-option`,
        method: 'PUT',
        headers: headerParameters,
        query: queryParameters,
        body: ProductOptionUpdateRequestToJSON(requestParameters.productOptionUpdateRequest)
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      ProductOptionUpdateResponseFromJSON(jsonValue)
    );
  }

  /**
   * Update Product Option
   */
  async sellProductOptionPut(
    requestParameters: SellProductOptionPutRequest = {},
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<ProductOptionUpdateResponse> {
    const response = await this.sellProductOptionPutRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Summary
   */
  async sellProductOptionRankPostRaw(
    requestParameters: SellProductOptionRankPostRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<ProductOptionRankUpdateResponse>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    const response = await this.request(
      {
        path: `/sell/product-option/rank`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: ProductOptionRankUpdateRequestToJSON(requestParameters.productOptionRankUpdateRequest)
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      ProductOptionRankUpdateResponseFromJSON(jsonValue)
    );
  }

  /**
   * Summary
   */
  async sellProductOptionRankPost(
    requestParameters: SellProductOptionRankPostRequest = {},
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<ProductOptionRankUpdateResponse> {
    const response = await this.sellProductOptionRankPostRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Update product option translations for product option id
   */
  async sellProductOptionTranslationPostRaw(
    requestParameters: SellProductOptionTranslationPostRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<ProductOptionTranslationUpdateResponse>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    const response = await this.request(
      {
        path: `/sell/product-option-translation`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: ProductOptionTranslationUpdateRequestToJSON(
          requestParameters.productOptionTranslationUpdateRequest
        )
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      ProductOptionTranslationUpdateResponseFromJSON(jsonValue)
    );
  }

  /**
   * Update product option translations for product option id
   */
  async sellProductOptionTranslationPost(
    requestParameters: SellProductOptionTranslationPostRequest = {},
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<ProductOptionTranslationUpdateResponse> {
    const response = await this.sellProductOptionTranslationPostRaw(
      requestParameters,
      initOverrides
    );
    return await response.value();
  }

  /**
   * Get product option translation
   */
  async sellProductOptionTranslationProductOptionIdGetRaw(
    requestParameters: SellProductOptionTranslationProductOptionIdGetRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<ProductOptionTranslationReadResponse>> {
    if (requestParameters.localeCode === null || requestParameters.localeCode === undefined) {
      throw new runtime.RequiredError(
        'localeCode',
        'Required parameter requestParameters.localeCode was null or undefined when calling sellProductOptionTranslationProductOptionIdGet.'
      );
    }

    if (
      requestParameters.productOptionId === null ||
      requestParameters.productOptionId === undefined
    ) {
      throw new runtime.RequiredError(
        'productOptionId',
        'Required parameter requestParameters.productOptionId was null or undefined when calling sellProductOptionTranslationProductOptionIdGet.'
      );
    }

    const queryParameters: any = {};

    if (requestParameters.localeCode !== undefined) {
      queryParameters['locale_code'] = requestParameters.localeCode;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request(
      {
        path: `/sell/product-option-translation/{product_option_id}`.replace(
          `{${'product_option_id'}}`,
          encodeURIComponent(String(requestParameters.productOptionId))
        ),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      ProductOptionTranslationReadResponseFromJSON(jsonValue)
    );
  }

  /**
   * Get product option translation
   */
  async sellProductOptionTranslationProductOptionIdGet(
    requestParameters: SellProductOptionTranslationProductOptionIdGetRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<ProductOptionTranslationReadResponse> {
    const response = await this.sellProductOptionTranslationProductOptionIdGetRaw(
      requestParameters,
      initOverrides
    );
    return await response.value();
  }

  /**
   * Get product option questions
   */
  async sellProductOptionsProductOptionIdQuestionsGetRaw(
    requestParameters: SellProductOptionsProductOptionIdQuestionsGetRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<ProductOptionQuestionReadResponse>> {
    if (requestParameters.localeCode === null || requestParameters.localeCode === undefined) {
      throw new runtime.RequiredError(
        'localeCode',
        'Required parameter requestParameters.localeCode was null or undefined when calling sellProductOptionsProductOptionIdQuestionsGet.'
      );
    }

    if (
      requestParameters.productOptionId === null ||
      requestParameters.productOptionId === undefined
    ) {
      throw new runtime.RequiredError(
        'productOptionId',
        'Required parameter requestParameters.productOptionId was null or undefined when calling sellProductOptionsProductOptionIdQuestionsGet.'
      );
    }

    const queryParameters: any = {};

    if (requestParameters.localeCode !== undefined) {
      queryParameters['locale_code'] = requestParameters.localeCode;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request(
      {
        path: `/sell/product-options/{product_option_id}/questions`.replace(
          `{${'product_option_id'}}`,
          encodeURIComponent(String(requestParameters.productOptionId))
        ),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      ProductOptionQuestionReadResponseFromJSON(jsonValue)
    );
  }

  /**
   * Get product option questions
   */
  async sellProductOptionsProductOptionIdQuestionsGet(
    requestParameters: SellProductOptionsProductOptionIdQuestionsGetRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<ProductOptionQuestionReadResponse> {
    const response = await this.sellProductOptionsProductOptionIdQuestionsGetRaw(
      requestParameters,
      initOverrides
    );
    return await response.value();
  }

  /**
   * Update product option questions for product option id
   */
  async sellProductOptionsProductOptionIdQuestionsPutRaw(
    requestParameters: SellProductOptionsProductOptionIdQuestionsPutRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<ProductOptionQuestionUpdateResponse>> {
    if (
      requestParameters.productOptionId === null ||
      requestParameters.productOptionId === undefined
    ) {
      throw new runtime.RequiredError(
        'productOptionId',
        'Required parameter requestParameters.productOptionId was null or undefined when calling sellProductOptionsProductOptionIdQuestionsPut.'
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    const response = await this.request(
      {
        path: `/sell/product-options/{product_option_id}/questions`.replace(
          `{${'product_option_id'}}`,
          encodeURIComponent(String(requestParameters.productOptionId))
        ),
        method: 'PUT',
        headers: headerParameters,
        query: queryParameters,
        body: ProductOptionQuestionUpdateRequestToJSON(
          requestParameters.productOptionQuestionUpdateRequest
        )
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      ProductOptionQuestionUpdateResponseFromJSON(jsonValue)
    );
  }

  /**
   * Update product option questions for product option id
   */
  async sellProductOptionsProductOptionIdQuestionsPut(
    requestParameters: SellProductOptionsProductOptionIdQuestionsPutRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<ProductOptionQuestionUpdateResponse> {
    const response = await this.sellProductOptionsProductOptionIdQuestionsPutRaw(
      requestParameters,
      initOverrides
    );
    return await response.value();
  }

  /**
   * Create product
   */
  async sellProductPostRaw(
    requestParameters: SellProductPostRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<ProductCreateResponse>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    const response = await this.request(
      {
        path: `/sell/product`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: ProductCreateRequestToJSON(requestParameters.productCreateRequest)
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      ProductCreateResponseFromJSON(jsonValue)
    );
  }

  /**
   * Create product
   */
  async sellProductPost(
    requestParameters: SellProductPostRequest = {},
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<ProductCreateResponse> {
    const response = await this.sellProductPostRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Delete product
   */
  async sellProductProductIdDeleteRaw(
    requestParameters: SellProductProductIdDeleteRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<ProductDeleteResponse>> {
    if (requestParameters.productId === null || requestParameters.productId === undefined) {
      throw new runtime.RequiredError(
        'productId',
        'Required parameter requestParameters.productId was null or undefined when calling sellProductProductIdDelete.'
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    const response = await this.request(
      {
        path: `/sell/product/{product_id}`.replace(
          `{${'product_id'}}`,
          encodeURIComponent(String(requestParameters.productId))
        ),
        method: 'DELETE',
        headers: headerParameters,
        query: queryParameters,
        body: ProductDeleteRequestToJSON(requestParameters.productDeleteRequest)
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      ProductDeleteResponseFromJSON(jsonValue)
    );
  }

  /**
   * Delete product
   */
  async sellProductProductIdDelete(
    requestParameters: SellProductProductIdDeleteRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<ProductDeleteResponse> {
    const response = await this.sellProductProductIdDeleteRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Summary
   */
  async sellProductProductIdGetRaw(
    requestParameters: SellProductProductIdGetRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<ProductByIdReadResponse>> {
    if (requestParameters.localeCode === null || requestParameters.localeCode === undefined) {
      throw new runtime.RequiredError(
        'localeCode',
        'Required parameter requestParameters.localeCode was null or undefined when calling sellProductProductIdGet.'
      );
    }

    if (requestParameters.productId === null || requestParameters.productId === undefined) {
      throw new runtime.RequiredError(
        'productId',
        'Required parameter requestParameters.productId was null or undefined when calling sellProductProductIdGet.'
      );
    }

    const queryParameters: any = {};

    if (requestParameters.localeCode !== undefined) {
      queryParameters['locale_code'] = requestParameters.localeCode;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request(
      {
        path: `/sell/product/{product_id}`.replace(
          `{${'product_id'}}`,
          encodeURIComponent(String(requestParameters.productId))
        ),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      ProductByIdReadResponseFromJSON(jsonValue)
    );
  }

  /**
   * Summary
   */
  async sellProductProductIdGet(
    requestParameters: SellProductProductIdGetRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<ProductByIdReadResponse> {
    const response = await this.sellProductProductIdGetRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Clone product
   */
  async sellProductProductIdPostRaw(
    requestParameters: SellProductProductIdPostRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<ProductCloneResponse>> {
    if (requestParameters.productId === null || requestParameters.productId === undefined) {
      throw new runtime.RequiredError(
        'productId',
        'Required parameter requestParameters.productId was null or undefined when calling sellProductProductIdPost.'
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request(
      {
        path: `/sell/product/{product_id}`.replace(
          `{${'product_id'}}`,
          encodeURIComponent(String(requestParameters.productId))
        ),
        method: 'POST',
        headers: headerParameters,
        query: queryParameters
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      ProductCloneResponseFromJSON(jsonValue)
    );
  }

  /**
   * Clone product
   */
  async sellProductProductIdPost(
    requestParameters: SellProductProductIdPostRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<ProductCloneResponse> {
    const response = await this.sellProductProductIdPostRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Update product
   */
  async sellProductProductIdPutRaw(
    requestParameters: SellProductProductIdPutRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<ProductUpdateResponse>> {
    if (requestParameters.productId === null || requestParameters.productId === undefined) {
      throw new runtime.RequiredError(
        'productId',
        'Required parameter requestParameters.productId was null or undefined when calling sellProductProductIdPut.'
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    const response = await this.request(
      {
        path: `/sell/product/{product_id}`.replace(
          `{${'product_id'}}`,
          encodeURIComponent(String(requestParameters.productId))
        ),
        method: 'PUT',
        headers: headerParameters,
        query: queryParameters,
        body: ProductUpdateRequestToJSON(requestParameters.productUpdateRequest)
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      ProductUpdateResponseFromJSON(jsonValue)
    );
  }

  /**
   * Update product
   */
  async sellProductProductIdPut(
    requestParameters: SellProductProductIdPutRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<ProductUpdateResponse> {
    const response = await this.sellProductProductIdPutRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Update product status
   */
  async sellProductStatusProductIdPutRaw(
    requestParameters: SellProductStatusProductIdPutRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<ProductStatusUpdateResponse>> {
    if (requestParameters.productId === null || requestParameters.productId === undefined) {
      throw new runtime.RequiredError(
        'productId',
        'Required parameter requestParameters.productId was null or undefined when calling sellProductStatusProductIdPut.'
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    const response = await this.request(
      {
        path: `/sell/product/status/{product_id}`.replace(
          `{${'product_id'}}`,
          encodeURIComponent(String(requestParameters.productId))
        ),
        method: 'PUT',
        headers: headerParameters,
        query: queryParameters,
        body: ProductStatusUpdateRequestToJSON(requestParameters.productStatusUpdateRequest)
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      ProductStatusUpdateResponseFromJSON(jsonValue)
    );
  }

  /**
   * Update product status
   */
  async sellProductStatusProductIdPut(
    requestParameters: SellProductStatusProductIdPutRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<ProductStatusUpdateResponse> {
    const response = await this.sellProductStatusProductIdPutRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Update product translations for product id
   */
  async sellProductTranslationPostRaw(
    requestParameters: SellProductTranslationPostRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<ProductTranslationUpdateResponse>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    const response = await this.request(
      {
        path: `/sell/product-translation`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: ProductTranslationUpdateRequestToJSON(
          requestParameters.productTranslationUpdateRequest
        )
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      ProductTranslationUpdateResponseFromJSON(jsonValue)
    );
  }

  /**
   * Update product translations for product id
   */
  async sellProductTranslationPost(
    requestParameters: SellProductTranslationPostRequest = {},
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<ProductTranslationUpdateResponse> {
    const response = await this.sellProductTranslationPostRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Get product translation
   */
  async sellProductTranslationProductIdGetRaw(
    requestParameters: SellProductTranslationProductIdGetRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<ProductTranslationReadResponse>> {
    if (requestParameters.localeCode === null || requestParameters.localeCode === undefined) {
      throw new runtime.RequiredError(
        'localeCode',
        'Required parameter requestParameters.localeCode was null or undefined when calling sellProductTranslationProductIdGet.'
      );
    }

    if (requestParameters.productId === null || requestParameters.productId === undefined) {
      throw new runtime.RequiredError(
        'productId',
        'Required parameter requestParameters.productId was null or undefined when calling sellProductTranslationProductIdGet.'
      );
    }

    const queryParameters: any = {};

    if (requestParameters.localeCode !== undefined) {
      queryParameters['locale_code'] = requestParameters.localeCode;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request(
      {
        path: `/sell/product-translation/{product_id}`.replace(
          `{${'product_id'}}`,
          encodeURIComponent(String(requestParameters.productId))
        ),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      ProductTranslationReadResponseFromJSON(jsonValue)
    );
  }

  /**
   * Get product translation
   */
  async sellProductTranslationProductIdGet(
    requestParameters: SellProductTranslationProductIdGetRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<ProductTranslationReadResponse> {
    const response = await this.sellProductTranslationProductIdGetRaw(
      requestParameters,
      initOverrides
    );
    return await response.value();
  }

  /**
   * Get All Products and Options
   */
  async sellProductsPostRaw(
    requestParameters: SellProductsPostRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<ProductsReadResponse>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    const response = await this.request(
      {
        path: `/sell/products`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: ProductsReadRequestToJSON(requestParameters.productsReadRequest)
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      ProductsReadResponseFromJSON(jsonValue)
    );
  }

  /**
   * Get All Products and Options
   */
  async sellProductsPost(
    requestParameters: SellProductsPostRequest = {},
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<ProductsReadResponse> {
    const response = await this.sellProductsPostRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Create Promo Code
   */
  async sellPromoCodesPostRaw(
    requestParameters: SellPromoCodesPostRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<PromoCodeCreateResponse>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    const response = await this.request(
      {
        path: `/sell/promo-codes`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: PromoCodeCreateRequestToJSON(requestParameters.promoCodeCreateRequest)
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      PromoCodeCreateResponseFromJSON(jsonValue)
    );
  }

  /**
   * Create Promo Code
   */
  async sellPromoCodesPost(
    requestParameters: SellPromoCodesPostRequest = {},
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<PromoCodeCreateResponse> {
    const response = await this.sellPromoCodesPostRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Create question
   */
  async sellQuestionsPostRaw(
    requestParameters: SellQuestionsPostRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<QuestionCreateResponse>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    const response = await this.request(
      {
        path: `/sell/questions`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: QuestionCreateRequestToJSON(requestParameters.questionCreateRequest)
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      QuestionCreateResponseFromJSON(jsonValue)
    );
  }

  /**
   * Create question
   */
  async sellQuestionsPost(
    requestParameters: SellQuestionsPostRequest = {},
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<QuestionCreateResponse> {
    const response = await this.sellQuestionsPostRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Get a question by id
   */
  async sellQuestionsQuestionIdGetRaw(
    requestParameters: SellQuestionsQuestionIdGetRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<QuestionByIdReadResponse>> {
    if (requestParameters.localeCode === null || requestParameters.localeCode === undefined) {
      throw new runtime.RequiredError(
        'localeCode',
        'Required parameter requestParameters.localeCode was null or undefined when calling sellQuestionsQuestionIdGet.'
      );
    }

    if (requestParameters.questionId === null || requestParameters.questionId === undefined) {
      throw new runtime.RequiredError(
        'questionId',
        'Required parameter requestParameters.questionId was null or undefined when calling sellQuestionsQuestionIdGet.'
      );
    }

    const queryParameters: any = {};

    if (requestParameters.localeCode !== undefined) {
      queryParameters['locale_code'] = requestParameters.localeCode;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request(
      {
        path: `/sell/questions/{question_id}`.replace(
          `{${'question_id'}}`,
          encodeURIComponent(String(requestParameters.questionId))
        ),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      QuestionByIdReadResponseFromJSON(jsonValue)
    );
  }

  /**
   * Get a question by id
   */
  async sellQuestionsQuestionIdGet(
    requestParameters: SellQuestionsQuestionIdGetRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<QuestionByIdReadResponse> {
    const response = await this.sellQuestionsQuestionIdGetRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Update question by id
   */
  async sellQuestionsQuestionIdPutRaw(
    requestParameters: SellQuestionsQuestionIdPutRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<QuestionUpdateResponse>> {
    if (requestParameters.questionId === null || requestParameters.questionId === undefined) {
      throw new runtime.RequiredError(
        'questionId',
        'Required parameter requestParameters.questionId was null or undefined when calling sellQuestionsQuestionIdPut.'
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    const response = await this.request(
      {
        path: `/sell/questions/{question_id}`.replace(
          `{${'question_id'}}`,
          encodeURIComponent(String(requestParameters.questionId))
        ),
        method: 'PUT',
        headers: headerParameters,
        query: queryParameters,
        body: QuestionUpdateRequestToJSON(requestParameters.questionUpdateRequest)
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      QuestionUpdateResponseFromJSON(jsonValue)
    );
  }

  /**
   * Update question by id
   */
  async sellQuestionsQuestionIdPut(
    requestParameters: SellQuestionsQuestionIdPutRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<QuestionUpdateResponse> {
    const response = await this.sellQuestionsQuestionIdPutRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Get all questions
   */
  async sellQuestionsSearchPostRaw(
    requestParameters: SellQuestionsSearchPostRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<QuestionsReadResponse>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    const response = await this.request(
      {
        path: `/sell/questions/search`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: QuestionsReadRequestToJSON(requestParameters.questionsReadRequest)
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      QuestionsReadResponseFromJSON(jsonValue)
    );
  }

  /**
   * Get all questions
   */
  async sellQuestionsSearchPost(
    requestParameters: SellQuestionsSearchPostRequest = {},
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<QuestionsReadResponse> {
    const response = await this.sellQuestionsSearchPostRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Get Ticket by code
   */
  async sellTicketCodeGetRaw(
    requestParameters: SellTicketCodeGetRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<TicketByCodeReadResponse>> {
    if (requestParameters.localeCode === null || requestParameters.localeCode === undefined) {
      throw new runtime.RequiredError(
        'localeCode',
        'Required parameter requestParameters.localeCode was null or undefined when calling sellTicketCodeGet.'
      );
    }

    if (requestParameters.code === null || requestParameters.code === undefined) {
      throw new runtime.RequiredError(
        'code',
        'Required parameter requestParameters.code was null or undefined when calling sellTicketCodeGet.'
      );
    }

    const queryParameters: any = {};

    if (requestParameters.localeCode !== undefined) {
      queryParameters['locale_code'] = requestParameters.localeCode;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request(
      {
        path: `/sell/ticket/{code}`.replace(
          `{${'code'}}`,
          encodeURIComponent(String(requestParameters.code))
        ),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      TicketByCodeReadResponseFromJSON(jsonValue)
    );
  }

  /**
   * Get Ticket by code
   */
  async sellTicketCodeGet(
    requestParameters: SellTicketCodeGetRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<TicketByCodeReadResponse> {
    const response = await this.sellTicketCodeGetRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Redeem ticket validities for a specific ticket code
   */
  async sellTicketCodeRedeemPostRaw(
    requestParameters: SellTicketCodeRedeemPostRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<TicketsRedemptionUpdateResponse>> {
    if (requestParameters.code === null || requestParameters.code === undefined) {
      throw new runtime.RequiredError(
        'code',
        'Required parameter requestParameters.code was null or undefined when calling sellTicketCodeRedeemPost.'
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    const response = await this.request(
      {
        path: `/sell/ticket/{code}/redeem`.replace(
          `{${'code'}}`,
          encodeURIComponent(String(requestParameters.code))
        ),
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: TicketsRedemptionUpdateRequestToJSON(requestParameters.ticketsRedemptionUpdateRequest)
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      TicketsRedemptionUpdateResponseFromJSON(jsonValue)
    );
  }

  /**
   * Redeem ticket validities for a specific ticket code
   */
  async sellTicketCodeRedeemPost(
    requestParameters: SellTicketCodeRedeemPostRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<TicketsRedemptionUpdateResponse> {
    const response = await this.sellTicketCodeRedeemPostRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Return the ticket configuration by product option id
   */
  async sellTicketConfigurationProductOptionIdGetRaw(
    requestParameters: SellTicketConfigurationProductOptionIdGetRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<TicketConfigurationByProductOptionIdReadResponse>> {
    if (requestParameters.localeCode === null || requestParameters.localeCode === undefined) {
      throw new runtime.RequiredError(
        'localeCode',
        'Required parameter requestParameters.localeCode was null or undefined when calling sellTicketConfigurationProductOptionIdGet.'
      );
    }

    if (
      requestParameters.productOptionId === null ||
      requestParameters.productOptionId === undefined
    ) {
      throw new runtime.RequiredError(
        'productOptionId',
        'Required parameter requestParameters.productOptionId was null or undefined when calling sellTicketConfigurationProductOptionIdGet.'
      );
    }

    const queryParameters: any = {};

    if (requestParameters.localeCode !== undefined) {
      queryParameters['locale_code'] = requestParameters.localeCode;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request(
      {
        path: `/sell/ticket-configuration/{product_option_id}`.replace(
          `{${'product_option_id'}}`,
          encodeURIComponent(String(requestParameters.productOptionId))
        ),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      TicketConfigurationByProductOptionIdReadResponseFromJSON(jsonValue)
    );
  }

  /**
   * Return the ticket configuration by product option id
   */
  async sellTicketConfigurationProductOptionIdGet(
    requestParameters: SellTicketConfigurationProductOptionIdGetRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<TicketConfigurationByProductOptionIdReadResponse> {
    const response = await this.sellTicketConfigurationProductOptionIdGetRaw(
      requestParameters,
      initOverrides
    );
    return await response.value();
  }

  /**
   * Update the ticket configuration associated with a product id
   */
  async sellTicketConfigurationProductOptionIdPutRaw(
    requestParameters: SellTicketConfigurationProductOptionIdPutRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<TicketConfigurationUpdateResponse>> {
    if (requestParameters.localeCode === null || requestParameters.localeCode === undefined) {
      throw new runtime.RequiredError(
        'localeCode',
        'Required parameter requestParameters.localeCode was null or undefined when calling sellTicketConfigurationProductOptionIdPut.'
      );
    }

    if (
      requestParameters.productOptionId === null ||
      requestParameters.productOptionId === undefined
    ) {
      throw new runtime.RequiredError(
        'productOptionId',
        'Required parameter requestParameters.productOptionId was null or undefined when calling sellTicketConfigurationProductOptionIdPut.'
      );
    }

    const queryParameters: any = {};

    if (requestParameters.localeCode !== undefined) {
      queryParameters['locale_code'] = requestParameters.localeCode;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    const response = await this.request(
      {
        path: `/sell/ticket-configuration/{product_option_id}`.replace(
          `{${'product_option_id'}}`,
          encodeURIComponent(String(requestParameters.productOptionId))
        ),
        method: 'PUT',
        headers: headerParameters,
        query: queryParameters,
        body: TicketConfigurationUpdateRequestToJSON(
          requestParameters.ticketConfigurationUpdateRequest
        )
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      TicketConfigurationUpdateResponseFromJSON(jsonValue)
    );
  }

  /**
   * Update the ticket configuration associated with a product id
   */
  async sellTicketConfigurationProductOptionIdPut(
    requestParameters: SellTicketConfigurationProductOptionIdPutRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<TicketConfigurationUpdateResponse> {
    const response = await this.sellTicketConfigurationProductOptionIdPutRaw(
      requestParameters,
      initOverrides
    );
    return await response.value();
  }

  /**
   * Log of redeemed tickets
   */
  async sellTicketRedemptionLogPostRaw(
    requestParameters: SellTicketRedemptionLogPostRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<TicketsRedemptionReadResponse>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    const response = await this.request(
      {
        path: `/sell/ticket/redemption-log`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: TicketsRedemptionReadRequestToJSON(requestParameters.ticketsRedemptionReadRequest)
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      TicketsRedemptionReadResponseFromJSON(jsonValue)
    );
  }

  /**
   * Log of redeemed tickets
   */
  async sellTicketRedemptionLogPost(
    requestParameters: SellTicketRedemptionLogPostRequest = {},
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<TicketsRedemptionReadResponse> {
    const response = await this.sellTicketRedemptionLogPostRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Get timeslot by id
   */
  async sellTimeSlotTimeSlotIdGetRaw(
    requestParameters: SellTimeSlotTimeSlotIdGetRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<TimeSlotByIdReadResponse>> {
    if (requestParameters.localeCode === null || requestParameters.localeCode === undefined) {
      throw new runtime.RequiredError(
        'localeCode',
        'Required parameter requestParameters.localeCode was null or undefined when calling sellTimeSlotTimeSlotIdGet.'
      );
    }

    if (requestParameters.timeSlotId === null || requestParameters.timeSlotId === undefined) {
      throw new runtime.RequiredError(
        'timeSlotId',
        'Required parameter requestParameters.timeSlotId was null or undefined when calling sellTimeSlotTimeSlotIdGet.'
      );
    }

    const queryParameters: any = {};

    if (requestParameters.localeCode !== undefined) {
      queryParameters['locale_code'] = requestParameters.localeCode;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request(
      {
        path: `/sell/time-slot/{time_slot_id}`.replace(
          `{${'time_slot_id'}}`,
          encodeURIComponent(String(requestParameters.timeSlotId))
        ),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      TimeSlotByIdReadResponseFromJSON(jsonValue)
    );
  }

  /**
   * Get timeslot by id
   */
  async sellTimeSlotTimeSlotIdGet(
    requestParameters: SellTimeSlotTimeSlotIdGetRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<TimeSlotByIdReadResponse> {
    const response = await this.sellTimeSlotTimeSlotIdGetRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Get all timeslots / availability
   */
  async sellTimeSlotsPostRaw(
    requestParameters: SellTimeSlotsPostRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<TimeSlotReadResponse>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    const response = await this.request(
      {
        path: `/sell/time-slots`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: TimeSlotReadRequestToJSON(requestParameters.timeSlotReadRequest)
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      TimeSlotReadResponseFromJSON(jsonValue)
    );
  }

  /**
   * Get all timeslots / availability
   */
  async sellTimeSlotsPost(
    requestParameters: SellTimeSlotsPostRequest = {},
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<TimeSlotReadResponse> {
    const response = await this.sellTimeSlotsPostRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Batch update timeslots
   */
  async sellUpdateTimeslotsPatchRaw(
    requestParameters: SellUpdateTimeslotsPatchRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<TimeSlotBatchUpdateResponse>> {
    if (
      requestParameters.timeSlotBatchUpdateRequestInner === null ||
      requestParameters.timeSlotBatchUpdateRequestInner === undefined
    ) {
      throw new runtime.RequiredError(
        'timeSlotBatchUpdateRequestInner',
        'Required parameter requestParameters.timeSlotBatchUpdateRequestInner was null or undefined when calling sellUpdateTimeslotsPatch.'
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    const response = await this.request(
      {
        path: `/sell/update-timeslots`,
        method: 'PATCH',
        headers: headerParameters,
        query: queryParameters,
        body: requestParameters.timeSlotBatchUpdateRequestInner.map(
          TimeSlotBatchUpdateRequestInnerToJSON
        )
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      TimeSlotBatchUpdateResponseFromJSON(jsonValue)
    );
  }

  /**
   * Batch update timeslots
   */
  async sellUpdateTimeslotsPatch(
    requestParameters: SellUpdateTimeslotsPatchRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<TimeSlotBatchUpdateResponse> {
    const response = await this.sellUpdateTimeslotsPatchRaw(requestParameters, initOverrides);
    return await response.value();
  }
}
