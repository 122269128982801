/* tslint:disable */
/* eslint-disable */
/**
 * Ticketing Portal
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { AllocationsReadResponseAllocationsInner } from './AllocationsReadResponseAllocationsInner';
import {
  AllocationsReadResponseAllocationsInnerFromJSON,
  AllocationsReadResponseAllocationsInnerFromJSONTyped,
  AllocationsReadResponseAllocationsInnerToJSON
} from './AllocationsReadResponseAllocationsInner';

/**
 *
 * @export
 * @interface AllocationsReadResponse
 */
export interface AllocationsReadResponse {
  /**
   *
   * @type {boolean}
   * @memberof AllocationsReadResponse
   */
  success: boolean;
  /**
   *
   * @type {string}
   * @memberof AllocationsReadResponse
   */
  exception_code?: string;
  /**
   *
   * @type {string}
   * @memberof AllocationsReadResponse
   */
  exception_message?: string;
  /**
   *
   * @type {Array<AllocationsReadResponseAllocationsInner>}
   * @memberof AllocationsReadResponse
   */
  allocations: Array<AllocationsReadResponseAllocationsInner>;
}

/**
 * Check if a given object implements the AllocationsReadResponse interface.
 */
export function instanceOfAllocationsReadResponse(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && 'success' in value;
  isInstance = isInstance && 'allocations' in value;

  return isInstance;
}

export function AllocationsReadResponseFromJSON(json: any): AllocationsReadResponse {
  return AllocationsReadResponseFromJSONTyped(json, false);
}

export function AllocationsReadResponseFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): AllocationsReadResponse {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    success: json['success'],
    exception_code: !exists(json, 'exception_code') ? undefined : json['exception_code'],
    exception_message: !exists(json, 'exception_message') ? undefined : json['exception_message'],
    allocations: (json['allocations'] as Array<any>).map(
      AllocationsReadResponseAllocationsInnerFromJSON
    )
  };
}

export function AllocationsReadResponseToJSON(value?: AllocationsReadResponse | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    success: value.success,
    exception_code: value.exception_code,
    exception_message: value.exception_message,
    allocations: (value.allocations as Array<any>).map(
      AllocationsReadResponseAllocationsInnerToJSON
    )
  };
}
