import { ROUTE_NAMES } from '@/constants/routes';
import { PAGE_TITLES } from '@/constants/breadcrumbs';
import { InitializationReadResponsePermissionsEnum } from '@openapi/models';

export const PRICINGCATEGORIES_ROUTES = [
  {
    path: 'manage/pricing-categories',
    name: ROUTE_NAMES.PRICING_CATEGORIES,
    component: () => import('@/views/pages/manage/PricingCategories.vue'),
    meta: {
      breadcrumbs: [PAGE_TITLES.MANAGE, PAGE_TITLES.PRICING_CATEGORIES],
      menu: ['main'],
      permissions: [InitializationReadResponsePermissionsEnum.ProductView],
      label: PAGE_TITLES.PRICING_CATEGORIES,
      icon: 'pi pi-fw pi-users'
    }
  },
  {
    path: 'manage/pricing-categories/+',
    name: ROUTE_NAMES.NEW_PRICING_CATEGORY,
    component: () => import('@/views/pages/manage/PricingCategoryNew.vue'),
    meta: {
      breadcrumbs: [
        PAGE_TITLES.MANAGE,
        PAGE_TITLES.PRICING_CATEGORIES,
        PAGE_TITLES.NEW_PRICING_CATEGORY
      ]
    }
  },
  {
    path: 'manage/pricing-categories/:pricingCategoryId',
    name: ROUTE_NAMES.EDIT_PRICING_CATEGORY,
    component: () => import('@/views/pages/manage/PricingCategory.vue'),
    meta: {
      breadcrumbs: [
        PAGE_TITLES.MANAGE,
        PAGE_TITLES.PRICING_CATEGORIES,
        PAGE_TITLES.EDIT_PRICING_CATEGORY
      ]
    }
  }
];
