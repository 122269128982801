/* tslint:disable */
/* eslint-disable */
/**
 * Ticketing Portal
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface BookingConfirmRejectRequest
 */
export interface BookingConfirmRejectRequest {
  /**
   *
   * @type {string}
   * @memberof BookingConfirmRejectRequest
   */
  locale_code: string;
  /**
   *
   * @type {boolean}
   * @memberof BookingConfirmRejectRequest
   */
  confirm_reject: boolean;
}

/**
 * Check if a given object implements the BookingConfirmRejectRequest interface.
 */
export function instanceOfBookingConfirmRejectRequest(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && 'locale_code' in value;
  isInstance = isInstance && 'confirm_reject' in value;

  return isInstance;
}

export function BookingConfirmRejectRequestFromJSON(json: any): BookingConfirmRejectRequest {
  return BookingConfirmRejectRequestFromJSONTyped(json, false);
}

export function BookingConfirmRejectRequestFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): BookingConfirmRejectRequest {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    locale_code: json['locale_code'],
    confirm_reject: json['confirm_reject']
  };
}

export function BookingConfirmRejectRequestToJSON(value?: BookingConfirmRejectRequest | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    locale_code: value.locale_code,
    confirm_reject: value.confirm_reject
  };
}
