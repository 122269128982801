/* tslint:disable */
/* eslint-disable */
/**
 * Ticketing Portal
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ChannelsReadResponseChannelsInner } from './ChannelsReadResponseChannelsInner';
import {
  ChannelsReadResponseChannelsInnerFromJSON,
  ChannelsReadResponseChannelsInnerFromJSONTyped,
  ChannelsReadResponseChannelsInnerToJSON
} from './ChannelsReadResponseChannelsInner';
import type { ChannelsReadResponseMeta } from './ChannelsReadResponseMeta';
import {
  ChannelsReadResponseMetaFromJSON,
  ChannelsReadResponseMetaFromJSONTyped,
  ChannelsReadResponseMetaToJSON
} from './ChannelsReadResponseMeta';

/**
 *
 * @export
 * @interface ChannelsReadResponse
 */
export interface ChannelsReadResponse {
  /**
   *
   * @type {boolean}
   * @memberof ChannelsReadResponse
   */
  success: boolean;
  /**
   *
   * @type {string}
   * @memberof ChannelsReadResponse
   */
  exception_code?: string;
  /**
   *
   * @type {string}
   * @memberof ChannelsReadResponse
   */
  exception_message?: string;
  /**
   *
   * @type {Array<ChannelsReadResponseChannelsInner>}
   * @memberof ChannelsReadResponse
   */
  channels: Array<ChannelsReadResponseChannelsInner>;
  /**
   *
   * @type {ChannelsReadResponseMeta}
   * @memberof ChannelsReadResponse
   */
  meta: ChannelsReadResponseMeta;
}

/**
 * Check if a given object implements the ChannelsReadResponse interface.
 */
export function instanceOfChannelsReadResponse(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && 'success' in value;
  isInstance = isInstance && 'channels' in value;
  isInstance = isInstance && 'meta' in value;

  return isInstance;
}

export function ChannelsReadResponseFromJSON(json: any): ChannelsReadResponse {
  return ChannelsReadResponseFromJSONTyped(json, false);
}

export function ChannelsReadResponseFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): ChannelsReadResponse {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    success: json['success'],
    exception_code: !exists(json, 'exception_code') ? undefined : json['exception_code'],
    exception_message: !exists(json, 'exception_message') ? undefined : json['exception_message'],
    channels: (json['channels'] as Array<any>).map(ChannelsReadResponseChannelsInnerFromJSON),
    meta: ChannelsReadResponseMetaFromJSON(json['meta'])
  };
}

export function ChannelsReadResponseToJSON(value?: ChannelsReadResponse | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    success: value.success,
    exception_code: value.exception_code,
    exception_message: value.exception_message,
    channels: (value.channels as Array<any>).map(ChannelsReadResponseChannelsInnerToJSON),
    meta: ChannelsReadResponseMetaToJSON(value.meta)
  };
}
