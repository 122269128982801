/* tslint:disable */
/* eslint-disable */
/**
 * Ticketing Portal
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface TimeSlotDeleteResponseTimeSlotsInner
 */
export interface TimeSlotDeleteResponseTimeSlotsInner {
  /**
   *
   * @type {string}
   * @memberof TimeSlotDeleteResponseTimeSlotsInner
   */
  date_time: string;
  /**
   *
   * @type {number}
   * @memberof TimeSlotDeleteResponseTimeSlotsInner
   */
  capacity?: number | null;
  /**
   *
   * @type {string}
   * @memberof TimeSlotDeleteResponseTimeSlotsInner
   */
  status: TimeSlotDeleteResponseTimeSlotsInnerStatusEnum;
  /**
   *
   * @type {string}
   * @memberof TimeSlotDeleteResponseTimeSlotsInner
   */
  availability_block_id?: string | null;
  /**
   *
   * @type {string}
   * @memberof TimeSlotDeleteResponseTimeSlotsInner
   */
  pricing_id: string;
  /**
   *
   * @type {string}
   * @memberof TimeSlotDeleteResponseTimeSlotsInner
   */
  created_at: string;
  /**
   *
   * @type {string}
   * @memberof TimeSlotDeleteResponseTimeSlotsInner
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof TimeSlotDeleteResponseTimeSlotsInner
   */
  start_date: string;
  /**
   *
   * @type {string}
   * @memberof TimeSlotDeleteResponseTimeSlotsInner
   */
  start_time: string;
  /**
   *
   * @type {string}
   * @memberof TimeSlotDeleteResponseTimeSlotsInner
   */
  end_date?: string | null;
  /**
   *
   * @type {string}
   * @memberof TimeSlotDeleteResponseTimeSlotsInner
   */
  end_time?: string | null;
  /**
   *
   * @type {Array<string>}
   * @memberof TimeSlotDeleteResponseTimeSlotsInner
   */
  bookings?: Array<string>;
}

/**
 * @export
 */
export const TimeSlotDeleteResponseTimeSlotsInnerStatusEnum = {
  Active: 'active',
  Deleted: 'deleted',
  NotBookable: 'not_bookable'
} as const;
export type TimeSlotDeleteResponseTimeSlotsInnerStatusEnum =
  (typeof TimeSlotDeleteResponseTimeSlotsInnerStatusEnum)[keyof typeof TimeSlotDeleteResponseTimeSlotsInnerStatusEnum];

/**
 * Check if a given object implements the TimeSlotDeleteResponseTimeSlotsInner interface.
 */
export function instanceOfTimeSlotDeleteResponseTimeSlotsInner(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && 'date_time' in value;
  isInstance = isInstance && 'status' in value;
  isInstance = isInstance && 'pricing_id' in value;
  isInstance = isInstance && 'created_at' in value;
  isInstance = isInstance && 'id' in value;
  isInstance = isInstance && 'start_date' in value;
  isInstance = isInstance && 'start_time' in value;

  return isInstance;
}

export function TimeSlotDeleteResponseTimeSlotsInnerFromJSON(
  json: any
): TimeSlotDeleteResponseTimeSlotsInner {
  return TimeSlotDeleteResponseTimeSlotsInnerFromJSONTyped(json, false);
}

export function TimeSlotDeleteResponseTimeSlotsInnerFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): TimeSlotDeleteResponseTimeSlotsInner {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    date_time: json['date_time'],
    capacity: !exists(json, 'capacity') ? undefined : json['capacity'],
    status: json['status'],
    availability_block_id: !exists(json, 'availability_block_id')
      ? undefined
      : json['availability_block_id'],
    pricing_id: json['pricing_id'],
    created_at: json['created_at'],
    id: json['id'],
    start_date: json['start_date'],
    start_time: json['start_time'],
    end_date: !exists(json, 'end_date') ? undefined : json['end_date'],
    end_time: !exists(json, 'end_time') ? undefined : json['end_time'],
    bookings: !exists(json, 'bookings') ? undefined : json['bookings']
  };
}

export function TimeSlotDeleteResponseTimeSlotsInnerToJSON(
  value?: TimeSlotDeleteResponseTimeSlotsInner | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    date_time: value.date_time,
    capacity: value.capacity,
    status: value.status,
    availability_block_id: value.availability_block_id,
    pricing_id: value.pricing_id,
    created_at: value.created_at,
    id: value.id,
    start_date: value.start_date,
    start_time: value.start_time,
    end_date: value.end_date,
    end_time: value.end_time,
    bookings: value.bookings
  };
}
