<template>
  <ul class="layout-menu p-1">
    <AppNavItem
      v-for="(routeConfig, i) in mainMenuRoutesBasedOnPermissions"
      :key="i"
      :route-config="routeConfig"
      :index="i"
    ></AppNavItem>
  </ul>
</template>

<script setup lang="ts">
import AppNavItem from './AppNavItem.vue';
import useRouteHandling from '@/composables/use-route-handling';

const { mainMenuRoutesBasedOnPermissions } = useRouteHandling();
</script>

<style lang="scss" scoped>
.layout-menu {
  margin-top: 4rem;
  overflow-y: auto;
}
</style>
