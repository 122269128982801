/* tslint:disable */
/* eslint-disable */
/**
 * Ticketing Portal
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface TimeSlotBlockUpdateRequestInner
 */
export interface TimeSlotBlockUpdateRequestInner {
  /**
   *
   * @type {string}
   * @memberof TimeSlotBlockUpdateRequestInner
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof TimeSlotBlockUpdateRequestInner
   */
  status: TimeSlotBlockUpdateRequestInnerStatusEnum;
}

/**
 * @export
 */
export const TimeSlotBlockUpdateRequestInnerStatusEnum = {
  Active: 'active',
  Deleted: 'deleted',
  NotBookable: 'not_bookable'
} as const;
export type TimeSlotBlockUpdateRequestInnerStatusEnum =
  (typeof TimeSlotBlockUpdateRequestInnerStatusEnum)[keyof typeof TimeSlotBlockUpdateRequestInnerStatusEnum];

/**
 * Check if a given object implements the TimeSlotBlockUpdateRequestInner interface.
 */
export function instanceOfTimeSlotBlockUpdateRequestInner(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && 'id' in value;
  isInstance = isInstance && 'status' in value;

  return isInstance;
}

export function TimeSlotBlockUpdateRequestInnerFromJSON(
  json: any
): TimeSlotBlockUpdateRequestInner {
  return TimeSlotBlockUpdateRequestInnerFromJSONTyped(json, false);
}

export function TimeSlotBlockUpdateRequestInnerFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): TimeSlotBlockUpdateRequestInner {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: json['id'],
    status: json['status']
  };
}

export function TimeSlotBlockUpdateRequestInnerToJSON(
  value?: TimeSlotBlockUpdateRequestInner | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    id: value.id,
    status: value.status
  };
}
