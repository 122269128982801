/* tslint:disable */
/* eslint-disable */
/**
 * Ticketing Portal
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface ProductMediaReadResponseProductMediaInner
 */
export interface ProductMediaReadResponseProductMediaInner {
  /**
   *
   * @type {string}
   * @memberof ProductMediaReadResponseProductMediaInner
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof ProductMediaReadResponseProductMediaInner
   */
  directus_file_id: string;
}

/**
 * Check if a given object implements the ProductMediaReadResponseProductMediaInner interface.
 */
export function instanceOfProductMediaReadResponseProductMediaInner(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && 'id' in value;
  isInstance = isInstance && 'directus_file_id' in value;

  return isInstance;
}

export function ProductMediaReadResponseProductMediaInnerFromJSON(
  json: any
): ProductMediaReadResponseProductMediaInner {
  return ProductMediaReadResponseProductMediaInnerFromJSONTyped(json, false);
}

export function ProductMediaReadResponseProductMediaInnerFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): ProductMediaReadResponseProductMediaInner {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: json['id'],
    directus_file_id: json['directus_file_id']
  };
}

export function ProductMediaReadResponseProductMediaInnerToJSON(
  value?: ProductMediaReadResponseProductMediaInner | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    id: value.id,
    directus_file_id: value.directus_file_id
  };
}
