/* tslint:disable */
/* eslint-disable */
/**
 * Ticketing Portal
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { NewsletterSettingsReadResponseNewsletterSettings } from './NewsletterSettingsReadResponseNewsletterSettings';
import {
  NewsletterSettingsReadResponseNewsletterSettingsFromJSON,
  NewsletterSettingsReadResponseNewsletterSettingsFromJSONTyped,
  NewsletterSettingsReadResponseNewsletterSettingsToJSON
} from './NewsletterSettingsReadResponseNewsletterSettings';

/**
 *
 * @export
 * @interface NewsletterSettingsReadResponse
 */
export interface NewsletterSettingsReadResponse {
  /**
   *
   * @type {boolean}
   * @memberof NewsletterSettingsReadResponse
   */
  success: boolean;
  /**
   *
   * @type {string}
   * @memberof NewsletterSettingsReadResponse
   */
  exception_code?: string;
  /**
   *
   * @type {string}
   * @memberof NewsletterSettingsReadResponse
   */
  exception_message?: string;
  /**
   *
   * @type {NewsletterSettingsReadResponseNewsletterSettings}
   * @memberof NewsletterSettingsReadResponse
   */
  newsletter_settings: NewsletterSettingsReadResponseNewsletterSettings;
}

/**
 * Check if a given object implements the NewsletterSettingsReadResponse interface.
 */
export function instanceOfNewsletterSettingsReadResponse(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && 'success' in value;
  isInstance = isInstance && 'newsletter_settings' in value;

  return isInstance;
}

export function NewsletterSettingsReadResponseFromJSON(json: any): NewsletterSettingsReadResponse {
  return NewsletterSettingsReadResponseFromJSONTyped(json, false);
}

export function NewsletterSettingsReadResponseFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): NewsletterSettingsReadResponse {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    success: json['success'],
    exception_code: !exists(json, 'exception_code') ? undefined : json['exception_code'],
    exception_message: !exists(json, 'exception_message') ? undefined : json['exception_message'],
    newsletter_settings: NewsletterSettingsReadResponseNewsletterSettingsFromJSON(
      json['newsletter_settings']
    )
  };
}

export function NewsletterSettingsReadResponseToJSON(
  value?: NewsletterSettingsReadResponse | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    success: value.success,
    exception_code: value.exception_code,
    exception_message: value.exception_message,
    newsletter_settings: NewsletterSettingsReadResponseNewsletterSettingsToJSON(
      value.newsletter_settings
    )
  };
}
