import { ref } from 'vue';
import type { Ref } from 'vue';
import { manageApi } from '@/service/TicketingApi';
import { ManageApi, type ManageTranslatePostRequest } from '@openapi/apis/ManageApi';
import { useLocaleStore } from '@/stores/locales';
import { Configuration } from '@openapi/runtime';
import { DIRECTUS_API_URL } from '@/config';
import type { FieldEntry } from 'vee-validate';
import { filterObject } from '@/utils/object';

export interface TranslateTabsFormSchema {
  fields: {
    as: string;
    name: string;
    label: string;
    [key: string]: unknown;
    rules?: string;
  }[];
}
export interface TranslateFieldArray {
  locale_code: string;
  // Keys can be strings, numbers, or symbols.
  // If you know it to be strings only, you can also restrict it to that.
  // For the value you can use any or unknown,
  // with unknown being the more defensive approach.
  [x: string | number | symbol]: unknown;
}

export interface AutoTranslate {
  fields: FieldEntry<TranslateFieldArray>[];
  selectedLocaleCode: string;
  schema: TranslateTabsFormSchema;
  update: (idx: number, value: TranslateFieldArray) => void;
  formGroupName: string;
}

export default function useTranslate() {
  const loading: Ref<boolean> = ref(false);

  const translate = async (requestParameters: ManageTranslatePostRequest) => {
    loading.value = true;
    const response = await manageApi.manageTranslatePost(requestParameters);
    if (!response.success) {
      throw new Error('Error while auto-translating strings');
    }
    loading.value = false;
    return response.translations;
  };

  const fetchTranslationsByLocale = async () => {
    const config = new Configuration({
      basePath: DIRECTUS_API_URL,
      headers: {
        'Content-Type': 'application/json'
      }
    });

    const manageUnauthenticatedApi: ManageApi = new ManageApi(config);

    const { getSelectedLocale } = useLocaleStore();
    loading.value = true;
    const requestParameters = {
      localeCode: getSelectedLocale
    };
    const response = await manageUnauthenticatedApi.manageTranslationsGet(requestParameters);
    if (!response.success) {
      throw new Error('Error while fetching the receipt settings');
    }
    loading.value = false;
    return response.translations;
  };

  async function autoTranslate(
    fields: AutoTranslate['fields'],
    selectedLocaleCode: AutoTranslate['selectedLocaleCode'],
    schema: AutoTranslate['schema'],
    update: AutoTranslate['update']
  ) {
    // Find all fields that need to be translated except for locale_code
    const fieldsForSelectedLocale = fields.find(
      (field) => field.value.locale_code === selectedLocaleCode
    );

    if (!fieldsForSelectedLocale) throw new Error('No fields to translate found');
    const namesOfFieldsToBeTranslated = Object.values(schema.fields).map((a) => a.name);
    const fieldsToTranslateWithoutLocaleCodeAndId = filterObject(
      fieldsForSelectedLocale.value,
      ([key]) => {
        if (typeof key !== 'string') {
          throw new Error('Name field is undefined or not a string');
        }
        return namesOfFieldsToBeTranslated.includes(key);
      }
    );

    // if no fields to translate, return
    if (Object.keys(fieldsToTranslateWithoutLocaleCodeAndId).length === 0) {
      return;
    }

    const requestParams = {
      translateCreateRequest: {
        locale_code: selectedLocaleCode,
        translate_from_locale: selectedLocaleCode,
        strings_to_translate: fieldsToTranslateWithoutLocaleCodeAndId
      }
    };

    const translations = await translate(requestParams);
    if (!translations) throw new Error('No translations found');

    // Update all fields with the translated values except for the original locale
    for (const key in fields) {
      const { locale_code } = fields[key].value;
      if (locale_code === selectedLocaleCode) continue;
      update(parseInt(key), {
        ...fields[key].value,
        ...translations[locale_code],
        locale_code
      });
    }
  }

  return { loading, translate, fetchTranslationsByLocale, autoTranslate };
}
