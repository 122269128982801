import { ROUTE_NAMES } from '@/constants/routes';
import { PAGE_TITLES } from '@/constants/breadcrumbs';
import { InitializationReadResponsePermissionsEnum } from '@openapi/models';

export const SCANNER_ROUTES = [
  {
    path: 'sell/bookings/scanner/:ticketCode?',
    name: ROUTE_NAMES.BOOKING_SCANNER,
    component: () => import('@/views/pages/sell/ticket-scanner/TicketScannerPage.vue'),
    meta: {
      breadcrumbs: [PAGE_TITLES.SELL, PAGE_TITLES.BOOKINGS, PAGE_TITLES.TICKET_SCANNER],
      menu: ['main'],
      permissions: [InitializationReadResponsePermissionsEnum.ScannerView],
      icon: 'pi pi-fw pi-qrcode',
      label: PAGE_TITLES.TICKET_SCANNER
    }
  }
];
