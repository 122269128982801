/* tslint:disable */
/* eslint-disable */
/**
 * Ticketing Portal
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface GiftCodeReadResponseMeta
 */
export interface GiftCodeReadResponseMeta {
  /**
   * total number of gift codes
   * @type {number}
   * @memberof GiftCodeReadResponseMeta
   */
  total_count: number;
}

/**
 * Check if a given object implements the GiftCodeReadResponseMeta interface.
 */
export function instanceOfGiftCodeReadResponseMeta(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && 'total_count' in value;

  return isInstance;
}

export function GiftCodeReadResponseMetaFromJSON(json: any): GiftCodeReadResponseMeta {
  return GiftCodeReadResponseMetaFromJSONTyped(json, false);
}

export function GiftCodeReadResponseMetaFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): GiftCodeReadResponseMeta {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    total_count: json['total_count']
  };
}

export function GiftCodeReadResponseMetaToJSON(value?: GiftCodeReadResponseMeta | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    total_count: value.total_count
  };
}
