import * as Sentry from '@sentry/vue';
import type { App } from 'vue';
import { isProduction, PORTAL_RELEASE_VERSION } from '@/config';

export const useSentry = (app: App) => {
  if (!isProduction) {
    return;
  }

  Sentry.init({
    app,
    dsn: 'https://39ac1565cd9b4f3893d0438958ca3835@o551949.ingest.sentry.io/4505192797634560',
    environment: import.meta.env.MODE, // This translates to 'development', 'testing' or 'production'
    release: PORTAL_RELEASE_VERSION,
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: [
      /^https:\/\/cdn\.getyourguide\.com/,
      /^https:\/\/ticketing-directus\.gygdev\.gygtest\.com/,
      /^https:\/\/ticketing-backend\.getyourguide\.com/
    ],
    integrations: [
      new Sentry.BrowserTracing({
        // Disable creating a transaction on location change (navigation to another page)
        startTransactionOnLocationChange: false,
        // Start sentry transaction on initial page load
        startTransactionOnPageLoad: true
      }),
      new Sentry.Replay({
        // Masking configuration
        maskAllText: false,
        maskAllInputs: false,

        // Capture requests and responses bodies
        networkCaptureBodies: true,
        networkDetailAllowUrls: [
          /^https:\/\/cdn\.getyourguide\.com/,
          /^https:\/\/ticketing-directus\.gygdev\.gygtest\.com/,
          /^https:\/\/ticketing-backend\.getyourguide\.com/
        ],

        blockAllMedia: false
      })
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, // Capture 100% of the transactions
    // Session Replay
    replaysSessionSampleRate: 0, // This sets the sample rate at 0%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    logErrors: true
  });
};
