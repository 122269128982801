/* tslint:disable */
/* eslint-disable */
/**
 * Ticketing Portal
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { PricingAvailabilityReadResponsePricingsInnerAvailabilityBlocksInner } from './PricingAvailabilityReadResponsePricingsInnerAvailabilityBlocksInner';
import {
  PricingAvailabilityReadResponsePricingsInnerAvailabilityBlocksInnerFromJSON,
  PricingAvailabilityReadResponsePricingsInnerAvailabilityBlocksInnerFromJSONTyped,
  PricingAvailabilityReadResponsePricingsInnerAvailabilityBlocksInnerToJSON
} from './PricingAvailabilityReadResponsePricingsInnerAvailabilityBlocksInner';
import type { PricingAvailabilityReadResponsePricingsInnerPricingCategoryPricesInner } from './PricingAvailabilityReadResponsePricingsInnerPricingCategoryPricesInner';
import {
  PricingAvailabilityReadResponsePricingsInnerPricingCategoryPricesInnerFromJSON,
  PricingAvailabilityReadResponsePricingsInnerPricingCategoryPricesInnerFromJSONTyped,
  PricingAvailabilityReadResponsePricingsInnerPricingCategoryPricesInnerToJSON
} from './PricingAvailabilityReadResponsePricingsInnerPricingCategoryPricesInner';

/**
 *
 * @export
 * @interface PricingAvailabilityReadResponsePricingsInner
 */
export interface PricingAvailabilityReadResponsePricingsInner {
  /**
   *
   * @type {string}
   * @memberof PricingAvailabilityReadResponsePricingsInner
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof PricingAvailabilityReadResponsePricingsInner
   */
  pricing_id: string;
  /**
   *
   * @type {boolean}
   * @memberof PricingAvailabilityReadResponsePricingsInner
   */
  is_currently_active: boolean;
  /**
   *
   * @type {Array<PricingAvailabilityReadResponsePricingsInnerPricingCategoryPricesInner>}
   * @memberof PricingAvailabilityReadResponsePricingsInner
   */
  pricing_category_prices: Array<PricingAvailabilityReadResponsePricingsInnerPricingCategoryPricesInner>;
  /**
   *
   * @type {Array<PricingAvailabilityReadResponsePricingsInnerPricingCategoryPricesInner>}
   * @memberof PricingAvailabilityReadResponsePricingsInner
   */
  addon_prices: Array<PricingAvailabilityReadResponsePricingsInnerPricingCategoryPricesInner>;
  /**
   *
   * @type {Array<PricingAvailabilityReadResponsePricingsInnerAvailabilityBlocksInner>}
   * @memberof PricingAvailabilityReadResponsePricingsInner
   */
  availability_blocks: Array<PricingAvailabilityReadResponsePricingsInnerAvailabilityBlocksInner>;
}

/**
 * Check if a given object implements the PricingAvailabilityReadResponsePricingsInner interface.
 */
export function instanceOfPricingAvailabilityReadResponsePricingsInner(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && 'name' in value;
  isInstance = isInstance && 'pricing_id' in value;
  isInstance = isInstance && 'is_currently_active' in value;
  isInstance = isInstance && 'pricing_category_prices' in value;
  isInstance = isInstance && 'addon_prices' in value;
  isInstance = isInstance && 'availability_blocks' in value;

  return isInstance;
}

export function PricingAvailabilityReadResponsePricingsInnerFromJSON(
  json: any
): PricingAvailabilityReadResponsePricingsInner {
  return PricingAvailabilityReadResponsePricingsInnerFromJSONTyped(json, false);
}

export function PricingAvailabilityReadResponsePricingsInnerFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): PricingAvailabilityReadResponsePricingsInner {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    name: json['name'],
    pricing_id: json['pricing_id'],
    is_currently_active: json['is_currently_active'],
    pricing_category_prices: (json['pricing_category_prices'] as Array<any>).map(
      PricingAvailabilityReadResponsePricingsInnerPricingCategoryPricesInnerFromJSON
    ),
    addon_prices: (json['addon_prices'] as Array<any>).map(
      PricingAvailabilityReadResponsePricingsInnerPricingCategoryPricesInnerFromJSON
    ),
    availability_blocks: (json['availability_blocks'] as Array<any>).map(
      PricingAvailabilityReadResponsePricingsInnerAvailabilityBlocksInnerFromJSON
    )
  };
}

export function PricingAvailabilityReadResponsePricingsInnerToJSON(
  value?: PricingAvailabilityReadResponsePricingsInner | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    name: value.name,
    pricing_id: value.pricing_id,
    is_currently_active: value.is_currently_active,
    pricing_category_prices: (value.pricing_category_prices as Array<any>).map(
      PricingAvailabilityReadResponsePricingsInnerPricingCategoryPricesInnerToJSON
    ),
    addon_prices: (value.addon_prices as Array<any>).map(
      PricingAvailabilityReadResponsePricingsInnerPricingCategoryPricesInnerToJSON
    ),
    availability_blocks: (value.availability_blocks as Array<any>).map(
      PricingAvailabilityReadResponsePricingsInnerAvailabilityBlocksInnerToJSON
    )
  };
}
