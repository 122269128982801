/* tslint:disable */
/* eslint-disable */
/**
 * Ticketing Portal
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface BookingByIdReadResponseBookingPaymentMethodsInner
 */
export interface BookingByIdReadResponseBookingPaymentMethodsInner {
  /**
   *
   * @type {string}
   * @memberof BookingByIdReadResponseBookingPaymentMethodsInner
   */
  payment_method: BookingByIdReadResponseBookingPaymentMethodsInnerPaymentMethodEnum;
  /**
   *
   * @type {string}
   * @memberof BookingByIdReadResponseBookingPaymentMethodsInner
   */
  amount: string;
}

/**
 * @export
 */
export const BookingByIdReadResponseBookingPaymentMethodsInnerPaymentMethodEnum = {
  AdyenWeb: 'adyen_web',
  AdyenPos: 'adyen_pos',
  Cash: 'cash',
  GiftCode: 'gift_code',
  Other: 'other',
  ExternalDevice: 'external_device'
} as const;
export type BookingByIdReadResponseBookingPaymentMethodsInnerPaymentMethodEnum =
  (typeof BookingByIdReadResponseBookingPaymentMethodsInnerPaymentMethodEnum)[keyof typeof BookingByIdReadResponseBookingPaymentMethodsInnerPaymentMethodEnum];

/**
 * Check if a given object implements the BookingByIdReadResponseBookingPaymentMethodsInner interface.
 */
export function instanceOfBookingByIdReadResponseBookingPaymentMethodsInner(
  value: object
): boolean {
  let isInstance = true;
  isInstance = isInstance && 'payment_method' in value;
  isInstance = isInstance && 'amount' in value;

  return isInstance;
}

export function BookingByIdReadResponseBookingPaymentMethodsInnerFromJSON(
  json: any
): BookingByIdReadResponseBookingPaymentMethodsInner {
  return BookingByIdReadResponseBookingPaymentMethodsInnerFromJSONTyped(json, false);
}

export function BookingByIdReadResponseBookingPaymentMethodsInnerFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): BookingByIdReadResponseBookingPaymentMethodsInner {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    payment_method: json['payment_method'],
    amount: json['amount']
  };
}

export function BookingByIdReadResponseBookingPaymentMethodsInnerToJSON(
  value?: BookingByIdReadResponseBookingPaymentMethodsInner | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    payment_method: value.payment_method,
    amount: value.amount
  };
}
