/* tslint:disable */
/* eslint-disable */
/**
 * Ticketing Portal
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ProductOptionAddressReadResponseAddressTranslationsInner } from './ProductOptionAddressReadResponseAddressTranslationsInner';
import {
  ProductOptionAddressReadResponseAddressTranslationsInnerFromJSON,
  ProductOptionAddressReadResponseAddressTranslationsInnerFromJSONTyped,
  ProductOptionAddressReadResponseAddressTranslationsInnerToJSON
} from './ProductOptionAddressReadResponseAddressTranslationsInner';
import type { ProductOptionAddressReadResponseProductOptionAddress } from './ProductOptionAddressReadResponseProductOptionAddress';
import {
  ProductOptionAddressReadResponseProductOptionAddressFromJSON,
  ProductOptionAddressReadResponseProductOptionAddressFromJSONTyped,
  ProductOptionAddressReadResponseProductOptionAddressToJSON
} from './ProductOptionAddressReadResponseProductOptionAddress';

/**
 *
 * @export
 * @interface ProductOptionAddressUpdateRequest
 */
export interface ProductOptionAddressUpdateRequest {
  /**
   *
   * @type {string}
   * @memberof ProductOptionAddressUpdateRequest
   */
  locale_code: string;
  /**
   *
   * @type {ProductOptionAddressReadResponseProductOptionAddress}
   * @memberof ProductOptionAddressUpdateRequest
   */
  product_option_address: ProductOptionAddressReadResponseProductOptionAddress;
  /**
   *
   * @type {Array<ProductOptionAddressReadResponseAddressTranslationsInner>}
   * @memberof ProductOptionAddressUpdateRequest
   */
  address_translations: Array<ProductOptionAddressReadResponseAddressTranslationsInner>;
}

/**
 * Check if a given object implements the ProductOptionAddressUpdateRequest interface.
 */
export function instanceOfProductOptionAddressUpdateRequest(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && 'locale_code' in value;
  isInstance = isInstance && 'product_option_address' in value;
  isInstance = isInstance && 'address_translations' in value;

  return isInstance;
}

export function ProductOptionAddressUpdateRequestFromJSON(
  json: any
): ProductOptionAddressUpdateRequest {
  return ProductOptionAddressUpdateRequestFromJSONTyped(json, false);
}

export function ProductOptionAddressUpdateRequestFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): ProductOptionAddressUpdateRequest {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    locale_code: json['locale_code'],
    product_option_address: ProductOptionAddressReadResponseProductOptionAddressFromJSON(
      json['product_option_address']
    ),
    address_translations: (json['address_translations'] as Array<any>).map(
      ProductOptionAddressReadResponseAddressTranslationsInnerFromJSON
    )
  };
}

export function ProductOptionAddressUpdateRequestToJSON(
  value?: ProductOptionAddressUpdateRequest | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    locale_code: value.locale_code,
    product_option_address: ProductOptionAddressReadResponseProductOptionAddressToJSON(
      value.product_option_address
    ),
    address_translations: (value.address_translations as Array<any>).map(
      ProductOptionAddressReadResponseAddressTranslationsInnerToJSON
    )
  };
}
