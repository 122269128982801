/* tslint:disable */
/* eslint-disable */
/**
 * Ticketing Portal
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface GiftCodeByIdReadResponseGiftCode
 */
export interface GiftCodeByIdReadResponseGiftCode {
  /**
   *
   * @type {string}
   * @memberof GiftCodeByIdReadResponseGiftCode
   */
  code: string;
  /**
   *
   * @type {string}
   * @memberof GiftCodeByIdReadResponseGiftCode
   */
  original_amount_formatted: string;
  /**
   *
   * @type {number}
   * @memberof GiftCodeByIdReadResponseGiftCode
   */
  amount_left: number;
  /**
   *
   * @type {number}
   * @memberof GiftCodeByIdReadResponseGiftCode
   */
  num_fraction_digits_for_display: number;
  /**
   *
   * @type {string}
   * @memberof GiftCodeByIdReadResponseGiftCode
   */
  currency: string;
  /**
   *
   * @type {string}
   * @memberof GiftCodeByIdReadResponseGiftCode
   */
  created_at: string;
  /**
   *
   * @type {string}
   * @memberof GiftCodeByIdReadResponseGiftCode
   */
  valid_until?: string | null;
}

/**
 * Check if a given object implements the GiftCodeByIdReadResponseGiftCode interface.
 */
export function instanceOfGiftCodeByIdReadResponseGiftCode(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && 'code' in value;
  isInstance = isInstance && 'original_amount_formatted' in value;
  isInstance = isInstance && 'amount_left' in value;
  isInstance = isInstance && 'num_fraction_digits_for_display' in value;
  isInstance = isInstance && 'currency' in value;
  isInstance = isInstance && 'created_at' in value;

  return isInstance;
}

export function GiftCodeByIdReadResponseGiftCodeFromJSON(
  json: any
): GiftCodeByIdReadResponseGiftCode {
  return GiftCodeByIdReadResponseGiftCodeFromJSONTyped(json, false);
}

export function GiftCodeByIdReadResponseGiftCodeFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): GiftCodeByIdReadResponseGiftCode {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    code: json['code'],
    original_amount_formatted: json['original_amount_formatted'],
    amount_left: json['amount_left'],
    num_fraction_digits_for_display: json['num_fraction_digits_for_display'],
    currency: json['currency'],
    created_at: json['created_at'],
    valid_until: !exists(json, 'valid_until') ? undefined : json['valid_until']
  };
}

export function GiftCodeByIdReadResponseGiftCodeToJSON(
  value?: GiftCodeByIdReadResponseGiftCode | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    code: value.code,
    original_amount_formatted: value.original_amount_formatted,
    amount_left: value.amount_left,
    num_fraction_digits_for_display: value.num_fraction_digits_for_display,
    currency: value.currency,
    created_at: value.created_at,
    valid_until: value.valid_until
  };
}
