/* tslint:disable */
/* eslint-disable */
/**
 * Ticketing Portal
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { PricingAvailabilityCreateRequestPricingCategoryPricesInner } from './PricingAvailabilityCreateRequestPricingCategoryPricesInner';
import {
  PricingAvailabilityCreateRequestPricingCategoryPricesInnerFromJSON,
  PricingAvailabilityCreateRequestPricingCategoryPricesInnerFromJSONTyped,
  PricingAvailabilityCreateRequestPricingCategoryPricesInnerToJSON
} from './PricingAvailabilityCreateRequestPricingCategoryPricesInner';
import type { PricingUpdateRequestAddonPricesInner } from './PricingUpdateRequestAddonPricesInner';
import {
  PricingUpdateRequestAddonPricesInnerFromJSON,
  PricingUpdateRequestAddonPricesInnerFromJSONTyped,
  PricingUpdateRequestAddonPricesInnerToJSON
} from './PricingUpdateRequestAddonPricesInner';

/**
 *
 * @export
 * @interface PricingUpdateRequest
 */
export interface PricingUpdateRequest {
  /**
   *
   * @type {string}
   * @memberof PricingUpdateRequest
   */
  locale_code: string;
  /**
   *
   * @type {string}
   * @memberof PricingUpdateRequest
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof PricingUpdateRequest
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof PricingUpdateRequest
   */
  product_option_id: string;
  /**
   *
   * @type {Array<PricingAvailabilityCreateRequestPricingCategoryPricesInner>}
   * @memberof PricingUpdateRequest
   */
  pricing_category_prices: Array<PricingAvailabilityCreateRequestPricingCategoryPricesInner>;
  /**
   *
   * @type {Array<PricingUpdateRequestAddonPricesInner>}
   * @memberof PricingUpdateRequest
   */
  addon_prices: Array<PricingUpdateRequestAddonPricesInner>;
}

/**
 * Check if a given object implements the PricingUpdateRequest interface.
 */
export function instanceOfPricingUpdateRequest(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && 'locale_code' in value;
  isInstance = isInstance && 'id' in value;
  isInstance = isInstance && 'name' in value;
  isInstance = isInstance && 'product_option_id' in value;
  isInstance = isInstance && 'pricing_category_prices' in value;
  isInstance = isInstance && 'addon_prices' in value;

  return isInstance;
}

export function PricingUpdateRequestFromJSON(json: any): PricingUpdateRequest {
  return PricingUpdateRequestFromJSONTyped(json, false);
}

export function PricingUpdateRequestFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): PricingUpdateRequest {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    locale_code: json['locale_code'],
    id: json['id'],
    name: json['name'],
    product_option_id: json['product_option_id'],
    pricing_category_prices: (json['pricing_category_prices'] as Array<any>).map(
      PricingAvailabilityCreateRequestPricingCategoryPricesInnerFromJSON
    ),
    addon_prices: (json['addon_prices'] as Array<any>).map(
      PricingUpdateRequestAddonPricesInnerFromJSON
    )
  };
}

export function PricingUpdateRequestToJSON(value?: PricingUpdateRequest | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    locale_code: value.locale_code,
    id: value.id,
    name: value.name,
    product_option_id: value.product_option_id,
    pricing_category_prices: (value.pricing_category_prices as Array<any>).map(
      PricingAvailabilityCreateRequestPricingCategoryPricesInnerToJSON
    ),
    addon_prices: (value.addon_prices as Array<any>).map(PricingUpdateRequestAddonPricesInnerToJSON)
  };
}
