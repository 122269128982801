/* tslint:disable */
/* eslint-disable */
/**
 * Ticketing Portal
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { BookingsCountReadResponseBookingsInnerBookings } from './BookingsCountReadResponseBookingsInnerBookings';
import {
  BookingsCountReadResponseBookingsInnerBookingsFromJSON,
  BookingsCountReadResponseBookingsInnerBookingsFromJSONTyped,
  BookingsCountReadResponseBookingsInnerBookingsToJSON
} from './BookingsCountReadResponseBookingsInnerBookings';
import type { BookingsCountReadResponseBookingsInnerPeriod } from './BookingsCountReadResponseBookingsInnerPeriod';
import {
  BookingsCountReadResponseBookingsInnerPeriodFromJSON,
  BookingsCountReadResponseBookingsInnerPeriodFromJSONTyped,
  BookingsCountReadResponseBookingsInnerPeriodToJSON
} from './BookingsCountReadResponseBookingsInnerPeriod';

/**
 *
 * @export
 * @interface BookingsCountReadResponseBookingsInner
 */
export interface BookingsCountReadResponseBookingsInner {
  /**
   *
   * @type {BookingsCountReadResponseBookingsInnerPeriod}
   * @memberof BookingsCountReadResponseBookingsInner
   */
  period: BookingsCountReadResponseBookingsInnerPeriod;
  /**
   *
   * @type {BookingsCountReadResponseBookingsInnerBookings}
   * @memberof BookingsCountReadResponseBookingsInner
   */
  bookings: BookingsCountReadResponseBookingsInnerBookings;
}

/**
 * Check if a given object implements the BookingsCountReadResponseBookingsInner interface.
 */
export function instanceOfBookingsCountReadResponseBookingsInner(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && 'period' in value;
  isInstance = isInstance && 'bookings' in value;

  return isInstance;
}

export function BookingsCountReadResponseBookingsInnerFromJSON(
  json: any
): BookingsCountReadResponseBookingsInner {
  return BookingsCountReadResponseBookingsInnerFromJSONTyped(json, false);
}

export function BookingsCountReadResponseBookingsInnerFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): BookingsCountReadResponseBookingsInner {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    period: BookingsCountReadResponseBookingsInnerPeriodFromJSON(json['period']),
    bookings: BookingsCountReadResponseBookingsInnerBookingsFromJSON(json['bookings'])
  };
}

export function BookingsCountReadResponseBookingsInnerToJSON(
  value?: BookingsCountReadResponseBookingsInner | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    period: BookingsCountReadResponseBookingsInnerPeriodToJSON(value.period),
    bookings: BookingsCountReadResponseBookingsInnerBookingsToJSON(value.bookings)
  };
}
