/* tslint:disable */
/* eslint-disable */
/**
 * Ticketing Portal
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { TimeSlotDeleteRequestTimeSlotsInner } from './TimeSlotDeleteRequestTimeSlotsInner';
import {
  TimeSlotDeleteRequestTimeSlotsInnerFromJSON,
  TimeSlotDeleteRequestTimeSlotsInnerFromJSONTyped,
  TimeSlotDeleteRequestTimeSlotsInnerToJSON
} from './TimeSlotDeleteRequestTimeSlotsInner';

/**
 *
 * @export
 * @interface TimeSlotDeleteRequest
 */
export interface TimeSlotDeleteRequest {
  /**
   *
   * @type {boolean}
   * @memberof TimeSlotDeleteRequest
   */
  set_bookings_to_not_bookable?: boolean;
  /**
   *
   * @type {Array<TimeSlotDeleteRequestTimeSlotsInner>}
   * @memberof TimeSlotDeleteRequest
   */
  time_slots: Array<TimeSlotDeleteRequestTimeSlotsInner>;
}

/**
 * Check if a given object implements the TimeSlotDeleteRequest interface.
 */
export function instanceOfTimeSlotDeleteRequest(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && 'time_slots' in value;

  return isInstance;
}

export function TimeSlotDeleteRequestFromJSON(json: any): TimeSlotDeleteRequest {
  return TimeSlotDeleteRequestFromJSONTyped(json, false);
}

export function TimeSlotDeleteRequestFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): TimeSlotDeleteRequest {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    set_bookings_to_not_bookable: !exists(json, 'set_bookings_to_not_bookable')
      ? undefined
      : json['set_bookings_to_not_bookable'],
    time_slots: (json['time_slots'] as Array<any>).map(TimeSlotDeleteRequestTimeSlotsInnerFromJSON)
  };
}

export function TimeSlotDeleteRequestToJSON(value?: TimeSlotDeleteRequest | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    set_bookings_to_not_bookable: value.set_bookings_to_not_bookable,
    time_slots: (value.time_slots as Array<any>).map(TimeSlotDeleteRequestTimeSlotsInnerToJSON)
  };
}
