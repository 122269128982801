/* tslint:disable */
/* eslint-disable */
/**
 * Ticketing Portal
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface ChannelsReadResponseChannelsInner
 */
export interface ChannelsReadResponseChannelsInner {
  /**
   *
   * @type {string}
   * @memberof ChannelsReadResponseChannelsInner
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof ChannelsReadResponseChannelsInner
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof ChannelsReadResponseChannelsInner
   */
  status: ChannelsReadResponseChannelsInnerStatusEnum;
}

/**
 * @export
 */
export const ChannelsReadResponseChannelsInnerStatusEnum = {
  Active: 'active',
  Inactive: 'inactive',
  Deleted: 'deleted'
} as const;
export type ChannelsReadResponseChannelsInnerStatusEnum =
  (typeof ChannelsReadResponseChannelsInnerStatusEnum)[keyof typeof ChannelsReadResponseChannelsInnerStatusEnum];

/**
 * Check if a given object implements the ChannelsReadResponseChannelsInner interface.
 */
export function instanceOfChannelsReadResponseChannelsInner(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && 'id' in value;
  isInstance = isInstance && 'name' in value;
  isInstance = isInstance && 'status' in value;

  return isInstance;
}

export function ChannelsReadResponseChannelsInnerFromJSON(
  json: any
): ChannelsReadResponseChannelsInner {
  return ChannelsReadResponseChannelsInnerFromJSONTyped(json, false);
}

export function ChannelsReadResponseChannelsInnerFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): ChannelsReadResponseChannelsInner {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: json['id'],
    name: json['name'],
    status: json['status']
  };
}

export function ChannelsReadResponseChannelsInnerToJSON(
  value?: ChannelsReadResponseChannelsInner | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    id: value.id,
    name: value.name,
    status: value.status
  };
}
