/* tslint:disable */
/* eslint-disable */
/**
 * Ticketing Portal
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { AddonsReadResponseAddonsInnerQuestionsInner } from './AddonsReadResponseAddonsInnerQuestionsInner';
import {
  AddonsReadResponseAddonsInnerQuestionsInnerFromJSON,
  AddonsReadResponseAddonsInnerQuestionsInnerFromJSONTyped,
  AddonsReadResponseAddonsInnerQuestionsInnerToJSON
} from './AddonsReadResponseAddonsInnerQuestionsInner';

/**
 *
 * @export
 * @interface AddonsReadResponseAddonsInner
 */
export interface AddonsReadResponseAddonsInner {
  /**
   *
   * @type {string}
   * @memberof AddonsReadResponseAddonsInner
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof AddonsReadResponseAddonsInner
   */
  name: string;
  /**
   *
   * @type {number}
   * @memberof AddonsReadResponseAddonsInner
   */
  min_quantity: number;
  /**
   *
   * @type {number}
   * @memberof AddonsReadResponseAddonsInner
   */
  max_quantity: number | null;
  /**
   *
   * @type {string}
   * @memberof AddonsReadResponseAddonsInner
   */
  added_to: AddonsReadResponseAddonsInnerAddedToEnum;
  /**
   *
   * @type {string}
   * @memberof AddonsReadResponseAddonsInner
   */
  status: AddonsReadResponseAddonsInnerStatusEnum;
  /**
   *
   * @type {Array<AddonsReadResponseAddonsInnerQuestionsInner>}
   * @memberof AddonsReadResponseAddonsInner
   */
  questions: Array<AddonsReadResponseAddonsInnerQuestionsInner>;
}

/**
 * @export
 */
export const AddonsReadResponseAddonsInnerAddedToEnum = {
  Booking: 'booking',
  Participant: 'participant'
} as const;
export type AddonsReadResponseAddonsInnerAddedToEnum =
  (typeof AddonsReadResponseAddonsInnerAddedToEnum)[keyof typeof AddonsReadResponseAddonsInnerAddedToEnum];

/**
 * @export
 */
export const AddonsReadResponseAddonsInnerStatusEnum = {
  Active: 'active',
  Inactive: 'inactive',
  Deleted: 'deleted'
} as const;
export type AddonsReadResponseAddonsInnerStatusEnum =
  (typeof AddonsReadResponseAddonsInnerStatusEnum)[keyof typeof AddonsReadResponseAddonsInnerStatusEnum];

/**
 * Check if a given object implements the AddonsReadResponseAddonsInner interface.
 */
export function instanceOfAddonsReadResponseAddonsInner(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && 'id' in value;
  isInstance = isInstance && 'name' in value;
  isInstance = isInstance && 'min_quantity' in value;
  isInstance = isInstance && 'max_quantity' in value;
  isInstance = isInstance && 'added_to' in value;
  isInstance = isInstance && 'status' in value;
  isInstance = isInstance && 'questions' in value;

  return isInstance;
}

export function AddonsReadResponseAddonsInnerFromJSON(json: any): AddonsReadResponseAddonsInner {
  return AddonsReadResponseAddonsInnerFromJSONTyped(json, false);
}

export function AddonsReadResponseAddonsInnerFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): AddonsReadResponseAddonsInner {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: json['id'],
    name: json['name'],
    min_quantity: json['min_quantity'],
    max_quantity: json['max_quantity'],
    added_to: json['added_to'],
    status: json['status'],
    questions: (json['questions'] as Array<any>).map(
      AddonsReadResponseAddonsInnerQuestionsInnerFromJSON
    )
  };
}

export function AddonsReadResponseAddonsInnerToJSON(
  value?: AddonsReadResponseAddonsInner | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    id: value.id,
    name: value.name,
    min_quantity: value.min_quantity,
    max_quantity: value.max_quantity,
    added_to: value.added_to,
    status: value.status,
    questions: (value.questions as Array<any>).map(
      AddonsReadResponseAddonsInnerQuestionsInnerToJSON
    )
  };
}
