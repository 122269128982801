/* tslint:disable */
/* eslint-disable */
/**
 * Ticketing Portal
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface ProductOptionDeleteRequest
 */
export interface ProductOptionDeleteRequest {
  /**
   *
   * @type {string}
   * @memberof ProductOptionDeleteRequest
   */
  locale_code: string;
  /**
   *
   * @type {string}
   * @memberof ProductOptionDeleteRequest
   */
  product_option_id: string;
}

/**
 * Check if a given object implements the ProductOptionDeleteRequest interface.
 */
export function instanceOfProductOptionDeleteRequest(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && 'locale_code' in value;
  isInstance = isInstance && 'product_option_id' in value;

  return isInstance;
}

export function ProductOptionDeleteRequestFromJSON(json: any): ProductOptionDeleteRequest {
  return ProductOptionDeleteRequestFromJSONTyped(json, false);
}

export function ProductOptionDeleteRequestFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): ProductOptionDeleteRequest {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    locale_code: json['locale_code'],
    product_option_id: json['product_option_id']
  };
}

export function ProductOptionDeleteRequestToJSON(value?: ProductOptionDeleteRequest | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    locale_code: value.locale_code,
    product_option_id: value.product_option_id
  };
}
