import { useCurrencyStore } from '@/stores/currencies';
import { useLocaleStore } from '@/stores/locales';
import { Temporal } from '@js-temporal/polyfill';

export function formatDate(date: Date) {
  const startDate = Temporal.PlainDate.from({
    year: date.getFullYear(),
    month: date.getMonth() + 1,
    day: date.getDate()
  });
  return startDate.toString();
}

export function formatTime(date: Date) {
  const startDate = Temporal.PlainTime.from({ hour: date.getHours(), minute: date.getMinutes() });
  return startDate.toString({ smallestUnit: 'minute' });
}

export const formatCurrency = (amount: number, locale?: string, currency?: string) => {
  const { getSelectedLocale } = useLocaleStore();
  const { getCurrencyCode } = useCurrencyStore();
  return Intl.NumberFormat(locale || getSelectedLocale, {
    style: 'currency',
    currency: currency || getCurrencyCode
  }).format(amount);
};

export function formatAvailabilitiesHeader(date: string, localeCode: string) {
  const dateTimeFormatOptions: Intl.DateTimeFormatOptions = {
    dateStyle: 'full'
  };
  const startDate = Temporal.PlainDate.from(date);
  return startDate.toLocaleString(localeCode, dateTimeFormatOptions);
}

export const removeTimeZone = (dateString: Date | string) => {
  const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  if (dateString instanceof Date) {
    return Temporal.Instant.from(dateString.toISOString())
      .toZonedDateTimeISO(timeZone)
      .toPlainDate()
      .toString();
  } else {
    return dateString;
  }
};

export const stringOrDash = (value: string | null | undefined) => {
  return value || '-';
};
