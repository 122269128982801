/* tslint:disable */
/* eslint-disable */
/**
 * Ticketing Portal
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ReceiptSettingsPartnerTranslationsInner } from './ReceiptSettingsPartnerTranslationsInner';
import {
  ReceiptSettingsPartnerTranslationsInnerFromJSON,
  ReceiptSettingsPartnerTranslationsInnerFromJSONTyped,
  ReceiptSettingsPartnerTranslationsInnerToJSON
} from './ReceiptSettingsPartnerTranslationsInner';

/**
 *
 * @export
 * @interface ReceiptSettingsUpdateRequest
 */
export interface ReceiptSettingsUpdateRequest {
  /**
   *
   * @type {string}
   * @memberof ReceiptSettingsUpdateRequest
   */
  locale_code: string;
  /**
   *
   * @type {string}
   * @memberof ReceiptSettingsUpdateRequest
   */
  id?: string;
  /**
   *
   * @type {Array<ReceiptSettingsPartnerTranslationsInner>}
   * @memberof ReceiptSettingsUpdateRequest
   */
  partner_translations: Array<ReceiptSettingsPartnerTranslationsInner>;
  /**
   *
   * @type {boolean}
   * @memberof ReceiptSettingsUpdateRequest
   */
  attach_receipt?: boolean;
}

/**
 * Check if a given object implements the ReceiptSettingsUpdateRequest interface.
 */
export function instanceOfReceiptSettingsUpdateRequest(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && 'locale_code' in value;
  isInstance = isInstance && 'partner_translations' in value;

  return isInstance;
}

export function ReceiptSettingsUpdateRequestFromJSON(json: any): ReceiptSettingsUpdateRequest {
  return ReceiptSettingsUpdateRequestFromJSONTyped(json, false);
}

export function ReceiptSettingsUpdateRequestFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): ReceiptSettingsUpdateRequest {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    locale_code: json['locale_code'],
    id: !exists(json, 'id') ? undefined : json['id'],
    partner_translations: (json['partner_translations'] as Array<any>).map(
      ReceiptSettingsPartnerTranslationsInnerFromJSON
    ),
    attach_receipt: !exists(json, 'attach_receipt') ? undefined : json['attach_receipt']
  };
}

export function ReceiptSettingsUpdateRequestToJSON(
  value?: ReceiptSettingsUpdateRequest | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    locale_code: value.locale_code,
    id: value.id,
    partner_translations: (value.partner_translations as Array<any>).map(
      ReceiptSettingsPartnerTranslationsInnerToJSON
    ),
    attach_receipt: value.attach_receipt
  };
}
