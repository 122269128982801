/* tslint:disable */
/* eslint-disable */
/**
 * Ticketing Portal
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface UserSettingsUpdateRequest
 */
export interface UserSettingsUpdateRequest {
  /**
   *
   * @type {string}
   * @memberof UserSettingsUpdateRequest
   */
  locale_code: string;
  /**
   *
   * @type {string}
   * @memberof UserSettingsUpdateRequest
   */
  first_name: string;
  /**
   *
   * @type {string}
   * @memberof UserSettingsUpdateRequest
   */
  last_name: string;
  /**
   *
   * @type {string}
   * @memberof UserSettingsUpdateRequest
   */
  language: string;
}

/**
 * Check if a given object implements the UserSettingsUpdateRequest interface.
 */
export function instanceOfUserSettingsUpdateRequest(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && 'locale_code' in value;
  isInstance = isInstance && 'first_name' in value;
  isInstance = isInstance && 'last_name' in value;
  isInstance = isInstance && 'language' in value;

  return isInstance;
}

export function UserSettingsUpdateRequestFromJSON(json: any): UserSettingsUpdateRequest {
  return UserSettingsUpdateRequestFromJSONTyped(json, false);
}

export function UserSettingsUpdateRequestFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): UserSettingsUpdateRequest {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    locale_code: json['locale_code'],
    first_name: json['first_name'],
    last_name: json['last_name'],
    language: json['language']
  };
}

export function UserSettingsUpdateRequestToJSON(value?: UserSettingsUpdateRequest | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    locale_code: value.locale_code,
    first_name: value.first_name,
    last_name: value.last_name,
    language: value.language
  };
}
