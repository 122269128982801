<template>
  <Field v-slot="{ value }" :name="name" :rules="rules">
    <FileUpload
      ref="fu"
      auto
      :show-upload-button="false"
      :show-cancel-button="false"
      name="demo[]"
      custom-upload
      :accept="accept"
      :max-file-size="5000000"
      @uploader="uploader"
    >
      <template #content>
        <div class="files-validation">
          <div v-if="value" class="files-validation__container">
            <Button
              icon="pi pi-times"
              size="small"
              severity="secondary"
              rounded
              aria-label="Remove"
              class="files-validation__button"
              @click="onRemove()"
            />
            <img
              v-if="isUploadingImages"
              class="files-validation__image h-40 w-full object-cover shadow rounded-border"
              :src="getResizedImage(value)"
            />
            <p v-else>{{ filenameDownload }}</p>
          </div>
        </div>
      </template>
      <template #empty>
        <p v-if="!value">{{ $t('portal_file_upload_empty') }}</p>
      </template>
    </FileUpload>
  </Field>
</template>

<script setup lang="ts">
import { Field, useField } from 'vee-validate';
import { computed, ref, toRefs, watch } from 'vue';
import FileUpload, { type FileUploadUploaderEvent } from 'primevue/fileupload';
import { directus } from '@/service/directus';
import Button from 'primevue/button';
import { useToast } from 'primevue/usetoast';
import { getResizedImage } from '@/utils/images';
import { uploadFiles } from '@directus/sdk';
const props = defineProps({
  autofocus: {
    type: Boolean,
    required: false,
    default: false
  },
  name: {
    type: String,
    required: true
  },
  placeholder: {
    type: String,
    required: false,
    default: ''
  },
  rules: {
    type: String,
    required: false,
    default: ''
  },
  folder: {
    type: String,
    required: true,
    default: '316ec7cc-5d14-4a1e-b7c0-053b93c543dd'
  },
  accept: {
    type: String,
    required: false,
    default: 'image/*'
  }
});

const isUploadingImages = computed(() => props.accept.startsWith('image/'));
const filenameDownload = ref<string | null>(null);

type FileUploadMessages = {
  messages: string[];
};
const fu = ref<FileUploadMessages | null>(null);

const { name, folder, accept } = toRefs(props);
const { setValue } = useField(name);
const toast = useToast();

const uploader = async (event: FileUploadUploaderEvent) => {
  const { files } = event;
  if (Array.isArray(files)) {
    for (const fileIndex in files) {
      const form = new FormData();
      form.append('folder', folder.value);
      form.append('files[]', files[fileIndex]);
      const value = await directus.request(uploadFiles(form));
      if (value?.id) {
        setValue(value.id);
        setFilenameDownload(value.filename_download);
      }
    }
  }
};

const onRemove = async () => {
  setValue(null);
  setFilenameDownload(null);
};

const messages = computed(() => fu.value?.messages || []);

watch(messages, ([newMessage]) => {
  if (newMessage) {
    toast.add({
      severity: 'error',
      summary: 'Error',
      detail: newMessage,
      life: 10000
    });
  }
});

const setFilenameDownload = (filename: string | null) => {
  filenameDownload.value = filename;
};
</script>

<style scoped lang="scss">
.files-validation {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  grid-template-rows: repeat(auto-fill, 1fr);
  grid-gap: 1em;

  &__container {
    position: relative;

    &:hover {
      cursor: move;
      .files-validation__button {
        transform: scale(1);
      }
    }
  }

  &__button {
    transition: transform 0.2s ease-in-out;
    position: absolute;
    top: -1em;
    right: -1em;
    transform: scale(0);
    &:hover {
      cursor: pointer;
    }
  }

  &__image {
    object-fit: cover;
  }
}
</style>
