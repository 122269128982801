import posthog from 'posthog-js';
import type { App } from 'vue';
const POSTHOG_DISABLED_FLAG = 'disablePosthogTracking';

export default {
  install(app: App) {
    app.config.globalProperties.$posthog = posthog.init(
      'phc_OQYTRcZ564uvgPcXXjKkqv1LkHQ2cldGidHDiXWL4f4',
      {
        api_host: 'https://app.posthog.com'
      }
    );
    if (shouldDisablePosthogTracking()) {
      disablePosthogTracking();
    }
  }
};

export const shouldDisablePosthogTracking = (): boolean => {
  return window.localStorage.getItem(POSTHOG_DISABLED_FLAG) === 'true';
};

export const setDisablePosthogTrackingFlag = () => {
  // The flag is stored in localStorage so that it persists across page reloads
  window.localStorage.setItem(POSTHOG_DISABLED_FLAG, 'true');
};
export const disablePosthogTracking = () => {
  posthog.opt_out_capturing();
};
