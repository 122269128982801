/* tslint:disable */
/* eslint-disable */
/**
 * Ticketing Portal
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface AddonsReadRequestDownload
 */
export interface AddonsReadRequestDownload {
  /**
   *
   * @type {string}
   * @memberof AddonsReadRequestDownload
   */
  format?: AddonsReadRequestDownloadFormatEnum;
  /**
   *
   * @type {string}
   * @memberof AddonsReadRequestDownload
   */
  file_name?: string;
}

/**
 * @export
 */
export const AddonsReadRequestDownloadFormatEnum = {
  Txt: 'txt',
  Json: 'json',
  Csv: 'csv',
  Xls: 'xls',
  Xlsx: 'xlsx',
  Xml: 'xml',
  Css: 'css',
  Html: 'html'
} as const;
export type AddonsReadRequestDownloadFormatEnum =
  (typeof AddonsReadRequestDownloadFormatEnum)[keyof typeof AddonsReadRequestDownloadFormatEnum];

/**
 * Check if a given object implements the AddonsReadRequestDownload interface.
 */
export function instanceOfAddonsReadRequestDownload(value: object): boolean {
  let isInstance = true;

  return isInstance;
}

export function AddonsReadRequestDownloadFromJSON(json: any): AddonsReadRequestDownload {
  return AddonsReadRequestDownloadFromJSONTyped(json, false);
}

export function AddonsReadRequestDownloadFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): AddonsReadRequestDownload {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    format: !exists(json, 'format') ? undefined : json['format'],
    file_name: !exists(json, 'file_name') ? undefined : json['file_name']
  };
}

export function AddonsReadRequestDownloadToJSON(value?: AddonsReadRequestDownload | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    format: value.format,
    file_name: value.file_name
  };
}
