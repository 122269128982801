/* tslint:disable */
/* eslint-disable */
/**
 * Ticketing Portal
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface PricingAvailabilityReadResponsePricingsInnerAvailabilityBlocksInner
 */
export interface PricingAvailabilityReadResponsePricingsInnerAvailabilityBlocksInner {
  /**
   *
   * @type {string}
   * @memberof PricingAvailabilityReadResponsePricingsInnerAvailabilityBlocksInner
   */
  availability_block_id: string;
  /**
   *
   * @type {string}
   * @memberof PricingAvailabilityReadResponsePricingsInnerAvailabilityBlocksInner
   */
  start_date: string;
  /**
   *
   * @type {string}
   * @memberof PricingAvailabilityReadResponsePricingsInnerAvailabilityBlocksInner
   */
  end_date: string;
  /**
   *
   * @type {string}
   * @memberof PricingAvailabilityReadResponsePricingsInnerAvailabilityBlocksInner
   */
  created_at: string;
  /**
   *
   * @type {number}
   * @memberof PricingAvailabilityReadResponsePricingsInnerAvailabilityBlocksInner
   */
  timeslot_count: number;
  /**
   *
   * @type {number}
   * @memberof PricingAvailabilityReadResponsePricingsInnerAvailabilityBlocksInner
   */
  booking_count: number;
}

/**
 * Check if a given object implements the PricingAvailabilityReadResponsePricingsInnerAvailabilityBlocksInner interface.
 */
export function instanceOfPricingAvailabilityReadResponsePricingsInnerAvailabilityBlocksInner(
  value: object
): boolean {
  let isInstance = true;
  isInstance = isInstance && 'availability_block_id' in value;
  isInstance = isInstance && 'start_date' in value;
  isInstance = isInstance && 'end_date' in value;
  isInstance = isInstance && 'created_at' in value;
  isInstance = isInstance && 'timeslot_count' in value;
  isInstance = isInstance && 'booking_count' in value;

  return isInstance;
}

export function PricingAvailabilityReadResponsePricingsInnerAvailabilityBlocksInnerFromJSON(
  json: any
): PricingAvailabilityReadResponsePricingsInnerAvailabilityBlocksInner {
  return PricingAvailabilityReadResponsePricingsInnerAvailabilityBlocksInnerFromJSONTyped(
    json,
    false
  );
}

export function PricingAvailabilityReadResponsePricingsInnerAvailabilityBlocksInnerFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): PricingAvailabilityReadResponsePricingsInnerAvailabilityBlocksInner {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    availability_block_id: json['availability_block_id'],
    start_date: json['start_date'],
    end_date: json['end_date'],
    created_at: json['created_at'],
    timeslot_count: json['timeslot_count'],
    booking_count: json['booking_count']
  };
}

export function PricingAvailabilityReadResponsePricingsInnerAvailabilityBlocksInnerToJSON(
  value?: PricingAvailabilityReadResponsePricingsInnerAvailabilityBlocksInner | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    availability_block_id: value.availability_block_id,
    start_date: value.start_date,
    end_date: value.end_date,
    created_at: value.created_at,
    timeslot_count: value.timeslot_count,
    booking_count: value.booking_count
  };
}
