<template>
  <Button
    v-if="isLoggedIn && store.getImpersonatePartnerName"
    v-tooltip.top="'Click to change partner'"
    class="impersonate"
    outlined
    severity="secondary"
    size="small"
    :label="`Partner: ${store.getImpersonatePartnerName}`"
    @click="onClick"
  ></Button>
</template>

<script setup lang="ts">
import { ROUTE_NAMES } from '@/constants/routes';
import { authRoutes } from '@/router';
import { useUserStore } from '@/stores/user';
import Button from 'primevue/button';
import { computed } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { DEFAULT_LOCALE } from '@/constants/i18n';
import { useAuth } from '@/composables/use-auth';

const store = useUserStore();
const router = useRouter();
const route = useRoute();
const { updateUserRole } = useAuth();

const onClick = async () => {
  await updateUserRole({
    rolesUpdateRequest: {
      locale_code: DEFAULT_LOCALE
    }
  });

  await router.push({ name: ROUTE_NAMES.LOGIN_IMPERSONATE, query: { gyg_staff: 'true' } });
};
const isLoggedIn = computed(() => route.name && !authRoutes.includes(route.name.toString()));
</script>
