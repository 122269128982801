/* tslint:disable */
/* eslint-disable */
/**
 * Ticketing Portal
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface BusinessInformationReadResponsePartnerTax
 */
export interface BusinessInformationReadResponsePartnerTax {
  /**
   *
   * @type {number}
   * @memberof BusinessInformationReadResponsePartnerTax
   */
  value: number;
  /**
   *
   * @type {string}
   * @memberof BusinessInformationReadResponsePartnerTax
   */
  type: BusinessInformationReadResponsePartnerTaxTypeEnum;
}

/**
 * @export
 */
export const BusinessInformationReadResponsePartnerTaxTypeEnum = {
  Absolute: 'absolute',
  Relative: 'relative'
} as const;
export type BusinessInformationReadResponsePartnerTaxTypeEnum =
  (typeof BusinessInformationReadResponsePartnerTaxTypeEnum)[keyof typeof BusinessInformationReadResponsePartnerTaxTypeEnum];

/**
 * Check if a given object implements the BusinessInformationReadResponsePartnerTax interface.
 */
export function instanceOfBusinessInformationReadResponsePartnerTax(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && 'value' in value;
  isInstance = isInstance && 'type' in value;

  return isInstance;
}

export function BusinessInformationReadResponsePartnerTaxFromJSON(
  json: any
): BusinessInformationReadResponsePartnerTax {
  return BusinessInformationReadResponsePartnerTaxFromJSONTyped(json, false);
}

export function BusinessInformationReadResponsePartnerTaxFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): BusinessInformationReadResponsePartnerTax {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    value: json['value'],
    type: json['type']
  };
}

export function BusinessInformationReadResponsePartnerTaxToJSON(
  value?: BusinessInformationReadResponsePartnerTax | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    value: value.value,
    type: value.type
  };
}
