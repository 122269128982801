/* tslint:disable */
/* eslint-disable */
/**
 * Ticketing Portal
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { PayoutsReadResponsePayoutsInner } from './PayoutsReadResponsePayoutsInner';
import {
  PayoutsReadResponsePayoutsInnerFromJSON,
  PayoutsReadResponsePayoutsInnerFromJSONTyped,
  PayoutsReadResponsePayoutsInnerToJSON
} from './PayoutsReadResponsePayoutsInner';

/**
 *
 * @export
 * @interface PayoutsReadResponse
 */
export interface PayoutsReadResponse {
  /**
   *
   * @type {boolean}
   * @memberof PayoutsReadResponse
   */
  success: boolean;
  /**
   *
   * @type {string}
   * @memberof PayoutsReadResponse
   */
  exception_code?: string;
  /**
   *
   * @type {string}
   * @memberof PayoutsReadResponse
   */
  exception_message?: string;
  /**
   *
   * @type {Array<PayoutsReadResponsePayoutsInner>}
   * @memberof PayoutsReadResponse
   */
  payouts: Array<PayoutsReadResponsePayoutsInner>;
}

/**
 * Check if a given object implements the PayoutsReadResponse interface.
 */
export function instanceOfPayoutsReadResponse(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && 'success' in value;
  isInstance = isInstance && 'payouts' in value;

  return isInstance;
}

export function PayoutsReadResponseFromJSON(json: any): PayoutsReadResponse {
  return PayoutsReadResponseFromJSONTyped(json, false);
}

export function PayoutsReadResponseFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): PayoutsReadResponse {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    success: json['success'],
    exception_code: !exists(json, 'exception_code') ? undefined : json['exception_code'],
    exception_message: !exists(json, 'exception_message') ? undefined : json['exception_message'],
    payouts: (json['payouts'] as Array<any>).map(PayoutsReadResponsePayoutsInnerFromJSON)
  };
}

export function PayoutsReadResponseToJSON(value?: PayoutsReadResponse | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    success: value.success,
    exception_code: value.exception_code,
    exception_message: value.exception_message,
    payouts: (value.payouts as Array<any>).map(PayoutsReadResponsePayoutsInnerToJSON)
  };
}
