<template>
  <div class="flex flex-col gap-2 sm:flex-row sm:items-center">
    <ToggleSwitch :model-value="value" v-bind="$attrs" @update:model-value="onToggleChange" />
    <div class="flex flex-col">
      <label :for="name">{{ label }}</label>
      <small v-if="helpText" class="mt-1">{{ helpText }}</small>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useField } from 'vee-validate';
import ToggleSwitch from 'primevue/toggleswitch';

const props = defineProps({
  name: {
    type: String,
    required: true,
    default: ''
  },
  rules: {
    type: String,
    required: false,
    default: ''
  },
  modelValue: {
    type: Boolean,
    required: false,
    default: false
  },
  label: {
    type: String,
    required: false,
    default: null
  },
  helpText: {
    type: String,
    required: false,
    default: null
  }
});

const { value, handleChange } = useField<boolean>(() => props.name, props.rules, {
  initialValue: props.modelValue
});
const onToggleChange = (value: boolean) => {
  handleChange(value);
};
</script>
