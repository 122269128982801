<template>
  <Field as="input" type="color" :name="name" :rules="rules" />
</template>

<script setup lang="ts">
import { Field } from 'vee-validate';
import { toRefs } from 'vue';

const props = defineProps({
  autofocus: {
    type: Boolean,
    required: false,
    default: false
  },
  name: {
    type: String,
    required: true
  },
  placeholder: {
    type: String,
    required: false,
    default: ''
  },
  rules: {
    type: String,
    required: false,
    default: ''
  }
});
const { name, rules } = toRefs(props);
</script>
