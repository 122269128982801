/* tslint:disable */
/* eslint-disable */
/**
 * Ticketing Portal
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface PromoCodeUpdateRequest
 */
export interface PromoCodeUpdateRequest {
  /**
   *
   * @type {string}
   * @memberof PromoCodeUpdateRequest
   */
  locale_code: string;
  /**
   *
   * @type {string}
   * @memberof PromoCodeUpdateRequest
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof PromoCodeUpdateRequest
   */
  partner_id: string;
  /**
   *
   * @type {string}
   * @memberof PromoCodeUpdateRequest
   */
  comment?: string | null;
  /**
   *
   * @type {string}
   * @memberof PromoCodeUpdateRequest
   */
  type?: PromoCodeUpdateRequestTypeEnum;
  /**
   *
   * @type {string}
   * @memberof PromoCodeUpdateRequest
   */
  valid_from: string;
  /**
   *
   * @type {string}
   * @memberof PromoCodeUpdateRequest
   */
  valid_until?: string | null;
  /**
   *
   * @type {number}
   * @memberof PromoCodeUpdateRequest
   */
  original_num_uses?: number | null;
  /**
   *
   * @type {number}
   * @memberof PromoCodeUpdateRequest
   */
  remaining_num_uses?: number | null;
  /**
   *
   * @type {string}
   * @memberof PromoCodeUpdateRequest
   */
  status: PromoCodeUpdateRequestStatusEnum;
  /**
   *
   * @type {boolean}
   * @memberof PromoCodeUpdateRequest
   */
  is_exclusive?: boolean;
}

/**
 * @export
 */
export const PromoCodeUpdateRequestTypeEnum = {
  AbsolutePerParticipant: 'absolute_per_participant',
  AbsolutePerCart: 'absolute_per_cart',
  Relative: 'relative'
} as const;
export type PromoCodeUpdateRequestTypeEnum =
  (typeof PromoCodeUpdateRequestTypeEnum)[keyof typeof PromoCodeUpdateRequestTypeEnum];

/**
 * @export
 */
export const PromoCodeUpdateRequestStatusEnum = {
  Active: 'active',
  Inactive: 'inactive',
  Deleted: 'deleted'
} as const;
export type PromoCodeUpdateRequestStatusEnum =
  (typeof PromoCodeUpdateRequestStatusEnum)[keyof typeof PromoCodeUpdateRequestStatusEnum];

/**
 * Check if a given object implements the PromoCodeUpdateRequest interface.
 */
export function instanceOfPromoCodeUpdateRequest(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && 'locale_code' in value;
  isInstance = isInstance && 'id' in value;
  isInstance = isInstance && 'partner_id' in value;
  isInstance = isInstance && 'valid_from' in value;
  isInstance = isInstance && 'status' in value;

  return isInstance;
}

export function PromoCodeUpdateRequestFromJSON(json: any): PromoCodeUpdateRequest {
  return PromoCodeUpdateRequestFromJSONTyped(json, false);
}

export function PromoCodeUpdateRequestFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): PromoCodeUpdateRequest {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    locale_code: json['locale_code'],
    id: json['id'],
    partner_id: json['partner_id'],
    comment: !exists(json, 'comment') ? undefined : json['comment'],
    type: !exists(json, 'type') ? undefined : json['type'],
    valid_from: json['valid_from'],
    valid_until: !exists(json, 'valid_until') ? undefined : json['valid_until'],
    original_num_uses: !exists(json, 'original_num_uses') ? undefined : json['original_num_uses'],
    remaining_num_uses: !exists(json, 'remaining_num_uses')
      ? undefined
      : json['remaining_num_uses'],
    status: json['status'],
    is_exclusive: !exists(json, 'is_exclusive') ? undefined : json['is_exclusive']
  };
}

export function PromoCodeUpdateRequestToJSON(value?: PromoCodeUpdateRequest | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    locale_code: value.locale_code,
    id: value.id,
    partner_id: value.partner_id,
    comment: value.comment,
    type: value.type,
    valid_from: value.valid_from,
    valid_until: value.valid_until,
    original_num_uses: value.original_num_uses,
    remaining_num_uses: value.remaining_num_uses,
    status: value.status,
    is_exclusive: value.is_exclusive
  };
}
