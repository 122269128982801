/* tslint:disable */
/* eslint-disable */
/**
 * Ticketing Portal
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ProductOptionByIdReadResponseProductOption } from './ProductOptionByIdReadResponseProductOption';
import {
  ProductOptionByIdReadResponseProductOptionFromJSON,
  ProductOptionByIdReadResponseProductOptionFromJSONTyped,
  ProductOptionByIdReadResponseProductOptionToJSON
} from './ProductOptionByIdReadResponseProductOption';

/**
 *
 * @export
 * @interface ProductOptionByIdReadResponse
 */
export interface ProductOptionByIdReadResponse {
  /**
   *
   * @type {boolean}
   * @memberof ProductOptionByIdReadResponse
   */
  success: boolean;
  /**
   *
   * @type {string}
   * @memberof ProductOptionByIdReadResponse
   */
  exception_code?: string;
  /**
   *
   * @type {string}
   * @memberof ProductOptionByIdReadResponse
   */
  exception_message?: string;
  /**
   *
   * @type {ProductOptionByIdReadResponseProductOption}
   * @memberof ProductOptionByIdReadResponse
   */
  product_option: ProductOptionByIdReadResponseProductOption;
}

/**
 * Check if a given object implements the ProductOptionByIdReadResponse interface.
 */
export function instanceOfProductOptionByIdReadResponse(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && 'success' in value;
  isInstance = isInstance && 'product_option' in value;

  return isInstance;
}

export function ProductOptionByIdReadResponseFromJSON(json: any): ProductOptionByIdReadResponse {
  return ProductOptionByIdReadResponseFromJSONTyped(json, false);
}

export function ProductOptionByIdReadResponseFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): ProductOptionByIdReadResponse {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    success: json['success'],
    exception_code: !exists(json, 'exception_code') ? undefined : json['exception_code'],
    exception_message: !exists(json, 'exception_message') ? undefined : json['exception_message'],
    product_option: ProductOptionByIdReadResponseProductOptionFromJSON(json['product_option'])
  };
}

export function ProductOptionByIdReadResponseToJSON(
  value?: ProductOptionByIdReadResponse | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    success: value.success,
    exception_code: value.exception_code,
    exception_message: value.exception_message,
    product_option: ProductOptionByIdReadResponseProductOptionToJSON(value.product_option)
  };
}
