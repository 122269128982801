/* tslint:disable */
/* eslint-disable */
/**
 * Ticketing Portal
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { EmailTrigger } from './EmailTrigger';
import {
  EmailTriggerFromJSON,
  EmailTriggerFromJSONTyped,
  EmailTriggerToJSON
} from './EmailTrigger';
import type { EmailTriggerReadResponseMeta } from './EmailTriggerReadResponseMeta';
import {
  EmailTriggerReadResponseMetaFromJSON,
  EmailTriggerReadResponseMetaFromJSONTyped,
  EmailTriggerReadResponseMetaToJSON
} from './EmailTriggerReadResponseMeta';

/**
 *
 * @export
 * @interface EmailTriggerReadResponse
 */
export interface EmailTriggerReadResponse {
  /**
   *
   * @type {boolean}
   * @memberof EmailTriggerReadResponse
   */
  success: boolean;
  /**
   *
   * @type {string}
   * @memberof EmailTriggerReadResponse
   */
  exception_code?: string;
  /**
   *
   * @type {string}
   * @memberof EmailTriggerReadResponse
   */
  exception_message?: string;
  /**
   *
   * @type {Array<EmailTrigger>}
   * @memberof EmailTriggerReadResponse
   */
  email_triggers: Array<EmailTrigger>;
  /**
   *
   * @type {EmailTriggerReadResponseMeta}
   * @memberof EmailTriggerReadResponse
   */
  meta: EmailTriggerReadResponseMeta;
}

/**
 * Check if a given object implements the EmailTriggerReadResponse interface.
 */
export function instanceOfEmailTriggerReadResponse(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && 'success' in value;
  isInstance = isInstance && 'email_triggers' in value;
  isInstance = isInstance && 'meta' in value;

  return isInstance;
}

export function EmailTriggerReadResponseFromJSON(json: any): EmailTriggerReadResponse {
  return EmailTriggerReadResponseFromJSONTyped(json, false);
}

export function EmailTriggerReadResponseFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): EmailTriggerReadResponse {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    success: json['success'],
    exception_code: !exists(json, 'exception_code') ? undefined : json['exception_code'],
    exception_message: !exists(json, 'exception_message') ? undefined : json['exception_message'],
    email_triggers: (json['email_triggers'] as Array<any>).map(EmailTriggerFromJSON),
    meta: EmailTriggerReadResponseMetaFromJSON(json['meta'])
  };
}

export function EmailTriggerReadResponseToJSON(value?: EmailTriggerReadResponse | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    success: value.success,
    exception_code: value.exception_code,
    exception_message: value.exception_message,
    email_triggers: (value.email_triggers as Array<any>).map(EmailTriggerToJSON),
    meta: EmailTriggerReadResponseMetaToJSON(value.meta)
  };
}
