/* tslint:disable */
/* eslint-disable */
/**
 * Ticketing Portal
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface UserUpdateRequest
 */
export interface UserUpdateRequest {
  /**
   *
   * @type {string}
   * @memberof UserUpdateRequest
   */
  locale_code: string;
  /**
   * User first name
   * @type {string}
   * @memberof UserUpdateRequest
   */
  first_name: string;
  /**
   * User last name
   * @type {string}
   * @memberof UserUpdateRequest
   */
  last_name: string;
  /**
   * User status
   * @type {string}
   * @memberof UserUpdateRequest
   */
  status: UserUpdateRequestStatusEnum;
  /**
   * List of roles assigned to the user
   * @type {Array<string>}
   * @memberof UserUpdateRequest
   */
  roles: Array<string>;
}

/**
 * @export
 */
export const UserUpdateRequestStatusEnum = {
  Draft: 'draft',
  Invited: 'invited',
  Unverified: 'unverified',
  Active: 'active',
  Suspended: 'suspended',
  Archived: 'archived'
} as const;
export type UserUpdateRequestStatusEnum =
  (typeof UserUpdateRequestStatusEnum)[keyof typeof UserUpdateRequestStatusEnum];

/**
 * Check if a given object implements the UserUpdateRequest interface.
 */
export function instanceOfUserUpdateRequest(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && 'locale_code' in value;
  isInstance = isInstance && 'first_name' in value;
  isInstance = isInstance && 'last_name' in value;
  isInstance = isInstance && 'status' in value;
  isInstance = isInstance && 'roles' in value;

  return isInstance;
}

export function UserUpdateRequestFromJSON(json: any): UserUpdateRequest {
  return UserUpdateRequestFromJSONTyped(json, false);
}

export function UserUpdateRequestFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): UserUpdateRequest {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    locale_code: json['locale_code'],
    first_name: json['first_name'],
    last_name: json['last_name'],
    status: json['status'],
    roles: json['roles']
  };
}

export function UserUpdateRequestToJSON(value?: UserUpdateRequest | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    locale_code: value.locale_code,
    first_name: value.first_name,
    last_name: value.last_name,
    status: value.status,
    roles: value.roles
  };
}
