import { ROUTE_NAMES } from '@/constants/routes';
import { PAGE_TITLES } from '@/constants/breadcrumbs';
import { InitializationReadResponsePermissionsEnum } from '@openapi/models';

export const AVAILABILITIES_ROUTES = [
  {
    path: 'sell/availabilities',
    name: ROUTE_NAMES.AVAILABILITIES,
    component: () => import('@/views/pages/sell/Availabilities.vue'),
    meta: {
      breadcrumbs: [PAGE_TITLES.SELL, PAGE_TITLES.AVAILABILITIES],
      label: PAGE_TITLES.AVAILABILITIES,
      menu: ['main'],
      permissions: [InitializationReadResponsePermissionsEnum.ProductView],
      icon: 'pi pi-fw pi-calendar'
    }
  }
];
