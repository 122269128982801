<template>
  <Field
    v-slot="{ handleChange, value }"
    :name="name"
    :rules="rules"
    class="flex flex-col gap-2 sm:flex-row sm:items-center"
  >
    <span v-show="false">
      <!--Hack for vue -->
      {{ value }}
    </span>
    <MultiSelect
      :model-value="value"
      display="chip"
      :options="options"
      :option-label="optionLabel"
      :option-value="optionValue"
      @update:model-value="handleChange"
    ></MultiSelect>
    <div class="flex flex-col">
      <label :for="name">{{ label }}</label>
      <small v-if="helpText" class="mt-1">{{ helpText }}</small>
    </div>
  </Field>
</template>

<script setup lang="ts">
import { Field } from 'vee-validate';
import MultiSelect from 'primevue/multiselect';
import { toRefs } from 'vue';

const props = defineProps({
  name: {
    type: String,
    required: true,
    default: ''
  },
  rules: {
    type: String,
    required: false,
    default: ''
  },
  label: {
    type: String,
    required: false,
    default: null
  },
  helpText: {
    type: String,
    required: false,
    default: null
  },
  options: {
    type: Array,
    required: true,
    default: () => []
  },
  modelValue: {
    type: Array,
    required: false,
    default: () => []
  },
  optionLabel: {
    type: String,
    required: false,
    default: 'label'
  },
  optionValue: {
    type: String,
    required: false,
    default: 'value'
  }
});
const { name, options, rules, optionLabel, optionValue } = toRefs(props);
</script>
