/* tslint:disable */
/* eslint-disable */
/**
 * Ticketing Portal
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { BookingsRevenueReadResponseRevenuesInner } from './BookingsRevenueReadResponseRevenuesInner';
import {
  BookingsRevenueReadResponseRevenuesInnerFromJSON,
  BookingsRevenueReadResponseRevenuesInnerFromJSONTyped,
  BookingsRevenueReadResponseRevenuesInnerToJSON
} from './BookingsRevenueReadResponseRevenuesInner';

/**
 *
 * @export
 * @interface BookingsRevenueReadResponse
 */
export interface BookingsRevenueReadResponse {
  /**
   *
   * @type {boolean}
   * @memberof BookingsRevenueReadResponse
   */
  success: boolean;
  /**
   *
   * @type {string}
   * @memberof BookingsRevenueReadResponse
   */
  exception_code?: string;
  /**
   *
   * @type {string}
   * @memberof BookingsRevenueReadResponse
   */
  exception_message?: string;
  /**
   *
   * @type {Array<BookingsRevenueReadResponseRevenuesInner>}
   * @memberof BookingsRevenueReadResponse
   */
  revenues: Array<BookingsRevenueReadResponseRevenuesInner>;
}

/**
 * Check if a given object implements the BookingsRevenueReadResponse interface.
 */
export function instanceOfBookingsRevenueReadResponse(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && 'success' in value;
  isInstance = isInstance && 'revenues' in value;

  return isInstance;
}

export function BookingsRevenueReadResponseFromJSON(json: any): BookingsRevenueReadResponse {
  return BookingsRevenueReadResponseFromJSONTyped(json, false);
}

export function BookingsRevenueReadResponseFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): BookingsRevenueReadResponse {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    success: json['success'],
    exception_code: !exists(json, 'exception_code') ? undefined : json['exception_code'],
    exception_message: !exists(json, 'exception_message') ? undefined : json['exception_message'],
    revenues: (json['revenues'] as Array<any>).map(BookingsRevenueReadResponseRevenuesInnerFromJSON)
  };
}

export function BookingsRevenueReadResponseToJSON(value?: BookingsRevenueReadResponse | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    success: value.success,
    exception_code: value.exception_code,
    exception_message: value.exception_message,
    revenues: (value.revenues as Array<any>).map(BookingsRevenueReadResponseRevenuesInnerToJSON)
  };
}
