import type {
  InitializationReadResponse,
  InitializationReadResponsePermissionsEnum
} from '@openapi/models';
import { ref } from 'vue';
import type { Ref } from 'vue';

const permissions: Ref<InitializationReadResponse['permissions']> = ref([]);

export default function usePermissions() {
  const setPermissions = async (permissionsToSet: InitializationReadResponse['permissions']) => {
    permissions.value = permissionsToSet;
  };

  const hasPermission = (permission: Array<InitializationReadResponsePermissionsEnum>) =>
    permissions.value.some((p) => permission.includes(p));

  return {
    setPermissions,
    hasPermission
  };
}
