/* tslint:disable */
/* eslint-disable */
/**
 * Ticketing Portal
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { AllocationsReadResponseAllocationsInnerProductOptionsInner } from './AllocationsReadResponseAllocationsInnerProductOptionsInner';
import {
  AllocationsReadResponseAllocationsInnerProductOptionsInnerFromJSON,
  AllocationsReadResponseAllocationsInnerProductOptionsInnerFromJSONTyped,
  AllocationsReadResponseAllocationsInnerProductOptionsInnerToJSON
} from './AllocationsReadResponseAllocationsInnerProductOptionsInner';

/**
 *
 * @export
 * @interface AllocationsReadResponseAllocationsInner
 */
export interface AllocationsReadResponseAllocationsInner {
  /**
   * the channel id
   * @type {string}
   * @memberof AllocationsReadResponseAllocationsInner
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof AllocationsReadResponseAllocationsInner
   */
  name: string;
  /**
   *
   * @type {boolean}
   * @memberof AllocationsReadResponseAllocationsInner
   */
  allows_booking_management: boolean;
  /**
   *
   * @type {boolean}
   * @memberof AllocationsReadResponseAllocationsInner
   */
  requires_psp_payment: boolean;
  /**
   *
   * @type {Array<AllocationsReadResponseAllocationsInnerProductOptionsInner>}
   * @memberof AllocationsReadResponseAllocationsInner
   */
  product_options: Array<AllocationsReadResponseAllocationsInnerProductOptionsInner>;
}

/**
 * Check if a given object implements the AllocationsReadResponseAllocationsInner interface.
 */
export function instanceOfAllocationsReadResponseAllocationsInner(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && 'id' in value;
  isInstance = isInstance && 'name' in value;
  isInstance = isInstance && 'allows_booking_management' in value;
  isInstance = isInstance && 'requires_psp_payment' in value;
  isInstance = isInstance && 'product_options' in value;

  return isInstance;
}

export function AllocationsReadResponseAllocationsInnerFromJSON(
  json: any
): AllocationsReadResponseAllocationsInner {
  return AllocationsReadResponseAllocationsInnerFromJSONTyped(json, false);
}

export function AllocationsReadResponseAllocationsInnerFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): AllocationsReadResponseAllocationsInner {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: json['id'],
    name: json['name'],
    allows_booking_management: json['allows_booking_management'],
    requires_psp_payment: json['requires_psp_payment'],
    product_options: (json['product_options'] as Array<any>).map(
      AllocationsReadResponseAllocationsInnerProductOptionsInnerFromJSON
    )
  };
}

export function AllocationsReadResponseAllocationsInnerToJSON(
  value?: AllocationsReadResponseAllocationsInner | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    id: value.id,
    name: value.name,
    allows_booking_management: value.allows_booking_management,
    requires_psp_payment: value.requires_psp_payment,
    product_options: (value.product_options as Array<any>).map(
      AllocationsReadResponseAllocationsInnerProductOptionsInnerToJSON
    )
  };
}
