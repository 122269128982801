/* tslint:disable */
/* eslint-disable */
/**
 * Ticketing Portal
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { GiftCodeOrdersReadResponseGiftCodeOrdersInner } from './GiftCodeOrdersReadResponseGiftCodeOrdersInner';
import {
  GiftCodeOrdersReadResponseGiftCodeOrdersInnerFromJSON,
  GiftCodeOrdersReadResponseGiftCodeOrdersInnerFromJSONTyped,
  GiftCodeOrdersReadResponseGiftCodeOrdersInnerToJSON
} from './GiftCodeOrdersReadResponseGiftCodeOrdersInner';
import type { GiftCodeOrdersReadResponseMeta } from './GiftCodeOrdersReadResponseMeta';
import {
  GiftCodeOrdersReadResponseMetaFromJSON,
  GiftCodeOrdersReadResponseMetaFromJSONTyped,
  GiftCodeOrdersReadResponseMetaToJSON
} from './GiftCodeOrdersReadResponseMeta';

/**
 *
 * @export
 * @interface GiftCodeOrdersReadResponse
 */
export interface GiftCodeOrdersReadResponse {
  /**
   *
   * @type {boolean}
   * @memberof GiftCodeOrdersReadResponse
   */
  success: boolean;
  /**
   *
   * @type {string}
   * @memberof GiftCodeOrdersReadResponse
   */
  exception_code?: string;
  /**
   *
   * @type {string}
   * @memberof GiftCodeOrdersReadResponse
   */
  exception_message?: string;
  /**
   *
   * @type {GiftCodeOrdersReadResponseMeta}
   * @memberof GiftCodeOrdersReadResponse
   */
  meta: GiftCodeOrdersReadResponseMeta;
  /**
   *
   * @type {Array<GiftCodeOrdersReadResponseGiftCodeOrdersInner>}
   * @memberof GiftCodeOrdersReadResponse
   */
  gift_code_orders: Array<GiftCodeOrdersReadResponseGiftCodeOrdersInner>;
}

/**
 * Check if a given object implements the GiftCodeOrdersReadResponse interface.
 */
export function instanceOfGiftCodeOrdersReadResponse(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && 'success' in value;
  isInstance = isInstance && 'meta' in value;
  isInstance = isInstance && 'gift_code_orders' in value;

  return isInstance;
}

export function GiftCodeOrdersReadResponseFromJSON(json: any): GiftCodeOrdersReadResponse {
  return GiftCodeOrdersReadResponseFromJSONTyped(json, false);
}

export function GiftCodeOrdersReadResponseFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): GiftCodeOrdersReadResponse {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    success: json['success'],
    exception_code: !exists(json, 'exception_code') ? undefined : json['exception_code'],
    exception_message: !exists(json, 'exception_message') ? undefined : json['exception_message'],
    meta: GiftCodeOrdersReadResponseMetaFromJSON(json['meta']),
    gift_code_orders: (json['gift_code_orders'] as Array<any>).map(
      GiftCodeOrdersReadResponseGiftCodeOrdersInnerFromJSON
    )
  };
}

export function GiftCodeOrdersReadResponseToJSON(value?: GiftCodeOrdersReadResponse | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    success: value.success,
    exception_code: value.exception_code,
    exception_message: value.exception_message,
    meta: GiftCodeOrdersReadResponseMetaToJSON(value.meta),
    gift_code_orders: (value.gift_code_orders as Array<any>).map(
      GiftCodeOrdersReadResponseGiftCodeOrdersInnerToJSON
    )
  };
}
