/* tslint:disable */
/* eslint-disable */
/**
 * Ticketing Portal
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface ProductUpdateRequest
 */
export interface ProductUpdateRequest {
  /**
   *
   * @type {string}
   * @memberof ProductUpdateRequest
   */
  locale_code: string;
  /**
   *
   * @type {string}
   * @memberof ProductUpdateRequest
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof ProductUpdateRequest
   */
  name: string | null;
  /**
   *
   * @type {string}
   * @memberof ProductUpdateRequest
   */
  timezone: string;
  /**
   *
   * @type {string}
   * @memberof ProductUpdateRequest
   */
  cancellation_policy_id: string;
}

/**
 * Check if a given object implements the ProductUpdateRequest interface.
 */
export function instanceOfProductUpdateRequest(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && 'locale_code' in value;
  isInstance = isInstance && 'id' in value;
  isInstance = isInstance && 'name' in value;
  isInstance = isInstance && 'timezone' in value;
  isInstance = isInstance && 'cancellation_policy_id' in value;

  return isInstance;
}

export function ProductUpdateRequestFromJSON(json: any): ProductUpdateRequest {
  return ProductUpdateRequestFromJSONTyped(json, false);
}

export function ProductUpdateRequestFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): ProductUpdateRequest {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    locale_code: json['locale_code'],
    id: json['id'],
    name: json['name'],
    timezone: json['timezone'],
    cancellation_policy_id: json['cancellation_policy_id']
  };
}

export function ProductUpdateRequestToJSON(value?: ProductUpdateRequest | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    locale_code: value.locale_code,
    id: value.id,
    name: value.name,
    timezone: value.timezone,
    cancellation_policy_id: value.cancellation_policy_id
  };
}
