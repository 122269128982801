/* tslint:disable */
/* eslint-disable */
/**
 * Ticketing Portal
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { BookingByIdReadResponseBookingPartnerQuestionAnswersParticipantInnerAnswersInner } from './BookingByIdReadResponseBookingPartnerQuestionAnswersParticipantInnerAnswersInner';
import {
  BookingByIdReadResponseBookingPartnerQuestionAnswersParticipantInnerAnswersInnerFromJSON,
  BookingByIdReadResponseBookingPartnerQuestionAnswersParticipantInnerAnswersInnerFromJSONTyped,
  BookingByIdReadResponseBookingPartnerQuestionAnswersParticipantInnerAnswersInnerToJSON
} from './BookingByIdReadResponseBookingPartnerQuestionAnswersParticipantInnerAnswersInner';

/**
 *
 * @export
 * @interface BookingByIdReadResponseBookingPartnerQuestionAnswersParticipantInner
 */
export interface BookingByIdReadResponseBookingPartnerQuestionAnswersParticipantInner {
  /**
   *
   * @type {string}
   * @memberof BookingByIdReadResponseBookingPartnerQuestionAnswersParticipantInner
   */
  partner_question_id: string;
  /**
   *
   * @type {string}
   * @memberof BookingByIdReadResponseBookingPartnerQuestionAnswersParticipantInner
   */
  question_text: string;
  /**
   *
   * @type {Array<BookingByIdReadResponseBookingPartnerQuestionAnswersParticipantInnerAnswersInner>}
   * @memberof BookingByIdReadResponseBookingPartnerQuestionAnswersParticipantInner
   */
  answers: Array<BookingByIdReadResponseBookingPartnerQuestionAnswersParticipantInnerAnswersInner>;
}

/**
 * Check if a given object implements the BookingByIdReadResponseBookingPartnerQuestionAnswersParticipantInner interface.
 */
export function instanceOfBookingByIdReadResponseBookingPartnerQuestionAnswersParticipantInner(
  value: object
): boolean {
  let isInstance = true;
  isInstance = isInstance && 'partner_question_id' in value;
  isInstance = isInstance && 'question_text' in value;
  isInstance = isInstance && 'answers' in value;

  return isInstance;
}

export function BookingByIdReadResponseBookingPartnerQuestionAnswersParticipantInnerFromJSON(
  json: any
): BookingByIdReadResponseBookingPartnerQuestionAnswersParticipantInner {
  return BookingByIdReadResponseBookingPartnerQuestionAnswersParticipantInnerFromJSONTyped(
    json,
    false
  );
}

export function BookingByIdReadResponseBookingPartnerQuestionAnswersParticipantInnerFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): BookingByIdReadResponseBookingPartnerQuestionAnswersParticipantInner {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    partner_question_id: json['partner_question_id'],
    question_text: json['question_text'],
    answers: (json['answers'] as Array<any>).map(
      BookingByIdReadResponseBookingPartnerQuestionAnswersParticipantInnerAnswersInnerFromJSON
    )
  };
}

export function BookingByIdReadResponseBookingPartnerQuestionAnswersParticipantInnerToJSON(
  value?: BookingByIdReadResponseBookingPartnerQuestionAnswersParticipantInner | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    partner_question_id: value.partner_question_id,
    question_text: value.question_text,
    answers: (value.answers as Array<any>).map(
      BookingByIdReadResponseBookingPartnerQuestionAnswersParticipantInnerAnswersInnerToJSON
    )
  };
}
