/* tslint:disable */
/* eslint-disable */
/**
 * Ticketing Portal
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { NewsletterSettingsPartnerTranslationsInner } from './NewsletterSettingsPartnerTranslationsInner';
import {
  NewsletterSettingsPartnerTranslationsInnerFromJSON,
  NewsletterSettingsPartnerTranslationsInnerFromJSONTyped,
  NewsletterSettingsPartnerTranslationsInnerToJSON
} from './NewsletterSettingsPartnerTranslationsInner';

/**
 *
 * @export
 * @interface NewsletterSettingsUpdateResponseNewsletterSettings
 */
export interface NewsletterSettingsUpdateResponseNewsletterSettings {
  /**
   *
   * @type {string}
   * @memberof NewsletterSettingsUpdateResponseNewsletterSettings
   */
  id: string;
  /**
   *
   * @type {boolean}
   * @memberof NewsletterSettingsUpdateResponseNewsletterSettings
   */
  show_subscribe_to_newsletter?: boolean | null;
  /**
   *
   * @type {Array<NewsletterSettingsPartnerTranslationsInner>}
   * @memberof NewsletterSettingsUpdateResponseNewsletterSettings
   */
  partner_translation: Array<NewsletterSettingsPartnerTranslationsInner>;
}

/**
 * Check if a given object implements the NewsletterSettingsUpdateResponseNewsletterSettings interface.
 */
export function instanceOfNewsletterSettingsUpdateResponseNewsletterSettings(
  value: object
): boolean {
  let isInstance = true;
  isInstance = isInstance && 'id' in value;
  isInstance = isInstance && 'partner_translation' in value;

  return isInstance;
}

export function NewsletterSettingsUpdateResponseNewsletterSettingsFromJSON(
  json: any
): NewsletterSettingsUpdateResponseNewsletterSettings {
  return NewsletterSettingsUpdateResponseNewsletterSettingsFromJSONTyped(json, false);
}

export function NewsletterSettingsUpdateResponseNewsletterSettingsFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): NewsletterSettingsUpdateResponseNewsletterSettings {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: json['id'],
    show_subscribe_to_newsletter: !exists(json, 'show_subscribe_to_newsletter')
      ? undefined
      : json['show_subscribe_to_newsletter'],
    partner_translation: (json['partner_translation'] as Array<any>).map(
      NewsletterSettingsPartnerTranslationsInnerFromJSON
    )
  };
}

export function NewsletterSettingsUpdateResponseNewsletterSettingsToJSON(
  value?: NewsletterSettingsUpdateResponseNewsletterSettings | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    id: value.id,
    show_subscribe_to_newsletter: value.show_subscribe_to_newsletter,
    partner_translation: (value.partner_translation as Array<any>).map(
      NewsletterSettingsPartnerTranslationsInnerToJSON
    )
  };
}
