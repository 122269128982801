import DOMPurify from 'dompurify';
import type { App } from 'vue';

/**
 * Vue plugin for v-html-safe directive (safe alternative to insecure v-html)
 *
 * @usage   <div v-html-safe="message"></div>
 */
const ALLOWED_ATTR = ['target', 'href', 'rel'];
const ALLOWED_TAGS = ['a', 'b', 'br', 'i', 'img', 'p', 'span', 'strong'];
export default {
  install(Vue: App, options?: DOMPurify.Config) {
    Vue.directive('html-safe', {
      created: function (el: { innerHTML: any }, binding: { value: any }) {
        el.innerHTML = DOMPurify.sanitize(binding.value, {
          ...options,
          ALLOWED_ATTR,
          ALLOWED_TAGS
        });
      },

      updated: function (el: { innerHTML: any }, binding: { value: any; oldValue: any }) {
        if (binding.value !== binding.oldValue)
          el.innerHTML = DOMPurify.sanitize(binding.value, {
            ...options,
            ALLOWED_ATTR,
            ALLOWED_TAGS
          });
      }
    });
  }
};
