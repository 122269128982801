/* tslint:disable */
/* eslint-disable */
/**
 * Ticketing Portal
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface TimeSlotBatchUpdateRequestInner
 */
export interface TimeSlotBatchUpdateRequestInner {
  /**
   *
   * @type {string}
   * @memberof TimeSlotBatchUpdateRequestInner
   */
  id: string;
  /**
   *
   * @type {number}
   * @memberof TimeSlotBatchUpdateRequestInner
   */
  capacity?: number | null;
  /**
   *
   * @type {string}
   * @memberof TimeSlotBatchUpdateRequestInner
   */
  status?: TimeSlotBatchUpdateRequestInnerStatusEnum;
  /**
   *
   * @type {string}
   * @memberof TimeSlotBatchUpdateRequestInner
   */
  pricing_id?: string;
}

/**
 * @export
 */
export const TimeSlotBatchUpdateRequestInnerStatusEnum = {
  Active: 'active',
  Deleted: 'deleted',
  NotBookable: 'not_bookable'
} as const;
export type TimeSlotBatchUpdateRequestInnerStatusEnum =
  (typeof TimeSlotBatchUpdateRequestInnerStatusEnum)[keyof typeof TimeSlotBatchUpdateRequestInnerStatusEnum];

/**
 * Check if a given object implements the TimeSlotBatchUpdateRequestInner interface.
 */
export function instanceOfTimeSlotBatchUpdateRequestInner(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && 'id' in value;

  return isInstance;
}

export function TimeSlotBatchUpdateRequestInnerFromJSON(
  json: any
): TimeSlotBatchUpdateRequestInner {
  return TimeSlotBatchUpdateRequestInnerFromJSONTyped(json, false);
}

export function TimeSlotBatchUpdateRequestInnerFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): TimeSlotBatchUpdateRequestInner {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: json['id'],
    capacity: !exists(json, 'capacity') ? undefined : json['capacity'],
    status: !exists(json, 'status') ? undefined : json['status'],
    pricing_id: !exists(json, 'pricing_id') ? undefined : json['pricing_id']
  };
}

export function TimeSlotBatchUpdateRequestInnerToJSON(
  value?: TimeSlotBatchUpdateRequestInner | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    id: value.id,
    capacity: value.capacity,
    status: value.status,
    pricing_id: value.pricing_id
  };
}
