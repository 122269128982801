<template>
  <Field v-slot="{ handleChange, value }" :name="name" :rules="rules">
    <InputNumber
      :model-value="value"
      :disabled="disabled"
      :mode="mode"
      :locale="locale"
      :currency="currency"
      :min="min"
      :max="max"
      :placeholder="placeholder"
      :max-fraction-digits="maxFractionDigits"
      :suffix="suffix"
      @update:model-value="handleChange"
    ></InputNumber>

    <!-- display help text if condition is true -->
    <div v-if="helpTextCondition">
      <Message class="w-fit" severity="info" size="small">{{ helpTextLabel }}</Message>
    </div>
  </Field>
</template>

<script setup lang="ts">
import { Field } from 'vee-validate';
import { toRefs, type PropType } from 'vue';
import InputNumber from 'primevue/inputnumber';
import Message from 'primevue/message';

enum Mode {
  currency = 'currency',
  decimal = 'decimal'
}
const props = defineProps({
  autofocus: {
    type: Boolean,
    required: false,
    default: false
  },
  name: {
    type: String,
    required: true,
    default: ''
  },
  placeholder: {
    type: String,
    required: false,
    default: ''
  },
  rules: {
    type: String,
    required: false,
    default: ''
  },
  min: {
    type: Number,
    required: false,
    default: undefined
  },
  max: {
    type: Number,
    required: false,
    default: undefined
  },
  currency: {
    type: String,
    required: false,
    default: 'EUR'
  },
  mode: {
    type: String as PropType<Mode>,
    required: false,
    default: undefined
  },
  locale: {
    type: String,
    required: false,
    default: undefined
  },
  disabled: {
    type: Boolean,
    required: false,
    default: () => false
  },
  suffix: {
    type: String,
    required: false,
    default: undefined
  },
  maxFractionDigits: {
    type: Number,
    required: false,
    default: undefined
  },
  helpTextCondition: {
    type: Boolean,
    required: false,
    default: false
  },
  helpTextLabel: {
    type: String,
    required: false,
    default: ''
  }
});
const {
  name,
  placeholder,
  rules,
  currency,
  mode,
  locale,
  suffix,
  maxFractionDigits,
  helpTextCondition,
  helpTextLabel,
  max
} = toRefs(props);
</script>
