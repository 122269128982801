import { ROUTE_NAMES } from '@/constants/routes';
import { PAGE_TITLES } from '@/constants/breadcrumbs';
import { InitializationReadResponsePermissionsEnum } from '@openapi/models';

export const SETTINGS_ROUTES = [
  {
    path: 'settings',
    name: ROUTE_NAMES.SETTINGS,
    component: () => import('@/views/pages/settings/Settings.vue'),
    redirect: { path: '/settings/business-information' },
    meta: {
      breadcrumbs: [PAGE_TITLES.SETTINGS],
      menu: ['main'],
      permissions: [InitializationReadResponsePermissionsEnum.SettingsView],
      label: PAGE_TITLES.SETTINGS,
      icon: 'pi pi-fw pi-cog'
    },
    children: [
      {
        path: 'email',
        name: ROUTE_NAMES.EMAIL_SETTINGS,
        component: () => import('@/views/pages/settings/EmailSettings.vue'),
        meta: {
          breadcrumbs: [PAGE_TITLES.SETTINGS, PAGE_TITLES.EMAIL_SETTINGS]
        }
      },
      {
        path: 'cancellation-policy',
        name: ROUTE_NAMES.CANCELLATION_POLICY,
        component: () => import('@/views/pages/settings/CancellationPolicy.vue'),
        meta: {
          breadcrumbs: [PAGE_TITLES.SETTINGS, PAGE_TITLES.CANCELLATION_POLICY]
        }
      },
      {
        path: 'cancellation-policy/:cancellationPolicyId',
        name: ROUTE_NAMES.EDIT_CANCELLATION_POLICY,
        component: () => import('@/views/pages/settings/CancellationPolicyForm.vue'),
        meta: {
          breadcrumbs: [PAGE_TITLES.SETTINGS, PAGE_TITLES.CANCELLATION_POLICY]
        }
      },
      {
        path: 'cancellation-policy/+',
        name: ROUTE_NAMES.NEW_CANCELLATION_POLICY,
        component: () => import('@/views/pages/settings/CancellationPolicyForm.vue'),
        meta: {
          breadcrumbs: [PAGE_TITLES.SETTINGS, PAGE_TITLES.CANCELLATION_POLICY]
        }
      },
      {
        path: 'business-information',
        name: ROUTE_NAMES.BUSINESS_INFORMATION,
        component: () => import('@/views/pages/settings/BusinessInformation.vue'),
        meta: {
          breadcrumbs: [PAGE_TITLES.SETTINGS, PAGE_TITLES.BUSINESS_INFORMATION]
        }
      },
      {
        path: 'newsletter',
        name: ROUTE_NAMES.NEWSLETTER_SETTINGS,
        component: () => import('@/views/pages/settings/NewsletterSettings.vue'),
        meta: {
          breadcrumbs: [PAGE_TITLES.SETTINGS, PAGE_TITLES.NEWSLETTER_SETTINGS]
        }
      },
      {
        path: 'customization',
        name: ROUTE_NAMES.CUSTOMIZATION_SETTINGS,
        component: () => import('@/views/pages/settings/CustomizationSettings.vue'),
        meta: {
          breadcrumbs: [PAGE_TITLES.SETTINGS, PAGE_TITLES.CUSTOMIZATION_SETTINGS]
        }
      },
      {
        path: 'integrations',
        name: ROUTE_NAMES.INTEGRATION_SETTINGS,
        component: () => import('@/views/pages/settings/IntegrationSettings.vue'),
        meta: {
          breadcrumbs: [PAGE_TITLES.SETTINGS, PAGE_TITLES.INTEGRATION_SETTINGS]
        }
      },
      {
        path: 'integration/:integrationId',
        name: ROUTE_NAMES.INTEGRATION_EDIT,
        component: () => import('@/views/pages/settings/IntegrationEdit.vue'),
        meta: {
          breadcrumbs: [
            PAGE_TITLES.SETTINGS,
            PAGE_TITLES.INTEGRATION_SETTINGS,
            PAGE_TITLES.INTEGRATION_EDIT
          ]
        }
      },
      {
        path: 'onboarding',
        name: ROUTE_NAMES.ONBOARDING,
        component: () => import('@/views/pages/settings/onboarding/Onboarding.vue'),
        meta: {
          breadcrumbs: [PAGE_TITLES.MANAGE, PAGE_TITLES.ONBOARDING],
          label: PAGE_TITLES.ONBOARDING,
          icon: 'pi pi-fw pi-compass'
        }
      }
    ]
  }
];
