/* tslint:disable */
/* eslint-disable */
/**
 * Ticketing Portal
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface ProductOptionRankUpdateRequest
 */
export interface ProductOptionRankUpdateRequest {
  /**
   *
   * @type {string}
   * @memberof ProductOptionRankUpdateRequest
   */
  locale_code: string;
  /**
   *
   * @type {Array<string>}
   * @memberof ProductOptionRankUpdateRequest
   */
  product_option_ids: Array<string>;
}

/**
 * Check if a given object implements the ProductOptionRankUpdateRequest interface.
 */
export function instanceOfProductOptionRankUpdateRequest(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && 'locale_code' in value;
  isInstance = isInstance && 'product_option_ids' in value;

  return isInstance;
}

export function ProductOptionRankUpdateRequestFromJSON(json: any): ProductOptionRankUpdateRequest {
  return ProductOptionRankUpdateRequestFromJSONTyped(json, false);
}

export function ProductOptionRankUpdateRequestFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): ProductOptionRankUpdateRequest {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    locale_code: json['locale_code'],
    product_option_ids: json['product_option_ids']
  };
}

export function ProductOptionRankUpdateRequestToJSON(
  value?: ProductOptionRankUpdateRequest | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    locale_code: value.locale_code,
    product_option_ids: value.product_option_ids
  };
}
