/* tslint:disable */
/* eslint-disable */
/**
 * Ticketing Portal
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface ProductOptionChannelConfigurationByOptionIdResponseProductOptionChannelConfigurationsInner
 */
export interface ProductOptionChannelConfigurationByOptionIdResponseProductOptionChannelConfigurationsInner {
  /**
   *
   * @type {string}
   * @memberof ProductOptionChannelConfigurationByOptionIdResponseProductOptionChannelConfigurationsInner
   */
  id: string | null;
  /**
   *
   * @type {string}
   * @memberof ProductOptionChannelConfigurationByOptionIdResponseProductOptionChannelConfigurationsInner
   */
  product_option_id: string;
  /**
   *
   * @type {string}
   * @memberof ProductOptionChannelConfigurationByOptionIdResponseProductOptionChannelConfigurationsInner
   */
  reservable_before: string | null;
  /**
   *
   * @type {string}
   * @memberof ProductOptionChannelConfigurationByOptionIdResponseProductOptionChannelConfigurationsInner
   */
  reservable_after: string | null;
  /**
   *
   * @type {string}
   * @memberof ProductOptionChannelConfigurationByOptionIdResponseProductOptionChannelConfigurationsInner
   */
  status: ProductOptionChannelConfigurationByOptionIdResponseProductOptionChannelConfigurationsInnerStatusEnum;
  /**
   *
   * @type {string}
   * @memberof ProductOptionChannelConfigurationByOptionIdResponseProductOptionChannelConfigurationsInner
   */
  channel_id: string;
  /**
   *
   * @type {string}
   * @memberof ProductOptionChannelConfigurationByOptionIdResponseProductOptionChannelConfigurationsInner
   */
  channel_name: string;
}

/**
 * @export
 */
export const ProductOptionChannelConfigurationByOptionIdResponseProductOptionChannelConfigurationsInnerStatusEnum =
  {
    Active: 'active',
    Inactive: 'inactive',
    Deleted: 'deleted'
  } as const;
export type ProductOptionChannelConfigurationByOptionIdResponseProductOptionChannelConfigurationsInnerStatusEnum =
  (typeof ProductOptionChannelConfigurationByOptionIdResponseProductOptionChannelConfigurationsInnerStatusEnum)[keyof typeof ProductOptionChannelConfigurationByOptionIdResponseProductOptionChannelConfigurationsInnerStatusEnum];

/**
 * Check if a given object implements the ProductOptionChannelConfigurationByOptionIdResponseProductOptionChannelConfigurationsInner interface.
 */
export function instanceOfProductOptionChannelConfigurationByOptionIdResponseProductOptionChannelConfigurationsInner(
  value: object
): boolean {
  let isInstance = true;
  isInstance = isInstance && 'id' in value;
  isInstance = isInstance && 'product_option_id' in value;
  isInstance = isInstance && 'reservable_before' in value;
  isInstance = isInstance && 'reservable_after' in value;
  isInstance = isInstance && 'status' in value;
  isInstance = isInstance && 'channel_id' in value;
  isInstance = isInstance && 'channel_name' in value;

  return isInstance;
}

export function ProductOptionChannelConfigurationByOptionIdResponseProductOptionChannelConfigurationsInnerFromJSON(
  json: any
): ProductOptionChannelConfigurationByOptionIdResponseProductOptionChannelConfigurationsInner {
  return ProductOptionChannelConfigurationByOptionIdResponseProductOptionChannelConfigurationsInnerFromJSONTyped(
    json,
    false
  );
}

export function ProductOptionChannelConfigurationByOptionIdResponseProductOptionChannelConfigurationsInnerFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): ProductOptionChannelConfigurationByOptionIdResponseProductOptionChannelConfigurationsInner {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: json['id'],
    product_option_id: json['product_option_id'],
    reservable_before: json['reservable_before'],
    reservable_after: json['reservable_after'],
    status: json['status'],
    channel_id: json['channel_id'],
    channel_name: json['channel_name']
  };
}

export function ProductOptionChannelConfigurationByOptionIdResponseProductOptionChannelConfigurationsInnerToJSON(
  value?: ProductOptionChannelConfigurationByOptionIdResponseProductOptionChannelConfigurationsInner | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    id: value.id,
    product_option_id: value.product_option_id,
    reservable_before: value.reservable_before,
    reservable_after: value.reservable_after,
    status: value.status,
    channel_id: value.channel_id,
    channel_name: value.channel_name
  };
}
