import { ROUTE_NAMES } from '@/constants/routes';
import { PAGE_TITLES } from '@/constants/breadcrumbs';
import { InitializationReadResponsePermissionsEnum } from '@openapi/models';

export const QUESTIONS_ROUTES = [
  {
    path: `/sell/${ROUTE_NAMES.QUESTIONS}`,
    name: ROUTE_NAMES.QUESTIONS,
    component: () => import('@/views/pages/sell/question/Questions.vue'),
    meta: {
      breadcrumbs: [PAGE_TITLES.SELL, PAGE_TITLES.QUESTIONS],
      label: PAGE_TITLES.QUESTIONS,
      menu: ['main'],
      permissions: [InitializationReadResponsePermissionsEnum.QuestionView],
      icon: 'pi pi-fw pi-question-circle'
    }
  },
  {
    path: `/sell/${ROUTE_NAMES.QUESTIONS}/+`,
    name: ROUTE_NAMES.NEW_QUESTION,
    component: () => import('@/views/pages/sell/question/QuestionNew.vue'),
    meta: {
      breadcrumbs: [PAGE_TITLES.SELL, PAGE_TITLES.QUESTIONS, PAGE_TITLES.NEW_QUESTION]
    }
  },
  {
    path: `sell/${ROUTE_NAMES.QUESTIONS}/:questionId`,
    name: ROUTE_NAMES.EDIT_QUESTION,
    component: () => import('@/views/pages/sell/question/QuestionEdit.vue'),
    meta: {
      breadcrumbs: [PAGE_TITLES.SELL, PAGE_TITLES.QUESTIONS, PAGE_TITLES.EDIT_QUESTION]
    }
  }
];
