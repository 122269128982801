/* tslint:disable */
/* eslint-disable */
/**
 * Ticketing Portal
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface IntegrationCreateRequestIntegration
 */
export interface IntegrationCreateRequestIntegration {
  /**
   *
   * @type {string}
   * @memberof IntegrationCreateRequestIntegration
   */
  name?: string | null;
  /**
   *
   * @type {string}
   * @memberof IntegrationCreateRequestIntegration
   */
  locale_code?: string | null;
  /**
   *
   * @type {string}
   * @memberof IntegrationCreateRequestIntegration
   */
  comment?: string | null;
}

/**
 * Check if a given object implements the IntegrationCreateRequestIntegration interface.
 */
export function instanceOfIntegrationCreateRequestIntegration(value: object): boolean {
  let isInstance = true;

  return isInstance;
}

export function IntegrationCreateRequestIntegrationFromJSON(
  json: any
): IntegrationCreateRequestIntegration {
  return IntegrationCreateRequestIntegrationFromJSONTyped(json, false);
}

export function IntegrationCreateRequestIntegrationFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): IntegrationCreateRequestIntegration {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    name: !exists(json, 'name') ? undefined : json['name'],
    locale_code: !exists(json, 'locale_code') ? undefined : json['locale_code'],
    comment: !exists(json, 'comment') ? undefined : json['comment']
  };
}

export function IntegrationCreateRequestIntegrationToJSON(
  value?: IntegrationCreateRequestIntegration | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    name: value.name,
    locale_code: value.locale_code,
    comment: value.comment
  };
}
