import { ROUTE_NAMES } from '@/constants/routes';
import { PAGE_TITLES } from '@/constants/breadcrumbs';
import { InitializationReadResponsePermissionsEnum } from '@openapi/models';

export const CUSTOMERS_ROUTES = [
  {
    path: 'sell/customers',
    name: ROUTE_NAMES.CUSTOMERS,
    component: () => import('@/views/pages/sell/Customers.vue'),
    meta: {
      breadcrumbs: [PAGE_TITLES.SELL, PAGE_TITLES.CUSTOMERS],
      label: PAGE_TITLES.CUSTOMERS,
      icon: 'pi pi-fw pi-user',
      menu: ['main'],
      permissions: [InitializationReadResponsePermissionsEnum.CustomerView]
    }
  },
  {
    path: 'sell/customers/:customerId',
    name: ROUTE_NAMES.EDIT_CUSTOMER,
    component: () => import('@/views/pages/sell/Customer.vue'),
    meta: {
      breadcrumbs: [PAGE_TITLES.SELL, PAGE_TITLES.CUSTOMERS, PAGE_TITLES.EDIT_CUSTOMER]
    }
  }
];
