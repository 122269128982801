/* tslint:disable */
/* eslint-disable */
/**
 * Ticketing Portal
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface PromoCodeByIdReadResponsePromoCode
 */
export interface PromoCodeByIdReadResponsePromoCode {
  /**
   *
   * @type {string}
   * @memberof PromoCodeByIdReadResponsePromoCode
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof PromoCodeByIdReadResponsePromoCode
   */
  partner_id: string;
  /**
   * the promo code
   * @type {string}
   * @memberof PromoCodeByIdReadResponsePromoCode
   */
  code: string;
  /**
   *
   * @type {string}
   * @memberof PromoCodeByIdReadResponsePromoCode
   */
  comment?: string | null;
  /**
   *
   * @type {string}
   * @memberof PromoCodeByIdReadResponsePromoCode
   */
  type?: PromoCodeByIdReadResponsePromoCodeTypeEnum;
  /**
   *
   * @type {number}
   * @memberof PromoCodeByIdReadResponsePromoCode
   */
  value: number;
  /**
   *
   * @type {boolean}
   * @memberof PromoCodeByIdReadResponsePromoCode
   */
  once_per_customer?: boolean;
  /**
   *
   * @type {string}
   * @memberof PromoCodeByIdReadResponsePromoCode
   */
  created_at: string;
  /**
   *
   * @type {string}
   * @memberof PromoCodeByIdReadResponsePromoCode
   */
  valid_from: string;
  /**
   *
   * @type {string}
   * @memberof PromoCodeByIdReadResponsePromoCode
   */
  valid_until?: string | null;
  /**
   *
   * @type {number}
   * @memberof PromoCodeByIdReadResponsePromoCode
   */
  original_num_uses?: number | null;
  /**
   *
   * @type {number}
   * @memberof PromoCodeByIdReadResponsePromoCode
   */
  remaining_num_uses?: number | null;
  /**
   *
   * @type {string}
   * @memberof PromoCodeByIdReadResponsePromoCode
   */
  status: PromoCodeByIdReadResponsePromoCodeStatusEnum;
  /**
   *
   * @type {boolean}
   * @memberof PromoCodeByIdReadResponsePromoCode
   */
  is_exclusive: boolean;
}

/**
 * @export
 */
export const PromoCodeByIdReadResponsePromoCodeTypeEnum = {
  AbsolutePerParticipant: 'absolute_per_participant',
  AbsolutePerCart: 'absolute_per_cart',
  Relative: 'relative'
} as const;
export type PromoCodeByIdReadResponsePromoCodeTypeEnum =
  (typeof PromoCodeByIdReadResponsePromoCodeTypeEnum)[keyof typeof PromoCodeByIdReadResponsePromoCodeTypeEnum];

/**
 * @export
 */
export const PromoCodeByIdReadResponsePromoCodeStatusEnum = {
  Active: 'active',
  Inactive: 'inactive',
  Deleted: 'deleted'
} as const;
export type PromoCodeByIdReadResponsePromoCodeStatusEnum =
  (typeof PromoCodeByIdReadResponsePromoCodeStatusEnum)[keyof typeof PromoCodeByIdReadResponsePromoCodeStatusEnum];

/**
 * Check if a given object implements the PromoCodeByIdReadResponsePromoCode interface.
 */
export function instanceOfPromoCodeByIdReadResponsePromoCode(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && 'id' in value;
  isInstance = isInstance && 'partner_id' in value;
  isInstance = isInstance && 'code' in value;
  isInstance = isInstance && 'value' in value;
  isInstance = isInstance && 'created_at' in value;
  isInstance = isInstance && 'valid_from' in value;
  isInstance = isInstance && 'status' in value;
  isInstance = isInstance && 'is_exclusive' in value;

  return isInstance;
}

export function PromoCodeByIdReadResponsePromoCodeFromJSON(
  json: any
): PromoCodeByIdReadResponsePromoCode {
  return PromoCodeByIdReadResponsePromoCodeFromJSONTyped(json, false);
}

export function PromoCodeByIdReadResponsePromoCodeFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): PromoCodeByIdReadResponsePromoCode {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: json['id'],
    partner_id: json['partner_id'],
    code: json['code'],
    comment: !exists(json, 'comment') ? undefined : json['comment'],
    type: !exists(json, 'type') ? undefined : json['type'],
    value: json['value'],
    once_per_customer: !exists(json, 'once_per_customer') ? undefined : json['once_per_customer'],
    created_at: json['created_at'],
    valid_from: json['valid_from'],
    valid_until: !exists(json, 'valid_until') ? undefined : json['valid_until'],
    original_num_uses: !exists(json, 'original_num_uses') ? undefined : json['original_num_uses'],
    remaining_num_uses: !exists(json, 'remaining_num_uses')
      ? undefined
      : json['remaining_num_uses'],
    status: json['status'],
    is_exclusive: json['is_exclusive']
  };
}

export function PromoCodeByIdReadResponsePromoCodeToJSON(
  value?: PromoCodeByIdReadResponsePromoCode | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    id: value.id,
    partner_id: value.partner_id,
    code: value.code,
    comment: value.comment,
    type: value.type,
    value: value.value,
    once_per_customer: value.once_per_customer,
    created_at: value.created_at,
    valid_from: value.valid_from,
    valid_until: value.valid_until,
    original_num_uses: value.original_num_uses,
    remaining_num_uses: value.remaining_num_uses,
    status: value.status,
    is_exclusive: value.is_exclusive
  };
}
