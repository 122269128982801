/* tslint:disable */
/* eslint-disable */
/**
 * Ticketing Portal
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { IntegrationReadResponseAvailableProductsInner } from './IntegrationReadResponseAvailableProductsInner';
import {
  IntegrationReadResponseAvailableProductsInnerFromJSON,
  IntegrationReadResponseAvailableProductsInnerFromJSONTyped,
  IntegrationReadResponseAvailableProductsInnerToJSON
} from './IntegrationReadResponseAvailableProductsInner';

/**
 *
 * @export
 * @interface IntegrationReadResponseIntegration
 */
export interface IntegrationReadResponseIntegration {
  /**
   *
   * @type {string}
   * @memberof IntegrationReadResponseIntegration
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof IntegrationReadResponseIntegration
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof IntegrationReadResponseIntegration
   */
  locale_code?: string;
  /**
   *
   * @type {string}
   * @memberof IntegrationReadResponseIntegration
   */
  comment?: string;
  /**
   *
   * @type {string}
   * @memberof IntegrationReadResponseIntegration
   */
  status: IntegrationReadResponseIntegrationStatusEnum;
  /**
   *
   * @type {Array<IntegrationReadResponseAvailableProductsInner>}
   * @memberof IntegrationReadResponseIntegration
   */
  products?: Array<IntegrationReadResponseAvailableProductsInner>;
}

/**
 * @export
 */
export const IntegrationReadResponseIntegrationStatusEnum = {
  Active: 'active',
  Inactive: 'inactive',
  Deleted: 'deleted'
} as const;
export type IntegrationReadResponseIntegrationStatusEnum =
  (typeof IntegrationReadResponseIntegrationStatusEnum)[keyof typeof IntegrationReadResponseIntegrationStatusEnum];

/**
 * Check if a given object implements the IntegrationReadResponseIntegration interface.
 */
export function instanceOfIntegrationReadResponseIntegration(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && 'id' in value;
  isInstance = isInstance && 'name' in value;
  isInstance = isInstance && 'status' in value;

  return isInstance;
}

export function IntegrationReadResponseIntegrationFromJSON(
  json: any
): IntegrationReadResponseIntegration {
  return IntegrationReadResponseIntegrationFromJSONTyped(json, false);
}

export function IntegrationReadResponseIntegrationFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): IntegrationReadResponseIntegration {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: json['id'],
    name: json['name'],
    locale_code: !exists(json, 'locale_code') ? undefined : json['locale_code'],
    comment: !exists(json, 'comment') ? undefined : json['comment'],
    status: json['status'],
    products: !exists(json, 'products')
      ? undefined
      : (json['products'] as Array<any>).map(IntegrationReadResponseAvailableProductsInnerFromJSON)
  };
}

export function IntegrationReadResponseIntegrationToJSON(
  value?: IntegrationReadResponseIntegration | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    id: value.id,
    name: value.name,
    locale_code: value.locale_code,
    comment: value.comment,
    status: value.status,
    products:
      value.products === undefined
        ? undefined
        : (value.products as Array<any>).map(IntegrationReadResponseAvailableProductsInnerToJSON)
  };
}
