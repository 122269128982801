import { createI18n } from 'vue-i18n';
import { DEFAULT_LOCALE, FALLBACK_LOCALE } from '@/constants/i18n';
import useTranslate from './composables/use-translate';
import { useLocaleStore } from './stores/locales';

export const fetchPortalTranslations = async () => {
  const { getSelectedLocale } = useLocaleStore();
  const { fetchTranslationsByLocale } = useTranslate();
  const translations = await fetchTranslationsByLocale();
  i18n.global.setLocaleMessage(getSelectedLocale, translations);
};

const i18n = createI18n({
  fallbackLocale: FALLBACK_LOCALE,
  locale: DEFAULT_LOCALE,
  legacy: false,
  globalInjection: true,
  silentFallbackWarn: true
});

// Shows the original key in the UI when the URL contains the query parameter "disable-i18n"
const showOriginalKey = () => {
  const urlPath = window.location.hash;
  const queryStringStartIndex = urlPath.indexOf('?');
  const searchParams = queryStringStartIndex !== -1 ? urlPath.slice(queryStringStartIndex) : '';
  return new URLSearchParams(searchParams).has('disable-i18n');
};

if (showOriginalKey()) {
  i18n.global.t = (key: string) => key;
}

export default i18n;
