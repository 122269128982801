/* tslint:disable */
/* eslint-disable */
/**
 * Ticketing Portal
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface PricingFlexibleCreateRequestPricingCategoryPricesInner
 */
export interface PricingFlexibleCreateRequestPricingCategoryPricesInner {
  /**
   *
   * @type {string}
   * @memberof PricingFlexibleCreateRequestPricingCategoryPricesInner
   */
  pricing_category_id: string;
  /**
   *
   * @type {number}
   * @memberof PricingFlexibleCreateRequestPricingCategoryPricesInner
   */
  value: number;
}

/**
 * Check if a given object implements the PricingFlexibleCreateRequestPricingCategoryPricesInner interface.
 */
export function instanceOfPricingFlexibleCreateRequestPricingCategoryPricesInner(
  value: object
): boolean {
  let isInstance = true;
  isInstance = isInstance && 'pricing_category_id' in value;
  isInstance = isInstance && 'value' in value;

  return isInstance;
}

export function PricingFlexibleCreateRequestPricingCategoryPricesInnerFromJSON(
  json: any
): PricingFlexibleCreateRequestPricingCategoryPricesInner {
  return PricingFlexibleCreateRequestPricingCategoryPricesInnerFromJSONTyped(json, false);
}

export function PricingFlexibleCreateRequestPricingCategoryPricesInnerFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): PricingFlexibleCreateRequestPricingCategoryPricesInner {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    pricing_category_id: json['pricing_category_id'],
    value: json['value']
  };
}

export function PricingFlexibleCreateRequestPricingCategoryPricesInnerToJSON(
  value?: PricingFlexibleCreateRequestPricingCategoryPricesInner | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    pricing_category_id: value.pricing_category_id,
    value: value.value
  };
}
