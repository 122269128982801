/* tslint:disable */
/* eslint-disable */
/**
 * Ticketing Portal
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { DataTableFiltersCreateRequestFilterCriteria } from './DataTableFiltersCreateRequestFilterCriteria';
import {
  DataTableFiltersCreateRequestFilterCriteriaFromJSON,
  DataTableFiltersCreateRequestFilterCriteriaFromJSONTyped,
  DataTableFiltersCreateRequestFilterCriteriaToJSON
} from './DataTableFiltersCreateRequestFilterCriteria';

/**
 *
 * @export
 * @interface DataTableFiltersCreateResponseFilter
 */
export interface DataTableFiltersCreateResponseFilter {
  /**
   *
   * @type {string}
   * @memberof DataTableFiltersCreateResponseFilter
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof DataTableFiltersCreateResponseFilter
   */
  filter_name: string;
  /**
   *
   * @type {DataTableFiltersCreateRequestFilterCriteria}
   * @memberof DataTableFiltersCreateResponseFilter
   */
  filter_criteria: DataTableFiltersCreateRequestFilterCriteria;
}

/**
 * Check if a given object implements the DataTableFiltersCreateResponseFilter interface.
 */
export function instanceOfDataTableFiltersCreateResponseFilter(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && 'id' in value;
  isInstance = isInstance && 'filter_name' in value;
  isInstance = isInstance && 'filter_criteria' in value;

  return isInstance;
}

export function DataTableFiltersCreateResponseFilterFromJSON(
  json: any
): DataTableFiltersCreateResponseFilter {
  return DataTableFiltersCreateResponseFilterFromJSONTyped(json, false);
}

export function DataTableFiltersCreateResponseFilterFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): DataTableFiltersCreateResponseFilter {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: json['id'],
    filter_name: json['filter_name'],
    filter_criteria: DataTableFiltersCreateRequestFilterCriteriaFromJSON(json['filter_criteria'])
  };
}

export function DataTableFiltersCreateResponseFilterToJSON(
  value?: DataTableFiltersCreateResponseFilter | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    id: value.id,
    filter_name: value.filter_name,
    filter_criteria: DataTableFiltersCreateRequestFilterCriteriaToJSON(value.filter_criteria)
  };
}
