/* tslint:disable */
/* eslint-disable */
/**
 * Ticketing Portal
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { PayoutDetailsByPayoutIdReadResponseMeta } from './PayoutDetailsByPayoutIdReadResponseMeta';
import {
  PayoutDetailsByPayoutIdReadResponseMetaFromJSON,
  PayoutDetailsByPayoutIdReadResponseMetaFromJSONTyped,
  PayoutDetailsByPayoutIdReadResponseMetaToJSON
} from './PayoutDetailsByPayoutIdReadResponseMeta';
import type { PayoutDetailsByPayoutIdReadResponsePayoutDetails } from './PayoutDetailsByPayoutIdReadResponsePayoutDetails';
import {
  PayoutDetailsByPayoutIdReadResponsePayoutDetailsFromJSON,
  PayoutDetailsByPayoutIdReadResponsePayoutDetailsFromJSONTyped,
  PayoutDetailsByPayoutIdReadResponsePayoutDetailsToJSON
} from './PayoutDetailsByPayoutIdReadResponsePayoutDetails';

/**
 *
 * @export
 * @interface PayoutDetailsByPayoutIdReadResponse
 */
export interface PayoutDetailsByPayoutIdReadResponse {
  /**
   *
   * @type {boolean}
   * @memberof PayoutDetailsByPayoutIdReadResponse
   */
  success: boolean;
  /**
   *
   * @type {string}
   * @memberof PayoutDetailsByPayoutIdReadResponse
   */
  exception_code?: string;
  /**
   *
   * @type {string}
   * @memberof PayoutDetailsByPayoutIdReadResponse
   */
  exception_message?: string;
  /**
   *
   * @type {PayoutDetailsByPayoutIdReadResponseMeta}
   * @memberof PayoutDetailsByPayoutIdReadResponse
   */
  meta: PayoutDetailsByPayoutIdReadResponseMeta;
  /**
   *
   * @type {PayoutDetailsByPayoutIdReadResponsePayoutDetails}
   * @memberof PayoutDetailsByPayoutIdReadResponse
   */
  payout_details: PayoutDetailsByPayoutIdReadResponsePayoutDetails;
}

/**
 * Check if a given object implements the PayoutDetailsByPayoutIdReadResponse interface.
 */
export function instanceOfPayoutDetailsByPayoutIdReadResponse(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && 'success' in value;
  isInstance = isInstance && 'meta' in value;
  isInstance = isInstance && 'payout_details' in value;

  return isInstance;
}

export function PayoutDetailsByPayoutIdReadResponseFromJSON(
  json: any
): PayoutDetailsByPayoutIdReadResponse {
  return PayoutDetailsByPayoutIdReadResponseFromJSONTyped(json, false);
}

export function PayoutDetailsByPayoutIdReadResponseFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): PayoutDetailsByPayoutIdReadResponse {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    success: json['success'],
    exception_code: !exists(json, 'exception_code') ? undefined : json['exception_code'],
    exception_message: !exists(json, 'exception_message') ? undefined : json['exception_message'],
    meta: PayoutDetailsByPayoutIdReadResponseMetaFromJSON(json['meta']),
    payout_details: PayoutDetailsByPayoutIdReadResponsePayoutDetailsFromJSON(json['payout_details'])
  };
}

export function PayoutDetailsByPayoutIdReadResponseToJSON(
  value?: PayoutDetailsByPayoutIdReadResponse | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    success: value.success,
    exception_code: value.exception_code,
    exception_message: value.exception_message,
    meta: PayoutDetailsByPayoutIdReadResponseMetaToJSON(value.meta),
    payout_details: PayoutDetailsByPayoutIdReadResponsePayoutDetailsToJSON(value.payout_details)
  };
}
