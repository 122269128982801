/* tslint:disable */
/* eslint-disable */
/**
 * Ticketing Portal
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface TimeSlotReadResponseTimeSlotsInner
 */
export interface TimeSlotReadResponseTimeSlotsInner {
  /**
   *
   * @type {string}
   * @memberof TimeSlotReadResponseTimeSlotsInner
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof TimeSlotReadResponseTimeSlotsInner
   */
  status: TimeSlotReadResponseTimeSlotsInnerStatusEnum;
  /**
   *
   * @type {string}
   * @memberof TimeSlotReadResponseTimeSlotsInner
   */
  start_date: string;
  /**
   *
   * @type {string}
   * @memberof TimeSlotReadResponseTimeSlotsInner
   */
  start_time: string;
  /**
   *
   * @type {string}
   * @memberof TimeSlotReadResponseTimeSlotsInner
   */
  end_time: string | null;
  /**
   *
   * @type {string}
   * @memberof TimeSlotReadResponseTimeSlotsInner
   */
  time_slot_type: string;
  /**
   *
   * @type {string}
   * @memberof TimeSlotReadResponseTimeSlotsInner
   */
  date_time: string;
  /**
   *
   * @type {string}
   * @memberof TimeSlotReadResponseTimeSlotsInner
   */
  product_id: string;
  /**
   *
   * @type {string}
   * @memberof TimeSlotReadResponseTimeSlotsInner
   */
  product_name: string;
  /**
   *
   * @type {string}
   * @memberof TimeSlotReadResponseTimeSlotsInner
   */
  pricing_name: string;
  /**
   *
   * @type {number}
   * @memberof TimeSlotReadResponseTimeSlotsInner
   */
  participant_count: number;
  /**
   *
   * @type {number}
   * @memberof TimeSlotReadResponseTimeSlotsInner
   */
  remaining_vacancies: number;
  /**
   *
   * @type {number}
   * @memberof TimeSlotReadResponseTimeSlotsInner
   */
  capacity: number | null;
  /**
   *
   * @type {string}
   * @memberof TimeSlotReadResponseTimeSlotsInner
   */
  product_option_id: string;
  /**
   *
   * @type {string}
   * @memberof TimeSlotReadResponseTimeSlotsInner
   */
  product_option_name: string;
}

/**
 * @export
 */
export const TimeSlotReadResponseTimeSlotsInnerStatusEnum = {
  Active: 'active',
  Deleted: 'deleted',
  NotBookable: 'not_bookable'
} as const;
export type TimeSlotReadResponseTimeSlotsInnerStatusEnum =
  (typeof TimeSlotReadResponseTimeSlotsInnerStatusEnum)[keyof typeof TimeSlotReadResponseTimeSlotsInnerStatusEnum];

/**
 * Check if a given object implements the TimeSlotReadResponseTimeSlotsInner interface.
 */
export function instanceOfTimeSlotReadResponseTimeSlotsInner(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && 'id' in value;
  isInstance = isInstance && 'status' in value;
  isInstance = isInstance && 'start_date' in value;
  isInstance = isInstance && 'start_time' in value;
  isInstance = isInstance && 'end_time' in value;
  isInstance = isInstance && 'time_slot_type' in value;
  isInstance = isInstance && 'date_time' in value;
  isInstance = isInstance && 'product_id' in value;
  isInstance = isInstance && 'product_name' in value;
  isInstance = isInstance && 'pricing_name' in value;
  isInstance = isInstance && 'participant_count' in value;
  isInstance = isInstance && 'remaining_vacancies' in value;
  isInstance = isInstance && 'capacity' in value;
  isInstance = isInstance && 'product_option_id' in value;
  isInstance = isInstance && 'product_option_name' in value;

  return isInstance;
}

export function TimeSlotReadResponseTimeSlotsInnerFromJSON(
  json: any
): TimeSlotReadResponseTimeSlotsInner {
  return TimeSlotReadResponseTimeSlotsInnerFromJSONTyped(json, false);
}

export function TimeSlotReadResponseTimeSlotsInnerFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): TimeSlotReadResponseTimeSlotsInner {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: json['id'],
    status: json['status'],
    start_date: json['start_date'],
    start_time: json['start_time'],
    end_time: json['end_time'],
    time_slot_type: json['time_slot_type'],
    date_time: json['date_time'],
    product_id: json['product_id'],
    product_name: json['product_name'],
    pricing_name: json['pricing_name'],
    participant_count: json['participant_count'],
    remaining_vacancies: json['remaining_vacancies'],
    capacity: json['capacity'],
    product_option_id: json['product_option_id'],
    product_option_name: json['product_option_name']
  };
}

export function TimeSlotReadResponseTimeSlotsInnerToJSON(
  value?: TimeSlotReadResponseTimeSlotsInner | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    id: value.id,
    status: value.status,
    start_date: value.start_date,
    start_time: value.start_time,
    end_time: value.end_time,
    time_slot_type: value.time_slot_type,
    date_time: value.date_time,
    product_id: value.product_id,
    product_name: value.product_name,
    pricing_name: value.pricing_name,
    participant_count: value.participant_count,
    remaining_vacancies: value.remaining_vacancies,
    capacity: value.capacity,
    product_option_id: value.product_option_id,
    product_option_name: value.product_option_name
  };
}
