/* tslint:disable */
/* eslint-disable */
/**
 * Ticketing Portal
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from '../runtime';
import type {
  BookingsCountReadResponse,
  BookingsRevenueGraphReadResponse,
  BookingsRevenueReadResponse,
  PayoutByIdReadResponse,
  PayoutDetailsByPayoutIdReadRequest,
  PayoutDetailsByPayoutIdReadResponse,
  PayoutDetailsDownloadRequest,
  PayoutDetailsRefundsByPayoutIdReadRequest,
  PayoutDetailsRefundsByPayoutIdReadResponse,
  PayoutDetailsRefundsDownloadRequest,
  PayoutsDataTableReadRequest,
  PayoutsDataTableReadResponse,
  PayoutsReadResponse
} from '../models';
import {
  BookingsCountReadResponseFromJSON,
  BookingsCountReadResponseToJSON,
  BookingsRevenueGraphReadResponseFromJSON,
  BookingsRevenueGraphReadResponseToJSON,
  BookingsRevenueReadResponseFromJSON,
  BookingsRevenueReadResponseToJSON,
  PayoutByIdReadResponseFromJSON,
  PayoutByIdReadResponseToJSON,
  PayoutDetailsByPayoutIdReadRequestFromJSON,
  PayoutDetailsByPayoutIdReadRequestToJSON,
  PayoutDetailsByPayoutIdReadResponseFromJSON,
  PayoutDetailsByPayoutIdReadResponseToJSON,
  PayoutDetailsDownloadRequestFromJSON,
  PayoutDetailsDownloadRequestToJSON,
  PayoutDetailsRefundsByPayoutIdReadRequestFromJSON,
  PayoutDetailsRefundsByPayoutIdReadRequestToJSON,
  PayoutDetailsRefundsByPayoutIdReadResponseFromJSON,
  PayoutDetailsRefundsByPayoutIdReadResponseToJSON,
  PayoutDetailsRefundsDownloadRequestFromJSON,
  PayoutDetailsRefundsDownloadRequestToJSON,
  PayoutsDataTableReadRequestFromJSON,
  PayoutsDataTableReadRequestToJSON,
  PayoutsDataTableReadResponseFromJSON,
  PayoutsDataTableReadResponseToJSON,
  PayoutsReadResponseFromJSON,
  PayoutsReadResponseToJSON
} from '../models';

export interface ReportingBookingsCountGetRequest {
  periods: Array<string>;
  localeCode?: string;
}

export interface ReportingBookingsGraphGetRequest {
  range: Array<string>;
  periodType: string;
  localeCode?: string;
}

export interface ReportingBookingsRevenueGetRequest {
  periods: Array<number>;
  periodType: string;
  localeCode?: string;
}

export interface ReportingPayoutsGetRequest {
  localeCode?: string;
}

export interface ReportingPayoutsPayoutIdDetailsDownloadPostRequest {
  payoutId: string;
  payoutDetailsDownloadRequest?: PayoutDetailsDownloadRequest;
}

export interface ReportingPayoutsPayoutIdDetailsPostRequest {
  payoutId: string;
  payoutDetailsByPayoutIdReadRequest?: PayoutDetailsByPayoutIdReadRequest;
}

export interface ReportingPayoutsPayoutIdGetRequest {
  payoutId: string;
  localeCode?: string;
}

export interface ReportingPayoutsPayoutIdRefundsDownloadPostRequest {
  payoutId: string;
  payoutDetailsRefundsDownloadRequest?: PayoutDetailsRefundsDownloadRequest;
}

export interface ReportingPayoutsPayoutIdRefundsPostRequest {
  payoutId: string;
  payoutDetailsRefundsByPayoutIdReadRequest?: PayoutDetailsRefundsByPayoutIdReadRequest;
}

export interface ReportingPayoutsPostRequest {
  payoutsDataTableReadRequest?: PayoutsDataTableReadRequest;
}

/**
 *
 */
export class ReportingApi extends runtime.BaseAPI {
  /**
   *      This endpoint provides a report on the total count of bookings for specified periods.     It returns the count of bookings for the current period and the previous period.     The periods are defined by the number of days from the current date, and multiple periods can be requested.     For example, periods could be specified as [7, 30, 90] for weekly, monthly, and quarterly reports.
   * Get Bookings count
   */
  async reportingBookingsCountGetRaw(
    requestParameters: ReportingBookingsCountGetRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<BookingsCountReadResponse>> {
    if (requestParameters.periods === null || requestParameters.periods === undefined) {
      throw new runtime.RequiredError(
        'periods',
        'Required parameter requestParameters.periods was null or undefined when calling reportingBookingsCountGet.'
      );
    }

    const queryParameters: any = {};

    if (requestParameters.periods) {
      queryParameters['periods'] = requestParameters.periods;
    }

    if (requestParameters.localeCode !== undefined) {
      queryParameters['locale_code'] = requestParameters.localeCode;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request(
      {
        path: `/reporting/bookings/count`,
        method: 'GET',
        headers: headerParameters,
        query: queryParameters
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      BookingsCountReadResponseFromJSON(jsonValue)
    );
  }

  /**
   *      This endpoint provides a report on the total count of bookings for specified periods.     It returns the count of bookings for the current period and the previous period.     The periods are defined by the number of days from the current date, and multiple periods can be requested.     For example, periods could be specified as [7, 30, 90] for weekly, monthly, and quarterly reports.
   * Get Bookings count
   */
  async reportingBookingsCountGet(
    requestParameters: ReportingBookingsCountGetRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<BookingsCountReadResponse> {
    const response = await this.reportingBookingsCountGetRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   *      Get Reporting Bookings revenue graph
   * Get Bookings revenue graph
   */
  async reportingBookingsGraphGetRaw(
    requestParameters: ReportingBookingsGraphGetRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<BookingsRevenueGraphReadResponse>> {
    if (requestParameters.range === null || requestParameters.range === undefined) {
      throw new runtime.RequiredError(
        'range',
        'Required parameter requestParameters.range was null or undefined when calling reportingBookingsGraphGet.'
      );
    }

    if (requestParameters.periodType === null || requestParameters.periodType === undefined) {
      throw new runtime.RequiredError(
        'periodType',
        'Required parameter requestParameters.periodType was null or undefined when calling reportingBookingsGraphGet.'
      );
    }

    const queryParameters: any = {};

    if (requestParameters.range) {
      queryParameters['range'] = requestParameters.range;
    }

    if (requestParameters.localeCode !== undefined) {
      queryParameters['locale_code'] = requestParameters.localeCode;
    }

    if (requestParameters.periodType !== undefined) {
      queryParameters['period_type'] = requestParameters.periodType;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request(
      {
        path: `/reporting/bookings/graph`,
        method: 'GET',
        headers: headerParameters,
        query: queryParameters
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      BookingsRevenueGraphReadResponseFromJSON(jsonValue)
    );
  }

  /**
   *      Get Reporting Bookings revenue graph
   * Get Bookings revenue graph
   */
  async reportingBookingsGraphGet(
    requestParameters: ReportingBookingsGraphGetRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<BookingsRevenueGraphReadResponse> {
    const response = await this.reportingBookingsGraphGetRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   *      This endpoint provides a report on the total revenue of bookings for specified periods.     It returns the total revenue of bookings for the current period and the previous period.     The periods are defined by the number of days from the current date, and multiple periods can be requested.     For example, periods could be specified as [7, 30, 90] for weekly, monthly, and quarterly reports.
   * Get Bookings revenue
   */
  async reportingBookingsRevenueGetRaw(
    requestParameters: ReportingBookingsRevenueGetRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<BookingsRevenueReadResponse>> {
    if (requestParameters.periods === null || requestParameters.periods === undefined) {
      throw new runtime.RequiredError(
        'periods',
        'Required parameter requestParameters.periods was null or undefined when calling reportingBookingsRevenueGet.'
      );
    }

    if (requestParameters.periodType === null || requestParameters.periodType === undefined) {
      throw new runtime.RequiredError(
        'periodType',
        'Required parameter requestParameters.periodType was null or undefined when calling reportingBookingsRevenueGet.'
      );
    }

    const queryParameters: any = {};

    if (requestParameters.periods) {
      queryParameters['periods'] = requestParameters.periods;
    }

    if (requestParameters.localeCode !== undefined) {
      queryParameters['locale_code'] = requestParameters.localeCode;
    }

    if (requestParameters.periodType !== undefined) {
      queryParameters['period_type'] = requestParameters.periodType;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request(
      {
        path: `/reporting/bookings/revenue`,
        method: 'GET',
        headers: headerParameters,
        query: queryParameters
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      BookingsRevenueReadResponseFromJSON(jsonValue)
    );
  }

  /**
   *      This endpoint provides a report on the total revenue of bookings for specified periods.     It returns the total revenue of bookings for the current period and the previous period.     The periods are defined by the number of days from the current date, and multiple periods can be requested.     For example, periods could be specified as [7, 30, 90] for weekly, monthly, and quarterly reports.
   * Get Bookings revenue
   */
  async reportingBookingsRevenueGet(
    requestParameters: ReportingBookingsRevenueGetRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<BookingsRevenueReadResponse> {
    const response = await this.reportingBookingsRevenueGetRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   *      This endpoint provides a report on the payouts and deposit held back (current and past).     It returns an array of period objects that include payouts, deposits, returns, TTV and currency.     The period length is given by the payout frequency of the partner, the deposit is recalculated     for every period. Start and end dates of the period will also be returned.     For example, payout frequency could be weekly, monthly, and quarterly. The payout amount,     deposit amount etc. will be in relation to that period.
   * Get Payouts of a Partner
   */
  async reportingPayoutsGetRaw(
    requestParameters: ReportingPayoutsGetRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<PayoutsReadResponse>> {
    const queryParameters: any = {};

    if (requestParameters.localeCode !== undefined) {
      queryParameters['locale_code'] = requestParameters.localeCode;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request(
      {
        path: `/reporting/payouts`,
        method: 'GET',
        headers: headerParameters,
        query: queryParameters
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      PayoutsReadResponseFromJSON(jsonValue)
    );
  }

  /**
   *      This endpoint provides a report on the payouts and deposit held back (current and past).     It returns an array of period objects that include payouts, deposits, returns, TTV and currency.     The period length is given by the payout frequency of the partner, the deposit is recalculated     for every period. Start and end dates of the period will also be returned.     For example, payout frequency could be weekly, monthly, and quarterly. The payout amount,     deposit amount etc. will be in relation to that period.
   * Get Payouts of a Partner
   */
  async reportingPayoutsGet(
    requestParameters: ReportingPayoutsGetRequest = {},
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<PayoutsReadResponse> {
    const response = await this.reportingPayoutsGetRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Download payout details from datatable
   */
  async reportingPayoutsPayoutIdDetailsDownloadPostRaw(
    requestParameters: ReportingPayoutsPayoutIdDetailsDownloadPostRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<Blob>> {
    if (requestParameters.payoutId === null || requestParameters.payoutId === undefined) {
      throw new runtime.RequiredError(
        'payoutId',
        'Required parameter requestParameters.payoutId was null or undefined when calling reportingPayoutsPayoutIdDetailsDownloadPost.'
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    const response = await this.request(
      {
        path: `/reporting/payouts/{payout_id}/details/download`.replace(
          `{${'payout_id'}}`,
          encodeURIComponent(String(requestParameters.payoutId))
        ),
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: PayoutDetailsDownloadRequestToJSON(requestParameters.payoutDetailsDownloadRequest)
      },
      initOverrides
    );

    return new runtime.BlobApiResponse(response);
  }

  /**
   * Download payout details from datatable
   */
  async reportingPayoutsPayoutIdDetailsDownloadPost(
    requestParameters: ReportingPayoutsPayoutIdDetailsDownloadPostRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<Blob> {
    const response = await this.reportingPayoutsPayoutIdDetailsDownloadPostRaw(
      requestParameters,
      initOverrides
    );
    return await response.value();
  }

  /**
   *      Displays the details about a payout.
   * Get payout details by payout id
   */
  async reportingPayoutsPayoutIdDetailsPostRaw(
    requestParameters: ReportingPayoutsPayoutIdDetailsPostRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<PayoutDetailsByPayoutIdReadResponse>> {
    if (requestParameters.payoutId === null || requestParameters.payoutId === undefined) {
      throw new runtime.RequiredError(
        'payoutId',
        'Required parameter requestParameters.payoutId was null or undefined when calling reportingPayoutsPayoutIdDetailsPost.'
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    const response = await this.request(
      {
        path: `/reporting/payouts/{payout_id}/details`.replace(
          `{${'payout_id'}}`,
          encodeURIComponent(String(requestParameters.payoutId))
        ),
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: PayoutDetailsByPayoutIdReadRequestToJSON(
          requestParameters.payoutDetailsByPayoutIdReadRequest
        )
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      PayoutDetailsByPayoutIdReadResponseFromJSON(jsonValue)
    );
  }

  /**
   *      Displays the details about a payout.
   * Get payout details by payout id
   */
  async reportingPayoutsPayoutIdDetailsPost(
    requestParameters: ReportingPayoutsPayoutIdDetailsPostRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<PayoutDetailsByPayoutIdReadResponse> {
    const response = await this.reportingPayoutsPayoutIdDetailsPostRaw(
      requestParameters,
      initOverrides
    );
    return await response.value();
  }

  /**
   *      Displays information about a payout.
   * Get payout by id
   */
  async reportingPayoutsPayoutIdGetRaw(
    requestParameters: ReportingPayoutsPayoutIdGetRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<PayoutByIdReadResponse>> {
    if (requestParameters.payoutId === null || requestParameters.payoutId === undefined) {
      throw new runtime.RequiredError(
        'payoutId',
        'Required parameter requestParameters.payoutId was null or undefined when calling reportingPayoutsPayoutIdGet.'
      );
    }

    const queryParameters: any = {};

    if (requestParameters.localeCode !== undefined) {
      queryParameters['locale_code'] = requestParameters.localeCode;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request(
      {
        path: `/reporting/payouts/{payout_id}`.replace(
          `{${'payout_id'}}`,
          encodeURIComponent(String(requestParameters.payoutId))
        ),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      PayoutByIdReadResponseFromJSON(jsonValue)
    );
  }

  /**
   *      Displays information about a payout.
   * Get payout by id
   */
  async reportingPayoutsPayoutIdGet(
    requestParameters: ReportingPayoutsPayoutIdGetRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<PayoutByIdReadResponse> {
    const response = await this.reportingPayoutsPayoutIdGetRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Download payout details refunds from datatable
   */
  async reportingPayoutsPayoutIdRefundsDownloadPostRaw(
    requestParameters: ReportingPayoutsPayoutIdRefundsDownloadPostRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<Blob>> {
    if (requestParameters.payoutId === null || requestParameters.payoutId === undefined) {
      throw new runtime.RequiredError(
        'payoutId',
        'Required parameter requestParameters.payoutId was null or undefined when calling reportingPayoutsPayoutIdRefundsDownloadPost.'
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    const response = await this.request(
      {
        path: `/reporting/payouts/{payout_id}/refunds/download`.replace(
          `{${'payout_id'}}`,
          encodeURIComponent(String(requestParameters.payoutId))
        ),
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: PayoutDetailsRefundsDownloadRequestToJSON(
          requestParameters.payoutDetailsRefundsDownloadRequest
        )
      },
      initOverrides
    );

    return new runtime.BlobApiResponse(response);
  }

  /**
   * Download payout details refunds from datatable
   */
  async reportingPayoutsPayoutIdRefundsDownloadPost(
    requestParameters: ReportingPayoutsPayoutIdRefundsDownloadPostRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<Blob> {
    const response = await this.reportingPayoutsPayoutIdRefundsDownloadPostRaw(
      requestParameters,
      initOverrides
    );
    return await response.value();
  }

  /**
   *      Displays the details about a payout.
   * Get payout details by payout id
   */
  async reportingPayoutsPayoutIdRefundsPostRaw(
    requestParameters: ReportingPayoutsPayoutIdRefundsPostRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<PayoutDetailsRefundsByPayoutIdReadResponse>> {
    if (requestParameters.payoutId === null || requestParameters.payoutId === undefined) {
      throw new runtime.RequiredError(
        'payoutId',
        'Required parameter requestParameters.payoutId was null or undefined when calling reportingPayoutsPayoutIdRefundsPost.'
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    const response = await this.request(
      {
        path: `/reporting/payouts/{payout_id}/refunds`.replace(
          `{${'payout_id'}}`,
          encodeURIComponent(String(requestParameters.payoutId))
        ),
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: PayoutDetailsRefundsByPayoutIdReadRequestToJSON(
          requestParameters.payoutDetailsRefundsByPayoutIdReadRequest
        )
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      PayoutDetailsRefundsByPayoutIdReadResponseFromJSON(jsonValue)
    );
  }

  /**
   *      Displays the details about a payout.
   * Get payout details by payout id
   */
  async reportingPayoutsPayoutIdRefundsPost(
    requestParameters: ReportingPayoutsPayoutIdRefundsPostRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<PayoutDetailsRefundsByPayoutIdReadResponse> {
    const response = await this.reportingPayoutsPayoutIdRefundsPostRaw(
      requestParameters,
      initOverrides
    );
    return await response.value();
  }

  /**
   *      This endpoint provides a list of payouts for a data table.
   * Get Payouts of a data table
   */
  async reportingPayoutsPostRaw(
    requestParameters: ReportingPayoutsPostRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<PayoutsDataTableReadResponse>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    const response = await this.request(
      {
        path: `/reporting/payouts`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: PayoutsDataTableReadRequestToJSON(requestParameters.payoutsDataTableReadRequest)
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      PayoutsDataTableReadResponseFromJSON(jsonValue)
    );
  }

  /**
   *      This endpoint provides a list of payouts for a data table.
   * Get Payouts of a data table
   */
  async reportingPayoutsPost(
    requestParameters: ReportingPayoutsPostRequest = {},
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<PayoutsDataTableReadResponse> {
    const response = await this.reportingPayoutsPostRaw(requestParameters, initOverrides);
    return await response.value();
  }
}
