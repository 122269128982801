/* tslint:disable */
/* eslint-disable */
/**
 * Ticketing Portal
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { CancellationPolicyDeleteRequestCancellationPolicy } from './CancellationPolicyDeleteRequestCancellationPolicy';
import {
  CancellationPolicyDeleteRequestCancellationPolicyFromJSON,
  CancellationPolicyDeleteRequestCancellationPolicyFromJSONTyped,
  CancellationPolicyDeleteRequestCancellationPolicyToJSON
} from './CancellationPolicyDeleteRequestCancellationPolicy';

/**
 *
 * @export
 * @interface CancellationPolicyDeleteRequest
 */
export interface CancellationPolicyDeleteRequest {
  /**
   *
   * @type {string}
   * @memberof CancellationPolicyDeleteRequest
   */
  locale_code: string;
  /**
   *
   * @type {CancellationPolicyDeleteRequestCancellationPolicy}
   * @memberof CancellationPolicyDeleteRequest
   */
  cancellation_policy: CancellationPolicyDeleteRequestCancellationPolicy;
}

/**
 * Check if a given object implements the CancellationPolicyDeleteRequest interface.
 */
export function instanceOfCancellationPolicyDeleteRequest(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && 'locale_code' in value;
  isInstance = isInstance && 'cancellation_policy' in value;

  return isInstance;
}

export function CancellationPolicyDeleteRequestFromJSON(
  json: any
): CancellationPolicyDeleteRequest {
  return CancellationPolicyDeleteRequestFromJSONTyped(json, false);
}

export function CancellationPolicyDeleteRequestFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): CancellationPolicyDeleteRequest {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    locale_code: json['locale_code'],
    cancellation_policy: CancellationPolicyDeleteRequestCancellationPolicyFromJSON(
      json['cancellation_policy']
    )
  };
}

export function CancellationPolicyDeleteRequestToJSON(
  value?: CancellationPolicyDeleteRequest | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    locale_code: value.locale_code,
    cancellation_policy: CancellationPolicyDeleteRequestCancellationPolicyToJSON(
      value.cancellation_policy
    )
  };
}
