/* tslint:disable */
/* eslint-disable */
/**
 * Ticketing Portal
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { PricingCategoryUpdateRequestPricingCategoryTranslationsValue } from './PricingCategoryUpdateRequestPricingCategoryTranslationsValue';
import {
  PricingCategoryUpdateRequestPricingCategoryTranslationsValueFromJSON,
  PricingCategoryUpdateRequestPricingCategoryTranslationsValueFromJSONTyped,
  PricingCategoryUpdateRequestPricingCategoryTranslationsValueToJSON
} from './PricingCategoryUpdateRequestPricingCategoryTranslationsValue';

/**
 *
 * @export
 * @interface PricingCategoryCreateRequest
 */
export interface PricingCategoryCreateRequest {
  /**
   *
   * @type {string}
   * @memberof PricingCategoryCreateRequest
   */
  locale_code: string;
  /**
   *
   * @type {number}
   * @memberof PricingCategoryCreateRequest
   */
  min_age: number;
  /**
   *
   * @type {number}
   * @memberof PricingCategoryCreateRequest
   */
  max_age: number;
  /**
   * Pricing Category Translations
   * @type {{ [key: string]: PricingCategoryUpdateRequestPricingCategoryTranslationsValue; }}
   * @memberof PricingCategoryCreateRequest
   */
  pricing_category_translations?: {
    [key: string]: PricingCategoryUpdateRequestPricingCategoryTranslationsValue;
  };
  /**
   *
   * @type {number}
   * @memberof PricingCategoryCreateRequest
   */
  occupancy_per_unit: number;
  /**
   *
   * @type {number}
   * @memberof PricingCategoryCreateRequest
   */
  min_participants: number;
  /**
   *
   * @type {number}
   * @memberof PricingCategoryCreateRequest
   */
  max_participants: number | null;
}

/**
 * Check if a given object implements the PricingCategoryCreateRequest interface.
 */
export function instanceOfPricingCategoryCreateRequest(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && 'locale_code' in value;
  isInstance = isInstance && 'min_age' in value;
  isInstance = isInstance && 'max_age' in value;
  isInstance = isInstance && 'occupancy_per_unit' in value;
  isInstance = isInstance && 'min_participants' in value;
  isInstance = isInstance && 'max_participants' in value;

  return isInstance;
}

export function PricingCategoryCreateRequestFromJSON(json: any): PricingCategoryCreateRequest {
  return PricingCategoryCreateRequestFromJSONTyped(json, false);
}

export function PricingCategoryCreateRequestFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): PricingCategoryCreateRequest {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    locale_code: json['locale_code'],
    min_age: json['min_age'],
    max_age: json['max_age'],
    pricing_category_translations: !exists(json, 'pricing_category_translations')
      ? undefined
      : mapValues(
          json['pricing_category_translations'],
          PricingCategoryUpdateRequestPricingCategoryTranslationsValueFromJSON
        ),
    occupancy_per_unit: json['occupancy_per_unit'],
    min_participants: json['min_participants'],
    max_participants: json['max_participants']
  };
}

export function PricingCategoryCreateRequestToJSON(
  value?: PricingCategoryCreateRequest | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    locale_code: value.locale_code,
    min_age: value.min_age,
    max_age: value.max_age,
    pricing_category_translations:
      value.pricing_category_translations === undefined
        ? undefined
        : mapValues(
            value.pricing_category_translations,
            PricingCategoryUpdateRequestPricingCategoryTranslationsValueToJSON
          ),
    occupancy_per_unit: value.occupancy_per_unit,
    min_participants: value.min_participants,
    max_participants: value.max_participants
  };
}
