/* tslint:disable */
/* eslint-disable */
/**
 * Ticketing Portal
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { PayoutDetailsBooking } from './PayoutDetailsBooking';
import {
  PayoutDetailsBookingFromJSON,
  PayoutDetailsBookingFromJSONTyped,
  PayoutDetailsBookingToJSON
} from './PayoutDetailsBooking';

/**
 *
 * @export
 * @interface PayoutDetailsByPayoutIdReadResponsePayoutDetails
 */
export interface PayoutDetailsByPayoutIdReadResponsePayoutDetails {
  /**
   *
   * @type {Array<PayoutDetailsBooking>}
   * @memberof PayoutDetailsByPayoutIdReadResponsePayoutDetails
   */
  shopping_carts?: Array<PayoutDetailsBooking>;
}

/**
 * Check if a given object implements the PayoutDetailsByPayoutIdReadResponsePayoutDetails interface.
 */
export function instanceOfPayoutDetailsByPayoutIdReadResponsePayoutDetails(value: object): boolean {
  let isInstance = true;

  return isInstance;
}

export function PayoutDetailsByPayoutIdReadResponsePayoutDetailsFromJSON(
  json: any
): PayoutDetailsByPayoutIdReadResponsePayoutDetails {
  return PayoutDetailsByPayoutIdReadResponsePayoutDetailsFromJSONTyped(json, false);
}

export function PayoutDetailsByPayoutIdReadResponsePayoutDetailsFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): PayoutDetailsByPayoutIdReadResponsePayoutDetails {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    shopping_carts: !exists(json, 'shopping_carts')
      ? undefined
      : (json['shopping_carts'] as Array<any>).map(PayoutDetailsBookingFromJSON)
  };
}

export function PayoutDetailsByPayoutIdReadResponsePayoutDetailsToJSON(
  value?: PayoutDetailsByPayoutIdReadResponsePayoutDetails | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    shopping_carts:
      value.shopping_carts === undefined
        ? undefined
        : (value.shopping_carts as Array<any>).map(PayoutDetailsBookingToJSON)
  };
}
