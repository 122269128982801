/* tslint:disable */
/* eslint-disable */
/**
 * Ticketing Portal
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { GiftCodeReadResponseGiftCodesInner } from './GiftCodeReadResponseGiftCodesInner';
import {
  GiftCodeReadResponseGiftCodesInnerFromJSON,
  GiftCodeReadResponseGiftCodesInnerFromJSONTyped,
  GiftCodeReadResponseGiftCodesInnerToJSON
} from './GiftCodeReadResponseGiftCodesInner';
import type { GiftCodeReadResponseMeta } from './GiftCodeReadResponseMeta';
import {
  GiftCodeReadResponseMetaFromJSON,
  GiftCodeReadResponseMetaFromJSONTyped,
  GiftCodeReadResponseMetaToJSON
} from './GiftCodeReadResponseMeta';

/**
 *
 * @export
 * @interface GiftCodeReadResponse
 */
export interface GiftCodeReadResponse {
  /**
   *
   * @type {boolean}
   * @memberof GiftCodeReadResponse
   */
  success: boolean;
  /**
   *
   * @type {string}
   * @memberof GiftCodeReadResponse
   */
  exception_code?: string;
  /**
   *
   * @type {string}
   * @memberof GiftCodeReadResponse
   */
  exception_message?: string;
  /**
   *
   * @type {Array<GiftCodeReadResponseGiftCodesInner>}
   * @memberof GiftCodeReadResponse
   */
  gift_codes: Array<GiftCodeReadResponseGiftCodesInner>;
  /**
   *
   * @type {GiftCodeReadResponseMeta}
   * @memberof GiftCodeReadResponse
   */
  meta: GiftCodeReadResponseMeta;
}

/**
 * Check if a given object implements the GiftCodeReadResponse interface.
 */
export function instanceOfGiftCodeReadResponse(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && 'success' in value;
  isInstance = isInstance && 'gift_codes' in value;
  isInstance = isInstance && 'meta' in value;

  return isInstance;
}

export function GiftCodeReadResponseFromJSON(json: any): GiftCodeReadResponse {
  return GiftCodeReadResponseFromJSONTyped(json, false);
}

export function GiftCodeReadResponseFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): GiftCodeReadResponse {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    success: json['success'],
    exception_code: !exists(json, 'exception_code') ? undefined : json['exception_code'],
    exception_message: !exists(json, 'exception_message') ? undefined : json['exception_message'],
    gift_codes: (json['gift_codes'] as Array<any>).map(GiftCodeReadResponseGiftCodesInnerFromJSON),
    meta: GiftCodeReadResponseMetaFromJSON(json['meta'])
  };
}

export function GiftCodeReadResponseToJSON(value?: GiftCodeReadResponse | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    success: value.success,
    exception_code: value.exception_code,
    exception_message: value.exception_message,
    gift_codes: (value.gift_codes as Array<any>).map(GiftCodeReadResponseGiftCodesInnerToJSON),
    meta: GiftCodeReadResponseMetaToJSON(value.meta)
  };
}
