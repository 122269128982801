/* tslint:disable */
/* eslint-disable */
/**
 * Ticketing Portal
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * Product ID or SELECT_ALL_ITEMS
 * @export
 * @interface EmailTriggerProductId
 */
export interface EmailTriggerProductId {}

/**
 * Check if a given object implements the EmailTriggerProductId interface.
 */
export function instanceOfEmailTriggerProductId(value: object): boolean {
  let isInstance = true;

  return isInstance;
}

export function EmailTriggerProductIdFromJSON(json: any): EmailTriggerProductId {
  return EmailTriggerProductIdFromJSONTyped(json, false);
}

export function EmailTriggerProductIdFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): EmailTriggerProductId {
  return json;
}

export function EmailTriggerProductIdToJSON(value?: EmailTriggerProductId | null): any {
  return value;
}
