/* tslint:disable */
/* eslint-disable */
/**
 * Ticketing Portal
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface RegisterCreateRequest
 */
export interface RegisterCreateRequest {
  /**
   *
   * @type {string}
   * @memberof RegisterCreateRequest
   */
  locale_code: string;
  /**
   *
   * @type {string}
   * @memberof RegisterCreateRequest
   */
  first_name: string;
  /**
   *
   * @type {string}
   * @memberof RegisterCreateRequest
   */
  last_name: string;
  /**
   *
   * @type {string}
   * @memberof RegisterCreateRequest
   */
  email: string;
  /**
   *
   * @type {string}
   * @memberof RegisterCreateRequest
   */
  password: string;
  /**
   *
   * @type {string}
   * @memberof RegisterCreateRequest
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof RegisterCreateRequest
   */
  phone_nr: string;
  /**
   *
   * @type {string}
   * @memberof RegisterCreateRequest
   */
  phone_nr_public?: string;
  /**
   *
   * @type {string}
   * @memberof RegisterCreateRequest
   */
  backlink_url: string;
  /**
   *
   * @type {string}
   * @memberof RegisterCreateRequest
   */
  default_locale: string;
  /**
   *
   * @type {string}
   * @memberof RegisterCreateRequest
   */
  default_timezone: string;
  /**
   *
   * @type {string}
   * @memberof RegisterCreateRequest
   */
  country_code: string;
  /**
   *
   * @type {string}
   * @memberof RegisterCreateRequest
   */
  currency: string;
  /**
   *
   * @type {string}
   * @memberof RegisterCreateRequest
   */
  street: string;
  /**
   *
   * @type {string}
   * @memberof RegisterCreateRequest
   */
  zip: string;
  /**
   *
   * @type {string}
   * @memberof RegisterCreateRequest
   */
  city: string;
  /**
   *
   * @type {string}
   * @memberof RegisterCreateRequest
   */
  state: string;
  /**
   *
   * @type {string}
   * @memberof RegisterCreateRequest
   */
  country: string;
}

/**
 * Check if a given object implements the RegisterCreateRequest interface.
 */
export function instanceOfRegisterCreateRequest(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && 'locale_code' in value;
  isInstance = isInstance && 'first_name' in value;
  isInstance = isInstance && 'last_name' in value;
  isInstance = isInstance && 'email' in value;
  isInstance = isInstance && 'password' in value;
  isInstance = isInstance && 'name' in value;
  isInstance = isInstance && 'phone_nr' in value;
  isInstance = isInstance && 'backlink_url' in value;
  isInstance = isInstance && 'default_locale' in value;
  isInstance = isInstance && 'default_timezone' in value;
  isInstance = isInstance && 'country_code' in value;
  isInstance = isInstance && 'currency' in value;
  isInstance = isInstance && 'street' in value;
  isInstance = isInstance && 'zip' in value;
  isInstance = isInstance && 'city' in value;
  isInstance = isInstance && 'state' in value;
  isInstance = isInstance && 'country' in value;

  return isInstance;
}

export function RegisterCreateRequestFromJSON(json: any): RegisterCreateRequest {
  return RegisterCreateRequestFromJSONTyped(json, false);
}

export function RegisterCreateRequestFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): RegisterCreateRequest {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    locale_code: json['locale_code'],
    first_name: json['first_name'],
    last_name: json['last_name'],
    email: json['email'],
    password: json['password'],
    name: json['name'],
    phone_nr: json['phone_nr'],
    phone_nr_public: !exists(json, 'phone_nr_public') ? undefined : json['phone_nr_public'],
    backlink_url: json['backlink_url'],
    default_locale: json['default_locale'],
    default_timezone: json['default_timezone'],
    country_code: json['country_code'],
    currency: json['currency'],
    street: json['street'],
    zip: json['zip'],
    city: json['city'],
    state: json['state'],
    country: json['country']
  };
}

export function RegisterCreateRequestToJSON(value?: RegisterCreateRequest | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    locale_code: value.locale_code,
    first_name: value.first_name,
    last_name: value.last_name,
    email: value.email,
    password: value.password,
    name: value.name,
    phone_nr: value.phone_nr,
    phone_nr_public: value.phone_nr_public,
    backlink_url: value.backlink_url,
    default_locale: value.default_locale,
    default_timezone: value.default_timezone,
    country_code: value.country_code,
    currency: value.currency,
    street: value.street,
    zip: value.zip,
    city: value.city,
    state: value.state,
    country: value.country
  };
}
