/* tslint:disable */
/* eslint-disable */
/**
 * Ticketing Portal
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { PromoCodeUsagesReadResponsePromoCodeUsesInnerBookingsInner } from './PromoCodeUsagesReadResponsePromoCodeUsesInnerBookingsInner';
import {
  PromoCodeUsagesReadResponsePromoCodeUsesInnerBookingsInnerFromJSON,
  PromoCodeUsagesReadResponsePromoCodeUsesInnerBookingsInnerFromJSONTyped,
  PromoCodeUsagesReadResponsePromoCodeUsesInnerBookingsInnerToJSON
} from './PromoCodeUsagesReadResponsePromoCodeUsesInnerBookingsInner';

/**
 *
 * @export
 * @interface PromoCodeUsagesReadResponsePromoCodeUsesInner
 */
export interface PromoCodeUsagesReadResponsePromoCodeUsesInner {
  /**
   *
   * @type {string}
   * @memberof PromoCodeUsagesReadResponsePromoCodeUsesInner
   */
  created_at: string;
  /**
   *
   * @type {string}
   * @memberof PromoCodeUsagesReadResponsePromoCodeUsesInner
   */
  partner_total_price_pre_promo: string;
  /**
   *
   * @type {string}
   * @memberof PromoCodeUsagesReadResponsePromoCodeUsesInner
   */
  partner_promo_cost: string;
  /**
   *
   * @type {Array<PromoCodeUsagesReadResponsePromoCodeUsesInnerBookingsInner>}
   * @memberof PromoCodeUsagesReadResponsePromoCodeUsesInner
   */
  bookings: Array<PromoCodeUsagesReadResponsePromoCodeUsesInnerBookingsInner>;
}

/**
 * Check if a given object implements the PromoCodeUsagesReadResponsePromoCodeUsesInner interface.
 */
export function instanceOfPromoCodeUsagesReadResponsePromoCodeUsesInner(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && 'created_at' in value;
  isInstance = isInstance && 'partner_total_price_pre_promo' in value;
  isInstance = isInstance && 'partner_promo_cost' in value;
  isInstance = isInstance && 'bookings' in value;

  return isInstance;
}

export function PromoCodeUsagesReadResponsePromoCodeUsesInnerFromJSON(
  json: any
): PromoCodeUsagesReadResponsePromoCodeUsesInner {
  return PromoCodeUsagesReadResponsePromoCodeUsesInnerFromJSONTyped(json, false);
}

export function PromoCodeUsagesReadResponsePromoCodeUsesInnerFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): PromoCodeUsagesReadResponsePromoCodeUsesInner {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    created_at: json['created_at'],
    partner_total_price_pre_promo: json['partner_total_price_pre_promo'],
    partner_promo_cost: json['partner_promo_cost'],
    bookings: (json['bookings'] as Array<any>).map(
      PromoCodeUsagesReadResponsePromoCodeUsesInnerBookingsInnerFromJSON
    )
  };
}

export function PromoCodeUsagesReadResponsePromoCodeUsesInnerToJSON(
  value?: PromoCodeUsagesReadResponsePromoCodeUsesInner | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    created_at: value.created_at,
    partner_total_price_pre_promo: value.partner_total_price_pre_promo,
    partner_promo_cost: value.partner_promo_cost,
    bookings: (value.bookings as Array<any>).map(
      PromoCodeUsagesReadResponsePromoCodeUsesInnerBookingsInnerToJSON
    )
  };
}
