import { ROUTE_NAMES } from '@/constants/routes';
import { PAGE_TITLES } from '@/constants/breadcrumbs';
import { InitializationReadResponsePermissionsEnum } from '@openapi/models/InitializationReadResponse';

export const PRODUCT_ROUTES = [
  {
    path: 'sell/products',
    name: ROUTE_NAMES.PRODUCTS,
    component: () => import('@/views/pages/sell/products/Products.vue'),
    meta: {
      breadcrumbs: [PAGE_TITLES.SELL, PAGE_TITLES.PRODUCTS],
      label: PAGE_TITLES.PRODUCTS,
      icon: 'pi pi-fw pi-sitemap',
      menu: ['main'],
      permissions: [InitializationReadResponsePermissionsEnum.ProductView]
    }
  },
  {
    path: 'sell/products/:productId',
    name: ROUTE_NAMES.EDIT_PRODUCT,
    component: () => import('@/views/pages/sell/products/Product.vue'),
    meta: {
      breadcrumbs: [PAGE_TITLES.SELL, PAGE_TITLES.PRODUCTS, PAGE_TITLES.EDIT_PRODUCT]
    }
  }
];
