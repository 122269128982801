/* tslint:disable */
/* eslint-disable */
/**
 * Ticketing Portal
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { TicketByCodeReadResponseTicketParticipantValiditiesInnerPricingCategory } from './TicketByCodeReadResponseTicketParticipantValiditiesInnerPricingCategory';
import {
  TicketByCodeReadResponseTicketParticipantValiditiesInnerPricingCategoryFromJSON,
  TicketByCodeReadResponseTicketParticipantValiditiesInnerPricingCategoryFromJSONTyped,
  TicketByCodeReadResponseTicketParticipantValiditiesInnerPricingCategoryToJSON
} from './TicketByCodeReadResponseTicketParticipantValiditiesInnerPricingCategory';

/**
 *
 * @export
 * @interface TicketByCodeReadResponseTicketParticipantValiditiesInner
 */
export interface TicketByCodeReadResponseTicketParticipantValiditiesInner {
  /**
   *
   * @type {string}
   * @memberof TicketByCodeReadResponseTicketParticipantValiditiesInner
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof TicketByCodeReadResponseTicketParticipantValiditiesInner
   */
  valid_from: string;
  /**
   *
   * @type {string}
   * @memberof TicketByCodeReadResponseTicketParticipantValiditiesInner
   */
  valid_until: string;
  /**
   *
   * @type {number}
   * @memberof TicketByCodeReadResponseTicketParticipantValiditiesInner
   */
  original_num_validations: number;
  /**
   *
   * @type {number}
   * @memberof TicketByCodeReadResponseTicketParticipantValiditiesInner
   */
  remaining_num_validations: number;
  /**
   *
   * @type {boolean}
   * @memberof TicketByCodeReadResponseTicketParticipantValiditiesInner
   */
  reedemable_now: boolean;
  /**
   *
   * @type {TicketByCodeReadResponseTicketParticipantValiditiesInnerPricingCategory}
   * @memberof TicketByCodeReadResponseTicketParticipantValiditiesInner
   */
  pricing_category: TicketByCodeReadResponseTicketParticipantValiditiesInnerPricingCategory;
}

/**
 * Check if a given object implements the TicketByCodeReadResponseTicketParticipantValiditiesInner interface.
 */
export function instanceOfTicketByCodeReadResponseTicketParticipantValiditiesInner(
  value: object
): boolean {
  let isInstance = true;
  isInstance = isInstance && 'id' in value;
  isInstance = isInstance && 'valid_from' in value;
  isInstance = isInstance && 'valid_until' in value;
  isInstance = isInstance && 'original_num_validations' in value;
  isInstance = isInstance && 'remaining_num_validations' in value;
  isInstance = isInstance && 'reedemable_now' in value;
  isInstance = isInstance && 'pricing_category' in value;

  return isInstance;
}

export function TicketByCodeReadResponseTicketParticipantValiditiesInnerFromJSON(
  json: any
): TicketByCodeReadResponseTicketParticipantValiditiesInner {
  return TicketByCodeReadResponseTicketParticipantValiditiesInnerFromJSONTyped(json, false);
}

export function TicketByCodeReadResponseTicketParticipantValiditiesInnerFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): TicketByCodeReadResponseTicketParticipantValiditiesInner {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: json['id'],
    valid_from: json['valid_from'],
    valid_until: json['valid_until'],
    original_num_validations: json['original_num_validations'],
    remaining_num_validations: json['remaining_num_validations'],
    reedemable_now: json['reedemable_now'],
    pricing_category:
      TicketByCodeReadResponseTicketParticipantValiditiesInnerPricingCategoryFromJSON(
        json['pricing_category']
      )
  };
}

export function TicketByCodeReadResponseTicketParticipantValiditiesInnerToJSON(
  value?: TicketByCodeReadResponseTicketParticipantValiditiesInner | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    id: value.id,
    valid_from: value.valid_from,
    valid_until: value.valid_until,
    original_num_validations: value.original_num_validations,
    remaining_num_validations: value.remaining_num_validations,
    reedemable_now: value.reedemable_now,
    pricing_category: TicketByCodeReadResponseTicketParticipantValiditiesInnerPricingCategoryToJSON(
      value.pricing_category
    )
  };
}
