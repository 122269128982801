/* tslint:disable */
/* eslint-disable */
/**
 * Ticketing Portal
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { AddonUpdateRequestAddonTranslationsValue } from './AddonUpdateRequestAddonTranslationsValue';
import {
  AddonUpdateRequestAddonTranslationsValueFromJSON,
  AddonUpdateRequestAddonTranslationsValueFromJSONTyped,
  AddonUpdateRequestAddonTranslationsValueToJSON
} from './AddonUpdateRequestAddonTranslationsValue';

/**
 *
 * @export
 * @interface AddonUpdateRequest
 */
export interface AddonUpdateRequest {
  /**
   *
   * @type {string}
   * @memberof AddonUpdateRequest
   */
  locale_code: string;
  /**
   *
   * @type {string}
   * @memberof AddonUpdateRequest
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof AddonUpdateRequest
   */
  added_to: AddonUpdateRequestAddedToEnum;
  /**
   *
   * @type {string}
   * @memberof AddonUpdateRequest
   */
  status: AddonUpdateRequestStatusEnum;
  /**
   *
   * @type {number}
   * @memberof AddonUpdateRequest
   */
  min_quantity: number;
  /**
   *
   * @type {number}
   * @memberof AddonUpdateRequest
   */
  max_quantity: number | null;
  /**
   * Addon Translations
   * @type {{ [key: string]: AddonUpdateRequestAddonTranslationsValue; }}
   * @memberof AddonUpdateRequest
   */
  addon_translations?: { [key: string]: AddonUpdateRequestAddonTranslationsValue };
}

/**
 * @export
 */
export const AddonUpdateRequestAddedToEnum = {
  Booking: 'booking',
  Participant: 'participant'
} as const;
export type AddonUpdateRequestAddedToEnum =
  (typeof AddonUpdateRequestAddedToEnum)[keyof typeof AddonUpdateRequestAddedToEnum];

/**
 * @export
 */
export const AddonUpdateRequestStatusEnum = {
  Active: 'active',
  Inactive: 'inactive',
  Deleted: 'deleted'
} as const;
export type AddonUpdateRequestStatusEnum =
  (typeof AddonUpdateRequestStatusEnum)[keyof typeof AddonUpdateRequestStatusEnum];

/**
 * Check if a given object implements the AddonUpdateRequest interface.
 */
export function instanceOfAddonUpdateRequest(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && 'locale_code' in value;
  isInstance = isInstance && 'name' in value;
  isInstance = isInstance && 'added_to' in value;
  isInstance = isInstance && 'status' in value;
  isInstance = isInstance && 'min_quantity' in value;
  isInstance = isInstance && 'max_quantity' in value;

  return isInstance;
}

export function AddonUpdateRequestFromJSON(json: any): AddonUpdateRequest {
  return AddonUpdateRequestFromJSONTyped(json, false);
}

export function AddonUpdateRequestFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): AddonUpdateRequest {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    locale_code: json['locale_code'],
    name: json['name'],
    added_to: json['added_to'],
    status: json['status'],
    min_quantity: json['min_quantity'],
    max_quantity: json['max_quantity'],
    addon_translations: !exists(json, 'addon_translations')
      ? undefined
      : mapValues(json['addon_translations'], AddonUpdateRequestAddonTranslationsValueFromJSON)
  };
}

export function AddonUpdateRequestToJSON(value?: AddonUpdateRequest | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    locale_code: value.locale_code,
    name: value.name,
    added_to: value.added_to,
    status: value.status,
    min_quantity: value.min_quantity,
    max_quantity: value.max_quantity,
    addon_translations:
      value.addon_translations === undefined
        ? undefined
        : mapValues(value.addon_translations, AddonUpdateRequestAddonTranslationsValueToJSON)
  };
}
