/* tslint:disable */
/* eslint-disable */
/**
 * Ticketing Portal
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface PayoutsDataTableReadResponsePayoutsInner
 */
export interface PayoutsDataTableReadResponsePayoutsInner {
  /**
   * Payout ID
   * @type {string}
   * @memberof PayoutsDataTableReadResponsePayoutsInner
   */
  id: string;
  /**
   * Status of the payout
   * @type {string}
   * @memberof PayoutsDataTableReadResponsePayoutsInner
   */
  status: PayoutsDataTableReadResponsePayoutsInnerStatusEnum;
  /**
   * Start date of the current period in ISO 8601 date format
   * @type {string}
   * @memberof PayoutsDataTableReadResponsePayoutsInner
   */
  start_date: string;
  /**
   * End date of the current period in ISO 8601 date format
   * @type {string}
   * @memberof PayoutsDataTableReadResponsePayoutsInner
   */
  end_date: string;
  /**
   * Amount paid out for the period
   * @type {string}
   * @memberof PayoutsDataTableReadResponsePayoutsInner
   */
  payout_amount: string;
  /**
   * Total revenue transacted in the period by this partner
   * @type {string}
   * @memberof PayoutsDataTableReadResponsePayoutsInner
   */
  total_transacted_revenue_amount: string;
  /**
   * Reference of the payout
   * @type {string}
   * @memberof PayoutsDataTableReadResponsePayoutsInner
   */
  payout_reference: string;
  /**
   * Deposit amount retained for the period
   * @type {string}
   * @memberof PayoutsDataTableReadResponsePayoutsInner
   */
  risk_deposit_amount: string;
  /**
   * Amount of refunds for the period
   * @type {string}
   * @memberof PayoutsDataTableReadResponsePayoutsInner
   */
  returns_amount: string;
}

/**
 * @export
 */
export const PayoutsDataTableReadResponsePayoutsInnerStatusEnum = {
  Requested: 'requested',
  Pending: 'pending',
  TransferSentOut: 'transfer_sent_out',
  Failed: 'failed'
} as const;
export type PayoutsDataTableReadResponsePayoutsInnerStatusEnum =
  (typeof PayoutsDataTableReadResponsePayoutsInnerStatusEnum)[keyof typeof PayoutsDataTableReadResponsePayoutsInnerStatusEnum];

/**
 * Check if a given object implements the PayoutsDataTableReadResponsePayoutsInner interface.
 */
export function instanceOfPayoutsDataTableReadResponsePayoutsInner(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && 'id' in value;
  isInstance = isInstance && 'status' in value;
  isInstance = isInstance && 'start_date' in value;
  isInstance = isInstance && 'end_date' in value;
  isInstance = isInstance && 'payout_amount' in value;
  isInstance = isInstance && 'total_transacted_revenue_amount' in value;
  isInstance = isInstance && 'payout_reference' in value;
  isInstance = isInstance && 'risk_deposit_amount' in value;
  isInstance = isInstance && 'returns_amount' in value;

  return isInstance;
}

export function PayoutsDataTableReadResponsePayoutsInnerFromJSON(
  json: any
): PayoutsDataTableReadResponsePayoutsInner {
  return PayoutsDataTableReadResponsePayoutsInnerFromJSONTyped(json, false);
}

export function PayoutsDataTableReadResponsePayoutsInnerFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): PayoutsDataTableReadResponsePayoutsInner {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: json['id'],
    status: json['status'],
    start_date: json['start_date'],
    end_date: json['end_date'],
    payout_amount: json['payout_amount'],
    total_transacted_revenue_amount: json['total_transacted_revenue_amount'],
    payout_reference: json['payout_reference'],
    risk_deposit_amount: json['risk_deposit_amount'],
    returns_amount: json['returns_amount']
  };
}

export function PayoutsDataTableReadResponsePayoutsInnerToJSON(
  value?: PayoutsDataTableReadResponsePayoutsInner | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    id: value.id,
    status: value.status,
    start_date: value.start_date,
    end_date: value.end_date,
    payout_amount: value.payout_amount,
    total_transacted_revenue_amount: value.total_transacted_revenue_amount,
    payout_reference: value.payout_reference,
    risk_deposit_amount: value.risk_deposit_amount,
    returns_amount: value.returns_amount
  };
}
