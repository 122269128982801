/* tslint:disable */
/* eslint-disable */
/**
 * Ticketing Portal
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface PayoutDetailsRefundsShoppingCartDetails
 */
export interface PayoutDetailsRefundsShoppingCartDetails {
  /**
   *
   * @type {string}
   * @memberof PayoutDetailsRefundsShoppingCartDetails
   */
  checkout_completed_at?: string;
  /**
   *
   * @type {string}
   * @memberof PayoutDetailsRefundsShoppingCartDetails
   */
  refunded_at?: string;
  /**
   *
   * @type {string}
   * @memberof PayoutDetailsRefundsShoppingCartDetails
   */
  hash_code: string;
  /**
   *
   * @type {string}
   * @memberof PayoutDetailsRefundsShoppingCartDetails
   */
  booking_hash_code?: string;
  /**
   *
   * @type {string}
   * @memberof PayoutDetailsRefundsShoppingCartDetails
   */
  price_pre_promo: string;
  /**
   *
   * @type {string}
   * @memberof PayoutDetailsRefundsShoppingCartDetails
   */
  price: string;
  /**
   *
   * @type {string}
   * @memberof PayoutDetailsRefundsShoppingCartDetails
   */
  refund_amount: string;
}

/**
 * Check if a given object implements the PayoutDetailsRefundsShoppingCartDetails interface.
 */
export function instanceOfPayoutDetailsRefundsShoppingCartDetails(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && 'hash_code' in value;
  isInstance = isInstance && 'price_pre_promo' in value;
  isInstance = isInstance && 'price' in value;
  isInstance = isInstance && 'refund_amount' in value;

  return isInstance;
}

export function PayoutDetailsRefundsShoppingCartDetailsFromJSON(
  json: any
): PayoutDetailsRefundsShoppingCartDetails {
  return PayoutDetailsRefundsShoppingCartDetailsFromJSONTyped(json, false);
}

export function PayoutDetailsRefundsShoppingCartDetailsFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): PayoutDetailsRefundsShoppingCartDetails {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    checkout_completed_at: !exists(json, 'checkout_completed_at')
      ? undefined
      : json['checkout_completed_at'],
    refunded_at: !exists(json, 'refunded_at') ? undefined : json['refunded_at'],
    hash_code: json['hash_code'],
    booking_hash_code: !exists(json, 'booking_hash_code') ? undefined : json['booking_hash_code'],
    price_pre_promo: json['price_pre_promo'],
    price: json['price'],
    refund_amount: json['refund_amount']
  };
}

export function PayoutDetailsRefundsShoppingCartDetailsToJSON(
  value?: PayoutDetailsRefundsShoppingCartDetails | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    checkout_completed_at: value.checkout_completed_at,
    refunded_at: value.refunded_at,
    hash_code: value.hash_code,
    booking_hash_code: value.booking_hash_code,
    price_pre_promo: value.price_pre_promo,
    price: value.price,
    refund_amount: value.refund_amount
  };
}
