/* tslint:disable */
/* eslint-disable */
/**
 * Ticketing Portal
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ProductsReadResponseProductsInnerProductOptionsInner } from './ProductsReadResponseProductsInnerProductOptionsInner';
import {
  ProductsReadResponseProductsInnerProductOptionsInnerFromJSON,
  ProductsReadResponseProductsInnerProductOptionsInnerFromJSONTyped,
  ProductsReadResponseProductsInnerProductOptionsInnerToJSON
} from './ProductsReadResponseProductsInnerProductOptionsInner';

/**
 *
 * @export
 * @interface ProductsReadResponseProductsInner
 */
export interface ProductsReadResponseProductsInner {
  /**
   * the product id
   * @type {string}
   * @memberof ProductsReadResponseProductsInner
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof ProductsReadResponseProductsInner
   */
  name?: string;
  /**
   *
   * @type {string}
   * @memberof ProductsReadResponseProductsInner
   */
  status: ProductsReadResponseProductsInnerStatusEnum;
  /**
   *
   * @type {Array<ProductsReadResponseProductsInnerProductOptionsInner>}
   * @memberof ProductsReadResponseProductsInner
   */
  product_options: Array<ProductsReadResponseProductsInnerProductOptionsInner>;
}

/**
 * @export
 */
export const ProductsReadResponseProductsInnerStatusEnum = {
  Active: 'active',
  Deleted: 'deleted',
  Inactive: 'inactive',
  Draft: 'draft'
} as const;
export type ProductsReadResponseProductsInnerStatusEnum =
  (typeof ProductsReadResponseProductsInnerStatusEnum)[keyof typeof ProductsReadResponseProductsInnerStatusEnum];

/**
 * Check if a given object implements the ProductsReadResponseProductsInner interface.
 */
export function instanceOfProductsReadResponseProductsInner(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && 'id' in value;
  isInstance = isInstance && 'status' in value;
  isInstance = isInstance && 'product_options' in value;

  return isInstance;
}

export function ProductsReadResponseProductsInnerFromJSON(
  json: any
): ProductsReadResponseProductsInner {
  return ProductsReadResponseProductsInnerFromJSONTyped(json, false);
}

export function ProductsReadResponseProductsInnerFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): ProductsReadResponseProductsInner {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: json['id'],
    name: !exists(json, 'name') ? undefined : json['name'],
    status: json['status'],
    product_options: (json['product_options'] as Array<any>).map(
      ProductsReadResponseProductsInnerProductOptionsInnerFromJSON
    )
  };
}

export function ProductsReadResponseProductsInnerToJSON(
  value?: ProductsReadResponseProductsInner | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    id: value.id,
    name: value.name,
    status: value.status,
    product_options: (value.product_options as Array<any>).map(
      ProductsReadResponseProductsInnerProductOptionsInnerToJSON
    )
  };
}
