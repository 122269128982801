<template>
  <FieldArray :name="name" :rules="rules">
    <!-- Select Addons -->
    <MultiSelect
      v-model="selectedAddons"
      filter
      display="chip"
      :options="options"
      option-label="name"
      :placeholder="placeholder"
      :disabled="disabled"
      @change="onChange"
    />
    <!-- Default Pricing -->
    <AddonPrices
      v-if="selectedAddons.length"
      :fields="fields"
      :addons="selectedAddons"
      :name="name"
      :disabled="disabled"
    />
    <!-- Later Pricing Scales -->
  </FieldArray>
</template>

<script setup lang="ts">
import { FieldArray, useFieldArray } from 'vee-validate';
import { toRefs, type PropType, computed, ref, watch } from 'vue';
import MultiSelect, { type MultiSelectChangeEvent } from 'primevue/multiselect';
import type {
  AddonsReadResponseAddonsInner,
  PricingByIdReadResponsePricingAddonPricesInner
} from '@openapi/models';
import AddonPrices from '@/components/pricing/AddonPrices.vue';

const props = defineProps({
  name: {
    type: String,
    required: true
  },
  placeholder: {
    type: String,
    required: false,
    default: ''
  },
  rules: {
    type: String,
    required: false,
    default: 'required'
  },
  options: {
    type: Array as PropType<AddonsReadResponseAddonsInner[]>,
    required: true,
    default: () => []
  },
  disabled: {
    type: Boolean,
    required: false,
    default: () => false
  }
});

const { name, placeholder, options, rules } = toRefs(props);
const { replace, fields } = useFieldArray<PricingByIdReadResponsePricingAddonPricesInner>(name);

const selectedAddons = ref<AddonsReadResponseAddonsInner[]>([]);

// Selected addon (during editing)
const selectedAddonsPrices = computed(() => {
  const selected = fields.value.map((field) => field.value.addon_id);
  return options.value.filter((option) => selected.includes(option.id));
});

watch(selectedAddonsPrices, (value) => {
  selectedAddons.value = value;
});

// We need to keep track of the addons that are selected
// and then update the model value with the correct addon id
// We also need to keep the current value if a user has added the price
const onChange = async ({ value: modelValue }: MultiSelectChangeEvent) => {
  const payload = [];

  for (const value of modelValue) {
    let priceValue = 0;
    let id = '';

    // We need to keep the current value if it exists
    const currentValue = fields.value.find((item) => item.value.addon_id === value.id);
    if (currentValue && currentValue.value) {
      priceValue = currentValue.value.value;
    }
    // If updating the id will be present
    if (currentValue && currentValue.value && currentValue.value.id) {
      id = currentValue.value.id;
    }

    payload.push({
      id,
      addon_id: value.id,
      value: priceValue
    });
  }
  replace(payload);
};
</script>
