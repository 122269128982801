<template>
  <Field v-slot="{ value, handleChange }" :name="name" :rules="rules">
    <InputText
      type="datetime-local"
      :disabled="disabled"
      :model-value="value"
      :placeholder="placeholder"
      v-bind="$attrs"
      @update:model-value="handleChange"
    ></InputText>
  </Field>
</template>

<script setup lang="ts">
import { Field } from 'vee-validate';
import { toRefs } from 'vue';
import InputText from 'primevue/inputtext';

const props = defineProps({
  autofocus: {
    type: Boolean,
    required: false,
    default: false
  },
  name: {
    type: String,
    required: true,
    default: ''
  },
  placeholder: {
    type: String,
    required: false,
    default: ''
  },
  rules: {
    type: String,
    required: false,
    default: ''
  },
  disabled: {
    type: Boolean,
    required: false,
    default: () => false
  }
});

const { name, placeholder, rules } = toRefs(props);
</script>
