<template>
  <Field v-slot="{ handleChange, value }" :name="name" :rules="rules">
    <DatePicker
      :model-value="value ? new Date(value) : undefined"
      :placeholder="placeholder"
      date-format="yy-mm-dd"
      show-icon
      :time-only="timeOnly"
      @update:model-value="onChange($event, handleChange)"
    ></DatePicker>
  </Field>
</template>

<script setup lang="ts">
import { Field } from 'vee-validate';
import { toRefs } from 'vue';
import DatePicker from 'primevue/datepicker';
import { formatDate, formatTime } from '@/utils/format';
import { toTemporalInstant } from '@js-temporal/polyfill';

const props = defineProps({
  autofocus: {
    type: Boolean,
    required: false,
    default: false
  },
  name: {
    type: String,
    required: true
  },
  placeholder: {
    type: String,
    required: false,
    default: ''
  },
  rules: {
    type: String,
    required: false,
    default: ''
  },
  timeOnly: {
    type: Boolean,
    required: false,
    default: false
  }
});
const { name, placeholder, rules, timeOnly } = toRefs(props);
const onChange = (value: unknown, handleChange: (value: unknown) => void) => {
  if (!(value instanceof Date)) {
    return;
  }

  Date.prototype.toTemporalInstant = toTemporalInstant;

  let formatted = formatDate(value);
  if (timeOnly.value) {
    formatted = formatTime(value);
  }
  handleChange(formatted);
};
</script>
