/* tslint:disable */
/* eslint-disable */
/**
 * Ticketing Portal
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { PricingAvailabilityReadResponsePricingsInnerPricingCategoryPricesInner } from './PricingAvailabilityReadResponsePricingsInnerPricingCategoryPricesInner';
import {
  PricingAvailabilityReadResponsePricingsInnerPricingCategoryPricesInnerFromJSON,
  PricingAvailabilityReadResponsePricingsInnerPricingCategoryPricesInnerFromJSONTyped,
  PricingAvailabilityReadResponsePricingsInnerPricingCategoryPricesInnerToJSON
} from './PricingAvailabilityReadResponsePricingsInnerPricingCategoryPricesInner';

/**
 *
 * @export
 * @interface PricingReadResponsePricingInner
 */
export interface PricingReadResponsePricingInner {
  /**
   *
   * @type {string}
   * @memberof PricingReadResponsePricingInner
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof PricingReadResponsePricingInner
   */
  pricing_name: string;
  /**
   *
   * @type {string}
   * @memberof PricingReadResponsePricingInner
   */
  product_id: string;
  /**
   *
   * @type {string}
   * @memberof PricingReadResponsePricingInner
   */
  product_name?: string;
  /**
   *
   * @type {string}
   * @memberof PricingReadResponsePricingInner
   */
  start_date?: string;
  /**
   *
   * @type {string}
   * @memberof PricingReadResponsePricingInner
   */
  end_date?: string;
  /**
   *
   * @type {string}
   * @memberof PricingReadResponsePricingInner
   */
  product_option_id: string;
  /**
   *
   * @type {string}
   * @memberof PricingReadResponsePricingInner
   */
  product_option_name: string;
  /**
   *
   * @type {Array<PricingAvailabilityReadResponsePricingsInnerPricingCategoryPricesInner>}
   * @memberof PricingReadResponsePricingInner
   */
  pricing_configuration: Array<PricingAvailabilityReadResponsePricingsInnerPricingCategoryPricesInner>;
}

/**
 * Check if a given object implements the PricingReadResponsePricingInner interface.
 */
export function instanceOfPricingReadResponsePricingInner(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && 'id' in value;
  isInstance = isInstance && 'pricing_name' in value;
  isInstance = isInstance && 'product_id' in value;
  isInstance = isInstance && 'product_option_id' in value;
  isInstance = isInstance && 'product_option_name' in value;
  isInstance = isInstance && 'pricing_configuration' in value;

  return isInstance;
}

export function PricingReadResponsePricingInnerFromJSON(
  json: any
): PricingReadResponsePricingInner {
  return PricingReadResponsePricingInnerFromJSONTyped(json, false);
}

export function PricingReadResponsePricingInnerFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): PricingReadResponsePricingInner {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: json['id'],
    pricing_name: json['pricing_name'],
    product_id: json['product_id'],
    product_name: !exists(json, 'product_name') ? undefined : json['product_name'],
    start_date: !exists(json, 'start_date') ? undefined : json['start_date'],
    end_date: !exists(json, 'end_date') ? undefined : json['end_date'],
    product_option_id: json['product_option_id'],
    product_option_name: json['product_option_name'],
    pricing_configuration: (json['pricing_configuration'] as Array<any>).map(
      PricingAvailabilityReadResponsePricingsInnerPricingCategoryPricesInnerFromJSON
    )
  };
}

export function PricingReadResponsePricingInnerToJSON(
  value?: PricingReadResponsePricingInner | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    id: value.id,
    pricing_name: value.pricing_name,
    product_id: value.product_id,
    product_name: value.product_name,
    start_date: value.start_date,
    end_date: value.end_date,
    product_option_id: value.product_option_id,
    product_option_name: value.product_option_name,
    pricing_configuration: (value.pricing_configuration as Array<any>).map(
      PricingAvailabilityReadResponsePricingsInnerPricingCategoryPricesInnerToJSON
    )
  };
}
