/* tslint:disable */
/* eslint-disable */
/**
 * Ticketing Portal
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { QuestionsReadResponseMeta } from './QuestionsReadResponseMeta';
import {
  QuestionsReadResponseMetaFromJSON,
  QuestionsReadResponseMetaFromJSONTyped,
  QuestionsReadResponseMetaToJSON
} from './QuestionsReadResponseMeta';
import type { QuestionsReadResponseQuestionsInner } from './QuestionsReadResponseQuestionsInner';
import {
  QuestionsReadResponseQuestionsInnerFromJSON,
  QuestionsReadResponseQuestionsInnerFromJSONTyped,
  QuestionsReadResponseQuestionsInnerToJSON
} from './QuestionsReadResponseQuestionsInner';

/**
 *
 * @export
 * @interface QuestionsReadResponse
 */
export interface QuestionsReadResponse {
  /**
   *
   * @type {boolean}
   * @memberof QuestionsReadResponse
   */
  success: boolean;
  /**
   *
   * @type {string}
   * @memberof QuestionsReadResponse
   */
  exception_code?: string;
  /**
   *
   * @type {string}
   * @memberof QuestionsReadResponse
   */
  exception_message?: string;
  /**
   *
   * @type {QuestionsReadResponseMeta}
   * @memberof QuestionsReadResponse
   */
  meta: QuestionsReadResponseMeta;
  /**
   *
   * @type {Array<QuestionsReadResponseQuestionsInner>}
   * @memberof QuestionsReadResponse
   */
  questions: Array<QuestionsReadResponseQuestionsInner>;
}

/**
 * Check if a given object implements the QuestionsReadResponse interface.
 */
export function instanceOfQuestionsReadResponse(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && 'success' in value;
  isInstance = isInstance && 'meta' in value;
  isInstance = isInstance && 'questions' in value;

  return isInstance;
}

export function QuestionsReadResponseFromJSON(json: any): QuestionsReadResponse {
  return QuestionsReadResponseFromJSONTyped(json, false);
}

export function QuestionsReadResponseFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): QuestionsReadResponse {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    success: json['success'],
    exception_code: !exists(json, 'exception_code') ? undefined : json['exception_code'],
    exception_message: !exists(json, 'exception_message') ? undefined : json['exception_message'],
    meta: QuestionsReadResponseMetaFromJSON(json['meta']),
    questions: (json['questions'] as Array<any>).map(QuestionsReadResponseQuestionsInnerFromJSON)
  };
}

export function QuestionsReadResponseToJSON(value?: QuestionsReadResponse | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    success: value.success,
    exception_code: value.exception_code,
    exception_message: value.exception_message,
    meta: QuestionsReadResponseMetaToJSON(value.meta),
    questions: (value.questions as Array<any>).map(QuestionsReadResponseQuestionsInnerToJSON)
  };
}
