/* tslint:disable */
/* eslint-disable */
/**
 * Ticketing Portal
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { CancellationPolicyUpdateRequestCancellationPolicyRulesInner } from './CancellationPolicyUpdateRequestCancellationPolicyRulesInner';
import {
  CancellationPolicyUpdateRequestCancellationPolicyRulesInnerFromJSON,
  CancellationPolicyUpdateRequestCancellationPolicyRulesInnerFromJSONTyped,
  CancellationPolicyUpdateRequestCancellationPolicyRulesInnerToJSON
} from './CancellationPolicyUpdateRequestCancellationPolicyRulesInner';

/**
 *
 * @export
 * @interface CancellationPolicyUpdateRequestCancellationPolicy
 */
export interface CancellationPolicyUpdateRequestCancellationPolicy {
  /**
   *
   * @type {string}
   * @memberof CancellationPolicyUpdateRequestCancellationPolicy
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof CancellationPolicyUpdateRequestCancellationPolicy
   */
  name: string;
  /**
   *
   * @type {Array<CancellationPolicyUpdateRequestCancellationPolicyRulesInner>}
   * @memberof CancellationPolicyUpdateRequestCancellationPolicy
   */
  rules: Array<CancellationPolicyUpdateRequestCancellationPolicyRulesInner>;
  /**
   *
   * @type {boolean}
   * @memberof CancellationPolicyUpdateRequestCancellationPolicy
   */
  allows_customer_cancellation: boolean;
}

/**
 * Check if a given object implements the CancellationPolicyUpdateRequestCancellationPolicy interface.
 */
export function instanceOfCancellationPolicyUpdateRequestCancellationPolicy(
  value: object
): boolean {
  let isInstance = true;
  isInstance = isInstance && 'id' in value;
  isInstance = isInstance && 'name' in value;
  isInstance = isInstance && 'rules' in value;
  isInstance = isInstance && 'allows_customer_cancellation' in value;

  return isInstance;
}

export function CancellationPolicyUpdateRequestCancellationPolicyFromJSON(
  json: any
): CancellationPolicyUpdateRequestCancellationPolicy {
  return CancellationPolicyUpdateRequestCancellationPolicyFromJSONTyped(json, false);
}

export function CancellationPolicyUpdateRequestCancellationPolicyFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): CancellationPolicyUpdateRequestCancellationPolicy {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: json['id'],
    name: json['name'],
    rules: (json['rules'] as Array<any>).map(
      CancellationPolicyUpdateRequestCancellationPolicyRulesInnerFromJSON
    ),
    allows_customer_cancellation: json['allows_customer_cancellation']
  };
}

export function CancellationPolicyUpdateRequestCancellationPolicyToJSON(
  value?: CancellationPolicyUpdateRequestCancellationPolicy | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    id: value.id,
    name: value.name,
    rules: (value.rules as Array<any>).map(
      CancellationPolicyUpdateRequestCancellationPolicyRulesInnerToJSON
    ),
    allows_customer_cancellation: value.allows_customer_cancellation
  };
}
