/* tslint:disable */
/* eslint-disable */
/**
 * Ticketing Portal
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface CustomerUpdateRequest
 */
export interface CustomerUpdateRequest {
  /**
   *
   * @type {string}
   * @memberof CustomerUpdateRequest
   */
  locale_code: string;
  /**
   *
   * @type {string}
   * @memberof CustomerUpdateRequest
   */
  locale?: string;
  /**
   *
   * @type {string}
   * @memberof CustomerUpdateRequest
   */
  email?: string;
  /**
   *
   * @type {string}
   * @memberof CustomerUpdateRequest
   */
  phone_nr?: string;
  /**
   *
   * @type {string}
   * @memberof CustomerUpdateRequest
   */
  address_id?: string;
  /**
   *
   * @type {boolean}
   * @memberof CustomerUpdateRequest
   */
  subscribed_to_newsletter?: boolean;
  /**
   *
   * @type {string}
   * @memberof CustomerUpdateRequest
   */
  name?: string;
  /**
   *
   * @type {string}
   * @memberof CustomerUpdateRequest
   */
  id?: string;
}

/**
 * Check if a given object implements the CustomerUpdateRequest interface.
 */
export function instanceOfCustomerUpdateRequest(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && 'locale_code' in value;

  return isInstance;
}

export function CustomerUpdateRequestFromJSON(json: any): CustomerUpdateRequest {
  return CustomerUpdateRequestFromJSONTyped(json, false);
}

export function CustomerUpdateRequestFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): CustomerUpdateRequest {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    locale_code: json['locale_code'],
    locale: !exists(json, 'locale') ? undefined : json['locale'],
    email: !exists(json, 'email') ? undefined : json['email'],
    phone_nr: !exists(json, 'phone_nr') ? undefined : json['phone_nr'],
    address_id: !exists(json, 'address_id') ? undefined : json['address_id'],
    subscribed_to_newsletter: !exists(json, 'subscribed_to_newsletter')
      ? undefined
      : json['subscribed_to_newsletter'],
    name: !exists(json, 'name') ? undefined : json['name'],
    id: !exists(json, 'id') ? undefined : json['id']
  };
}

export function CustomerUpdateRequestToJSON(value?: CustomerUpdateRequest | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    locale_code: value.locale_code,
    locale: value.locale,
    email: value.email,
    phone_nr: value.phone_nr,
    address_id: value.address_id,
    subscribed_to_newsletter: value.subscribed_to_newsletter,
    name: value.name,
    id: value.id
  };
}
