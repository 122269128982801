/* tslint:disable */
/* eslint-disable */
/**
 * Ticketing Portal
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface CustomersReadResponseCustomersInner
 */
export interface CustomersReadResponseCustomersInner {
  /**
   *
   * @type {string}
   * @memberof CustomersReadResponseCustomersInner
   */
  email?: string;
  /**
   *
   * @type {string}
   * @memberof CustomersReadResponseCustomersInner
   */
  locale?: string;
  /**
   *
   * @type {string}
   * @memberof CustomersReadResponseCustomersInner
   */
  phone_nr?: string;
  /**
   *
   * @type {boolean}
   * @memberof CustomersReadResponseCustomersInner
   */
  subscribed_to_newsletter?: boolean;
  /**
   *
   * @type {string}
   * @memberof CustomersReadResponseCustomersInner
   */
  name?: string;
  /**
   *
   * @type {string}
   * @memberof CustomersReadResponseCustomersInner
   */
  id?: string;
  /**
   *
   * @type {string}
   * @memberof CustomersReadResponseCustomersInner
   */
  created_at?: string;
}

/**
 * Check if a given object implements the CustomersReadResponseCustomersInner interface.
 */
export function instanceOfCustomersReadResponseCustomersInner(value: object): boolean {
  let isInstance = true;

  return isInstance;
}

export function CustomersReadResponseCustomersInnerFromJSON(
  json: any
): CustomersReadResponseCustomersInner {
  return CustomersReadResponseCustomersInnerFromJSONTyped(json, false);
}

export function CustomersReadResponseCustomersInnerFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): CustomersReadResponseCustomersInner {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    email: !exists(json, 'email') ? undefined : json['email'],
    locale: !exists(json, 'locale') ? undefined : json['locale'],
    phone_nr: !exists(json, 'phone_nr') ? undefined : json['phone_nr'],
    subscribed_to_newsletter: !exists(json, 'subscribed_to_newsletter')
      ? undefined
      : json['subscribed_to_newsletter'],
    name: !exists(json, 'name') ? undefined : json['name'],
    id: !exists(json, 'id') ? undefined : json['id'],
    created_at: !exists(json, 'created_at') ? undefined : json['created_at']
  };
}

export function CustomersReadResponseCustomersInnerToJSON(
  value?: CustomersReadResponseCustomersInner | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    email: value.email,
    locale: value.locale,
    phone_nr: value.phone_nr,
    subscribed_to_newsletter: value.subscribed_to_newsletter,
    name: value.name,
    id: value.id,
    created_at: value.created_at
  };
}
