import { ROUTE_NAMES } from '@/constants/routes';
import { PAGE_TITLES } from '@/constants/breadcrumbs';
import { InitializationReadResponsePermissionsEnum } from '@openapi/models';

export const ADDONS_ROUTES = [
  {
    path: 'sell/addons',
    name: ROUTE_NAMES.ADDONS,
    component: () => import('@/views/pages/sell/addon/Addons.vue'),
    meta: {
      breadcrumbs: [PAGE_TITLES.SELL, PAGE_TITLES.ADDONS],
      label: PAGE_TITLES.ADDONS,
      menu: ['main'],
      permissions: [InitializationReadResponsePermissionsEnum.ProductView],
      icon: 'pi pi-fw pi-plus-circle'
    }
  },
  {
    path: 'sell/addons/+',
    name: ROUTE_NAMES.NEW_ADDON,
    component: () => import('@/views/pages/sell/addon/AddonNew.vue'),
    meta: {
      breadcrumbs: [PAGE_TITLES.SELL, PAGE_TITLES.ADDONS, PAGE_TITLES.NEW_ADDON]
    }
  },
  {
    path: 'sell/addons/:addonId',
    name: ROUTE_NAMES.EDIT_ADDON,
    component: () => import('@/views/pages/sell/addon/Addon.vue'),
    meta: {
      breadcrumbs: [PAGE_TITLES.SELL, PAGE_TITLES.ADDONS, PAGE_TITLES.EDIT_ADDON]
    }
  }
];
