/* tslint:disable */
/* eslint-disable */
/**
 * Ticketing Portal
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface PromoCodeUsagesReadResponsePromoCodeUsesInnerBookingsInner
 */
export interface PromoCodeUsagesReadResponsePromoCodeUsesInnerBookingsInner {
  /**
   *
   * @type {string}
   * @memberof PromoCodeUsagesReadResponsePromoCodeUsesInnerBookingsInner
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof PromoCodeUsagesReadResponsePromoCodeUsesInnerBookingsInner
   */
  hash_code: string;
}

/**
 * Check if a given object implements the PromoCodeUsagesReadResponsePromoCodeUsesInnerBookingsInner interface.
 */
export function instanceOfPromoCodeUsagesReadResponsePromoCodeUsesInnerBookingsInner(
  value: object
): boolean {
  let isInstance = true;
  isInstance = isInstance && 'id' in value;
  isInstance = isInstance && 'hash_code' in value;

  return isInstance;
}

export function PromoCodeUsagesReadResponsePromoCodeUsesInnerBookingsInnerFromJSON(
  json: any
): PromoCodeUsagesReadResponsePromoCodeUsesInnerBookingsInner {
  return PromoCodeUsagesReadResponsePromoCodeUsesInnerBookingsInnerFromJSONTyped(json, false);
}

export function PromoCodeUsagesReadResponsePromoCodeUsesInnerBookingsInnerFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): PromoCodeUsagesReadResponsePromoCodeUsesInnerBookingsInner {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: json['id'],
    hash_code: json['hash_code']
  };
}

export function PromoCodeUsagesReadResponsePromoCodeUsesInnerBookingsInnerToJSON(
  value?: PromoCodeUsagesReadResponsePromoCodeUsesInnerBookingsInner | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    id: value.id,
    hash_code: value.hash_code
  };
}
