/* tslint:disable */
/* eslint-disable */
/**
 * Ticketing Portal
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface ProductOptionReadResponseProductOptionInner
 */
export interface ProductOptionReadResponseProductOptionInner {
  /**
   *
   * @type {string}
   * @memberof ProductOptionReadResponseProductOptionInner
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof ProductOptionReadResponseProductOptionInner
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof ProductOptionReadResponseProductOptionInner
   */
  title?: string | null;
  /**
   *
   * @type {string}
   * @memberof ProductOptionReadResponseProductOptionInner
   */
  description?: string | null;
  /**
   *
   * @type {string}
   * @memberof ProductOptionReadResponseProductOptionInner
   */
  time_slot_type: ProductOptionReadResponseProductOptionInnerTimeSlotTypeEnum;
  /**
   *
   * @type {string}
   * @memberof ProductOptionReadResponseProductOptionInner
   */
  status: ProductOptionReadResponseProductOptionInnerStatusEnum;
  /**
   *
   * @type {string}
   * @memberof ProductOptionReadResponseProductOptionInner
   */
  partner_confirmation_type: ProductOptionReadResponseProductOptionInnerPartnerConfirmationTypeEnum;
}

/**
 * @export
 */
export const ProductOptionReadResponseProductOptionInnerTimeSlotTypeEnum = {
  StartingTime: 'starting_time',
  OpeningHours: 'opening_hours',
  TimeRange: 'time_range',
  Flexible: 'flexible'
} as const;
export type ProductOptionReadResponseProductOptionInnerTimeSlotTypeEnum =
  (typeof ProductOptionReadResponseProductOptionInnerTimeSlotTypeEnum)[keyof typeof ProductOptionReadResponseProductOptionInnerTimeSlotTypeEnum];

/**
 * @export
 */
export const ProductOptionReadResponseProductOptionInnerStatusEnum = {
  Active: 'active',
  Deleted: 'deleted',
  Inactive: 'inactive',
  Draft: 'draft'
} as const;
export type ProductOptionReadResponseProductOptionInnerStatusEnum =
  (typeof ProductOptionReadResponseProductOptionInnerStatusEnum)[keyof typeof ProductOptionReadResponseProductOptionInnerStatusEnum];

/**
 * @export
 */
export const ProductOptionReadResponseProductOptionInnerPartnerConfirmationTypeEnum = {
  Automatic: 'automatic',
  ConfirmOrReject: 'confirm_or_reject'
} as const;
export type ProductOptionReadResponseProductOptionInnerPartnerConfirmationTypeEnum =
  (typeof ProductOptionReadResponseProductOptionInnerPartnerConfirmationTypeEnum)[keyof typeof ProductOptionReadResponseProductOptionInnerPartnerConfirmationTypeEnum];

/**
 * Check if a given object implements the ProductOptionReadResponseProductOptionInner interface.
 */
export function instanceOfProductOptionReadResponseProductOptionInner(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && 'id' in value;
  isInstance = isInstance && 'name' in value;
  isInstance = isInstance && 'time_slot_type' in value;
  isInstance = isInstance && 'status' in value;
  isInstance = isInstance && 'partner_confirmation_type' in value;

  return isInstance;
}

export function ProductOptionReadResponseProductOptionInnerFromJSON(
  json: any
): ProductOptionReadResponseProductOptionInner {
  return ProductOptionReadResponseProductOptionInnerFromJSONTyped(json, false);
}

export function ProductOptionReadResponseProductOptionInnerFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): ProductOptionReadResponseProductOptionInner {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: json['id'],
    name: json['name'],
    title: !exists(json, 'title') ? undefined : json['title'],
    description: !exists(json, 'description') ? undefined : json['description'],
    time_slot_type: json['time_slot_type'],
    status: json['status'],
    partner_confirmation_type: json['partner_confirmation_type']
  };
}

export function ProductOptionReadResponseProductOptionInnerToJSON(
  value?: ProductOptionReadResponseProductOptionInner | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    id: value.id,
    name: value.name,
    title: value.title,
    description: value.description,
    time_slot_type: value.time_slot_type,
    status: value.status,
    partner_confirmation_type: value.partner_confirmation_type
  };
}
