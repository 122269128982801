/* tslint:disable */
/* eslint-disable */
/**
 * Ticketing Portal
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { PayoutDetailsRefundsShoppingCartDetails } from './PayoutDetailsRefundsShoppingCartDetails';
import {
  PayoutDetailsRefundsShoppingCartDetailsFromJSON,
  PayoutDetailsRefundsShoppingCartDetailsFromJSONTyped,
  PayoutDetailsRefundsShoppingCartDetailsToJSON
} from './PayoutDetailsRefundsShoppingCartDetails';

/**
 *
 * @export
 * @interface PayoutDetailsRefundsByPayoutIdReadResponsePayoutDetails
 */
export interface PayoutDetailsRefundsByPayoutIdReadResponsePayoutDetails {
  /**
   *
   * @type {Array<PayoutDetailsRefundsShoppingCartDetails>}
   * @memberof PayoutDetailsRefundsByPayoutIdReadResponsePayoutDetails
   */
  shopping_carts?: Array<PayoutDetailsRefundsShoppingCartDetails>;
}

/**
 * Check if a given object implements the PayoutDetailsRefundsByPayoutIdReadResponsePayoutDetails interface.
 */
export function instanceOfPayoutDetailsRefundsByPayoutIdReadResponsePayoutDetails(
  value: object
): boolean {
  let isInstance = true;

  return isInstance;
}

export function PayoutDetailsRefundsByPayoutIdReadResponsePayoutDetailsFromJSON(
  json: any
): PayoutDetailsRefundsByPayoutIdReadResponsePayoutDetails {
  return PayoutDetailsRefundsByPayoutIdReadResponsePayoutDetailsFromJSONTyped(json, false);
}

export function PayoutDetailsRefundsByPayoutIdReadResponsePayoutDetailsFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): PayoutDetailsRefundsByPayoutIdReadResponsePayoutDetails {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    shopping_carts: !exists(json, 'shopping_carts')
      ? undefined
      : (json['shopping_carts'] as Array<any>).map(PayoutDetailsRefundsShoppingCartDetailsFromJSON)
  };
}

export function PayoutDetailsRefundsByPayoutIdReadResponsePayoutDetailsToJSON(
  value?: PayoutDetailsRefundsByPayoutIdReadResponsePayoutDetails | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    shopping_carts:
      value.shopping_carts === undefined
        ? undefined
        : (value.shopping_carts as Array<any>).map(PayoutDetailsRefundsShoppingCartDetailsToJSON)
  };
}
