/* tslint:disable */
/* eslint-disable */
/**
 * Ticketing Portal
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { AddonsReadRequestSearchInnerSearchRange } from './AddonsReadRequestSearchInnerSearchRange';
import {
  AddonsReadRequestSearchInnerSearchRangeFromJSON,
  AddonsReadRequestSearchInnerSearchRangeFromJSONTyped,
  AddonsReadRequestSearchInnerSearchRangeToJSON
} from './AddonsReadRequestSearchInnerSearchRange';
import type { AddonsReadRequestSearchInnerSearchTerm } from './AddonsReadRequestSearchInnerSearchTerm';
import {
  AddonsReadRequestSearchInnerSearchTermFromJSON,
  AddonsReadRequestSearchInnerSearchTermFromJSONTyped,
  AddonsReadRequestSearchInnerSearchTermToJSON
} from './AddonsReadRequestSearchInnerSearchTerm';

/**
 *
 * @export
 * @interface AddonsReadRequestSearchInner
 */
export interface AddonsReadRequestSearchInner {
  /**
   *
   * @type {string}
   * @memberof AddonsReadRequestSearchInner
   */
  column_name: string;
  /**
   *
   * @type {AddonsReadRequestSearchInnerSearchTerm}
   * @memberof AddonsReadRequestSearchInner
   */
  search_term?: AddonsReadRequestSearchInnerSearchTerm;
  /**
   *
   * @type {AddonsReadRequestSearchInnerSearchRange}
   * @memberof AddonsReadRequestSearchInner
   */
  search_range?: AddonsReadRequestSearchInnerSearchRange;
}

/**
 * Check if a given object implements the AddonsReadRequestSearchInner interface.
 */
export function instanceOfAddonsReadRequestSearchInner(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && 'column_name' in value;

  return isInstance;
}

export function AddonsReadRequestSearchInnerFromJSON(json: any): AddonsReadRequestSearchInner {
  return AddonsReadRequestSearchInnerFromJSONTyped(json, false);
}

export function AddonsReadRequestSearchInnerFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): AddonsReadRequestSearchInner {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    column_name: json['column_name'],
    search_term: !exists(json, 'search_term')
      ? undefined
      : AddonsReadRequestSearchInnerSearchTermFromJSON(json['search_term']),
    search_range: !exists(json, 'search_range')
      ? undefined
      : AddonsReadRequestSearchInnerSearchRangeFromJSON(json['search_range'])
  };
}

export function AddonsReadRequestSearchInnerToJSON(
  value?: AddonsReadRequestSearchInner | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    column_name: value.column_name,
    search_term: AddonsReadRequestSearchInnerSearchTermToJSON(value.search_term),
    search_range: AddonsReadRequestSearchInnerSearchRangeToJSON(value.search_range)
  };
}
