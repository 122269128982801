/* tslint:disable */
/* eslint-disable */
/**
 * Ticketing Portal
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { DataTableFiltersCreateRequestFilterCriteria } from './DataTableFiltersCreateRequestFilterCriteria';
import {
  DataTableFiltersCreateRequestFilterCriteriaFromJSON,
  DataTableFiltersCreateRequestFilterCriteriaFromJSONTyped,
  DataTableFiltersCreateRequestFilterCriteriaToJSON
} from './DataTableFiltersCreateRequestFilterCriteria';

/**
 *
 * @export
 * @interface DataTableFiltersCreateRequest
 */
export interface DataTableFiltersCreateRequest {
  /**
   *
   * @type {string}
   * @memberof DataTableFiltersCreateRequest
   */
  locale_code: string;
  /**
   *
   * @type {string}
   * @memberof DataTableFiltersCreateRequest
   */
  id?: string;
  /**
   *
   * @type {string}
   * @memberof DataTableFiltersCreateRequest
   */
  filter_name: string;
  /**
   *
   * @type {DataTableFiltersCreateRequestFilterCriteria}
   * @memberof DataTableFiltersCreateRequest
   */
  filter_criteria: DataTableFiltersCreateRequestFilterCriteria;
}

/**
 * Check if a given object implements the DataTableFiltersCreateRequest interface.
 */
export function instanceOfDataTableFiltersCreateRequest(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && 'locale_code' in value;
  isInstance = isInstance && 'filter_name' in value;
  isInstance = isInstance && 'filter_criteria' in value;

  return isInstance;
}

export function DataTableFiltersCreateRequestFromJSON(json: any): DataTableFiltersCreateRequest {
  return DataTableFiltersCreateRequestFromJSONTyped(json, false);
}

export function DataTableFiltersCreateRequestFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): DataTableFiltersCreateRequest {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    locale_code: json['locale_code'],
    id: !exists(json, 'id') ? undefined : json['id'],
    filter_name: json['filter_name'],
    filter_criteria: DataTableFiltersCreateRequestFilterCriteriaFromJSON(json['filter_criteria'])
  };
}

export function DataTableFiltersCreateRequestToJSON(
  value?: DataTableFiltersCreateRequest | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    locale_code: value.locale_code,
    id: value.id,
    filter_name: value.filter_name,
    filter_criteria: DataTableFiltersCreateRequestFilterCriteriaToJSON(value.filter_criteria)
  };
}
