/* tslint:disable */
/* eslint-disable */
/**
 * Ticketing Portal
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface DataTableFiltersCreateRequestFilterCriteriaFiltersValue
 */
export interface DataTableFiltersCreateRequestFilterCriteriaFiltersValue {
  /**
   *
   * @type {any}
   * @memberof DataTableFiltersCreateRequestFilterCriteriaFiltersValue
   */
  value?: any | null;
  /**
   *
   * @type {string}
   * @memberof DataTableFiltersCreateRequestFilterCriteriaFiltersValue
   */
  matchMode: string;
}

/**
 * Check if a given object implements the DataTableFiltersCreateRequestFilterCriteriaFiltersValue interface.
 */
export function instanceOfDataTableFiltersCreateRequestFilterCriteriaFiltersValue(
  value: object
): boolean {
  let isInstance = true;
  isInstance = isInstance && 'matchMode' in value;

  return isInstance;
}

export function DataTableFiltersCreateRequestFilterCriteriaFiltersValueFromJSON(
  json: any
): DataTableFiltersCreateRequestFilterCriteriaFiltersValue {
  return DataTableFiltersCreateRequestFilterCriteriaFiltersValueFromJSONTyped(json, false);
}

export function DataTableFiltersCreateRequestFilterCriteriaFiltersValueFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): DataTableFiltersCreateRequestFilterCriteriaFiltersValue {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    value: !exists(json, 'value') ? undefined : json['value'],
    matchMode: json['matchMode']
  };
}

export function DataTableFiltersCreateRequestFilterCriteriaFiltersValueToJSON(
  value?: DataTableFiltersCreateRequestFilterCriteriaFiltersValue | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    value: value.value,
    matchMode: value.matchMode
  };
}
