<template>
  <router-link :to="{ name: ROUTE_NAMES.DASHBOARD }" class="layout-topbar-logo">
    <img :src="logoUrl" alt="GetYourGuide logo" class="logo" />
  </router-link>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import { ROUTE_NAMES } from '@/constants/routes';

const logoUrl = computed(() => {
  return 'https://cdn.getyourguide.com/pp/assets/static/logos/gyg.svg';
});
</script>

<style lang="scss" scoped>
.layout-topbar-logo {
  display: flex;
  align-items: center;
  color: var(--p-surface-900);
  font-size: 1.5rem;
  font-weight: 500;

  img {
    height: 2.5rem;
    margin-right: 0.5rem;
  }

  &:focus {
    outline: 0 none;
    outline-offset: 0;
    transition: box-shadow 0.2s;
    box-shadow: var(--focus-ring);
  }
}

@media (max-width: 991px) {
  .layout-topbar-logo {
    width: auto;
  }
}
</style>
