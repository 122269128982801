/* tslint:disable */
/* eslint-disable */
/**
 * Ticketing Portal
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface CancellationPolicyReadResponseMeta
 */
export interface CancellationPolicyReadResponseMeta {
  /**
   *
   * @type {number}
   * @memberof CancellationPolicyReadResponseMeta
   */
  total_count: number;
}

/**
 * Check if a given object implements the CancellationPolicyReadResponseMeta interface.
 */
export function instanceOfCancellationPolicyReadResponseMeta(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && 'total_count' in value;

  return isInstance;
}

export function CancellationPolicyReadResponseMetaFromJSON(
  json: any
): CancellationPolicyReadResponseMeta {
  return CancellationPolicyReadResponseMetaFromJSONTyped(json, false);
}

export function CancellationPolicyReadResponseMetaFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): CancellationPolicyReadResponseMeta {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    total_count: json['total_count']
  };
}

export function CancellationPolicyReadResponseMetaToJSON(
  value?: CancellationPolicyReadResponseMeta | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    total_count: value.total_count
  };
}
