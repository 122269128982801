/* tslint:disable */
/* eslint-disable */
/**
 * Ticketing Portal
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface PricingAvailabilityCreateRequestDays
 */
export interface PricingAvailabilityCreateRequestDays {
  /**
   *
   * @type {boolean}
   * @memberof PricingAvailabilityCreateRequestDays
   */
  monday: boolean;
  /**
   *
   * @type {boolean}
   * @memberof PricingAvailabilityCreateRequestDays
   */
  tuesday: boolean;
  /**
   *
   * @type {boolean}
   * @memberof PricingAvailabilityCreateRequestDays
   */
  wednesday: boolean;
  /**
   *
   * @type {boolean}
   * @memberof PricingAvailabilityCreateRequestDays
   */
  thursday: boolean;
  /**
   *
   * @type {boolean}
   * @memberof PricingAvailabilityCreateRequestDays
   */
  friday: boolean;
  /**
   *
   * @type {boolean}
   * @memberof PricingAvailabilityCreateRequestDays
   */
  saturday: boolean;
  /**
   *
   * @type {boolean}
   * @memberof PricingAvailabilityCreateRequestDays
   */
  sunday: boolean;
}

/**
 * Check if a given object implements the PricingAvailabilityCreateRequestDays interface.
 */
export function instanceOfPricingAvailabilityCreateRequestDays(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && 'monday' in value;
  isInstance = isInstance && 'tuesday' in value;
  isInstance = isInstance && 'wednesday' in value;
  isInstance = isInstance && 'thursday' in value;
  isInstance = isInstance && 'friday' in value;
  isInstance = isInstance && 'saturday' in value;
  isInstance = isInstance && 'sunday' in value;

  return isInstance;
}

export function PricingAvailabilityCreateRequestDaysFromJSON(
  json: any
): PricingAvailabilityCreateRequestDays {
  return PricingAvailabilityCreateRequestDaysFromJSONTyped(json, false);
}

export function PricingAvailabilityCreateRequestDaysFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): PricingAvailabilityCreateRequestDays {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    monday: json['monday'],
    tuesday: json['tuesday'],
    wednesday: json['wednesday'],
    thursday: json['thursday'],
    friday: json['friday'],
    saturday: json['saturday'],
    sunday: json['sunday']
  };
}

export function PricingAvailabilityCreateRequestDaysToJSON(
  value?: PricingAvailabilityCreateRequestDays | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    monday: value.monday,
    tuesday: value.tuesday,
    wednesday: value.wednesday,
    thursday: value.thursday,
    friday: value.friday,
    saturday: value.saturday,
    sunday: value.sunday
  };
}
