/* tslint:disable */
/* eslint-disable */
/**
 * Ticketing Portal
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ExternalTicketCodesByProductOptionIdReadResponseExternalTicketCodesInner } from './ExternalTicketCodesByProductOptionIdReadResponseExternalTicketCodesInner';
import {
  ExternalTicketCodesByProductOptionIdReadResponseExternalTicketCodesInnerFromJSON,
  ExternalTicketCodesByProductOptionIdReadResponseExternalTicketCodesInnerFromJSONTyped,
  ExternalTicketCodesByProductOptionIdReadResponseExternalTicketCodesInnerToJSON
} from './ExternalTicketCodesByProductOptionIdReadResponseExternalTicketCodesInner';

/**
 *
 * @export
 * @interface ExternalTicketCodesByProductOptionIdReadResponse
 */
export interface ExternalTicketCodesByProductOptionIdReadResponse {
  /**
   *
   * @type {boolean}
   * @memberof ExternalTicketCodesByProductOptionIdReadResponse
   */
  success: boolean;
  /**
   *
   * @type {string}
   * @memberof ExternalTicketCodesByProductOptionIdReadResponse
   */
  exception_code?: string;
  /**
   *
   * @type {string}
   * @memberof ExternalTicketCodesByProductOptionIdReadResponse
   */
  exception_message?: string;
  /**
   *
   * @type {Array<ExternalTicketCodesByProductOptionIdReadResponseExternalTicketCodesInner>}
   * @memberof ExternalTicketCodesByProductOptionIdReadResponse
   */
  external_ticket_codes?: Array<ExternalTicketCodesByProductOptionIdReadResponseExternalTicketCodesInner>;
}

/**
 * Check if a given object implements the ExternalTicketCodesByProductOptionIdReadResponse interface.
 */
export function instanceOfExternalTicketCodesByProductOptionIdReadResponse(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && 'success' in value;

  return isInstance;
}

export function ExternalTicketCodesByProductOptionIdReadResponseFromJSON(
  json: any
): ExternalTicketCodesByProductOptionIdReadResponse {
  return ExternalTicketCodesByProductOptionIdReadResponseFromJSONTyped(json, false);
}

export function ExternalTicketCodesByProductOptionIdReadResponseFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): ExternalTicketCodesByProductOptionIdReadResponse {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    success: json['success'],
    exception_code: !exists(json, 'exception_code') ? undefined : json['exception_code'],
    exception_message: !exists(json, 'exception_message') ? undefined : json['exception_message'],
    external_ticket_codes: !exists(json, 'external_ticket_codes')
      ? undefined
      : (json['external_ticket_codes'] as Array<any>).map(
          ExternalTicketCodesByProductOptionIdReadResponseExternalTicketCodesInnerFromJSON
        )
  };
}

export function ExternalTicketCodesByProductOptionIdReadResponseToJSON(
  value?: ExternalTicketCodesByProductOptionIdReadResponse | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    success: value.success,
    exception_code: value.exception_code,
    exception_message: value.exception_message,
    external_ticket_codes:
      value.external_ticket_codes === undefined
        ? undefined
        : (value.external_ticket_codes as Array<any>).map(
            ExternalTicketCodesByProductOptionIdReadResponseExternalTicketCodesInnerToJSON
          )
  };
}
