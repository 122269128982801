/* tslint:disable */
/* eslint-disable */
/**
 * Ticketing Portal
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface BookingsRevenueReadResponseRevenuesInnerRevenue
 */
export interface BookingsRevenueReadResponseRevenuesInnerRevenue {
  /**
   * Revenue of the current period
   * @type {number}
   * @memberof BookingsRevenueReadResponseRevenuesInnerRevenue
   */
  current_period: number;
  /**
   * Revenue of the previous period
   * @type {number}
   * @memberof BookingsRevenueReadResponseRevenuesInnerRevenue
   */
  previous_period: number;
  /**
   * Percentage change in revenue from the previous period to the current period
   * @type {string}
   * @memberof BookingsRevenueReadResponseRevenuesInnerRevenue
   */
  change: string;
}

/**
 * Check if a given object implements the BookingsRevenueReadResponseRevenuesInnerRevenue interface.
 */
export function instanceOfBookingsRevenueReadResponseRevenuesInnerRevenue(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && 'current_period' in value;
  isInstance = isInstance && 'previous_period' in value;
  isInstance = isInstance && 'change' in value;

  return isInstance;
}

export function BookingsRevenueReadResponseRevenuesInnerRevenueFromJSON(
  json: any
): BookingsRevenueReadResponseRevenuesInnerRevenue {
  return BookingsRevenueReadResponseRevenuesInnerRevenueFromJSONTyped(json, false);
}

export function BookingsRevenueReadResponseRevenuesInnerRevenueFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): BookingsRevenueReadResponseRevenuesInnerRevenue {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    current_period: json['current_period'],
    previous_period: json['previous_period'],
    change: json['change']
  };
}

export function BookingsRevenueReadResponseRevenuesInnerRevenueToJSON(
  value?: BookingsRevenueReadResponseRevenuesInnerRevenue | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    current_period: value.current_period,
    previous_period: value.previous_period,
    change: value.change
  };
}
