/* tslint:disable */
/* eslint-disable */
/**
 * Ticketing Portal
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { AddonUpdateRequestAddonTranslationsValue } from './AddonUpdateRequestAddonTranslationsValue';
import {
  AddonUpdateRequestAddonTranslationsValueFromJSON,
  AddonUpdateRequestAddonTranslationsValueFromJSONTyped,
  AddonUpdateRequestAddonTranslationsValueToJSON
} from './AddonUpdateRequestAddonTranslationsValue';

/**
 *
 * @export
 * @interface AddonCreateRequest
 */
export interface AddonCreateRequest {
  /**
   *
   * @type {string}
   * @memberof AddonCreateRequest
   */
  locale_code: string;
  /**
   *
   * @type {string}
   * @memberof AddonCreateRequest
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof AddonCreateRequest
   */
  added_to: AddonCreateRequestAddedToEnum;
  /**
   *
   * @type {number}
   * @memberof AddonCreateRequest
   */
  min_quantity: number;
  /**
   *
   * @type {number}
   * @memberof AddonCreateRequest
   */
  max_quantity: number | null;
  /**
   * Addon Translations
   * @type {{ [key: string]: AddonUpdateRequestAddonTranslationsValue; }}
   * @memberof AddonCreateRequest
   */
  addon_translations?: { [key: string]: AddonUpdateRequestAddonTranslationsValue };
}

/**
 * @export
 */
export const AddonCreateRequestAddedToEnum = {
  Booking: 'booking',
  Participant: 'participant'
} as const;
export type AddonCreateRequestAddedToEnum =
  (typeof AddonCreateRequestAddedToEnum)[keyof typeof AddonCreateRequestAddedToEnum];

/**
 * Check if a given object implements the AddonCreateRequest interface.
 */
export function instanceOfAddonCreateRequest(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && 'locale_code' in value;
  isInstance = isInstance && 'name' in value;
  isInstance = isInstance && 'added_to' in value;
  isInstance = isInstance && 'min_quantity' in value;
  isInstance = isInstance && 'max_quantity' in value;

  return isInstance;
}

export function AddonCreateRequestFromJSON(json: any): AddonCreateRequest {
  return AddonCreateRequestFromJSONTyped(json, false);
}

export function AddonCreateRequestFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): AddonCreateRequest {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    locale_code: json['locale_code'],
    name: json['name'],
    added_to: json['added_to'],
    min_quantity: json['min_quantity'],
    max_quantity: json['max_quantity'],
    addon_translations: !exists(json, 'addon_translations')
      ? undefined
      : mapValues(json['addon_translations'], AddonUpdateRequestAddonTranslationsValueFromJSON)
  };
}

export function AddonCreateRequestToJSON(value?: AddonCreateRequest | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    locale_code: value.locale_code,
    name: value.name,
    added_to: value.added_to,
    min_quantity: value.min_quantity,
    max_quantity: value.max_quantity,
    addon_translations:
      value.addon_translations === undefined
        ? undefined
        : mapValues(value.addon_translations, AddonUpdateRequestAddonTranslationsValueToJSON)
  };
}
