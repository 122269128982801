/* tslint:disable */
/* eslint-disable */
/**
 * Ticketing Portal
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { PricingAvailabilityCreateRequestDays } from './PricingAvailabilityCreateRequestDays';
import {
  PricingAvailabilityCreateRequestDaysFromJSON,
  PricingAvailabilityCreateRequestDaysFromJSONTyped,
  PricingAvailabilityCreateRequestDaysToJSON
} from './PricingAvailabilityCreateRequestDays';
import type { PricingAvailabilityCreateRequestPricingCategoryPricesInner } from './PricingAvailabilityCreateRequestPricingCategoryPricesInner';
import {
  PricingAvailabilityCreateRequestPricingCategoryPricesInnerFromJSON,
  PricingAvailabilityCreateRequestPricingCategoryPricesInnerFromJSONTyped,
  PricingAvailabilityCreateRequestPricingCategoryPricesInnerToJSON
} from './PricingAvailabilityCreateRequestPricingCategoryPricesInner';

/**
 *
 * @export
 * @interface PricingAvailabilityCreateRequest
 */
export interface PricingAvailabilityCreateRequest {
  /**
   *
   * @type {string}
   * @memberof PricingAvailabilityCreateRequest
   */
  locale_code: string;
  /**
   *
   * @type {string}
   * @memberof PricingAvailabilityCreateRequest
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof PricingAvailabilityCreateRequest
   */
  product_option_id: string;
  /**
   *
   * @type {string}
   * @memberof PricingAvailabilityCreateRequest
   */
  pricing_id?: string;
  /**
   *
   * @type {Array<PricingAvailabilityCreateRequestPricingCategoryPricesInner>}
   * @memberof PricingAvailabilityCreateRequest
   */
  pricing_category_prices: Array<PricingAvailabilityCreateRequestPricingCategoryPricesInner>;
  /**
   *
   * @type {string}
   * @memberof PricingAvailabilityCreateRequest
   */
  time_slot_type: PricingAvailabilityCreateRequestTimeSlotTypeEnum;
  /**
   *
   * @type {number}
   * @memberof PricingAvailabilityCreateRequest
   */
  capacity?: number | null;
  /**
   *
   * @type {string}
   * @memberof PricingAvailabilityCreateRequest
   */
  start_date: string;
  /**
   *
   * @type {string}
   * @memberof PricingAvailabilityCreateRequest
   */
  end_date: string;
  /**
   *
   * @type {string}
   * @memberof PricingAvailabilityCreateRequest
   */
  start_time: string;
  /**
   *
   * @type {string}
   * @memberof PricingAvailabilityCreateRequest
   */
  end_time: string;
  /**
   *
   * @type {PricingAvailabilityCreateRequestDays}
   * @memberof PricingAvailabilityCreateRequest
   */
  days: PricingAvailabilityCreateRequestDays;
  /**
   *
   * @type {number}
   * @memberof PricingAvailabilityCreateRequest
   */
  starting_times: number | null;
}

/**
 * @export
 */
export const PricingAvailabilityCreateRequestTimeSlotTypeEnum = {
  StartingTime: 'starting_time',
  OpeningHours: 'opening_hours',
  TimeRange: 'time_range',
  Flexible: 'flexible'
} as const;
export type PricingAvailabilityCreateRequestTimeSlotTypeEnum =
  (typeof PricingAvailabilityCreateRequestTimeSlotTypeEnum)[keyof typeof PricingAvailabilityCreateRequestTimeSlotTypeEnum];

/**
 * Check if a given object implements the PricingAvailabilityCreateRequest interface.
 */
export function instanceOfPricingAvailabilityCreateRequest(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && 'locale_code' in value;
  isInstance = isInstance && 'name' in value;
  isInstance = isInstance && 'product_option_id' in value;
  isInstance = isInstance && 'pricing_category_prices' in value;
  isInstance = isInstance && 'time_slot_type' in value;
  isInstance = isInstance && 'start_date' in value;
  isInstance = isInstance && 'end_date' in value;
  isInstance = isInstance && 'start_time' in value;
  isInstance = isInstance && 'end_time' in value;
  isInstance = isInstance && 'days' in value;
  isInstance = isInstance && 'starting_times' in value;

  return isInstance;
}

export function PricingAvailabilityCreateRequestFromJSON(
  json: any
): PricingAvailabilityCreateRequest {
  return PricingAvailabilityCreateRequestFromJSONTyped(json, false);
}

export function PricingAvailabilityCreateRequestFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): PricingAvailabilityCreateRequest {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    locale_code: json['locale_code'],
    name: json['name'],
    product_option_id: json['product_option_id'],
    pricing_id: !exists(json, 'pricing_id') ? undefined : json['pricing_id'],
    pricing_category_prices: (json['pricing_category_prices'] as Array<any>).map(
      PricingAvailabilityCreateRequestPricingCategoryPricesInnerFromJSON
    ),
    time_slot_type: json['time_slot_type'],
    capacity: !exists(json, 'capacity') ? undefined : json['capacity'],
    start_date: json['start_date'],
    end_date: json['end_date'],
    start_time: json['start_time'],
    end_time: json['end_time'],
    days: PricingAvailabilityCreateRequestDaysFromJSON(json['days']),
    starting_times: json['starting_times']
  };
}

export function PricingAvailabilityCreateRequestToJSON(
  value?: PricingAvailabilityCreateRequest | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    locale_code: value.locale_code,
    name: value.name,
    product_option_id: value.product_option_id,
    pricing_id: value.pricing_id,
    pricing_category_prices: (value.pricing_category_prices as Array<any>).map(
      PricingAvailabilityCreateRequestPricingCategoryPricesInnerToJSON
    ),
    time_slot_type: value.time_slot_type,
    capacity: value.capacity,
    start_date: value.start_date,
    end_date: value.end_date,
    start_time: value.start_time,
    end_time: value.end_time,
    days: PricingAvailabilityCreateRequestDaysToJSON(value.days),
    starting_times: value.starting_times
  };
}
