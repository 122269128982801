/* tslint:disable */
/* eslint-disable */
/**
 * Ticketing Portal
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface PromoCodeReadResponsePromoCodesInner
 */
export interface PromoCodeReadResponsePromoCodesInner {
  /**
   *
   * @type {string}
   * @memberof PromoCodeReadResponsePromoCodesInner
   */
  id: string;
  /**
   * the promo code
   * @type {string}
   * @memberof PromoCodeReadResponsePromoCodesInner
   */
  code: string;
  /**
   *
   * @type {string}
   * @memberof PromoCodeReadResponsePromoCodesInner
   */
  type: string;
  /**
   *
   * @type {number}
   * @memberof PromoCodeReadResponsePromoCodesInner
   */
  value: number;
  /**
   *
   * @type {string}
   * @memberof PromoCodeReadResponsePromoCodesInner
   */
  valid_from: string;
  /**
   *
   * @type {string}
   * @memberof PromoCodeReadResponsePromoCodesInner
   */
  valid_until: string | null;
  /**
   *
   * @type {string}
   * @memberof PromoCodeReadResponsePromoCodesInner
   */
  status: PromoCodeReadResponsePromoCodesInnerStatusEnum;
  /**
   *
   * @type {number}
   * @memberof PromoCodeReadResponsePromoCodesInner
   */
  uses: number;
  /**
   *
   * @type {boolean}
   * @memberof PromoCodeReadResponsePromoCodesInner
   */
  is_exclusive: boolean;
}

/**
 * @export
 */
export const PromoCodeReadResponsePromoCodesInnerStatusEnum = {
  Active: 'active',
  Inactive: 'inactive',
  Deleted: 'deleted'
} as const;
export type PromoCodeReadResponsePromoCodesInnerStatusEnum =
  (typeof PromoCodeReadResponsePromoCodesInnerStatusEnum)[keyof typeof PromoCodeReadResponsePromoCodesInnerStatusEnum];

/**
 * Check if a given object implements the PromoCodeReadResponsePromoCodesInner interface.
 */
export function instanceOfPromoCodeReadResponsePromoCodesInner(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && 'id' in value;
  isInstance = isInstance && 'code' in value;
  isInstance = isInstance && 'type' in value;
  isInstance = isInstance && 'value' in value;
  isInstance = isInstance && 'valid_from' in value;
  isInstance = isInstance && 'valid_until' in value;
  isInstance = isInstance && 'status' in value;
  isInstance = isInstance && 'uses' in value;
  isInstance = isInstance && 'is_exclusive' in value;

  return isInstance;
}

export function PromoCodeReadResponsePromoCodesInnerFromJSON(
  json: any
): PromoCodeReadResponsePromoCodesInner {
  return PromoCodeReadResponsePromoCodesInnerFromJSONTyped(json, false);
}

export function PromoCodeReadResponsePromoCodesInnerFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): PromoCodeReadResponsePromoCodesInner {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: json['id'],
    code: json['code'],
    type: json['type'],
    value: json['value'],
    valid_from: json['valid_from'],
    valid_until: json['valid_until'],
    status: json['status'],
    uses: json['uses'],
    is_exclusive: json['is_exclusive']
  };
}

export function PromoCodeReadResponsePromoCodesInnerToJSON(
  value?: PromoCodeReadResponsePromoCodesInner | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    id: value.id,
    code: value.code,
    type: value.type,
    value: value.value,
    valid_from: value.valid_from,
    valid_until: value.valid_until,
    status: value.status,
    uses: value.uses,
    is_exclusive: value.is_exclusive
  };
}
