/* tslint:disable */
/* eslint-disable */
/**
 * Ticketing Portal
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface PricingAvailabilityReadResponsePricingsInnerPricingCategoryPricesInner
 */
export interface PricingAvailabilityReadResponsePricingsInnerPricingCategoryPricesInner {
  /**
   *
   * @type {string}
   * @memberof PricingAvailabilityReadResponsePricingsInnerPricingCategoryPricesInner
   */
  name: string;
  /**
   *
   * @type {number}
   * @memberof PricingAvailabilityReadResponsePricingsInnerPricingCategoryPricesInner
   */
  value: number;
  /**
   *
   * @type {string}
   * @memberof PricingAvailabilityReadResponsePricingsInnerPricingCategoryPricesInner
   */
  formatted_value: string;
}

/**
 * Check if a given object implements the PricingAvailabilityReadResponsePricingsInnerPricingCategoryPricesInner interface.
 */
export function instanceOfPricingAvailabilityReadResponsePricingsInnerPricingCategoryPricesInner(
  value: object
): boolean {
  let isInstance = true;
  isInstance = isInstance && 'name' in value;
  isInstance = isInstance && 'value' in value;
  isInstance = isInstance && 'formatted_value' in value;

  return isInstance;
}

export function PricingAvailabilityReadResponsePricingsInnerPricingCategoryPricesInnerFromJSON(
  json: any
): PricingAvailabilityReadResponsePricingsInnerPricingCategoryPricesInner {
  return PricingAvailabilityReadResponsePricingsInnerPricingCategoryPricesInnerFromJSONTyped(
    json,
    false
  );
}

export function PricingAvailabilityReadResponsePricingsInnerPricingCategoryPricesInnerFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): PricingAvailabilityReadResponsePricingsInnerPricingCategoryPricesInner {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    name: json['name'],
    value: json['value'],
    formatted_value: json['formatted_value']
  };
}

export function PricingAvailabilityReadResponsePricingsInnerPricingCategoryPricesInnerToJSON(
  value?: PricingAvailabilityReadResponsePricingsInnerPricingCategoryPricesInner | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    name: value.name,
    value: value.value,
    formatted_value: value.formatted_value
  };
}
