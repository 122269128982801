import { ROUTE_NAMES } from '@/constants/routes';
import { PAGE_TITLES } from '@/constants/breadcrumbs';
import type { RouteRecordRaw } from 'vue-router';

export const OPTION_ROUTES: RouteRecordRaw[] = [
  // We need this for breadcrumbs to work properly
  {
    path: '/sell/option',
    redirect: '/sell/products'
  },
  {
    path: '/sell/option/:optionId',
    component: () => import('@/views/pages/sell/options/Option.vue'),
    meta: {
      breadcrumbs: [
        PAGE_TITLES.SELL,
        PAGE_TITLES.PRODUCT_OPTIONS,
        PAGE_TITLES.EDIT_PRODUCT_OPTION,
        PAGE_TITLES.EDIT_PRODUCT_OPTION_INFO
      ]
    },
    children: [
      {
        path: '',
        name: ROUTE_NAMES.EDIT_PRODUCT_OPTION_INFO,
        component: () => import('@/views/pages/sell/options/OptionInfo.vue'),
        meta: {
          breadcrumbs: [
            PAGE_TITLES.SELL,
            PAGE_TITLES.PRODUCT_OPTIONS,
            PAGE_TITLES.EDIT_PRODUCT_OPTION,
            PAGE_TITLES.EDIT_PRODUCT_OPTION_INFO
          ]
        }
      },
      {
        path: 'settings',
        name: ROUTE_NAMES.EDIT_PRODUCT_OPTION_SETTINGS,
        component: () => import('@/views/pages/sell/options/OptionSettings.vue'),
        meta: {
          breadcrumbs: [
            PAGE_TITLES.SELL,
            PAGE_TITLES.PRODUCT_OPTIONS,
            PAGE_TITLES.EDIT_PRODUCT_OPTION,
            PAGE_TITLES.EDIT_PRODUCT_OPTION_SETTINGS
          ]
        }
      },
      {
        path: 'address',
        name: ROUTE_NAMES.EDIT_PRODUCT_OPTION_ADDRESS,
        component: () => import('@/views/pages/sell/options/OptionAddress.vue'),
        meta: {
          breadcrumbs: [
            PAGE_TITLES.SELL,
            PAGE_TITLES.PRODUCT_OPTIONS,
            PAGE_TITLES.EDIT_PRODUCT_OPTION,
            PAGE_TITLES.EDIT_PRODUCT_OPTION_ADDRESS
          ]
        }
      },
      {
        path: 'pricing-availability/:pricingId',
        name: ROUTE_NAMES.EDIT_PRODUCT_PRICING,
        component: () => import('@/views/pages/sell/options/ProductPricingEdit.vue'),
        meta: {
          breadcrumbs: [
            PAGE_TITLES.SELL,
            PAGE_TITLES.PRODUCT_OPTIONS,
            PAGE_TITLES.EDIT_PRODUCT_OPTION,
            PAGE_TITLES.PRICING_AVAILABILITY,
            PAGE_TITLES.EDIT_PRODUCT_PRICING
          ]
        }
      },
      {
        path: 'pricing-availability',
        name: ROUTE_NAMES.PRICING_AVAILABILITY,
        component: () => import('@/views/pages/sell/options/ProductPricingAvailability.vue'),
        meta: {
          breadcrumbs: [
            PAGE_TITLES.SELL,
            PAGE_TITLES.PRODUCT_OPTIONS,
            PAGE_TITLES.EDIT_PRODUCT_OPTION,
            PAGE_TITLES.PRICING_AVAILABILITY
          ]
        }
      },
      {
        path: 'pricing-availability/+',
        name: ROUTE_NAMES.NEW_PRICING_AVAILABILITY,
        component: () => import('@/views/pages/sell/options/ProductPricingAvailabilityNew.vue'),
        meta: {
          breadcrumbs: [
            PAGE_TITLES.SELL,
            PAGE_TITLES.PRODUCT_OPTIONS,
            PAGE_TITLES.EDIT_PRODUCT_OPTION,
            PAGE_TITLES.PRICING_AVAILABILITY,
            PAGE_TITLES.NEW_PRICING_AVAILABILITY
          ]
        }
      },
      {
        path: 'pricing-flexible/+',
        name: ROUTE_NAMES.NEW_FLEXIBLE_PRICING,
        component: () => import('@/views/pages/sell/options/ProductFlexiblePricingNew.vue'),
        meta: {
          breadcrumbs: [
            PAGE_TITLES.SELL,
            PAGE_TITLES.PRODUCT_OPTIONS,
            PAGE_TITLES.EDIT_PRODUCT_OPTION,
            PAGE_TITLES.NEW_FLEXIBLE_PRICING
          ]
        }
      },
      {
        path: 'channels',
        name: ROUTE_NAMES.EDIT_PRODUCT_OPTION_CHANNELS,
        component: () => import('@/views/pages/sell/options/OptionChannels.vue'),
        meta: {
          breadcrumbs: [
            PAGE_TITLES.SELL,
            PAGE_TITLES.PRODUCT_OPTIONS,
            PAGE_TITLES.EDIT_PRODUCT_OPTION,
            PAGE_TITLES.EDIT_PRODUCT_OPTION_CHANNELS
          ]
        }
      },
      {
        path: 'questions',
        name: ROUTE_NAMES.EDIT_PRODUCT_OPTION_QUESTIONS,
        component: () => import('@/views/pages/sell/options/OptionQuestions.vue'),
        meta: {
          breadcrumbs: [
            PAGE_TITLES.SELL,
            PAGE_TITLES.PRODUCT_OPTIONS,
            PAGE_TITLES.EDIT_PRODUCT_OPTION,
            PAGE_TITLES.EDIT_PRODUCT_OPTION_QUESTIONS
          ]
        }
      },
      {
        path: 'ticket-configuration',
        name: ROUTE_NAMES.EDIT_PRODUCT_OPTION_TICKET_CONFIGURATION,
        component: () => import('@/views/pages/sell/options/ProductOptionTicketConfiguration.vue'),
        meta: {
          breadcrumbs: [
            PAGE_TITLES.SELL,
            PAGE_TITLES.PRODUCT_OPTIONS,
            PAGE_TITLES.EDIT_PRODUCT_OPTION,
            PAGE_TITLES.EDIT_PRODUCT_OPTION_TICKET_CONFIGURATION
          ]
        }
      }
    ]
  }
];
