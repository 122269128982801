import { useAuth } from '@/composables/use-auth';
import AppLayout from '@/layout/AppLayout.vue';
import { directus } from '@/service/directus';
import { navigateToLoginPage } from '@/service/TicketingApi';
import { useUserStore } from '@/stores/user';
import posthog from 'posthog-js';
import { nextTick } from 'vue';
import { createRouter, createWebHashHistory } from 'vue-router';

// NAMES
import { PAGE_TITLES } from '@/constants/breadcrumbs';
import { ROUTE_NAMES } from '@/constants/routes';

// ROUTES
import { ADDONS_ROUTES } from './addons';
import { AUTH_ROUTES } from './auth';
import { AVAILABILITIES_ROUTES } from './availabilities';
import { CHANNELS_ROUTES } from './channels';
import { COMMUNICATION_ROUTES } from './communication';
import { CUSTOMERS_ROUTES } from './customers';
import { GIFTCODES_ROUTES } from './giftcodes';
import { HOME_ROUTES } from './home';
import { OPTION_ROUTES } from './options';
import { ORDERS_ROUTES } from './orders';
import { PRICINGCATEGORIES_ROUTES } from './pricingcategories';
import { PRODUCT_ROUTES } from './products';
import { PROMOCODES_ROUTES } from './promocodes';
import { QUESTIONS_ROUTES } from './questions';
import { REPORTS_ROUTES } from './reports';
import { SCANNER_ROUTES } from './scanner';
import { SETTINGS_ROUTES } from './settings';
import { USERS_ROUTES } from './users';

export const authRoutes = [
  ROUTE_NAMES.LOGIN,
  ROUTE_NAMES.LOGIN_IMPERSONATE,
  ROUTE_NAMES.REGISTER,
  ROUTE_NAMES.REGISTER_CREATE_ACCOUNT,
  ROUTE_NAMES.REGISTER_BUSINESS_INFORMATION,
  ROUTE_NAMES.REGISTER_CONFIRMATION,
  ROUTE_NAMES.RESET_PASSWORD,
  ROUTE_NAMES.UPDATE_PASSWORD
];

const router = createRouter({
  history: createWebHashHistory(),
  routes: [
    ...AUTH_ROUTES,
    {
      path: '/',
      redirect: { name: ROUTE_NAMES.DASHBOARD },
      component: AppLayout,
      beforeEnter: async (to, from, next) => {
        // Fetch User
        const { fetchUser } = useUserStore();
        await fetchUser();

        const { checkUserRole } = useAuth();
        await checkUserRole();

        next();
      },
      children: [
        // Heads-up: this also determines the order the pages show in the main menu (if tagged as 'main')
        ...HOME_ROUTES,
        ...REPORTS_ROUTES,

        ...ORDERS_ROUTES,
        ...CUSTOMERS_ROUTES,
        ...AVAILABILITIES_ROUTES,
        ...CHANNELS_ROUTES,

        ...PRODUCT_ROUTES,
        ...OPTION_ROUTES,
        ...PRICINGCATEGORIES_ROUTES,
        ...ADDONS_ROUTES,
        ...QUESTIONS_ROUTES,

        ...PROMOCODES_ROUTES,
        ...GIFTCODES_ROUTES,
        ...COMMUNICATION_ROUTES,

        ...SCANNER_ROUTES,

        ...USERS_ROUTES,
        ...SETTINGS_ROUTES,

        {
          path: '/profile',
          name: ROUTE_NAMES.PROFILE,
          component: () => import('@/views/Profile.vue'),
          meta: {
            breadcrumbs: [PAGE_TITLES.PROFILE]
          }
        },
        {
          path: '/not-found',
          name: ROUTE_NAMES.NOT_FOUND,
          component: () => import('@/views/NotFound.vue'),
          meta: {
            breadcrumbs: [PAGE_TITLES.NOT_FOUND]
          }
        }
      ]
    },
    {
      path: '/:pathMatch(.*)*',
      redirect: '/not-found'
    }
  ],
  scrollBehavior() {
    return { top: 0 }; // always scroll to top
  }
});

// Global guards
router.beforeEach(async (to) => {
  try {
    const desiredRouteName = to.name?.toString();
    if (!desiredRouteName) {
      return;
    }
    // Allow access to all /auth/* routes
    if (authRoutes.includes(desiredRouteName)) {
      return;
    }

    // Avoid infinite loop to the login page
    if (desiredRouteName === ROUTE_NAMES.LOGIN) {
      return;
    }

    // Make sure the user is authenticated before taking to the desired route
    const isLoggedIn = await directus.getToken();
    if (!isLoggedIn) {
      await navigateToLoginPage(to);
    }
  } catch (error) {
    return { name: 'login' };
  }
});

router.afterEach((to) => {
  nextTick(() => {
    posthog.capture('$pageview', {
      $current_url: to.fullPath
    });
  });
});

export default router;
