import { DEFAULT_CURRENCY } from '@/constants/currency';
import type { Currency } from '@/types/types';
import { defineStore } from 'pinia';

interface CurrencyState {
  code: Currency['code'];
  num_fraction_digits: number;
}

export const useCurrencyStore = defineStore('CurrencyStore', {
  state: (): CurrencyState => {
    return {
      code: DEFAULT_CURRENCY,
      num_fraction_digits: 2
    };
  },

  getters: {
    getCurrencyCode: (state: CurrencyState) => {
      return state.code;
    },
    getNumFractionDigits: (state: CurrencyState) => {
      return state.num_fraction_digits;
    }
  },

  actions: {
    setSelected(code: Currency['code']) {
      this.code = code;
    },
    setNumFractionDigits(numFractionDigits: number) {
      this.num_fraction_digits = numFractionDigits;
    }
  }
});
