/* tslint:disable */
/* eslint-disable */
/**
 * Ticketing Portal
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ProductTranslationReadResponseProductTranslationsInner } from './ProductTranslationReadResponseProductTranslationsInner';
import {
  ProductTranslationReadResponseProductTranslationsInnerFromJSON,
  ProductTranslationReadResponseProductTranslationsInnerFromJSONTyped,
  ProductTranslationReadResponseProductTranslationsInnerToJSON
} from './ProductTranslationReadResponseProductTranslationsInner';

/**
 *
 * @export
 * @interface ProductTranslationReadResponse
 */
export interface ProductTranslationReadResponse {
  /**
   *
   * @type {boolean}
   * @memberof ProductTranslationReadResponse
   */
  success: boolean;
  /**
   *
   * @type {string}
   * @memberof ProductTranslationReadResponse
   */
  exception_code?: string;
  /**
   *
   * @type {string}
   * @memberof ProductTranslationReadResponse
   */
  exception_message?: string;
  /**
   *
   * @type {Array<ProductTranslationReadResponseProductTranslationsInner>}
   * @memberof ProductTranslationReadResponse
   */
  product_translations: Array<ProductTranslationReadResponseProductTranslationsInner>;
}

/**
 * Check if a given object implements the ProductTranslationReadResponse interface.
 */
export function instanceOfProductTranslationReadResponse(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && 'success' in value;
  isInstance = isInstance && 'product_translations' in value;

  return isInstance;
}

export function ProductTranslationReadResponseFromJSON(json: any): ProductTranslationReadResponse {
  return ProductTranslationReadResponseFromJSONTyped(json, false);
}

export function ProductTranslationReadResponseFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): ProductTranslationReadResponse {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    success: json['success'],
    exception_code: !exists(json, 'exception_code') ? undefined : json['exception_code'],
    exception_message: !exists(json, 'exception_message') ? undefined : json['exception_message'],
    product_translations: (json['product_translations'] as Array<any>).map(
      ProductTranslationReadResponseProductTranslationsInnerFromJSON
    )
  };
}

export function ProductTranslationReadResponseToJSON(
  value?: ProductTranslationReadResponse | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    success: value.success,
    exception_code: value.exception_code,
    exception_message: value.exception_message,
    product_translations: (value.product_translations as Array<any>).map(
      ProductTranslationReadResponseProductTranslationsInnerToJSON
    )
  };
}
