/* tslint:disable */
/* eslint-disable */
/**
 * Ticketing Portal
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface PayoutDetailsBooking
 */
export interface PayoutDetailsBooking {
  /**
   *
   * @type {string}
   * @memberof PayoutDetailsBooking
   */
  checkout_completed_at?: string;
  /**
   *
   * @type {string}
   * @memberof PayoutDetailsBooking
   */
  hash_code: string;
  /**
   *
   * @type {string}
   * @memberof PayoutDetailsBooking
   */
  bookings: string;
  /**
   *
   * @type {string}
   * @memberof PayoutDetailsBooking
   */
  gift_codes?: string;
  /**
   *
   * @type {string}
   * @memberof PayoutDetailsBooking
   */
  gift_codes_used?: string;
  /**
   *
   * @type {string}
   * @memberof PayoutDetailsBooking
   */
  promo_codes_used?: string;
  /**
   *
   * @type {string}
   * @memberof PayoutDetailsBooking
   */
  price_pre_promo: string;
  /**
   *
   * @type {string}
   * @memberof PayoutDetailsBooking
   */
  price: string;
  /**
   *
   * @type {string}
   * @memberof PayoutDetailsBooking
   */
  total_adyen_payments: string;
  /**
   *
   * @type {string}
   * @memberof PayoutDetailsBooking
   */
  commission: string;
}

/**
 * Check if a given object implements the PayoutDetailsBooking interface.
 */
export function instanceOfPayoutDetailsBooking(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && 'hash_code' in value;
  isInstance = isInstance && 'bookings' in value;
  isInstance = isInstance && 'price_pre_promo' in value;
  isInstance = isInstance && 'price' in value;
  isInstance = isInstance && 'total_adyen_payments' in value;
  isInstance = isInstance && 'commission' in value;

  return isInstance;
}

export function PayoutDetailsBookingFromJSON(json: any): PayoutDetailsBooking {
  return PayoutDetailsBookingFromJSONTyped(json, false);
}

export function PayoutDetailsBookingFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): PayoutDetailsBooking {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    checkout_completed_at: !exists(json, 'checkout_completed_at')
      ? undefined
      : json['checkout_completed_at'],
    hash_code: json['hash_code'],
    bookings: json['bookings'],
    gift_codes: !exists(json, 'gift_codes') ? undefined : json['gift_codes'],
    gift_codes_used: !exists(json, 'gift_codes_used') ? undefined : json['gift_codes_used'],
    promo_codes_used: !exists(json, 'promo_codes_used') ? undefined : json['promo_codes_used'],
    price_pre_promo: json['price_pre_promo'],
    price: json['price'],
    total_adyen_payments: json['total_adyen_payments'],
    commission: json['commission']
  };
}

export function PayoutDetailsBookingToJSON(value?: PayoutDetailsBooking | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    checkout_completed_at: value.checkout_completed_at,
    hash_code: value.hash_code,
    bookings: value.bookings,
    gift_codes: value.gift_codes,
    gift_codes_used: value.gift_codes_used,
    promo_codes_used: value.promo_codes_used,
    price_pre_promo: value.price_pre_promo,
    price: value.price,
    total_adyen_payments: value.total_adyen_payments,
    commission: value.commission
  };
}
