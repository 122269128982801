/* tslint:disable */
/* eslint-disable */
/**
 * Ticketing Portal
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { UsersReadResponseUsersInnerRolesInner } from './UsersReadResponseUsersInnerRolesInner';
import {
  UsersReadResponseUsersInnerRolesInnerFromJSON,
  UsersReadResponseUsersInnerRolesInnerFromJSONTyped,
  UsersReadResponseUsersInnerRolesInnerToJSON
} from './UsersReadResponseUsersInnerRolesInner';

/**
 *
 * @export
 * @interface UsersReadResponseUsersInner
 */
export interface UsersReadResponseUsersInner {
  /**
   *
   * @type {string}
   * @memberof UsersReadResponseUsersInner
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof UsersReadResponseUsersInner
   */
  first_name: string;
  /**
   *
   * @type {string}
   * @memberof UsersReadResponseUsersInner
   */
  last_name: string;
  /**
   *
   * @type {string}
   * @memberof UsersReadResponseUsersInner
   */
  email: string;
  /**
   *
   * @type {string}
   * @memberof UsersReadResponseUsersInner
   */
  status: UsersReadResponseUsersInnerStatusEnum;
  /**
   *
   * @type {Array<UsersReadResponseUsersInnerRolesInner>}
   * @memberof UsersReadResponseUsersInner
   */
  roles: Array<UsersReadResponseUsersInnerRolesInner>;
}

/**
 * @export
 */
export const UsersReadResponseUsersInnerStatusEnum = {
  Draft: 'draft',
  Invited: 'invited',
  Unverified: 'unverified',
  Active: 'active',
  Suspended: 'suspended',
  Archived: 'archived'
} as const;
export type UsersReadResponseUsersInnerStatusEnum =
  (typeof UsersReadResponseUsersInnerStatusEnum)[keyof typeof UsersReadResponseUsersInnerStatusEnum];

/**
 * Check if a given object implements the UsersReadResponseUsersInner interface.
 */
export function instanceOfUsersReadResponseUsersInner(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && 'id' in value;
  isInstance = isInstance && 'first_name' in value;
  isInstance = isInstance && 'last_name' in value;
  isInstance = isInstance && 'email' in value;
  isInstance = isInstance && 'status' in value;
  isInstance = isInstance && 'roles' in value;

  return isInstance;
}

export function UsersReadResponseUsersInnerFromJSON(json: any): UsersReadResponseUsersInner {
  return UsersReadResponseUsersInnerFromJSONTyped(json, false);
}

export function UsersReadResponseUsersInnerFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): UsersReadResponseUsersInner {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: json['id'],
    first_name: json['first_name'],
    last_name: json['last_name'],
    email: json['email'],
    status: json['status'],
    roles: (json['roles'] as Array<any>).map(UsersReadResponseUsersInnerRolesInnerFromJSON)
  };
}

export function UsersReadResponseUsersInnerToJSON(value?: UsersReadResponseUsersInner | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    id: value.id,
    first_name: value.first_name,
    last_name: value.last_name,
    email: value.email,
    status: value.status,
    roles: (value.roles as Array<any>).map(UsersReadResponseUsersInnerRolesInnerToJSON)
  };
}
