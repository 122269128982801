import posthog from 'posthog-js';
import type { Survey } from 'posthog-js';
import { ref } from 'vue';

export enum SURVEYS {
  ERROR_COLLECTION = '018b41dd-0a1a-0000-18b7-26be46b3ca52' // https://app.posthog.com/surveys/018b41dd-0a1a-0000-18b7-26be46b3ca52
}

const surveyVisible = ref(false);
const survey = ref<Survey>();

export const usePostHog = () => {
  function setSurveyVisibility(visible: boolean) {
    if (survey.value && survey.value.id) {
      surveyVisible.value = visible;
    }
  }

  async function setSurvey(id: SURVEYS) {
    const activeSurvey = await getApiSurveyById(id);
    if (activeSurvey) {
      survey.value = activeSurvey;
    }
  }

  function getApiSurveyById(id: SURVEYS): Promise<Survey | void> {
    return new Promise((resolve) => {
      posthog.getActiveMatchingSurveys((surveys) => {
        const filteredSurveys = surveys.filter((survey) => survey.type === 'api');
        const survey = filteredSurveys.find(
          (survey) => survey.id === id && survey.end_date === null
        );
        if (survey) {
          resolve(survey);
        } else {
          resolve();
        }
      });
    });
  }

  return {
    survey,
    surveyVisible,
    setSurveyVisibility,
    setSurvey
  };
};
