<template>
  <Drawer v-model:visible="visible" position="right">
    <div class="mx-auto flex flex-col md:mx-0">
      <h4 class="mb-2 font-semibold">Welcome</h4>
      <span class="mb-8 font-medium text-muted-color">{{ getFirstName }} {{ getLastName }}</span>
      <ul class="m-0 list-none p-0">
        <li>
          <a
            class="mb-4 flex cursor-pointer items-center border p-4 transition-colors duration-150 border-surface rounded-border hover:bg-emphasis"
            @click="onSettingsClick"
          >
            <span><i class="pi pi-cog text-xl text-primary"></i></span>
            <div class="ml-4">
              <span class="mb-2 font-semibold">{{ t('portal_settings') }}</span>
              <p class="m-0 text-muted-color">{{ t('portal_view_profile') }}</p>
            </div>
          </a>
        </li>
        <!--  <li>
          <a
            class="cursor-pointer flex border-surface mb-4 p-4 items-center border border-surface rounded-border hover:bg-emphasis transition-colors duration-150"
          >
            <span><i class="pi pi-cog text-xl text-primary"></i></span>
            <div class="ml-4">
              <span class="mb-2 font-semibold">Settings</span>
              <p class="text-muted-color m-0">Account settings</p>
            </div>
          </a>
        </li> -->
        <li>
          <a
            class="mb-4 flex cursor-pointer items-center border p-4 transition-colors duration-150 border-surface rounded-border hover:bg-emphasis"
            @click="onSignOutClick"
          >
            <span><i class="pi pi-power-off text-xl text-primary"></i></span>
            <div class="ml-4">
              <span class="mb-2 font-semibold">{{ t('portal_sign_out') }}</span>
              <p class="m-0 text-muted-color">{{ t('portal_sign_out_sub') }}</p>
            </div>
          </a>
        </li>
      </ul>
    </div>
    <div class="mx-auto mt-8 flex flex-col md:mx-0">
      <h4 class="mb-2 font-semibold">{{ t('portal_notifications') }}</h4>
      <span class="mb-8 font-medium text-muted-color">{{ notificationCount }}</span>
      <!-- <ul class="list-none m-0 p-0">
        <li>
          <a
            class="cursor-pointer flex border-surface mb-4 p-4 items-center border border-surface rounded-border hover:bg-emphasis transition-colors duration-150"
          >
            <span><i class="pi pi-comment text-xl text-primary"></i></span>
            <div class="ml-4">
              <span class="mb-2 font-semibold">Your post has new comments</span>
              <p class="text-muted-color m-0">5 min ago</p>
            </div>
          </a>
        </li>
        <li>
          <a
            class="cursor-pointer flex border-surface mb-4 p-4 items-center border border-surface rounded-border hover:bg-emphasis transition-colors duration-150"
          >
            <span><i class="pi pi-trash text-xl text-primary"></i></span>
            <div class="ml-4">
              <span class="mb-2 font-semibold">Your post has been deleted</span>
              <p class="text-muted-color m-0">15min ago</p>
            </div>
          </a>
        </li>
        <li>
          <a
            class="cursor-pointer flex border-surface mb-4 p-4 items-center border border-surface rounded-border hover:bg-emphasis transition-colors duration-150"
          >
            <span><i class="pi pi-folder text-xl text-primary"></i></span>
            <div class="ml-4">
              <span class="mb-2 font-semibold">Post has been updated</span>
              <p class="text-muted-color m-0">3h ago</p>
            </div>
          </a>
        </li>
      </ul> -->
    </div>
    <div class="mx-auto mt-8 flex flex-col self-end text-surface-500 md:mx-0 dark:text-surface-300">
      <Impersonate />
      <Button
        disabled
        severity="secondary"
        text
        class="mt-4"
        size="small"
        :label="`version: ${PORTAL_RELEASE_VERSION}`"
        @click="onTriggerError"
      />
    </div>
  </Drawer>
</template>

<script setup lang="ts">
import { directus } from '@/service/directus';
import { useUserStore } from '@/stores/user';
import Drawer from 'primevue/drawer';
import { computed, ref, toRefs, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRouter } from 'vue-router';
import { useToast } from 'primevue/usetoast';
import { ROUTE_NAMES } from '@/constants/routes';
import { DIRECTUS_API_URL, PORTAL_RELEASE_VERSION } from '@/config';
import { DEFAULT_LOCALE } from '@/constants/i18n';
import { useAuth } from '@/composables/use-auth';
import Impersonate from '@/components/auth/Impersonate.vue';
import Button from 'primevue/button';

const { t } = useI18n();
const props = defineProps({
  isVisible: {
    type: Boolean,
    required: true,
    default: false
  }
});
const { isVisible } = toRefs(props);
const visible = ref(false);
const router = useRouter();
const { getFirstName, getLastName, isGygAdminUser } = useUserStore();
const { updateUserRole } = useAuth();
const toast = useToast();
watch(isVisible, (value) => {
  visible.value = value;
});
const notificationCount = computed(() => t('portal_notifications_count').replace('%s', '0'));

const onSignOutClick = async () => {
  if (isGygAdminUser) {
    await updateUserRole({
      rolesUpdateRequest: {
        locale_code: DEFAULT_LOCALE
      }
    });
  }
  await directus.logout();
  // Removes the auth_expires_at from the local storage after successful sign out
  // It's necessary to do that manually because sdk doesn't remove it automatically
  window.localStorage.removeItem('auth_expires_at');
  toast.add({
    severity: 'success',
    summary: t('portal_success_title'),
    detail: t('portal_sign_out_success_text'),
    life: 10000
  });
  await router.push({ name: ROUTE_NAMES.LOGIN });
};
const onSettingsClick = async () => {
  await router.push({ name: ROUTE_NAMES.PROFILE });
  visible.value = false;
};

const onTriggerError = async () => {
  await fetch(`${DIRECTUS_API_URL}/infrastructure/_error`, { method: 'GET' });
  throw new Error('Test error triggered');
};
</script>
