/* tslint:disable */
/* eslint-disable */
/**
 * Ticketing Portal
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { BookingsCountReadResponseBookingsInnerPeriodCurrent } from './BookingsCountReadResponseBookingsInnerPeriodCurrent';
import {
  BookingsCountReadResponseBookingsInnerPeriodCurrentFromJSON,
  BookingsCountReadResponseBookingsInnerPeriodCurrentFromJSONTyped,
  BookingsCountReadResponseBookingsInnerPeriodCurrentToJSON
} from './BookingsCountReadResponseBookingsInnerPeriodCurrent';
import type { BookingsCountReadResponseBookingsInnerPeriodPrevious } from './BookingsCountReadResponseBookingsInnerPeriodPrevious';
import {
  BookingsCountReadResponseBookingsInnerPeriodPreviousFromJSON,
  BookingsCountReadResponseBookingsInnerPeriodPreviousFromJSONTyped,
  BookingsCountReadResponseBookingsInnerPeriodPreviousToJSON
} from './BookingsCountReadResponseBookingsInnerPeriodPrevious';

/**
 *
 * @export
 * @interface BookingsCountReadResponseBookingsInnerPeriod
 */
export interface BookingsCountReadResponseBookingsInnerPeriod {
  /**
   * Time period in days, represented as a string followed by "d". Example: "7d" for 7 days
   * @type {string}
   * @memberof BookingsCountReadResponseBookingsInnerPeriod
   */
  name: string;
  /**
   *
   * @type {BookingsCountReadResponseBookingsInnerPeriodCurrent}
   * @memberof BookingsCountReadResponseBookingsInnerPeriod
   */
  current: BookingsCountReadResponseBookingsInnerPeriodCurrent;
  /**
   *
   * @type {BookingsCountReadResponseBookingsInnerPeriodPrevious}
   * @memberof BookingsCountReadResponseBookingsInnerPeriod
   */
  previous: BookingsCountReadResponseBookingsInnerPeriodPrevious;
}

/**
 * Check if a given object implements the BookingsCountReadResponseBookingsInnerPeriod interface.
 */
export function instanceOfBookingsCountReadResponseBookingsInnerPeriod(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && 'name' in value;
  isInstance = isInstance && 'current' in value;
  isInstance = isInstance && 'previous' in value;

  return isInstance;
}

export function BookingsCountReadResponseBookingsInnerPeriodFromJSON(
  json: any
): BookingsCountReadResponseBookingsInnerPeriod {
  return BookingsCountReadResponseBookingsInnerPeriodFromJSONTyped(json, false);
}

export function BookingsCountReadResponseBookingsInnerPeriodFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): BookingsCountReadResponseBookingsInnerPeriod {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    name: json['name'],
    current: BookingsCountReadResponseBookingsInnerPeriodCurrentFromJSON(json['current']),
    previous: BookingsCountReadResponseBookingsInnerPeriodPreviousFromJSON(json['previous'])
  };
}

export function BookingsCountReadResponseBookingsInnerPeriodToJSON(
  value?: BookingsCountReadResponseBookingsInnerPeriod | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    name: value.name,
    current: BookingsCountReadResponseBookingsInnerPeriodCurrentToJSON(value.current),
    previous: BookingsCountReadResponseBookingsInnerPeriodPreviousToJSON(value.previous)
  };
}
