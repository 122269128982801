/* tslint:disable */
/* eslint-disable */
/**
 * Ticketing Portal
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { GiftCodeByIdReadResponseGiftCode } from './GiftCodeByIdReadResponseGiftCode';
import {
  GiftCodeByIdReadResponseGiftCodeFromJSON,
  GiftCodeByIdReadResponseGiftCodeFromJSONTyped,
  GiftCodeByIdReadResponseGiftCodeToJSON
} from './GiftCodeByIdReadResponseGiftCode';

/**
 *
 * @export
 * @interface GiftCodeByIdReadResponse
 */
export interface GiftCodeByIdReadResponse {
  /**
   *
   * @type {boolean}
   * @memberof GiftCodeByIdReadResponse
   */
  success: boolean;
  /**
   *
   * @type {string}
   * @memberof GiftCodeByIdReadResponse
   */
  exception_code?: string;
  /**
   *
   * @type {string}
   * @memberof GiftCodeByIdReadResponse
   */
  exception_message?: string;
  /**
   *
   * @type {GiftCodeByIdReadResponseGiftCode}
   * @memberof GiftCodeByIdReadResponse
   */
  gift_code: GiftCodeByIdReadResponseGiftCode;
}

/**
 * Check if a given object implements the GiftCodeByIdReadResponse interface.
 */
export function instanceOfGiftCodeByIdReadResponse(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && 'success' in value;
  isInstance = isInstance && 'gift_code' in value;

  return isInstance;
}

export function GiftCodeByIdReadResponseFromJSON(json: any): GiftCodeByIdReadResponse {
  return GiftCodeByIdReadResponseFromJSONTyped(json, false);
}

export function GiftCodeByIdReadResponseFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): GiftCodeByIdReadResponse {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    success: json['success'],
    exception_code: !exists(json, 'exception_code') ? undefined : json['exception_code'],
    exception_message: !exists(json, 'exception_message') ? undefined : json['exception_message'],
    gift_code: GiftCodeByIdReadResponseGiftCodeFromJSON(json['gift_code'])
  };
}

export function GiftCodeByIdReadResponseToJSON(value?: GiftCodeByIdReadResponse | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    success: value.success,
    exception_code: value.exception_code,
    exception_message: value.exception_message,
    gift_code: GiftCodeByIdReadResponseGiftCodeToJSON(value.gift_code)
  };
}
