/* tslint:disable */
/* eslint-disable */
/**
 * Ticketing Portal
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * Question Translation
 * @export
 * @interface QuestionUpdateRequestQuestionTranslationsValue
 */
export interface QuestionUpdateRequestQuestionTranslationsValue {
  /**
   *
   * @type {string}
   * @memberof QuestionUpdateRequestQuestionTranslationsValue
   */
  locale_code: string;
  /**
   *
   * @type {string}
   * @memberof QuestionUpdateRequestQuestionTranslationsValue
   */
  text: string | null;
  /**
   *
   * @type {string}
   * @memberof QuestionUpdateRequestQuestionTranslationsValue
   */
  description?: string | null;
  /**
   *
   * @type {string}
   * @memberof QuestionUpdateRequestQuestionTranslationsValue
   */
  id?: string | null;
  /**
   *
   * @type {string}
   * @memberof QuestionUpdateRequestQuestionTranslationsValue
   */
  partner_question_id?: string;
}

/**
 * Check if a given object implements the QuestionUpdateRequestQuestionTranslationsValue interface.
 */
export function instanceOfQuestionUpdateRequestQuestionTranslationsValue(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && 'locale_code' in value;
  isInstance = isInstance && 'text' in value;

  return isInstance;
}

export function QuestionUpdateRequestQuestionTranslationsValueFromJSON(
  json: any
): QuestionUpdateRequestQuestionTranslationsValue {
  return QuestionUpdateRequestQuestionTranslationsValueFromJSONTyped(json, false);
}

export function QuestionUpdateRequestQuestionTranslationsValueFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): QuestionUpdateRequestQuestionTranslationsValue {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    locale_code: json['locale_code'],
    text: json['text'],
    description: !exists(json, 'description') ? undefined : json['description'],
    id: !exists(json, 'id') ? undefined : json['id'],
    partner_question_id: !exists(json, 'partner_question_id')
      ? undefined
      : json['partner_question_id']
  };
}

export function QuestionUpdateRequestQuestionTranslationsValueToJSON(
  value?: QuestionUpdateRequestQuestionTranslationsValue | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    locale_code: value.locale_code,
    text: value.text,
    description: value.description,
    id: value.id,
    partner_question_id: value.partner_question_id
  };
}
