/* tslint:disable */
/* eslint-disable */
/**
 * Ticketing Portal
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface BookingByIdReadResponseBookingTicketsInner
 */
export interface BookingByIdReadResponseBookingTicketsInner {
  /**
   *
   * @type {string}
   * @memberof BookingByIdReadResponseBookingTicketsInner
   */
  code: string;
  /**
   *
   * @type {string}
   * @memberof BookingByIdReadResponseBookingTicketsInner
   */
  ticket_file_id: string;
}

/**
 * Check if a given object implements the BookingByIdReadResponseBookingTicketsInner interface.
 */
export function instanceOfBookingByIdReadResponseBookingTicketsInner(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && 'code' in value;
  isInstance = isInstance && 'ticket_file_id' in value;

  return isInstance;
}

export function BookingByIdReadResponseBookingTicketsInnerFromJSON(
  json: any
): BookingByIdReadResponseBookingTicketsInner {
  return BookingByIdReadResponseBookingTicketsInnerFromJSONTyped(json, false);
}

export function BookingByIdReadResponseBookingTicketsInnerFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): BookingByIdReadResponseBookingTicketsInner {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    code: json['code'],
    ticket_file_id: json['ticket_file_id']
  };
}

export function BookingByIdReadResponseBookingTicketsInnerToJSON(
  value?: BookingByIdReadResponseBookingTicketsInner | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    code: value.code,
    ticket_file_id: value.ticket_file_id
  };
}
