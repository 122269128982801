/* tslint:disable */
/* eslint-disable */
/**
 * Ticketing Portal
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface TicketConfigurationUpdateRequest
 */
export interface TicketConfigurationUpdateRequest {
  /**
   *
   * @type {string}
   * @memberof TicketConfigurationUpdateRequest
   */
  locale_code: string;
  /**
   *
   * @type {number}
   * @memberof TicketConfigurationUpdateRequest
   */
  external_tickets_low_threshold?: number | null;
  /**
   *
   * @type {string}
   * @memberof TicketConfigurationUpdateRequest
   */
  ticket_code_source: TicketConfigurationUpdateRequestTicketCodeSourceEnum;
}

/**
 * @export
 */
export const TicketConfigurationUpdateRequestTicketCodeSourceEnum = {
  GygTicketing: 'gyg_ticketing',
  ExternalTicketCodeTable: 'external_ticket_code_table',
  ReservationSystem: 'reservation_system'
} as const;
export type TicketConfigurationUpdateRequestTicketCodeSourceEnum =
  (typeof TicketConfigurationUpdateRequestTicketCodeSourceEnum)[keyof typeof TicketConfigurationUpdateRequestTicketCodeSourceEnum];

/**
 * Check if a given object implements the TicketConfigurationUpdateRequest interface.
 */
export function instanceOfTicketConfigurationUpdateRequest(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && 'locale_code' in value;
  isInstance = isInstance && 'ticket_code_source' in value;

  return isInstance;
}

export function TicketConfigurationUpdateRequestFromJSON(
  json: any
): TicketConfigurationUpdateRequest {
  return TicketConfigurationUpdateRequestFromJSONTyped(json, false);
}

export function TicketConfigurationUpdateRequestFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): TicketConfigurationUpdateRequest {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    locale_code: json['locale_code'],
    external_tickets_low_threshold: !exists(json, 'external_tickets_low_threshold')
      ? undefined
      : json['external_tickets_low_threshold'],
    ticket_code_source: json['ticket_code_source']
  };
}

export function TicketConfigurationUpdateRequestToJSON(
  value?: TicketConfigurationUpdateRequest | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    locale_code: value.locale_code,
    external_tickets_low_threshold: value.external_tickets_low_threshold,
    ticket_code_source: value.ticket_code_source
  };
}
