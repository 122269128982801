import { ROUTE_NAMES } from '@/constants/routes';
import { PAGE_TITLES } from '@/constants/breadcrumbs';
import { InitializationReadResponsePermissionsEnum } from '@openapi/models';

export const HOME_ROUTES = [
  {
    path: 'dashboard',
    name: ROUTE_NAMES.DASHBOARD,
    component: () => import('@/views/pages/home/Dashboard.vue'),
    meta: {
      breadcrumbs: [PAGE_TITLES.DASHBOARD],
      menu: ['main'],
      permissions: [InitializationReadResponsePermissionsEnum.DashboardView],
      label: PAGE_TITLES.DASHBOARD,
      icon: 'pi pi-fw pi-home'
    }
  }
];
