/* tslint:disable */
/* eslint-disable */
/**
 * Ticketing Portal
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { QuestionUpdateRequestQuestionTranslationsValue } from './QuestionUpdateRequestQuestionTranslationsValue';
import {
  QuestionUpdateRequestQuestionTranslationsValueFromJSON,
  QuestionUpdateRequestQuestionTranslationsValueFromJSONTyped,
  QuestionUpdateRequestQuestionTranslationsValueToJSON
} from './QuestionUpdateRequestQuestionTranslationsValue';

/**
 *
 * @export
 * @interface QuestionsReadResponseQuestionsInner
 */
export interface QuestionsReadResponseQuestionsInner {
  /**
   *
   * @type {string}
   * @memberof QuestionsReadResponseQuestionsInner
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof QuestionsReadResponseQuestionsInner
   */
  level: QuestionsReadResponseQuestionsInnerLevelEnum;
  /**
   *
   * @type {string}
   * @memberof QuestionsReadResponseQuestionsInner
   */
  answer_type: QuestionsReadResponseQuestionsInnerAnswerTypeEnum;
  /**
   * Question Translations
   * @type {{ [key: string]: QuestionUpdateRequestQuestionTranslationsValue; }}
   * @memberof QuestionsReadResponseQuestionsInner
   */
  question_translations?: { [key: string]: QuestionUpdateRequestQuestionTranslationsValue };
  /**
   *
   * @type {string}
   * @memberof QuestionsReadResponseQuestionsInner
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof QuestionsReadResponseQuestionsInner
   */
  status: QuestionsReadResponseQuestionsInnerStatusEnum;
  /**
   *
   * @type {Date}
   * @memberof QuestionsReadResponseQuestionsInner
   */
  created_at: Date;
}

/**
 * @export
 */
export const QuestionsReadResponseQuestionsInnerLevelEnum = {
  Booking: 'booking',
  Participant: 'participant'
} as const;
export type QuestionsReadResponseQuestionsInnerLevelEnum =
  (typeof QuestionsReadResponseQuestionsInnerLevelEnum)[keyof typeof QuestionsReadResponseQuestionsInnerLevelEnum];

/**
 * @export
 */
export const QuestionsReadResponseQuestionsInnerAnswerTypeEnum = {
  Text: 'text'
} as const;
export type QuestionsReadResponseQuestionsInnerAnswerTypeEnum =
  (typeof QuestionsReadResponseQuestionsInnerAnswerTypeEnum)[keyof typeof QuestionsReadResponseQuestionsInnerAnswerTypeEnum];

/**
 * @export
 */
export const QuestionsReadResponseQuestionsInnerStatusEnum = {
  Active: 'active',
  Inactive: 'inactive',
  Deleted: 'deleted'
} as const;
export type QuestionsReadResponseQuestionsInnerStatusEnum =
  (typeof QuestionsReadResponseQuestionsInnerStatusEnum)[keyof typeof QuestionsReadResponseQuestionsInnerStatusEnum];

/**
 * Check if a given object implements the QuestionsReadResponseQuestionsInner interface.
 */
export function instanceOfQuestionsReadResponseQuestionsInner(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && 'name' in value;
  isInstance = isInstance && 'level' in value;
  isInstance = isInstance && 'answer_type' in value;
  isInstance = isInstance && 'id' in value;
  isInstance = isInstance && 'status' in value;
  isInstance = isInstance && 'created_at' in value;

  return isInstance;
}

export function QuestionsReadResponseQuestionsInnerFromJSON(
  json: any
): QuestionsReadResponseQuestionsInner {
  return QuestionsReadResponseQuestionsInnerFromJSONTyped(json, false);
}

export function QuestionsReadResponseQuestionsInnerFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): QuestionsReadResponseQuestionsInner {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    name: json['name'],
    level: json['level'],
    answer_type: json['answer_type'],
    question_translations: !exists(json, 'question_translations')
      ? undefined
      : mapValues(
          json['question_translations'],
          QuestionUpdateRequestQuestionTranslationsValueFromJSON
        ),
    id: json['id'],
    status: json['status'],
    created_at: new Date(json['created_at'])
  };
}

export function QuestionsReadResponseQuestionsInnerToJSON(
  value?: QuestionsReadResponseQuestionsInner | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    name: value.name,
    level: value.level,
    answer_type: value.answer_type,
    question_translations:
      value.question_translations === undefined
        ? undefined
        : mapValues(
            value.question_translations,
            QuestionUpdateRequestQuestionTranslationsValueToJSON
          ),
    id: value.id,
    status: value.status,
    created_at: value.created_at.toISOString()
  };
}
