/* tslint:disable */
/* eslint-disable */
/**
 * Ticketing Portal
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface PromoCodeRestrictionsReadResponsePromoCodeProductRestrictionsInner
 */
export interface PromoCodeRestrictionsReadResponsePromoCodeProductRestrictionsInner {
  /**
   *
   * @type {string}
   * @memberof PromoCodeRestrictionsReadResponsePromoCodeProductRestrictionsInner
   */
  pricing_category_name: string;
  /**
   *
   * @type {string}
   * @memberof PromoCodeRestrictionsReadResponsePromoCodeProductRestrictionsInner
   */
  product_name: string;
  /**
   *
   * @type {string}
   * @memberof PromoCodeRestrictionsReadResponsePromoCodeProductRestrictionsInner
   */
  product_id: string;
  /**
   *
   * @type {string}
   * @memberof PromoCodeRestrictionsReadResponsePromoCodeProductRestrictionsInner
   */
  created_at: string;
}

/**
 * Check if a given object implements the PromoCodeRestrictionsReadResponsePromoCodeProductRestrictionsInner interface.
 */
export function instanceOfPromoCodeRestrictionsReadResponsePromoCodeProductRestrictionsInner(
  value: object
): boolean {
  let isInstance = true;
  isInstance = isInstance && 'pricing_category_name' in value;
  isInstance = isInstance && 'product_name' in value;
  isInstance = isInstance && 'product_id' in value;
  isInstance = isInstance && 'created_at' in value;

  return isInstance;
}

export function PromoCodeRestrictionsReadResponsePromoCodeProductRestrictionsInnerFromJSON(
  json: any
): PromoCodeRestrictionsReadResponsePromoCodeProductRestrictionsInner {
  return PromoCodeRestrictionsReadResponsePromoCodeProductRestrictionsInnerFromJSONTyped(
    json,
    false
  );
}

export function PromoCodeRestrictionsReadResponsePromoCodeProductRestrictionsInnerFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): PromoCodeRestrictionsReadResponsePromoCodeProductRestrictionsInner {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    pricing_category_name: json['pricing_category_name'],
    product_name: json['product_name'],
    product_id: json['product_id'],
    created_at: json['created_at']
  };
}

export function PromoCodeRestrictionsReadResponsePromoCodeProductRestrictionsInnerToJSON(
  value?: PromoCodeRestrictionsReadResponsePromoCodeProductRestrictionsInner | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    pricing_category_name: value.pricing_category_name,
    product_name: value.product_name,
    product_id: value.product_id,
    created_at: value.created_at
  };
}
