/* tslint:disable */
/* eslint-disable */
/**
 * Ticketing Portal
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { AddonsReadRequestDownload } from './AddonsReadRequestDownload';
import {
  AddonsReadRequestDownloadFromJSON,
  AddonsReadRequestDownloadFromJSONTyped,
  AddonsReadRequestDownloadToJSON
} from './AddonsReadRequestDownload';
import type { AddonsReadRequestSearchInner } from './AddonsReadRequestSearchInner';
import {
  AddonsReadRequestSearchInnerFromJSON,
  AddonsReadRequestSearchInnerFromJSONTyped,
  AddonsReadRequestSearchInnerToJSON
} from './AddonsReadRequestSearchInner';
import type { AddonsReadRequestSortOrderInner } from './AddonsReadRequestSortOrderInner';
import {
  AddonsReadRequestSortOrderInnerFromJSON,
  AddonsReadRequestSortOrderInnerFromJSONTyped,
  AddonsReadRequestSortOrderInnerToJSON
} from './AddonsReadRequestSortOrderInner';

/**
 *
 * @export
 * @interface PromoCodeRestrictionsReadRequest
 */
export interface PromoCodeRestrictionsReadRequest {
  /**
   *
   * @type {string}
   * @memberof PromoCodeRestrictionsReadRequest
   */
  locale_code: string;
  /**
   *
   * @type {Array<AddonsReadRequestSortOrderInner>}
   * @memberof PromoCodeRestrictionsReadRequest
   */
  sort_order?: Array<AddonsReadRequestSortOrderInner>;
  /**
   *
   * @type {Array<AddonsReadRequestSearchInner>}
   * @memberof PromoCodeRestrictionsReadRequest
   */
  search?: Array<AddonsReadRequestSearchInner>;
  /**
   *
   * @type {number}
   * @memberof PromoCodeRestrictionsReadRequest
   */
  page_size: number;
  /**
   *
   * @type {number}
   * @memberof PromoCodeRestrictionsReadRequest
   */
  page: number;
  /**
   *
   * @type {AddonsReadRequestDownload}
   * @memberof PromoCodeRestrictionsReadRequest
   */
  download?: AddonsReadRequestDownload;
}

/**
 * Check if a given object implements the PromoCodeRestrictionsReadRequest interface.
 */
export function instanceOfPromoCodeRestrictionsReadRequest(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && 'locale_code' in value;
  isInstance = isInstance && 'page_size' in value;
  isInstance = isInstance && 'page' in value;

  return isInstance;
}

export function PromoCodeRestrictionsReadRequestFromJSON(
  json: any
): PromoCodeRestrictionsReadRequest {
  return PromoCodeRestrictionsReadRequestFromJSONTyped(json, false);
}

export function PromoCodeRestrictionsReadRequestFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): PromoCodeRestrictionsReadRequest {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    locale_code: json['locale_code'],
    sort_order: !exists(json, 'sort_order')
      ? undefined
      : (json['sort_order'] as Array<any>).map(AddonsReadRequestSortOrderInnerFromJSON),
    search: !exists(json, 'search')
      ? undefined
      : (json['search'] as Array<any>).map(AddonsReadRequestSearchInnerFromJSON),
    page_size: json['page_size'],
    page: json['page'],
    download: !exists(json, 'download')
      ? undefined
      : AddonsReadRequestDownloadFromJSON(json['download'])
  };
}

export function PromoCodeRestrictionsReadRequestToJSON(
  value?: PromoCodeRestrictionsReadRequest | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    locale_code: value.locale_code,
    sort_order:
      value.sort_order === undefined
        ? undefined
        : (value.sort_order as Array<any>).map(AddonsReadRequestSortOrderInnerToJSON),
    search:
      value.search === undefined
        ? undefined
        : (value.search as Array<any>).map(AddonsReadRequestSearchInnerToJSON),
    page_size: value.page_size,
    page: value.page,
    download: AddonsReadRequestDownloadToJSON(value.download)
  };
}
