/* tslint:disable */
/* eslint-disable */
/**
 * Ticketing Portal
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface ProductUpdateResponse
 */
export interface ProductUpdateResponse {
  /**
   *
   * @type {boolean}
   * @memberof ProductUpdateResponse
   */
  success: boolean;
  /**
   *
   * @type {string}
   * @memberof ProductUpdateResponse
   */
  exception_code?: string;
  /**
   *
   * @type {string}
   * @memberof ProductUpdateResponse
   */
  exception_message?: string;
}

/**
 * Check if a given object implements the ProductUpdateResponse interface.
 */
export function instanceOfProductUpdateResponse(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && 'success' in value;

  return isInstance;
}

export function ProductUpdateResponseFromJSON(json: any): ProductUpdateResponse {
  return ProductUpdateResponseFromJSONTyped(json, false);
}

export function ProductUpdateResponseFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): ProductUpdateResponse {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    success: json['success'],
    exception_code: !exists(json, 'exception_code') ? undefined : json['exception_code'],
    exception_message: !exists(json, 'exception_message') ? undefined : json['exception_message']
  };
}

export function ProductUpdateResponseToJSON(value?: ProductUpdateResponse | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    success: value.success,
    exception_code: value.exception_code,
    exception_message: value.exception_message
  };
}
