/* tslint:disable */
/* eslint-disable */
/**
 * Ticketing Portal
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { TimeSlotDeleteResponseTimeSlotsInner } from './TimeSlotDeleteResponseTimeSlotsInner';
import {
  TimeSlotDeleteResponseTimeSlotsInnerFromJSON,
  TimeSlotDeleteResponseTimeSlotsInnerFromJSONTyped,
  TimeSlotDeleteResponseTimeSlotsInnerToJSON
} from './TimeSlotDeleteResponseTimeSlotsInner';

/**
 *
 * @export
 * @interface TimeSlotDeleteResponse
 */
export interface TimeSlotDeleteResponse {
  /**
   *
   * @type {boolean}
   * @memberof TimeSlotDeleteResponse
   */
  success: boolean;
  /**
   *
   * @type {string}
   * @memberof TimeSlotDeleteResponse
   */
  exception_code?: string;
  /**
   *
   * @type {string}
   * @memberof TimeSlotDeleteResponse
   */
  exception_message?: string;
  /**
   *
   * @type {Array<TimeSlotDeleteResponseTimeSlotsInner>}
   * @memberof TimeSlotDeleteResponse
   */
  time_slots?: Array<TimeSlotDeleteResponseTimeSlotsInner>;
}

/**
 * Check if a given object implements the TimeSlotDeleteResponse interface.
 */
export function instanceOfTimeSlotDeleteResponse(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && 'success' in value;

  return isInstance;
}

export function TimeSlotDeleteResponseFromJSON(json: any): TimeSlotDeleteResponse {
  return TimeSlotDeleteResponseFromJSONTyped(json, false);
}

export function TimeSlotDeleteResponseFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): TimeSlotDeleteResponse {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    success: json['success'],
    exception_code: !exists(json, 'exception_code') ? undefined : json['exception_code'],
    exception_message: !exists(json, 'exception_message') ? undefined : json['exception_message'],
    time_slots: !exists(json, 'time_slots')
      ? undefined
      : (json['time_slots'] as Array<any>).map(TimeSlotDeleteResponseTimeSlotsInnerFromJSON)
  };
}

export function TimeSlotDeleteResponseToJSON(value?: TimeSlotDeleteResponse | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    success: value.success,
    exception_code: value.exception_code,
    exception_message: value.exception_message,
    time_slots:
      value.time_slots === undefined
        ? undefined
        : (value.time_slots as Array<any>).map(TimeSlotDeleteResponseTimeSlotsInnerToJSON)
  };
}
