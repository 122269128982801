import { ROUTE_NAMES } from '@/constants/routes';
import { PAGE_TITLES } from '@/constants/breadcrumbs';
import { InitializationReadResponsePermissionsEnum } from '@openapi/models';

export const ORDERS_ROUTES = [
  // We need this for breadcrumbs to work properly
  {
    path: '/sell/booking',
    redirect: '/sell/orders/bookings'
  },
  {
    path: 'sell/orders',
    name: ROUTE_NAMES.ORDERS,
    redirect: { path: '/sell/orders/bookings' },
    component: () => import('@/views/pages/sell/Orders.vue'),
    meta: {
      breadcrumbs: [PAGE_TITLES.SELL, PAGE_TITLES.ORDERS],
      label: PAGE_TITLES.ORDERS,
      icon: 'pi pi-fw pi-book',
      menu: ['main'],
      permissions: [InitializationReadResponsePermissionsEnum.OrderView]
    },
    children: [
      {
        path: 'bookings',
        name: ROUTE_NAMES.BOOKINGS_TABLE,
        component: () => import('@/views/pages/sell/OrdersBookings.vue'),
        meta: {
          breadcrumbs: [PAGE_TITLES.SELL, PAGE_TITLES.ORDERS, PAGE_TITLES.BOOKINGS]
        }
      },
      {
        path: 'gift-codes',
        name: ROUTE_NAMES.GIFT_CODE_ORDERS,
        component: () => import('@/views/pages/sell/OrdersGiftCodes.vue'),
        meta: {
          breadcrumbs: [PAGE_TITLES.SELL, PAGE_TITLES.ORDERS, PAGE_TITLES.GIFT_CODES]
        }
      },
      {
        path: 'ticket-redemption-log',
        name: ROUTE_NAMES.TICKET_REDEMPTION_LOG,
        component: () => import('@/views/pages/sell/OrdersRedeemedTickets.vue'),
        meta: {
          breadcrumbs: [PAGE_TITLES.SELL, PAGE_TITLES.ORDERS, PAGE_TITLES.TICKET_REDEMPTION_LOG]
        }
      }
    ]
  },
  {
    path: `/sell/${ROUTE_NAMES.BOOKING}/:bookingId`,
    name: ROUTE_NAMES.BOOKING,
    component: () => import('@/views/pages/sell/Booking.vue'),
    meta: {
      breadcrumbs: [PAGE_TITLES.SELL, PAGE_TITLES.BOOKINGS, PAGE_TITLES.BOOKING]
    }
  }
];
