type Entry<T> = {
  [K in keyof T]: [K, T[K]];
}[keyof T];

/**
 * filterObject
 * @usage
 * const author = { name: "Steve", age: 93, height: 241 }
 *
 * const onlySteves = filterObject(author, ([k, v]) => v === "Steve")
 * { name: "Steve" }
 *
 * const onlyNumbers = filterObject(author, ([k, v]) => typeof v === "number")
 * { age: 93, height: 241 }
 *
 * const onlyNames = filterObject(author, ([k, v]) => k === "name")
 * { name: "Steve" }
 */
export function filterObject<T extends object>(
  obj: T,
  fn: (entry: Entry<T>, i: number, arr: Entry<T>[]) => boolean
) {
  return Object.fromEntries((Object.entries(obj) as Entry<T>[]).filter(fn)) as Partial<T>;
}

/**
 * The function transforms values in an object from empty strings to nulls.
 * For example: {"name": ""} becomes {"name": null}
 * @param obj
 */
export function transformObjectEmptyStringsToNulls<T extends object>(obj: T): T {
  return Object.fromEntries(
    Object.entries(obj).map(([key, value]) => [key, value === '' ? null : value])
  ) as T;
}

/**
 * Creates a deep clone of the given object.
 *
 * @template T - The type of the object being cloned.
 * @param obj - The object to be cloned.
 * @returns A deep clone of the given object.
 *
 * @example
 * // Cloning an array
 * const originalArray = [1, 2, 3];
 * const clonedArray = deepClone(originalArray);
 *
 * // Cloning an object
 * const originalObject = { name: 'John', age: 30 };
 * const clonedObject = deepClone(originalObject);
 */
export function deepClone<T>(obj: T): T {
  return JSON.parse(JSON.stringify(obj));
}
