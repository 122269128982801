/* tslint:disable */
/* eslint-disable */
/**
 * Ticketing Portal
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { EmailReadResponsePartnerTranslationsInner } from './EmailReadResponsePartnerTranslationsInner';
import {
  EmailReadResponsePartnerTranslationsInnerFromJSON,
  EmailReadResponsePartnerTranslationsInnerFromJSONTyped,
  EmailReadResponsePartnerTranslationsInnerToJSON
} from './EmailReadResponsePartnerTranslationsInner';

/**
 *
 * @export
 * @interface EmailUpdateRequest
 */
export interface EmailUpdateRequest {
  /**
   *
   * @type {string}
   * @memberof EmailUpdateRequest
   */
  locale_code: string;
  /**
   *
   * @type {Array<EmailReadResponsePartnerTranslationsInner>}
   * @memberof EmailUpdateRequest
   */
  partner_translations: Array<EmailReadResponsePartnerTranslationsInner>;
}

/**
 * Check if a given object implements the EmailUpdateRequest interface.
 */
export function instanceOfEmailUpdateRequest(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && 'locale_code' in value;
  isInstance = isInstance && 'partner_translations' in value;

  return isInstance;
}

export function EmailUpdateRequestFromJSON(json: any): EmailUpdateRequest {
  return EmailUpdateRequestFromJSONTyped(json, false);
}

export function EmailUpdateRequestFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): EmailUpdateRequest {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    locale_code: json['locale_code'],
    partner_translations: (json['partner_translations'] as Array<any>).map(
      EmailReadResponsePartnerTranslationsInnerFromJSON
    )
  };
}

export function EmailUpdateRequestToJSON(value?: EmailUpdateRequest | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    locale_code: value.locale_code,
    partner_translations: (value.partner_translations as Array<any>).map(
      EmailReadResponsePartnerTranslationsInnerToJSON
    )
  };
}
