/* tslint:disable */
/* eslint-disable */
/**
 * Ticketing Portal
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ProductOptionTranslationUpdateRequestProductOptionTranslationsInner } from './ProductOptionTranslationUpdateRequestProductOptionTranslationsInner';
import {
  ProductOptionTranslationUpdateRequestProductOptionTranslationsInnerFromJSON,
  ProductOptionTranslationUpdateRequestProductOptionTranslationsInnerFromJSONTyped,
  ProductOptionTranslationUpdateRequestProductOptionTranslationsInnerToJSON
} from './ProductOptionTranslationUpdateRequestProductOptionTranslationsInner';

/**
 *
 * @export
 * @interface ProductOptionTranslationUpdateRequest
 */
export interface ProductOptionTranslationUpdateRequest {
  /**
   *
   * @type {string}
   * @memberof ProductOptionTranslationUpdateRequest
   */
  locale_code: string;
  /**
   *
   * @type {Array<ProductOptionTranslationUpdateRequestProductOptionTranslationsInner>}
   * @memberof ProductOptionTranslationUpdateRequest
   */
  product_option_translations: Array<ProductOptionTranslationUpdateRequestProductOptionTranslationsInner>;
}

/**
 * Check if a given object implements the ProductOptionTranslationUpdateRequest interface.
 */
export function instanceOfProductOptionTranslationUpdateRequest(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && 'locale_code' in value;
  isInstance = isInstance && 'product_option_translations' in value;

  return isInstance;
}

export function ProductOptionTranslationUpdateRequestFromJSON(
  json: any
): ProductOptionTranslationUpdateRequest {
  return ProductOptionTranslationUpdateRequestFromJSONTyped(json, false);
}

export function ProductOptionTranslationUpdateRequestFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): ProductOptionTranslationUpdateRequest {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    locale_code: json['locale_code'],
    product_option_translations: (json['product_option_translations'] as Array<any>).map(
      ProductOptionTranslationUpdateRequestProductOptionTranslationsInnerFromJSON
    )
  };
}

export function ProductOptionTranslationUpdateRequestToJSON(
  value?: ProductOptionTranslationUpdateRequest | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    locale_code: value.locale_code,
    product_option_translations: (value.product_option_translations as Array<any>).map(
      ProductOptionTranslationUpdateRequestProductOptionTranslationsInnerToJSON
    )
  };
}
