/* tslint:disable */
/* eslint-disable */
/**
 * Ticketing Portal
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface EmailReadResponsePartnerTranslationsInner
 */
export interface EmailReadResponsePartnerTranslationsInner {
  /**
   *
   * @type {string}
   * @memberof EmailReadResponsePartnerTranslationsInner
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof EmailReadResponsePartnerTranslationsInner
   */
  locale_code: string;
  /**
   *
   * @type {string}
   * @memberof EmailReadResponsePartnerTranslationsInner
   */
  important_information?: string | null;
  /**
   *
   * @type {string}
   * @memberof EmailReadResponsePartnerTranslationsInner
   */
  footer_privacy?: string | null;
}

/**
 * Check if a given object implements the EmailReadResponsePartnerTranslationsInner interface.
 */
export function instanceOfEmailReadResponsePartnerTranslationsInner(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && 'id' in value;
  isInstance = isInstance && 'locale_code' in value;

  return isInstance;
}

export function EmailReadResponsePartnerTranslationsInnerFromJSON(
  json: any
): EmailReadResponsePartnerTranslationsInner {
  return EmailReadResponsePartnerTranslationsInnerFromJSONTyped(json, false);
}

export function EmailReadResponsePartnerTranslationsInnerFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): EmailReadResponsePartnerTranslationsInner {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: json['id'],
    locale_code: json['locale_code'],
    important_information: !exists(json, 'important_information')
      ? undefined
      : json['important_information'],
    footer_privacy: !exists(json, 'footer_privacy') ? undefined : json['footer_privacy']
  };
}

export function EmailReadResponsePartnerTranslationsInnerToJSON(
  value?: EmailReadResponsePartnerTranslationsInner | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    id: value.id,
    locale_code: value.locale_code,
    important_information: value.important_information,
    footer_privacy: value.footer_privacy
  };
}
