<template>
  <Dialog
    v-if="survey"
    v-model:visible="surveyVisible"
    :header="$t(survey.name)"
    class="survey-dialog"
  >
    <div>
      <template v-for="(question, index) in survey.questions" :key="index">
        <div v-if="question.type === SurveyQuestionType.Open" class="flex flex-col">
          <label class="mb-2">{{ $t(question.question) }}</label>
          <Textarea v-model="surveyResponse" rows="3" cols="30" />
        </div>
        <!-- We can add other types here later -->
      </template>
    </div>
    <template #footer>
      <Button
        :label="$t('portal_close')"
        severity="danger"
        icon="pi pi-times"
        text
        @click="closeDialog"
      />
      <Button
        :label="$t('portal_submit')"
        severity="danger"
        icon="pi pi-check"
        autofocus
        @click="submitDialog"
      />
    </template>
  </Dialog>
</template>

<script setup lang="ts">
import { ref, watch } from 'vue';
import posthog from 'posthog-js';
import Textarea from 'primevue/textarea';
import { SurveyQuestionType } from '@/types/PostHog';
import { usePostHog } from '@/composables/use-posthog';
import Dialog from 'primevue/dialog';
import Button from 'primevue/button';

const { surveyVisible, setSurveyVisibility, survey } = usePostHog();
const surveyResponse = ref();

watch(surveyVisible, (visible) => {
  if (visible === true) {
    showDialog();
  }
});

const showDialog = () => {
  // 1. When a user is shown a survey
  posthog.capture('survey shown', {
    $survey_id: survey.value?.id // required
  });
};

const closeDialog = () => {
  setSurveyVisibility(false);
  // 2. When a user has dismissed a survey
  posthog.capture('survey dismissed', {
    $survey_id: survey.value?.id // required
  });
};

const submitDialog = () => {
  setSurveyVisibility(false);
  // 3. When a user has responded to a survey
  posthog.capture('survey sent', {
    $survey_id: survey.value?.id, // required
    $survey_response: surveyResponse.value // required.
    // TODO expand this when supporting other survey types

    //`{survey_response}` must be a text or number value (depending on your question type).
    // For multiple choice select surveys, `{survey_response}` must be an array of values with the selected choices.
    // e.g., $survey_response: ["response_1", 8, "response_2"]
  });
  // Reset the response
  surveyResponse.value = undefined;
};
</script>

<style lang="scss">
.survey-dialog {
  position: fixed;
  bottom: 1em;
  right: 1em;
  max-width: calc(100vw - 2em);
}
</style>
