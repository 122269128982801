import { ROUTE_NAMES } from '@/constants/routes';
import { PAGE_TITLES } from '@/constants/breadcrumbs';

export const AUTH_ROUTES = [
  {
    path: '/auth/login',
    name: ROUTE_NAMES.LOGIN,
    component: () => import('@/views/pages/auth/Login.vue'),
    meta: {
      breadcrumbs: [PAGE_TITLES.LOGIN]
    }
  },
  {
    path: '/auth/reset-password',
    name: ROUTE_NAMES.RESET_PASSWORD,
    component: () => import('@/views/pages/auth/ResetPassword.vue'),
    meta: {
      breadcrumbs: [PAGE_TITLES.RESET_PASSWORD]
    }
  },
  {
    path: '/auth/update-password',
    name: ROUTE_NAMES.UPDATE_PASSWORD,
    component: () => import('@/views/pages/auth/UpdatePassword.vue'),
    meta: {
      breadcrumbs: [PAGE_TITLES.UPDATE_PASSWORD]
    }
  },
  {
    path: '/auth/register',
    name: ROUTE_NAMES.REGISTER,
    component: () => import('@/views/pages/auth/Register.vue'),
    meta: {
      breadcrumbs: [PAGE_TITLES.REGISTER]
    },
    children: [
      {
        path: '/auth/register',
        name: ROUTE_NAMES.REGISTER_CREATE_ACCOUNT,
        component: () => import('@/views/pages/auth/RegisterCreate.vue'),
        meta: {
          breadcrumbs: [PAGE_TITLES.REGISTER, PAGE_TITLES.REGISTER_CREATE_ACCOUNT]
        }
      },
      {
        path: '/auth/register/business-information',
        name: ROUTE_NAMES.REGISTER_BUSINESS_INFORMATION,
        component: () => import('@/views/pages/auth/RegisterBusinessInfo.vue'),
        meta: {
          breadcrumbs: [PAGE_TITLES.REGISTER, PAGE_TITLES.REGISTER_BUSINESS_INFORMATION]
        }
      },
      {
        path: '/auth/register/confirmation',
        name: ROUTE_NAMES.REGISTER_CONFIRMATION,
        component: () => import('@/views/pages/auth/RegisterConfirmation.vue'),
        meta: {
          breadcrumbs: [PAGE_TITLES.REGISTER, PAGE_TITLES.REGISTER_CONFIRMATION]
        }
      }
    ]
  },
  {
    path: '/auth/login-impersonate',
    name: ROUTE_NAMES.LOGIN_IMPERSONATE,
    component: () => import('@/views/pages/auth/LoginImpersonate.vue'),
    meta: {
      breadcrumbs: [PAGE_TITLES.LOGIN_IMPERSONATE]
    }
  },
  {
    path: '/auth/access',
    name: ROUTE_NAMES.ACCESS_DENIED,
    component: () => import('@/views/pages/auth/Access.vue'),
    meta: {
      breadcrumbs: [PAGE_TITLES.ACCESS_DENIED]
    }
  },
  {
    path: '/auth/error',
    name: ROUTE_NAMES.ERROR,
    component: () => import('@/views/pages/auth/Error.vue'),
    meta: {
      breadcrumbs: [PAGE_TITLES.ERROR]
    }
  }
];
