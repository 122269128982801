/* tslint:disable */
/* eslint-disable */
/**
 * Ticketing Portal
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { BookingByIdReadResponseRefundRefundsInner } from './BookingByIdReadResponseRefundRefundsInner';
import {
  BookingByIdReadResponseRefundRefundsInnerFromJSON,
  BookingByIdReadResponseRefundRefundsInnerFromJSONTyped,
  BookingByIdReadResponseRefundRefundsInnerToJSON
} from './BookingByIdReadResponseRefundRefundsInner';

/**
 *
 * @export
 * @interface BookingByIdReadResponseRefund
 */
export interface BookingByIdReadResponseRefund {
  /**
   *
   * @type {number}
   * @memberof BookingByIdReadResponseRefund
   */
  remaining_refund_amount: number;
  /**
   *
   * @type {Array<BookingByIdReadResponseRefundRefundsInner>}
   * @memberof BookingByIdReadResponseRefund
   */
  refunds: Array<BookingByIdReadResponseRefundRefundsInner>;
}

/**
 * Check if a given object implements the BookingByIdReadResponseRefund interface.
 */
export function instanceOfBookingByIdReadResponseRefund(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && 'remaining_refund_amount' in value;
  isInstance = isInstance && 'refunds' in value;

  return isInstance;
}

export function BookingByIdReadResponseRefundFromJSON(json: any): BookingByIdReadResponseRefund {
  return BookingByIdReadResponseRefundFromJSONTyped(json, false);
}

export function BookingByIdReadResponseRefundFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): BookingByIdReadResponseRefund {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    remaining_refund_amount: json['remaining_refund_amount'],
    refunds: (json['refunds'] as Array<any>).map(BookingByIdReadResponseRefundRefundsInnerFromJSON)
  };
}

export function BookingByIdReadResponseRefundToJSON(
  value?: BookingByIdReadResponseRefund | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    remaining_refund_amount: value.remaining_refund_amount,
    refunds: (value.refunds as Array<any>).map(BookingByIdReadResponseRefundRefundsInnerToJSON)
  };
}
