/* tslint:disable */
/* eslint-disable */
/**
 * Ticketing Portal
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface InitializationReadResponsePartnerCurrency
 */
export interface InitializationReadResponsePartnerCurrency {
  /**
   *
   * @type {string}
   * @memberof InitializationReadResponsePartnerCurrency
   */
  code: string;
  /**
   *
   * @type {number}
   * @memberof InitializationReadResponsePartnerCurrency
   */
  num_fraction_digits: number;
}

/**
 * Check if a given object implements the InitializationReadResponsePartnerCurrency interface.
 */
export function instanceOfInitializationReadResponsePartnerCurrency(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && 'code' in value;
  isInstance = isInstance && 'num_fraction_digits' in value;

  return isInstance;
}

export function InitializationReadResponsePartnerCurrencyFromJSON(
  json: any
): InitializationReadResponsePartnerCurrency {
  return InitializationReadResponsePartnerCurrencyFromJSONTyped(json, false);
}

export function InitializationReadResponsePartnerCurrencyFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): InitializationReadResponsePartnerCurrency {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    code: json['code'],
    num_fraction_digits: json['num_fraction_digits']
  };
}

export function InitializationReadResponsePartnerCurrencyToJSON(
  value?: InitializationReadResponsePartnerCurrency | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    code: value.code,
    num_fraction_digits: value.num_fraction_digits
  };
}
