/* tslint:disable */
/* eslint-disable */
/**
 * Ticketing Portal
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { AddonByIdReadResponseAddonTranslationsInner } from './AddonByIdReadResponseAddonTranslationsInner';
import {
  AddonByIdReadResponseAddonTranslationsInnerFromJSON,
  AddonByIdReadResponseAddonTranslationsInnerFromJSONTyped,
  AddonByIdReadResponseAddonTranslationsInnerToJSON
} from './AddonByIdReadResponseAddonTranslationsInner';

/**
 *
 * @export
 * @interface AddonByIdReadResponseAddon
 */
export interface AddonByIdReadResponseAddon {
  /**
   *
   * @type {string}
   * @memberof AddonByIdReadResponseAddon
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof AddonByIdReadResponseAddon
   */
  name: string;
  /**
   *
   * @type {number}
   * @memberof AddonByIdReadResponseAddon
   */
  min_quantity: number;
  /**
   *
   * @type {number}
   * @memberof AddonByIdReadResponseAddon
   */
  max_quantity: number | null;
  /**
   *
   * @type {string}
   * @memberof AddonByIdReadResponseAddon
   */
  added_to: AddonByIdReadResponseAddonAddedToEnum;
  /**
   *
   * @type {string}
   * @memberof AddonByIdReadResponseAddon
   */
  status: AddonByIdReadResponseAddonStatusEnum;
  /**
   *
   * @type {Array<AddonByIdReadResponseAddonTranslationsInner>}
   * @memberof AddonByIdReadResponseAddon
   */
  translations: Array<AddonByIdReadResponseAddonTranslationsInner>;
}

/**
 * @export
 */
export const AddonByIdReadResponseAddonAddedToEnum = {
  Booking: 'booking',
  Participant: 'participant'
} as const;
export type AddonByIdReadResponseAddonAddedToEnum =
  (typeof AddonByIdReadResponseAddonAddedToEnum)[keyof typeof AddonByIdReadResponseAddonAddedToEnum];

/**
 * @export
 */
export const AddonByIdReadResponseAddonStatusEnum = {
  Active: 'active',
  Inactive: 'inactive',
  Deleted: 'deleted'
} as const;
export type AddonByIdReadResponseAddonStatusEnum =
  (typeof AddonByIdReadResponseAddonStatusEnum)[keyof typeof AddonByIdReadResponseAddonStatusEnum];

/**
 * Check if a given object implements the AddonByIdReadResponseAddon interface.
 */
export function instanceOfAddonByIdReadResponseAddon(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && 'id' in value;
  isInstance = isInstance && 'name' in value;
  isInstance = isInstance && 'min_quantity' in value;
  isInstance = isInstance && 'max_quantity' in value;
  isInstance = isInstance && 'added_to' in value;
  isInstance = isInstance && 'status' in value;
  isInstance = isInstance && 'translations' in value;

  return isInstance;
}

export function AddonByIdReadResponseAddonFromJSON(json: any): AddonByIdReadResponseAddon {
  return AddonByIdReadResponseAddonFromJSONTyped(json, false);
}

export function AddonByIdReadResponseAddonFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): AddonByIdReadResponseAddon {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: json['id'],
    name: json['name'],
    min_quantity: json['min_quantity'],
    max_quantity: json['max_quantity'],
    added_to: json['added_to'],
    status: json['status'],
    translations: (json['translations'] as Array<any>).map(
      AddonByIdReadResponseAddonTranslationsInnerFromJSON
    )
  };
}

export function AddonByIdReadResponseAddonToJSON(value?: AddonByIdReadResponseAddon | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    id: value.id,
    name: value.name,
    min_quantity: value.min_quantity,
    max_quantity: value.max_quantity,
    added_to: value.added_to,
    status: value.status,
    translations: (value.translations as Array<any>).map(
      AddonByIdReadResponseAddonTranslationsInnerToJSON
    )
  };
}
