/* tslint:disable */
/* eslint-disable */
/**
 * Ticketing Portal
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ProductOptionReadResponseProductOptionInner } from './ProductOptionReadResponseProductOptionInner';
import {
  ProductOptionReadResponseProductOptionInnerFromJSON,
  ProductOptionReadResponseProductOptionInnerFromJSONTyped,
  ProductOptionReadResponseProductOptionInnerToJSON
} from './ProductOptionReadResponseProductOptionInner';

/**
 *
 * @export
 * @interface ProductOptionReadResponse
 */
export interface ProductOptionReadResponse {
  /**
   *
   * @type {boolean}
   * @memberof ProductOptionReadResponse
   */
  success: boolean;
  /**
   *
   * @type {string}
   * @memberof ProductOptionReadResponse
   */
  exception_code?: string;
  /**
   *
   * @type {string}
   * @memberof ProductOptionReadResponse
   */
  exception_message?: string;
  /**
   *
   * @type {Array<ProductOptionReadResponseProductOptionInner>}
   * @memberof ProductOptionReadResponse
   */
  product_option: Array<ProductOptionReadResponseProductOptionInner>;
}

/**
 * Check if a given object implements the ProductOptionReadResponse interface.
 */
export function instanceOfProductOptionReadResponse(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && 'success' in value;
  isInstance = isInstance && 'product_option' in value;

  return isInstance;
}

export function ProductOptionReadResponseFromJSON(json: any): ProductOptionReadResponse {
  return ProductOptionReadResponseFromJSONTyped(json, false);
}

export function ProductOptionReadResponseFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): ProductOptionReadResponse {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    success: json['success'],
    exception_code: !exists(json, 'exception_code') ? undefined : json['exception_code'],
    exception_message: !exists(json, 'exception_message') ? undefined : json['exception_message'],
    product_option: (json['product_option'] as Array<any>).map(
      ProductOptionReadResponseProductOptionInnerFromJSON
    )
  };
}

export function ProductOptionReadResponseToJSON(value?: ProductOptionReadResponse | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    success: value.success,
    exception_code: value.exception_code,
    exception_message: value.exception_message,
    product_option: (value.product_option as Array<any>).map(
      ProductOptionReadResponseProductOptionInnerToJSON
    )
  };
}
