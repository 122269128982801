<template>
  <Field v-slot="{ value, handleChange }" :name="name" :rules="rules">
    <label
      v-for="option in options"
      :key="option.label"
      :for="option.label"
      :class="[
        'flex cursor-pointer items-center rounded-t border-2 bg-surface-0 p-4 transition-all duration-200 ease-in dark:bg-surface-900',
        option.value === value ? 'border-primary' : 'border-surface'
      ]"
    >
      <RadioButton
        class="mr-4"
        :disabled="disabled"
        :model-value="value"
        :input-id="option.label"
        :value="option.value"
        @update:model-value="handleChange"
      />
      <div class="mr-6 md:mr-20">
        <p class="mb-0 font-medium">{{ option.label }}</p>
        <small v-if="option.subLabel" class="font-normal">{{ option.subLabel }}</small>
      </div>
    </label>
  </Field>
</template>

<script setup lang="ts">
import { Field } from 'vee-validate';
import { toRefs, type PropType } from 'vue';
import RadioButton from 'primevue/radiobutton';

const props = defineProps({
  autofocus: {
    type: Boolean,
    required: false,
    default: false
  },
  name: {
    type: String,
    required: true,
    default: ''
  },
  placeholder: {
    type: String,
    required: false,
    default: ''
  },
  options: {
    type: Array as PropType<{ label: string; value: string; subLabel?: string }[]>,
    required: true,
    default: () => []
  },
  rules: {
    type: String,
    required: false,
    default: ''
  },
  helpText: {
    type: String,
    required: false,
    default: null
  },
  label: {
    type: String,
    required: false,
    default: null
  },
  disabled: {
    type: Boolean,
    required: false,
    default: false
  }
});

const { name, options, rules } = toRefs(props);
</script>
