/* tslint:disable */
/* eslint-disable */
/**
 * Ticketing Portal
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ChannelsReadResponseMeta } from './ChannelsReadResponseMeta';
import {
  ChannelsReadResponseMetaFromJSON,
  ChannelsReadResponseMetaFromJSONTyped,
  ChannelsReadResponseMetaToJSON
} from './ChannelsReadResponseMeta';
import type { TimeSlotReadResponseTimeSlotsInner } from './TimeSlotReadResponseTimeSlotsInner';
import {
  TimeSlotReadResponseTimeSlotsInnerFromJSON,
  TimeSlotReadResponseTimeSlotsInnerFromJSONTyped,
  TimeSlotReadResponseTimeSlotsInnerToJSON
} from './TimeSlotReadResponseTimeSlotsInner';

/**
 *
 * @export
 * @interface TimeSlotReadResponse
 */
export interface TimeSlotReadResponse {
  /**
   *
   * @type {boolean}
   * @memberof TimeSlotReadResponse
   */
  success: boolean;
  /**
   *
   * @type {string}
   * @memberof TimeSlotReadResponse
   */
  exception_code?: string;
  /**
   *
   * @type {string}
   * @memberof TimeSlotReadResponse
   */
  exception_message?: string;
  /**
   *
   * @type {ChannelsReadResponseMeta}
   * @memberof TimeSlotReadResponse
   */
  meta: ChannelsReadResponseMeta;
  /**
   *
   * @type {Array<TimeSlotReadResponseTimeSlotsInner>}
   * @memberof TimeSlotReadResponse
   */
  time_slots: Array<TimeSlotReadResponseTimeSlotsInner>;
}

/**
 * Check if a given object implements the TimeSlotReadResponse interface.
 */
export function instanceOfTimeSlotReadResponse(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && 'success' in value;
  isInstance = isInstance && 'meta' in value;
  isInstance = isInstance && 'time_slots' in value;

  return isInstance;
}

export function TimeSlotReadResponseFromJSON(json: any): TimeSlotReadResponse {
  return TimeSlotReadResponseFromJSONTyped(json, false);
}

export function TimeSlotReadResponseFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): TimeSlotReadResponse {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    success: json['success'],
    exception_code: !exists(json, 'exception_code') ? undefined : json['exception_code'],
    exception_message: !exists(json, 'exception_message') ? undefined : json['exception_message'],
    meta: ChannelsReadResponseMetaFromJSON(json['meta']),
    time_slots: (json['time_slots'] as Array<any>).map(TimeSlotReadResponseTimeSlotsInnerFromJSON)
  };
}

export function TimeSlotReadResponseToJSON(value?: TimeSlotReadResponse | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    success: value.success,
    exception_code: value.exception_code,
    exception_message: value.exception_message,
    meta: ChannelsReadResponseMetaToJSON(value.meta),
    time_slots: (value.time_slots as Array<any>).map(TimeSlotReadResponseTimeSlotsInnerToJSON)
  };
}
