/* tslint:disable */
/* eslint-disable */
/**
 * Ticketing Portal
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface PayoutByIdReadResponsePayoutPartner
 */
export interface PayoutByIdReadResponsePayoutPartner {
  /**
   * Partner ID
   * @type {string}
   * @memberof PayoutByIdReadResponsePayoutPartner
   */
  id: string;
  /**
   * Partner name
   * @type {string}
   * @memberof PayoutByIdReadResponsePayoutPartner
   */
  name: string;
  /**
   * Partner logo file ID
   * @type {string}
   * @memberof PayoutByIdReadResponsePayoutPartner
   */
  logo_file_id: string | null;
}

/**
 * Check if a given object implements the PayoutByIdReadResponsePayoutPartner interface.
 */
export function instanceOfPayoutByIdReadResponsePayoutPartner(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && 'id' in value;
  isInstance = isInstance && 'name' in value;
  isInstance = isInstance && 'logo_file_id' in value;

  return isInstance;
}

export function PayoutByIdReadResponsePayoutPartnerFromJSON(
  json: any
): PayoutByIdReadResponsePayoutPartner {
  return PayoutByIdReadResponsePayoutPartnerFromJSONTyped(json, false);
}

export function PayoutByIdReadResponsePayoutPartnerFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): PayoutByIdReadResponsePayoutPartner {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: json['id'],
    name: json['name'],
    logo_file_id: json['logo_file_id']
  };
}

export function PayoutByIdReadResponsePayoutPartnerToJSON(
  value?: PayoutByIdReadResponsePayoutPartner | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    id: value.id,
    name: value.name,
    logo_file_id: value.logo_file_id
  };
}
