import { DEFAULT_LOCALE, DEFAULT_LOCALE_NAME } from '@/constants/i18n';
import type { Locale } from '@/types/types';
import { defineStore } from 'pinia';

interface LocalesState {
  locales: Locale[];
  selected: Locale['code'];
}

export const useLocaleStore = defineStore('LocaleStore', {
  state: (): LocalesState => {
    return {
      locales: [],
      selected: DEFAULT_LOCALE
    };
  },

  getters: {
    getSelectedLocale: (state: LocalesState) => {
      return state.selected;
    },
    getSelectedLocaleName: (state: LocalesState) => {
      return (
        state.locales.find((locale) => locale.code === state.selected)?.name || DEFAULT_LOCALE_NAME
      );
    },
    getLocales: (state: LocalesState) => {
      return state.locales;
    }
  },

  actions: {
    setSelected(locale: Locale['code']) {
      this.selected = locale;
    },
    setLocales(locales: Locale[]) {
      this.locales = locales;
    }
  }
});
