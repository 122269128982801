/* tslint:disable */
/* eslint-disable */
/**
 * Ticketing Portal
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface PricingCategoryRankUpdateRequest
 */
export interface PricingCategoryRankUpdateRequest {
  /**
   *
   * @type {string}
   * @memberof PricingCategoryRankUpdateRequest
   */
  locale_code: string;
  /**
   *
   * @type {Array<string>}
   * @memberof PricingCategoryRankUpdateRequest
   */
  pricing_category_ids: Array<string>;
}

/**
 * Check if a given object implements the PricingCategoryRankUpdateRequest interface.
 */
export function instanceOfPricingCategoryRankUpdateRequest(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && 'locale_code' in value;
  isInstance = isInstance && 'pricing_category_ids' in value;

  return isInstance;
}

export function PricingCategoryRankUpdateRequestFromJSON(
  json: any
): PricingCategoryRankUpdateRequest {
  return PricingCategoryRankUpdateRequestFromJSONTyped(json, false);
}

export function PricingCategoryRankUpdateRequestFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): PricingCategoryRankUpdateRequest {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    locale_code: json['locale_code'],
    pricing_category_ids: json['pricing_category_ids']
  };
}

export function PricingCategoryRankUpdateRequestToJSON(
  value?: PricingCategoryRankUpdateRequest | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    locale_code: value.locale_code,
    pricing_category_ids: value.pricing_category_ids
  };
}
