/* tslint:disable */
/* eslint-disable */
/**
 * Ticketing Portal
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface UserByIdReadResponseUser
 */
export interface UserByIdReadResponseUser {
  /**
   * User ID
   * @type {string}
   * @memberof UserByIdReadResponseUser
   */
  id: string;
  /**
   * User first name
   * @type {string}
   * @memberof UserByIdReadResponseUser
   */
  first_name: string;
  /**
   * User last name
   * @type {string}
   * @memberof UserByIdReadResponseUser
   */
  last_name: string;
  /**
   * User email address
   * @type {string}
   * @memberof UserByIdReadResponseUser
   */
  email: string;
  /**
   * User status
   * @type {string}
   * @memberof UserByIdReadResponseUser
   */
  status: UserByIdReadResponseUserStatusEnum;
  /**
   * List of roles assigned to the user
   * @type {Array<string>}
   * @memberof UserByIdReadResponseUser
   */
  roles: Array<string>;
}

/**
 * @export
 */
export const UserByIdReadResponseUserStatusEnum = {
  Draft: 'draft',
  Invited: 'invited',
  Unverified: 'unverified',
  Active: 'active',
  Suspended: 'suspended',
  Archived: 'archived'
} as const;
export type UserByIdReadResponseUserStatusEnum =
  (typeof UserByIdReadResponseUserStatusEnum)[keyof typeof UserByIdReadResponseUserStatusEnum];

/**
 * Check if a given object implements the UserByIdReadResponseUser interface.
 */
export function instanceOfUserByIdReadResponseUser(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && 'id' in value;
  isInstance = isInstance && 'first_name' in value;
  isInstance = isInstance && 'last_name' in value;
  isInstance = isInstance && 'email' in value;
  isInstance = isInstance && 'status' in value;
  isInstance = isInstance && 'roles' in value;

  return isInstance;
}

export function UserByIdReadResponseUserFromJSON(json: any): UserByIdReadResponseUser {
  return UserByIdReadResponseUserFromJSONTyped(json, false);
}

export function UserByIdReadResponseUserFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): UserByIdReadResponseUser {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: json['id'],
    first_name: json['first_name'],
    last_name: json['last_name'],
    email: json['email'],
    status: json['status'],
    roles: json['roles']
  };
}

export function UserByIdReadResponseUserToJSON(value?: UserByIdReadResponseUser | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    id: value.id,
    first_name: value.first_name,
    last_name: value.last_name,
    email: value.email,
    status: value.status,
    roles: value.roles
  };
}
