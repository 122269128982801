import type { ToastMessageOptions } from 'primevue/toast';

//@ts-expect-error - ToastEventBus types are not exported from primevue/toasteventbus
import ToastEventBus from 'primevue/toasteventbus';

export const useToastService = () => {
  const add = (message: ToastMessageOptions) => {
    ToastEventBus.emit('add', message);
  };

  return { add };
};
