import { fetchPortalTranslations } from '@/i18n';
import type { App } from 'vue';
import { useSentry } from '@/sentry';
import { configureVeeValidate } from '@/vee-validate';

/**
 * All the async calls necessary before starting the app should go here.
 */
export const bootstrapApp = async (app: App) => {
  // Initialize Sentry
  useSentry(app);

  // Load translations from the backend
  await fetchPortalTranslations();

  // Translate error messages in vee-validate
  await configureVeeValidate();
};
