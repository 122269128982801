/* tslint:disable */
/* eslint-disable */
/**
 * Ticketing Portal
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface ProductOptionAddressReadResponseProductOptionAddress
 */
export interface ProductOptionAddressReadResponseProductOptionAddress {
  /**
   *
   * @type {string}
   * @memberof ProductOptionAddressReadResponseProductOptionAddress
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof ProductOptionAddressReadResponseProductOptionAddress
   */
  address_id: string;
  /**
   *
   * @type {string}
   * @memberof ProductOptionAddressReadResponseProductOptionAddress
   */
  zip: string | null;
  /**
   *
   * @type {string}
   * @memberof ProductOptionAddressReadResponseProductOptionAddress
   */
  city: string | null;
  /**
   *
   * @type {string}
   * @memberof ProductOptionAddressReadResponseProductOptionAddress
   */
  country: string | null;
  /**
   *
   * @type {string}
   * @memberof ProductOptionAddressReadResponseProductOptionAddress
   */
  state: string | null;
  /**
   *
   * @type {string}
   * @memberof ProductOptionAddressReadResponseProductOptionAddress
   */
  street: string | null;
}

/**
 * Check if a given object implements the ProductOptionAddressReadResponseProductOptionAddress interface.
 */
export function instanceOfProductOptionAddressReadResponseProductOptionAddress(
  value: object
): boolean {
  let isInstance = true;
  isInstance = isInstance && 'id' in value;
  isInstance = isInstance && 'address_id' in value;
  isInstance = isInstance && 'zip' in value;
  isInstance = isInstance && 'city' in value;
  isInstance = isInstance && 'country' in value;
  isInstance = isInstance && 'state' in value;
  isInstance = isInstance && 'street' in value;

  return isInstance;
}

export function ProductOptionAddressReadResponseProductOptionAddressFromJSON(
  json: any
): ProductOptionAddressReadResponseProductOptionAddress {
  return ProductOptionAddressReadResponseProductOptionAddressFromJSONTyped(json, false);
}

export function ProductOptionAddressReadResponseProductOptionAddressFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): ProductOptionAddressReadResponseProductOptionAddress {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: json['id'],
    address_id: json['address_id'],
    zip: json['zip'],
    city: json['city'],
    country: json['country'],
    state: json['state'],
    street: json['street']
  };
}

export function ProductOptionAddressReadResponseProductOptionAddressToJSON(
  value?: ProductOptionAddressReadResponseProductOptionAddress | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    id: value.id,
    address_id: value.address_id,
    zip: value.zip,
    city: value.city,
    country: value.country,
    state: value.state,
    street: value.street
  };
}
