/* tslint:disable */
/* eslint-disable */
/**
 * Ticketing Portal
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { RolesReadResponse1RolesInner } from './RolesReadResponse1RolesInner';
import {
  RolesReadResponse1RolesInnerFromJSON,
  RolesReadResponse1RolesInnerFromJSONTyped,
  RolesReadResponse1RolesInnerToJSON
} from './RolesReadResponse1RolesInner';

/**
 *
 * @export
 * @interface RolesReadResponse1
 */
export interface RolesReadResponse1 {
  /**
   *
   * @type {boolean}
   * @memberof RolesReadResponse1
   */
  success: boolean;
  /**
   *
   * @type {string}
   * @memberof RolesReadResponse1
   */
  exception_code?: string;
  /**
   *
   * @type {string}
   * @memberof RolesReadResponse1
   */
  exception_message?: string;
  /**
   *
   * @type {Array<RolesReadResponse1RolesInner>}
   * @memberof RolesReadResponse1
   */
  roles: Array<RolesReadResponse1RolesInner>;
}

/**
 * Check if a given object implements the RolesReadResponse1 interface.
 */
export function instanceOfRolesReadResponse1(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && 'success' in value;
  isInstance = isInstance && 'roles' in value;

  return isInstance;
}

export function RolesReadResponse1FromJSON(json: any): RolesReadResponse1 {
  return RolesReadResponse1FromJSONTyped(json, false);
}

export function RolesReadResponse1FromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): RolesReadResponse1 {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    success: json['success'],
    exception_code: !exists(json, 'exception_code') ? undefined : json['exception_code'],
    exception_message: !exists(json, 'exception_message') ? undefined : json['exception_message'],
    roles: (json['roles'] as Array<any>).map(RolesReadResponse1RolesInnerFromJSON)
  };
}

export function RolesReadResponse1ToJSON(value?: RolesReadResponse1 | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    success: value.success,
    exception_code: value.exception_code,
    exception_message: value.exception_message,
    roles: (value.roles as Array<any>).map(RolesReadResponse1RolesInnerToJSON)
  };
}
