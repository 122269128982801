import { definePreset, palette } from '@primevue/themes';
import Aura from '@primevue/themes/aura';

const GET_YOUR_GUIDE_BLUE = '#0071eb';
const gygColorPaletteBlue = palette(GET_YOUR_GUIDE_BLUE);

export const GetYourGuideTicketingTheme = definePreset(Aura, {
  semantic: {
    colorScheme: {
      light: {
        primary: gygColorPaletteBlue,
        text: {
          color: '{neutral.700}'
        }
      }
    }
  },
  components: {
    card: {
      borderRadius: '0'
    }
  }
});
