/* tslint:disable */
/* eslint-disable */
/**
 * Ticketing Portal
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface ProductOptionByIdReadResponseProductOption
 */
export interface ProductOptionByIdReadResponseProductOption {
  /**
   *
   * @type {string}
   * @memberof ProductOptionByIdReadResponseProductOption
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof ProductOptionByIdReadResponseProductOption
   */
  product_id: string;
  /**
   *
   * @type {string}
   * @memberof ProductOptionByIdReadResponseProductOption
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof ProductOptionByIdReadResponseProductOption
   */
  time_slot_type: ProductOptionByIdReadResponseProductOptionTimeSlotTypeEnum;
  /**
   *
   * @type {string}
   * @memberof ProductOptionByIdReadResponseProductOption
   */
  status: ProductOptionByIdReadResponseProductOptionStatusEnum;
  /**
   *
   * @type {boolean}
   * @memberof ProductOptionByIdReadResponseProductOption
   */
  has_time_slots: boolean;
  /**
   *
   * @type {string}
   * @memberof ProductOptionByIdReadResponseProductOption
   */
  partner_confirmation_type: ProductOptionByIdReadResponseProductOptionPartnerConfirmationTypeEnum;
}

/**
 * @export
 */
export const ProductOptionByIdReadResponseProductOptionTimeSlotTypeEnum = {
  StartingTime: 'starting_time',
  OpeningHours: 'opening_hours',
  TimeRange: 'time_range',
  Flexible: 'flexible'
} as const;
export type ProductOptionByIdReadResponseProductOptionTimeSlotTypeEnum =
  (typeof ProductOptionByIdReadResponseProductOptionTimeSlotTypeEnum)[keyof typeof ProductOptionByIdReadResponseProductOptionTimeSlotTypeEnum];

/**
 * @export
 */
export const ProductOptionByIdReadResponseProductOptionStatusEnum = {
  Active: 'active',
  Deleted: 'deleted',
  Inactive: 'inactive',
  Draft: 'draft'
} as const;
export type ProductOptionByIdReadResponseProductOptionStatusEnum =
  (typeof ProductOptionByIdReadResponseProductOptionStatusEnum)[keyof typeof ProductOptionByIdReadResponseProductOptionStatusEnum];

/**
 * @export
 */
export const ProductOptionByIdReadResponseProductOptionPartnerConfirmationTypeEnum = {
  Automatic: 'automatic',
  ConfirmOrReject: 'confirm_or_reject'
} as const;
export type ProductOptionByIdReadResponseProductOptionPartnerConfirmationTypeEnum =
  (typeof ProductOptionByIdReadResponseProductOptionPartnerConfirmationTypeEnum)[keyof typeof ProductOptionByIdReadResponseProductOptionPartnerConfirmationTypeEnum];

/**
 * Check if a given object implements the ProductOptionByIdReadResponseProductOption interface.
 */
export function instanceOfProductOptionByIdReadResponseProductOption(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && 'id' in value;
  isInstance = isInstance && 'product_id' in value;
  isInstance = isInstance && 'name' in value;
  isInstance = isInstance && 'time_slot_type' in value;
  isInstance = isInstance && 'status' in value;
  isInstance = isInstance && 'has_time_slots' in value;
  isInstance = isInstance && 'partner_confirmation_type' in value;

  return isInstance;
}

export function ProductOptionByIdReadResponseProductOptionFromJSON(
  json: any
): ProductOptionByIdReadResponseProductOption {
  return ProductOptionByIdReadResponseProductOptionFromJSONTyped(json, false);
}

export function ProductOptionByIdReadResponseProductOptionFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): ProductOptionByIdReadResponseProductOption {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: json['id'],
    product_id: json['product_id'],
    name: json['name'],
    time_slot_type: json['time_slot_type'],
    status: json['status'],
    has_time_slots: json['has_time_slots'],
    partner_confirmation_type: json['partner_confirmation_type']
  };
}

export function ProductOptionByIdReadResponseProductOptionToJSON(
  value?: ProductOptionByIdReadResponseProductOption | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    id: value.id,
    product_id: value.product_id,
    name: value.name,
    time_slot_type: value.time_slot_type,
    status: value.status,
    has_time_slots: value.has_time_slots,
    partner_confirmation_type: value.partner_confirmation_type
  };
}
