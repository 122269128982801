/* tslint:disable */
/* eslint-disable */
/**
 * Ticketing Portal
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { TicketByCodeReadResponseTicketAddonValiditiesInner } from './TicketByCodeReadResponseTicketAddonValiditiesInner';
import {
  TicketByCodeReadResponseTicketAddonValiditiesInnerFromJSON,
  TicketByCodeReadResponseTicketAddonValiditiesInnerFromJSONTyped,
  TicketByCodeReadResponseTicketAddonValiditiesInnerToJSON
} from './TicketByCodeReadResponseTicketAddonValiditiesInner';
import type { TicketByCodeReadResponseTicketParticipantValiditiesInner } from './TicketByCodeReadResponseTicketParticipantValiditiesInner';
import {
  TicketByCodeReadResponseTicketParticipantValiditiesInnerFromJSON,
  TicketByCodeReadResponseTicketParticipantValiditiesInnerFromJSONTyped,
  TicketByCodeReadResponseTicketParticipantValiditiesInnerToJSON
} from './TicketByCodeReadResponseTicketParticipantValiditiesInner';

/**
 *
 * @export
 * @interface TicketByCodeReadResponseTicket
 */
export interface TicketByCodeReadResponseTicket {
  /**
   *
   * @type {string}
   * @memberof TicketByCodeReadResponseTicket
   */
  booking_id: string;
  /**
   * Flag to indicate if the booking of the ticket contains other tickets
   * @type {boolean}
   * @memberof TicketByCodeReadResponseTicket
   */
  booking_contains_other_tickets: boolean;
  /**
   *
   * @type {string}
   * @memberof TicketByCodeReadResponseTicket
   */
  code: string;
  /**
   *
   * @type {string}
   * @memberof TicketByCodeReadResponseTicket
   */
  booking_status: TicketByCodeReadResponseTicketBookingStatusEnum;
  /**
   *
   * @type {string}
   * @memberof TicketByCodeReadResponseTicket
   */
  ticket_status: TicketByCodeReadResponseTicketTicketStatusEnum;
  /**
   *
   * @type {string}
   * @memberof TicketByCodeReadResponseTicket
   */
  customer_name: string;
  /**
   *
   * @type {string}
   * @memberof TicketByCodeReadResponseTicket
   */
  product_name_translated: string;
  /**
   *
   * @type {Array<TicketByCodeReadResponseTicketParticipantValiditiesInner>}
   * @memberof TicketByCodeReadResponseTicket
   */
  participant_validities: Array<TicketByCodeReadResponseTicketParticipantValiditiesInner>;
  /**
   *
   * @type {Array<TicketByCodeReadResponseTicketAddonValiditiesInner>}
   * @memberof TicketByCodeReadResponseTicket
   */
  addon_validities: Array<TicketByCodeReadResponseTicketAddonValiditiesInner>;
}

/**
 * @export
 */
export const TicketByCodeReadResponseTicketBookingStatusEnum = {
  Active: 'active',
  CancelledByCustomer: 'cancelled_by_customer',
  CancelledByPartner: 'cancelled_by_partner',
  DeletedByCustomer: 'deleted_by_customer',
  Processed: 'processed',
  CancelledByAdmin: 'cancelled_by_admin',
  DeletedByCleanupJob: 'deleted_by_cleanup_job',
  DeletedForCloning: 'deleted_for_cloning',
  ReservationSystemUnconfirmed: 'reservation_system_unconfirmed',
  AwaitingPartnerConfirmation: 'awaiting_partner_confirmation',
  PendingPaymentCompletion: 'pending_payment_completion'
} as const;
export type TicketByCodeReadResponseTicketBookingStatusEnum =
  (typeof TicketByCodeReadResponseTicketBookingStatusEnum)[keyof typeof TicketByCodeReadResponseTicketBookingStatusEnum];

/**
 * @export
 */
export const TicketByCodeReadResponseTicketTicketStatusEnum = {
  Active: 'active',
  CancelledByCustomer: 'cancelled_by_customer',
  CancelledByPartner: 'cancelled_by_partner',
  CancelledByAdmin: 'cancelled_by_admin',
  OnSitePaymentConfirmed: 'on_site_payment_confirmed'
} as const;
export type TicketByCodeReadResponseTicketTicketStatusEnum =
  (typeof TicketByCodeReadResponseTicketTicketStatusEnum)[keyof typeof TicketByCodeReadResponseTicketTicketStatusEnum];

/**
 * Check if a given object implements the TicketByCodeReadResponseTicket interface.
 */
export function instanceOfTicketByCodeReadResponseTicket(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && 'booking_id' in value;
  isInstance = isInstance && 'booking_contains_other_tickets' in value;
  isInstance = isInstance && 'code' in value;
  isInstance = isInstance && 'booking_status' in value;
  isInstance = isInstance && 'ticket_status' in value;
  isInstance = isInstance && 'customer_name' in value;
  isInstance = isInstance && 'product_name_translated' in value;
  isInstance = isInstance && 'participant_validities' in value;
  isInstance = isInstance && 'addon_validities' in value;

  return isInstance;
}

export function TicketByCodeReadResponseTicketFromJSON(json: any): TicketByCodeReadResponseTicket {
  return TicketByCodeReadResponseTicketFromJSONTyped(json, false);
}

export function TicketByCodeReadResponseTicketFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): TicketByCodeReadResponseTicket {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    booking_id: json['booking_id'],
    booking_contains_other_tickets: json['booking_contains_other_tickets'],
    code: json['code'],
    booking_status: json['booking_status'],
    ticket_status: json['ticket_status'],
    customer_name: json['customer_name'],
    product_name_translated: json['product_name_translated'],
    participant_validities: (json['participant_validities'] as Array<any>).map(
      TicketByCodeReadResponseTicketParticipantValiditiesInnerFromJSON
    ),
    addon_validities: (json['addon_validities'] as Array<any>).map(
      TicketByCodeReadResponseTicketAddonValiditiesInnerFromJSON
    )
  };
}

export function TicketByCodeReadResponseTicketToJSON(
  value?: TicketByCodeReadResponseTicket | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    booking_id: value.booking_id,
    booking_contains_other_tickets: value.booking_contains_other_tickets,
    code: value.code,
    booking_status: value.booking_status,
    ticket_status: value.ticket_status,
    customer_name: value.customer_name,
    product_name_translated: value.product_name_translated,
    participant_validities: (value.participant_validities as Array<any>).map(
      TicketByCodeReadResponseTicketParticipantValiditiesInnerToJSON
    ),
    addon_validities: (value.addon_validities as Array<any>).map(
      TicketByCodeReadResponseTicketAddonValiditiesInnerToJSON
    )
  };
}
