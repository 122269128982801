import { ROUTE_NAMES } from '@/constants/routes';
import { PAGE_TITLES } from '@/constants/breadcrumbs';
import { InitializationReadResponsePermissionsEnum } from '@openapi/models';

export const CHANNELS_ROUTES = [
  {
    path: `/sell/${ROUTE_NAMES.CHANNELS}`,
    name: ROUTE_NAMES.CHANNELS,
    component: () => import('@/views/pages/sell/channel/Channels.vue'),
    redirect: { path: `/sell/${ROUTE_NAMES.CHANNELS}/${ROUTE_NAMES.CONNECTIONS}` },
    meta: {
      breadcrumbs: [PAGE_TITLES.SELL, PAGE_TITLES.CHANNELS],
      label: PAGE_TITLES.CHANNELS,
      menu: ['main'],
      permissions: [InitializationReadResponsePermissionsEnum.ChannelView],
      icon: 'pi pi-fw pi-external-link'
    },
    children: [
      {
        path: ROUTE_NAMES.CONNECTIONS,
        name: ROUTE_NAMES.CONNECTIONS,
        component: () => import('@/views/pages/sell/channel/Connections.vue'),
        meta: {
          breadcrumbs: [PAGE_TITLES.SELL, PAGE_TITLES.CHANNELS, PAGE_TITLES.CONNECTIONS]
        }
      },
      {
        path: `${ROUTE_NAMES.CONNECTIONS}/:channelId`,
        name: ROUTE_NAMES.EDIT_CONNECTION,
        component: () => import('@/views/pages/sell/channel/ChannelEdit.vue'),
        meta: {
          breadcrumbs: [
            PAGE_TITLES.SELL,
            PAGE_TITLES.CHANNELS,
            PAGE_TITLES.CONNECTIONS,
            PAGE_TITLES.EDIT_CONNECTION
          ]
        }
      },
      {
        path: ROUTE_NAMES.ALLOCATIONS,
        name: ROUTE_NAMES.ALLOCATIONS,
        component: () => import('@/views/pages/sell/channel/Allocations.vue'),
        meta: {
          breadcrumbs: [PAGE_TITLES.SELL, PAGE_TITLES.CHANNELS, PAGE_TITLES.ALLOCATIONS]
        }
      }
    ]
  }
];
