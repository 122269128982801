/* tslint:disable */
/* eslint-disable */
/**
 * Ticketing Portal
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { OrdersReadResponseOrdersInnerAddonsAddonsInner } from './OrdersReadResponseOrdersInnerAddonsAddonsInner';
import {
  OrdersReadResponseOrdersInnerAddonsAddonsInnerFromJSON,
  OrdersReadResponseOrdersInnerAddonsAddonsInnerFromJSONTyped,
  OrdersReadResponseOrdersInnerAddonsAddonsInnerToJSON
} from './OrdersReadResponseOrdersInnerAddonsAddonsInner';

/**
 *
 * @export
 * @interface OrdersReadResponseOrdersInnerAddons
 */
export interface OrdersReadResponseOrdersInnerAddons {
  /**
   *
   * @type {number}
   * @memberof OrdersReadResponseOrdersInnerAddons
   */
  total_addons: number;
  /**
   *
   * @type {Array<OrdersReadResponseOrdersInnerAddonsAddonsInner>}
   * @memberof OrdersReadResponseOrdersInnerAddons
   */
  addons: Array<OrdersReadResponseOrdersInnerAddonsAddonsInner>;
}

/**
 * Check if a given object implements the OrdersReadResponseOrdersInnerAddons interface.
 */
export function instanceOfOrdersReadResponseOrdersInnerAddons(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && 'total_addons' in value;
  isInstance = isInstance && 'addons' in value;

  return isInstance;
}

export function OrdersReadResponseOrdersInnerAddonsFromJSON(
  json: any
): OrdersReadResponseOrdersInnerAddons {
  return OrdersReadResponseOrdersInnerAddonsFromJSONTyped(json, false);
}

export function OrdersReadResponseOrdersInnerAddonsFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): OrdersReadResponseOrdersInnerAddons {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    total_addons: json['total_addons'],
    addons: (json['addons'] as Array<any>).map(
      OrdersReadResponseOrdersInnerAddonsAddonsInnerFromJSON
    )
  };
}

export function OrdersReadResponseOrdersInnerAddonsToJSON(
  value?: OrdersReadResponseOrdersInnerAddons | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    total_addons: value.total_addons,
    addons: (value.addons as Array<any>).map(OrdersReadResponseOrdersInnerAddonsAddonsInnerToJSON)
  };
}
