import i18n, { fetchPortalTranslations } from '@/i18n';
import { defineStore } from 'pinia';
import { useLocaleStore } from './locales';
import posthog from 'posthog-js';
import * as Sentry from '@sentry/vue';
import { DEFAULT_LOCALE } from '@/constants/i18n';
import { authApi } from '@/service/TicketingApi';
import { useCurrencyStore } from '@/stores/currencies';
import { isGygAdminEmail } from '@/utils/is-gyg-staff';
import type { UserSettingsUpdateRequest } from '@openapi/models';
import usePermissions from '@/composables/use-permissions';

export type UserSettings = Omit<UserSettingsUpdateRequest, 'locale_code'>;

interface UserStoreState {
  id: string;
  first_name: string;
  last_name: string;
  email: string;
  language: string;
  role: string;
  impersonatePartner: string | undefined | null;
}

export const useUserStore = defineStore('UserStore', {
  state: (): UserStoreState => {
    return {
      id: '',
      first_name: '',
      last_name: '',
      email: '',
      language: '',
      role: '',
      impersonatePartner: null
    };
  },

  getters: {
    getUser: (state: UserStoreState) => {
      return state;
    },
    getName: (state: UserStoreState) => {
      return `${state.first_name} ${state.last_name}`;
    },
    getFirstName: (state: UserStoreState) => {
      return state.first_name;
    },
    getLastName: (state: UserStoreState) => {
      return state.last_name;
    },
    getLanguage: (state: UserStoreState) => {
      return state.language || DEFAULT_LOCALE;
    },
    getImpersonatePartnerName: (state: UserStoreState) => {
      return state.impersonatePartner;
    },
    isGygAdminUser: (state: UserStoreState) => {
      return isGygAdminEmail(state.email);
    }
  },

  actions: {
    setImpersonatePartnerName(name: string | null) {
      this.impersonatePartner = name;
    },
    async fetchUser() {
      // Call initialization endpoint
      const { setPermissions } = usePermissions();
      const { setLocales, setSelected: setSelectedLocale, getSelectedLocale } = useLocaleStore();
      const { setSelected: setPartnerCurrency, setNumFractionDigits } = useCurrencyStore();
      const { user, partner, locales, permissions } = await authApi.authInitializationGet({
        localeCode: getSelectedLocale
      });

      setPermissions(permissions);
      setLocales(locales);
      setPartnerCurrency(partner.currency.code);
      setSelectedLocale(user.locale_code);
      setNumFractionDigits(partner.currency.num_fraction_digits);
      await fetchPortalTranslations();

      this.id = user.id;
      this.first_name = user.first_name;
      this.last_name = user.last_name;
      this.email = user.email;
      this.language = user.locale_code;
      this.role = user.role;

      // Set i18n locale for vee-validate
      i18n.global.locale.value = user.locale_code;

      posthog.setPersonPropertiesForFlags({ email: user.email });
      posthog.identify(
        user.id,
        {
          first_name: user.first_name,
          last_name: user.last_name,
          language: user.locale_code,
          email: user.email,
          role: user.role
        },
        {}
      );
      if (user) {
        Sentry.setUser({ id: user.id, first_name: user.first_name, last_name: user.last_name });
      }
    },
    async updateUserSettings(userSettings: UserSettings) {
      const { getSelectedLocale } = useLocaleStore();
      const authUserSettingsPostRequest = {
        userSettingsUpdateRequest: {
          first_name: userSettings.first_name,
          last_name: userSettings.last_name,
          language: userSettings.language,
          locale_code: getSelectedLocale
        }
      };
      const response = await authApi.authUserSettingsPost(authUserSettingsPostRequest);
      if (!response.success) {
        throw new Error('Error updating user settings');
      }

      await this.fetchUser();
      return response;
    }
  }
});
