/* tslint:disable */
/* eslint-disable */
/**
 * Ticketing Portal
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface ResetPasswordUpdateRequest
 */
export interface ResetPasswordUpdateRequest {
  /**
   *
   * @type {string}
   * @memberof ResetPasswordUpdateRequest
   */
  locale_code: string;
  /**
   *
   * @type {string}
   * @memberof ResetPasswordUpdateRequest
   */
  email: string;
  /**
   *
   * @type {string}
   * @memberof ResetPasswordUpdateRequest
   */
  url: string;
}

/**
 * Check if a given object implements the ResetPasswordUpdateRequest interface.
 */
export function instanceOfResetPasswordUpdateRequest(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && 'locale_code' in value;
  isInstance = isInstance && 'email' in value;
  isInstance = isInstance && 'url' in value;

  return isInstance;
}

export function ResetPasswordUpdateRequestFromJSON(json: any): ResetPasswordUpdateRequest {
  return ResetPasswordUpdateRequestFromJSONTyped(json, false);
}

export function ResetPasswordUpdateRequestFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): ResetPasswordUpdateRequest {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    locale_code: json['locale_code'],
    email: json['email'],
    url: json['url']
  };
}

export function ResetPasswordUpdateRequestToJSON(value?: ResetPasswordUpdateRequest | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    locale_code: value.locale_code,
    email: value.email,
    url: value.url
  };
}
